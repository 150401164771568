import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ROUTES } from 'components/App/App';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/img/owl3.png';
import ProfileBlock from './ProfileBlock';

const AdminHeader: React.FC = observer(() => {
  const { t } = useTranslation();
  return (
    <Navbar bg='dark' expand='lg' variant='dark'>
      <Container>
        <Navbar.Brand>
          <img src={logo} style={{ height: 50, margin: '-20px 0 -12px 0' }} alt='' />
        </Navbar.Brand>
        <Navbar.Brand href={ROUTES.home}>{t('global.admin')}</Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            <Nav.Link as={Link} to={ROUTES.projects}>
              {t('projects.mainTitle')}
            </Nav.Link>
            <Nav.Link as={Link} to={ROUTES.users}>
              {t('user.users')}
            </Nav.Link>
          </Nav>
          <Nav className='justify-content-end'>
            <ProfileBlock />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
});

export default AdminHeader;
