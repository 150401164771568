import BaseStore from './BaseStore';

export type TCreatePersonReturn = {
  person_id?: number;
  person_name: string;
  person_title: string;
  person_description: string;
  person_notice: string;
  person_person: number[];
  location_id: number;
  prompt: string;
  location?: string;
  links?: string;
  text?: string;
  image?: string;
  aiImgId?: number;
  imageAutoAdd?: boolean;
  added?: boolean;
};
class GeneratedPersonsStore extends BaseStore {
  idKey = 'aiImgId';
  get data(): TCreatePersonReturn[] {
    return this._data as TCreatePersonReturn[];
  }
}
export default GeneratedPersonsStore;
