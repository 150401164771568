/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/esm/Card';
import { Button, Spinner } from 'react-bootstrap';
import { Image, Pencil, Plus, Trash } from 'react-bootstrap-icons';
import { TCreateLocationReturn } from 'stores/GeneratedLocationsStore';
import { useStores } from 'services/useStore';
import { TCreateNewLocationSetup } from 'services/Api';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import styles from './style.module.scss';
import LocationShow from './LocationShow';

export interface IGenerateLocationPreset {
  location: TCreateLocationReturn;
  autoGenerateImage?: boolean;
}

const GenerateLocationPreset: React.FC<IGenerateLocationPreset> = observer(
  ({ location, autoGenerateImage = false }) => {
    const { t } = useTranslation();
    const history = useNavigate();
    const { api, sessionStore, generatedLocationsStore } = useStores();
    const [showSpiner, setShowSpiner] = useState<boolean>(false);
    const [buttonsMode, setButtonsMode] = useState(0);
    const [locationData, setLocationData] = useState<TCreateLocationReturn>();
    const getUrl = (img: string, type: string = 'generation') =>
      `url('${api.settings.url.server}${type}/${img}?${Math.round(Math.random() * 10000)}')`;
    // eslint-disable-next-line no-param-reassign
    //location.image = '83.png';
    const imgCheck = async (image: string) => {
      const result = await api.checkGenerateImage(image);
      if (result && locationData) {
        locationData.image = image;
        generatedLocationsStore.update(locationData);
        setButtonsMode(0);
      } else setTimeout(() => imgCheck(image), 3000);
    };
    const createImage = async () => {
      setButtonsMode(1);
      const prompt = location.prompt.replace('Draw ', '');
      if (!locationData) return;
      locationData.image = 'loading';
      generatedLocationsStore.update(locationData);
      const result = await api.generateImage(
        prompt,
        'location',
        location.aiImgId || 0,
        'painting',
        'portrait',
        600,
        800
      );
      console.log(result);
      setTimeout(() => imgCheck(result.img), 3000);
    };
    const create = async (editAfterCreate: boolean) => {
      if (!locationData) return;
      setShowSpiner(true);
      setButtonsMode(2);
      console.log(locationData);
      const setup: TCreateNewLocationSetup = {
        ...locationData,
        project_id: sessionStore.session.user_project,
        stage: 2,
      };
      setup.aiImgId = locationData.image ? locationData.aiImgId : 0;
      setup.generatedText = locationData.text;
      console.log(setup);
      const result = await api.generateLocation(setup);
      console.log(result);
      setShowSpiner(false);
      locationData.added = true;
      locationData.location_id = result[0].location_id;
      if (locationData.image) locationData.image = result[0].image;
      generatedLocationsStore.update(locationData);
      await api.getLocations();
      if (editAfterCreate) {
        api.toggleLocationGenerateDialog(false);
        history(ROUTES.locationEdit + '/' + result[0].location_id);
      }
    };
    const add = async () => {
      await create(false);
      setButtonsMode(3);
    };
    const edit = async () => {
      if (location.added && location.location_id) {
        api.toggleLocationGenerateDialog(false);
        history(ROUTES.locationEdit + '/' + location.location_id);
      } else await create(true);
    };
    const hide = async () => {
      generatedLocationsStore.remove(location.aiImgId || 0);
    };
    useEffect(() => {
      setLocationData(location);
      if (location.added) setButtonsMode(3);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
      if (locationData && autoGenerateImage && !locationData.image) {
        setTimeout(createImage, 0);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationData]);
    if (!locationData) return <></>;
    return (
      <Card className='mb-3'>
        <Card.Body>
          <Card.Title>{locationData.location_name}</Card.Title>

          {locationData.location_id ? (
            <Card.Subtitle className='mb-2 text-muted'>
              <LocationShow linkInDialog locationId={locationData.location_id} />
            </Card.Subtitle>
          ) : (
            ''
          )}

          <>
            {locationData.image ? (
              locationData.image === 'loading' ? (
                <div className={styles.generationLocationPresetSpiner}>
                  <Spinner style={{ margin: 'auto', scale: 50 }} variant='warning' />
                </div>
              ) : (
                <div
                  role='link'
                  className={styles.generationLocationPresetImage}
                  onClick={() =>
                    api.showImagePopup(
                      locationData.image || '',
                      locationData.location_id ? 'img' : 'generation'
                    )
                  }
                  style={{
                    backgroundImage: getUrl(
                      locationData.image,
                      locationData.location_id ? 'img' : 'generation'
                    ),
                  }}
                />
              )
            ) : (
              ''
            )}
            <div dangerouslySetInnerHTML={{ __html: locationData.location_title || '' }} />
            <span className='hr' />
            <div
              dangerouslySetInnerHTML={{
                __html: locationData.location_description || '',
              }}
            />
          </>
        </Card.Body>
        <Card.Footer style={{ textAlign: 'right' }}>
          {showSpiner ? (
            <Spinner style={{ position: 'relative', top: 6, right: 10 }} variant='warning' />
          ) : (
            ''
          )}
          {!buttonsMode ? (
            <Button variant='warning' style={{ marginBottom: 5 }} onClick={createImage}>
              <Image /> {t('generationRobot.genPicture')}
            </Button>
          ) : (
            ''
          )}{' '}
          {!buttonsMode || buttonsMode === 1 ? (
            <Button variant='success' style={{ marginBottom: 5 }} onClick={add}>
              <Plus /> {t('generationRobot.add')}
            </Button>
          ) : (
            ''
          )}{' '}
          {buttonsMode === 3 ? (
            <Button variant='primary' style={{ marginBottom: 5 }} onClick={edit}>
              <Pencil /> {t('generationRobot.edit')}
            </Button>
          ) : (
            ''
          )}{' '}
          <Button variant='danger' style={{ marginBottom: 5 }} onClick={hide}>
            <Trash /> {t('generationRobot.hide')}
          </Button>
        </Card.Footer>
      </Card>
    );
  }
);
export default GenerateLocationPreset;
