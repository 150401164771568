/* eslint-disable react/button-has-type */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GoogleLogout } from 'react-google-login';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';
import styles from '../Heplers/style.module.scss';

export interface IGoogleLogoutButton {
  clientId: string;
}

const GoogleLogoutButton: React.FC<IGoogleLogoutButton> = ({ clientId }) => {
  const { t } = useTranslation();
  const { api } = useStores();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseGoogle = () => {
    console.log('Logout');
    api.logout();
  };

  return (
    <GoogleLogout
      clientId={clientId}
      onLogoutSuccess={responseGoogle}
      render={(renderProps) => (
        <button
          onClick={renderProps.onClick}
          style={{ boxShadow: 'none' }}
          className={styles.customLogoutButton}
        >
          {t('loginForm.exit')}
        </button>
      )}
    />
  );
};
export default GoogleLogoutButton;
