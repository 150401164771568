/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Overlay, Popover } from 'react-bootstrap';
import { useStores } from 'services/useStore';
import { TPerson } from 'stores/PersonsStore';
import { Person } from 'react-bootstrap-icons';
import Ava from 'components/Heplers/Ava';
import LocationShow from 'components/Heplers/LocationShow';
import { TLocation } from 'stores/LocationsStore';
import { TUser } from 'stores/UsersStore';
import { observer } from 'mobx-react';
import { ProjectTypes } from 'stores/ProjectsStore';
import { useTranslation } from 'react-i18next';
import { DisplayTypes, GraphTypes, TGraphData, TGraphElement, TGraphLink } from './LinksGraph';
import { PersonAvatar, GraphNode, ShowLocation, UserAvatar } from './GraphElements';
import styles from './style.module.scss';

type TElement = {
  element: TGraphElement;
  x: number;
  y: number;
};

type TLinkElement = {
  source: TElement;
  target: TElement;
  type?: string;
  linkType?: string;
};

type T2D = {
  x: number;
  y: number;
};

type TElementNormalize = {
  [key: string]: number;
};

export interface IGraphFilter {
  showType: string;
  data: TGraphData;
  graphType: string;
  showLinks: boolean;
  showRelations: boolean;
  showQuests: boolean;
  onClickNode: (nodeId: number, type: string) => void;
  onClickGraph: () => void;
}

const ShowGraph: React.FC<IGraphFilter> = observer(
  ({
    showType,
    data,
    graphType,
    showLinks,
    showRelations,
    showQuests,
    onClickNode,
    onClickGraph,
  }) => {
    const { t } = useTranslation();
    const { api, personStore, locationStore, userStore, sessionStore } = useStores();
    const [height, setHeight] = useState(1);
    const [width, setWidth] = useState(1);
    const [scale, setScale] = useState(1);
    const [scaleMatrix, setScaleMatrix] = useState<any>();
    const [scaleMatrixData, setScaleMatrixData] = useState('');
    const [dragOffset, setDragOffset] = useState<T2D>();
    const [dragData, setDragData] = useState<T2D>();
    const [isDrag, setIsDrag] = useState(0);
    const [selectedIdList, setSelectedIdList] = useState<string[]>([]);
    const [selectedId, setSelectedId] = useState(0);
    const [selectedType, setSelectedType] = useState<string>(' ');

    const [overlayTarget, setOverlayTarget] = useState<{
      element: TPerson | TUser;
      type: string;
      target: any;
    }>();
    const [graph, setGraph] = useState<JSX.Element>();
    const [graphExt, setGraphExt] = useState<JSX.Element>();
    const [persons, setPersons] = useState<TElement[]>();
    const [links, setLinks] = useState<TLinkElement[]>();
    const ref = useRef(null);
    const svg = useRef<any>(null);
    const elementNormalize: TElementNormalize = {};
    let timePopover: NodeJS.Timeout;

    const gradToRad = (grad: number): number => grad * (Math.PI / 180);

    const fingDataset = (event: any): any => {
      let { target } = event;
      if (target.nodeName === 'svg') {
        return false;
      }
      while (target.nodeName !== 'g') {
        target = target.parentNode;
      }
      return target.dataset;
    };

    const popoverHide = () => {
      clearTimeout(timePopover);
      setOverlayTarget(undefined);
    };

    const popoverShow = (event: any) => {
      if (!elementNormalize || !persons) return;
      const dataset = fingDataset(event);
      if (dataset === false) return;
      const { type, id } = dataset;
      if (type === 'person') {
        clearTimeout(timePopover);
        timePopover = setTimeout(() => {
          setOverlayTarget({
            element: personStore.get(+id),
            target: event.target,
            type,
          });
        }, 300);
      } else if (type === 'user') {
        clearTimeout(timePopover);
        timePopover = setTimeout(() => {
          const element = userStore.get(+id);
          if (element)
            setOverlayTarget({
              element,
              target: event.target,
              type,
            });
        }, 300);
      } else popoverHide();
    };

    const onSelectElement = (elementId: number, type: string) => {
      onClickNode(elementId, type);
      //if (type !== 'person') return;
      setSelectedId(elementId);
      setSelectedType(type);
    };

    const onClickSVG = () => {
      setSelectedId(0);
      onClickGraph();
      setSelectedIdList([]);
    };

    const onClickHandle = (event: any) => {
      const dataset = fingDataset(event);
      if (dataset === false) {
        onClickSVG();
        return;
      }
      const { type, id } = dataset;
      if (type === 'person' || type === 'location' || type === 'user') onSelectElement(+id, type);
    };

    const onWheelHandle = (event: any) => {
      let newScale = event.deltaY > 0 ? 0.9 : 1.1;
      const newScaleValue = scale * newScale;
      newScale = newScaleValue < 0.05 ? 1 : newScale;
      newScale = newScaleValue > 10 ? 1 : newScale;
      //if (newScale + scale < 0) newScale = 0;
      let m = scaleMatrix;
      if (m.a === undefined) m = svg.current.createSVGMatrix().scale(1);
      const mm: T2D = {
        x: event.clientX - 14,
        y: event.clientY - 122,
      };
      m = m.translate(mm.x, mm.y).scale(newScale).translate(-mm.x, -mm.y);
      //m.e += mm.x;
      //m.f += mm.y;
      setScaleMatrix(m);
      setScale(newScaleValue);
    };

    const buildAvatar = (element: TGraphElement, x: number, y: number) => {
      if (graphType === GraphTypes.User)
        return UserAvatar({
          api,
          element,
          x,
          y,
          onMouseEnter: popoverShow,
          onMouseLeave: popoverHide,
          hide: selectedIdList.length
            ? !selectedIdList.includes(element.type + '_' + element.id)
            : false,
        });
      return PersonAvatar({
        api,
        element,
        x,
        y,
        onMouseEnter: popoverShow,
        onMouseLeave: popoverHide,
        hide: selectedIdList.length
          ? !selectedIdList.includes(element.type + '_' + element.id)
          : false,
      });
    };

    const builNode = (source: TElement, target: TElement, type?: string, linkType?: string) => {
      let showLink = selectedIdList.length
        ? source?.element.id === selectedId || target?.element.id === selectedId
        : true;
      if (showLink && !showLinks && type && type === 'link') showLink = false;
      if (showLink && !showRelations && type && (type === 'blacklist' || type === 'whitelist')) {
        showLink = false;
      }
      if (showLink && !showQuests && type && type === 'quest') showLink = false;
      return GraphNode({
        api,
        source,
        target,
        hide: !showLink,
        graphType,
        type,
        linkType,
      });
    };
    const calpulateSpiral = (
      nodes: TGraphElement[],
      centerX: number,
      centerY: number
    ): TElement[] => {
      const el: TElement[] = [];
      let l = 100;
      let a = 10;
      const id = 6;
      let i = 360 / id;
      let c = 1;
      let x;
      let y;
      [...nodes]
        .sort((aEl: TGraphElement, bEl: TGraphElement) => {
          if (aEl.sorter > bEl.sorter) return -1;
          return aEl.sorter < bEl.sorter ? 1 : 0;
        })
        .forEach((element: TGraphElement, index: number) => {
          elementNormalize[element.type + '_' + element.id] = index;
          x = Math.sin(gradToRad(a)) * l + centerX;
          y = Math.cos(gradToRad(a)) * l + centerY;
          el.push({
            element,
            x,
            y,
          });
          a += i;
          if (a >= 360) {
            c += 1;
            a -= 360;
            l += 100;
            i = 360 / (id + c * 4);
          }
        });
      return el;
    };

    const updateLinks = () => {
      if (!selectedId) {
        setSelectedIdList([]);
        return;
      }
      const sel: TElementNormalize = {
        [selectedType + '_' + selectedId]: 1,
      };
      links?.forEach((link: TLinkElement) => {
        if (link.source?.element.id !== selectedId && link.target?.element.id !== selectedId)
          return;
        if (!showLinks && link?.type === 'link') return;
        if (!showRelations && (link?.type === 'blackList' || link?.type === 'whiteList')) return;
        if (!showQuests && link?.type === 'quest') return;
        if (!link.source) return;
        sel[link.source.element.type + '_' + link.source.element.id] = 1;
        if (!link.target) return;
        sel[link.target.element.type + '_' + link.target.element.id] = 1;
      });
      const list = Object.keys(sel);
      setSelectedIdList(list);
    };

    const updateGraph = () => {
      if (!data.nodes.length) {
        setGraph(<g key='emain' />);
        return;
      }
      data.nodes.forEach((element, index) => {
        elementNormalize[element.type + '_' + element.id] = index;
      });

      data.links.forEach((element: TGraphLink) => {
        const sourceIndex = elementNormalize[element.source + ''];
        const targetIndex = elementNormalize[element.target + ''];
        // eslint-disable-next-line no-param-reassign
        if (data.nodes[sourceIndex]) data.nodes[sourceIndex].sorter += 1;
        // eslint-disable-next-line no-param-reassign
        if (data.nodes[targetIndex]) data.nodes[targetIndex].sorter += 1;
      });

      let el: TElement[] = [];
      const hw = width / 2;
      const hh = height / 2;
      if (showType === DisplayTypes.Links) {
        el = calpulateSpiral(
          data.nodes.filter((node: TGraphElement) => node.type === graphType),
          width / 2,
          height / 2
        );
        setGraphExt(<></>);
      } else if (showType === DisplayTypes.Location) {
        const l = locationStore._data.length * 120; //Math.min(width / 2, height / 2);
        const ad = 360 / locationStore._data.length;
        let a = 0;
        const graphExtData: any[] = [];
        el = calpulateSpiral(
          data.nodes.filter(
            (node: TGraphElement) => node.location_id === 0 && node.type === graphType
          ),
          hw,
          hh
        );
        locationStore._data.forEach((location: TLocation) => {
          const x = Math.sin(gradToRad(a)) * l + hw;
          const y = Math.cos(gradToRad(a)) * l + hh;
          a += ad;
          graphExtData.push(ShowLocation({ api, location, x, y, r: 350 }));
          el = [
            ...el,
            ...calpulateSpiral(
              data.nodes.filter(
                (node: TGraphElement) =>
                  node.location_id === location.location_id && node.type === graphType
              ),
              x,
              y
            ),
          ];
        });
        el.forEach((element: TElement, index) => {
          elementNormalize[element.element.type + '_' + element.element.id] = index;
        });
        setGraphExt(<g>{graphExtData}</g>);
      }
      const no: TLinkElement[] = [];
      data.links.forEach((element: TGraphLink) => {
        no.push({
          source: el[elementNormalize[element.source + '']],
          target: el[elementNormalize[element.target + '']],
          type: element?.type,
          linkType: element?.linkType,
        });
      });
      setLinks(no);
      setPersons(el);
    };
    useEffect(() => {
      if (persons)
        setGraph(
          <g key='main'>
            {graphExt}
            {links
              ? links.map((e: TLinkElement) => builNode(e.source, e.target, e?.type, e?.linkType))
              : ''}
            {persons.map((e: TElement) => buildAvatar(e.element, e.x, e.y))}
          </g>
        );
      else setGraph(<g key='emain' />);
      if (!scaleMatrix?.a) setScaleMatrix(svg.current.createSVGMatrix().scale(1));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [persons, selectedIdList, links, graphExt, showLinks, showRelations, showQuests]);

    useEffect(() => {
      updateLinks();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [persons, links, graphExt, selectedId, selectedType, showLinks, showRelations, showQuests]);

    useEffect(() => {
      setHeight(window.screen.availHeight - 250);
      setWidth(window.screen.availWidth - 50);
      updateLinks();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      updateGraph();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, showType]);

    useEffect(() => {
      if (!scaleMatrix) return;
      setScaleMatrixData(
        `matrix(${scaleMatrix.a},${scaleMatrix.b},${scaleMatrix.c},${scaleMatrix.d},${scaleMatrix.e},${scaleMatrix.f})`
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scaleMatrix]);

    const startDrag = (event: any) => {
      setDragOffset({
        x: (event.clientX - scaleMatrix.e) / scaleMatrix.a - (dragData?.x || 0),
        y: (event.clientY - scaleMatrix.f) / scaleMatrix.d - (dragData?.y || 0),
      });
      setIsDrag(1);
    };
    const endDrag = (event: any) => {
      if (isDrag !== 2) onClickHandle(event);
      setIsDrag(0);
    };
    const drag = (event: any) => {
      if (!isDrag || !dragOffset) return;
      const point: T2D = {
        x: (event.clientX - scaleMatrix.e) / scaleMatrix.a - dragOffset.x,
        y: (event.clientY - scaleMatrix.f) / scaleMatrix.d - dragOffset.y,
      };
      setDragData(point);
      setScaleMatrix(scaleMatrix.translate(point.x, point.y));
      setIsDrag(2);
    };

    return (
      <div className={styles.graphCanva} style={{ height }} ref={ref}>
        <svg
          ref={svg}
          className='map'
          height={height}
          width={width}
          onWheel={onWheelHandle}
          onMouseDown={startDrag}
          onMouseMove={drag}
          onMouseUp={endDrag}
          onMouseLeave={() => setIsDrag(0)}
        >
          <defs>
            <clipPath id='circleAvatar'>
              <circle cx='0' cy='0' r='25' />
            </clipPath>
            <clipPath id='circle350'>
              <circle cx='0' cy='0' r='350' />
            </clipPath>
          </defs>
          <g key='scaleContainer' style={{ transform: scaleMatrixData }}>
            {graph}
          </g>
        </svg>
        <Overlay
          show={overlayTarget !== undefined}
          target={overlayTarget?.target}
          placement='bottom'
          container={ref}
          containerPadding={20}
        >
          <Popover id='popover-contained'>
            {overlayTarget?.type === 'person' ? (
              <>
                <Popover.Header as='h3'>
                  <Person /> {(overlayTarget?.element as TPerson).person_name}
                </Popover.Header>
                <Popover.Body>
                  <div>
                    {(overlayTarget?.element as TPerson).user_image ? (
                      <Ava size={240} img={(overlayTarget?.element as TPerson).user_image} />
                    ) : (
                      <Ava
                        size={240}
                        img_id={overlayTarget?.element.img_id}
                        img_mime={overlayTarget?.element.img_mime}
                      />
                    )}
                  </div>
                  {(overlayTarget?.element as TPerson).person_title ? (
                    <div>{(overlayTarget?.element as TPerson).person_title}</div>
                  ) : (
                    <div>&nbsp;</div>
                  )}
                  {(overlayTarget?.element as TPerson).location_id ? (
                    <LocationShow
                      style={{ textAlign: 'right' }}
                      locationId={(overlayTarget?.element as TPerson).location_id}
                      hideEmpty
                      hideTitle
                    />
                  ) : (
                    <div>&nbsp;</div>
                  )}
                  {sessionStore.session.project_type !== ProjectTypes.LORE &&
                  (overlayTarget.element as TPerson).person_virtual ? (
                    <Badge bg='dark'>{t('global.virtual')}</Badge>
                  ) : (
                    ''
                  )}
                </Popover.Body>
              </>
            ) : (
              ''
            )}
            {overlayTarget?.type === 'user' ? (
              <>
                <Popover.Header as='h3'>
                  <Person /> {(overlayTarget?.element as TUser).user_name}
                </Popover.Header>
                {(overlayTarget?.element as TUser).img_id ? (
                  <Popover.Body>
                    <div>
                      <Ava
                        size={240}
                        img_id={(overlayTarget?.element as TUser).img_id}
                        img_mime={(overlayTarget?.element as TUser).img_mime}
                      />
                    </div>
                    <div>&nbsp;</div>
                  </Popover.Body>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
          </Popover>
        </Overlay>
      </div>
    );
  }
);
export default ShowGraph;
