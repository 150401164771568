import { observer } from 'mobx-react';
import React from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useStores } from 'services/useStore';
import RequestInfo from './RequestInfo';
import BotRequestInfo from './BotRequestInfo';

const Requests: React.FC = observer(() => {
  const requestId = useParams().params || 0;
  const { api } = useStores();

  return (
    <Container style={{ marginTop: 54, background: api.isBot() ? 'transparent' : 'white' }}>
      {api.isBot() ? (
        <BotRequestInfo isWindow={false} requestId={+requestId} />
      ) : (
        <RequestInfo requestId={+requestId} />
      )}
    </Container>
  );
});
export default Requests;
