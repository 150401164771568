import BaseStore from './BaseStore';

export type TTextFolder = {
  project_id: number;
  folder_id: number;
  folder_name: string;
  folder_parent: number;
  text_count?: number;
  folders_count?: number;
  level?: number;
};

export type TTextFolderTreeItem = {
  folder: TTextFolder;
  childs: TTextFolderTreeItem[];
};

class TextFoldersStore extends BaseStore {
  idKey = 'folder_id';
  get data(): TTextFolder[] {
    return this._data as TTextFolder[];
  }

  getFoldersByParent = (folderParent: number): TTextFolderTreeItem[] => {
    const result: TTextFolderTreeItem[] = [];
    if (!this._data.length) return result;
    this._data.forEach((folder: TTextFolder) => {
      if (folder.folder_parent !== folderParent) return;
      result.push({
        folder,
        childs: this.getFoldersByParent(folder.folder_id),
      });
    });
    return result;
  };
}
export default TextFoldersStore;
