/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import { Person } from 'react-bootstrap-icons';
import { useStores } from 'services/useStore';

export type TAva = {
  img_id?: number;
  img_mime?: string;
  img?: string;
  size: number;
  float?: boolean;
  width?: number | string;
  height?: number | string;
  className?: string;
  id?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMouseOver?: (event: any) => void;
  onMouseOut?: () => void;
};

const Ava: React.FC<TAva> = ({
  size,
  img_id,
  img_mime,
  img,
  float = true,
  width,
  height,
  className,
  id,
  onMouseOver,
  onMouseOut,
}) => {
  const { api } = useStores();
  const style = {
    minWidth: width || size,
    height: height || size,
    backgroundImage: '',
    fontSize: size,
    float: float ? 'left' : 'none',
  } as React.CSSProperties;
  if (img) style.backgroundImage = `url(${api.settings.url.server}img/${img})`;
  else if (img_id)
    style.backgroundImage = `url(${api.settings.url.server}img/${img_id}.${img_mime})`;
  return (
    <div
      className={className || 'ava_image'}
      style={style}
      data-id={id}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {!img_id && !img ? <Person /> : ''}
    </div>
  );
};
export default Ava;
