/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import { Card } from 'react-bootstrap';
import { useStores } from 'services/useStore';

export type TBotCard = {
  id: number;
  className?: string;
  onClick: (id: number) => void;
  img?: string | Promise<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  noParalax?: boolean;
  style?: React.CSSProperties;
};

const BotCard: React.FC<TBotCard> = ({
  className,
  id,
  onClick,
  img,
  children,
  noParalax = false,
  style = {},
}) => {
  const { api } = useStores();
  const currentId = +id;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handler = (event: React.MouseEvent, clickId: number) => {
    event.preventDefault();
    event.stopPropagation();
    if (onClick) onClick(clickId);
    return false;
  };
  return (
    <Card
      className={className || 'bot-list'}
      onClick={(event) => handler(event, currentId)}
      style={style}
    >
      {img ? (
        <div
          className={`image ${noParalax ? 'noparalax' : 'paralax'}`}
          style={{
            backgroundImage: `url(${api.settings.url.server}img/${img})`,
          }}
        />
      ) : (
        <></>
      )}
      <div className='description'>{children}</div>
    </Card>
  );
};
export default BotCard;
