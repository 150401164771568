/* eslint-disable react/button-has-type */
import React from 'react';
import { Telegram } from 'react-bootstrap-icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';

export interface ITelegramCheckButton {
  code: string;
}

const TelegramCheckButton: React.FC<ITelegramCheckButton> = ({ code }) => {
  const { t } = useTranslation();
  const openBot = () => {
    window.open('https://t.me/LARPHelperBot', '_blank');
  };

  return (
    <button onClick={openBot} className='customLoginButton'>
      <div className={code === '' ? 'customLoginButtonActive' : ''}>
        <Telegram fontSize={20} color='#0d6efd' />
      </div>{' '}
      {t(code !== '' ? 'profileForm.telegramCode' : 'profileForm.telegramConnected')}
      {code ? <span>{code}</span> : ''}
    </button>
  );
};
export default TelegramCheckButton;
