import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Container, ListGroup, Modal, Row } from 'react-bootstrap';
import { Link45deg, Pencil, TrashFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { TPersonLink } from 'stores/LinkStore';
import { TPerson } from 'stores/PersonsStore';
import { useStores } from '../../services/useStore';
import PersonLinkEdit from '../Dialogs/PersonLinkEdit';
import PersonShow from './PersonShow';

export interface IPersonLinkList {
  id: number;
  muteLoadFromAPI?: boolean;
  autogenerate?: boolean;
  linkInDialog?: boolean;
}

const PersonLinkList: React.FC<IPersonLinkList> = observer(
  ({ id, muteLoadFromAPI, autogenerate = false, linkInDialog = false }) => {
    const { t } = useTranslation();
    const { personLinks, api, personStore, sessionStore } = useStores();
    const currentPerson: TPerson = personStore.get(id);
    const isManager = api.isManager(currentPerson.project_id) && api.projectIsActive();
    const { data } = personLinks;
    const [linkData, setLinkData] = useState<TPersonLink[]>();
    const [deleteConfirmVisible, showDeleteConfirm] = useState(false);
    const [editModalVisible, showEditModal] = useState(false);
    const [inited, setInit] = useState(false);
    const [waitNumber, setWaitNumber] = useState(0);
    const [waitEditNumber, setWaitEditNumber] = useState(0);
    const updateLinkData = () => {
      let persons = personLinks.get({ fromId: id, type: 'person' });
      if (
        !api.isManager(currentPerson.project_id) &&
        currentPerson.user_id !== sessionStore.session.user_id
      ) {
        persons = persons.filter((person: TPersonLink) => {
          /// не показываем игрокам лишние связи
          if (person.type === 'hide' && !api.isManager(currentPerson.project_id)) return false;
          if (person.type === 'clone') return false;
          return true;
        });
      }
      setLinkData(persons);
      setInit(true);
    };
    if (!muteLoadFromAPI) {
      useEffect(() => {
        setInit(false);
        api.getLinks('person', id).then((linkInfo) => {
          if (linkInfo) personLinks.add(linkInfo, id);
          updateLinkData();
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);
    } else
      useEffect(() => {
        updateLinkData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [data]);
    const add = () => {
      setWaitEditNumber(0);
      showEditModal(true);
    };
    const edit = (toId: number) => {
      setWaitEditNumber(toId);
      showEditModal(true);
    };
    const remove = () => {
      if (!waitNumber) return;
      showDeleteConfirm(false);
      const reverse = linkData?.find((i) => i.fromId === waitNumber);
      api
        .removeLink(
          {
            fromId: reverse ? waitNumber : id,
            toId: reverse ? id : waitNumber,
            type: 'person',
            subType: 'person',
          },
          personLinks
        )
        .then(() => {
          updateLinkData();
          setWaitNumber(0);
          api.reloader();
        });
    };

    const removePromt = (toId: number) => {
      setWaitNumber(toId);
      showDeleteConfirm(true);
    };

    const removePromtClose = () => {
      setWaitNumber(0);
      showDeleteConfirm(false);
    };

    const saveHandler = async (toId: number, description: string, status: string, type: string) => {
      const reverse = linkData?.find((i) => i.fromId === toId);
      const saveData = reverse
        ? {
            fromId: toId,
            toId: id,
            description,
            nameFrom: personStore.get(toId).person_name,
            nameTo: currentPerson.person_name,
            status,
            type,
          }
        : {
            fromId: id,
            toId,
            description,
            nameFrom: currentPerson.person_name,
            nameTo: personStore.get(toId).person_name,
            status,
            type,
          };
      personLinks.add(
        {
          person: [saveData],
        },
        saveData.fromId
      );
      setWaitEditNumber(0);
      await api.setPersonLink(saveData);
      showEditModal(false);
      updateLinkData();
      setTimeout(api.reloader, 0);
    };

    const itemVatiant = (linkType: string | undefined): string | undefined => {
      switch (linkType) {
        case 'hide':
          return 'warning';
        case 'clone':
          return 'info';
        default:
          return '';
      }
    };

    const isDraft = (item: TPersonLink): string => {
      if (currentPerson.person_id === item.fromId && item.draftTo) return ' draftEl';
      if (currentPerson.person_id === item.toId && item.draftFrom) return ' draftEl';
      /*if (currentPerson?.person_draft) {
        if (item.draftTo) return ' draftEl';
        return '';
      }
      if (item.draftFrom || item.draftTo) return ' draftEl';*/
      return '';
    };

    useEffect(() => {
      updateLinkData();
    }, [personLinks.data]);

    if (!inited) return <></>;

    return (
      <>
        <ListGroup>
          <ListGroup.Item
            variant='success'
            key='head'
            as='li'
            className='d-flex justify-content-between align-items-start'
          >
            <div className='me-auto'>
              <div className='fw-bold'>
                <h6 style={{ marginBottom: 0 }}>
                  <Link45deg />
                  &nbsp;{t('person.personLinks')} [{linkData?.length}]
                </h6>
              </div>
            </div>
            {!autogenerate && api.projectIsActive() ? (
              <Button
                variant='success'
                onClick={() => add()}
                size='sm'
                type='button'
                style={{ width: 32 }}
              >
                +
              </Button>
            ) : (
              ''
            )}
          </ListGroup.Item>
          {linkData?.length ? (
            linkData.map((item: TPersonLink) => {
              const reverse = item.toId === id;
              return (
                <ListGroup.Item
                  key={`${item.toId}-${item.fromId}-${item.nameFrom}-${item.nameTo}`}
                  as='li'
                  variant={itemVatiant(item.type)}
                  className={'d-flex justify-content-between align-items-start ' + isDraft(item)}
                >
                  <Container fluid>
                    <Row>
                      <Col lg={12}>
                        <PersonShow
                          personId={reverse ? item.fromId : item.toId}
                          link
                          showUser
                          linkInDialog={linkInDialog}
                          description={
                            item.description && item.description !== 'undefined'
                              ? item.description
                              : ''
                          }
                        />
                      </Col>
                    </Row>
                  </Container>
                  {isManager ? (
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <Button
                        variant='primary'
                        onClick={() => edit(item.toId === id ? item.fromId : item.toId)}
                        size='sm'
                        type='button'
                      >
                        <Pencil />
                      </Button>{' '}
                      <Button
                        variant='danger'
                        onClick={() => removePromt(item.toId === id ? item.fromId : item.toId)}
                        size='sm'
                        type='button'
                      >
                        <TrashFill />
                      </Button>
                      {item.status && item.status !== 'undefined' ? (
                        <Badge style={{ marginTop: '0.25rem', display: 'block' }} bg='secondary'>
                          {item.status}
                        </Badge>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </ListGroup.Item>
              );
            })
          ) : (
            <ListGroup.Item
              key='empty'
              as='li'
              className='d-flex justify-content-between align-items-start'
            >
              &nbsp;
            </ListGroup.Item>
          )}
        </ListGroup>
        <Modal show={deleteConfirmVisible} key='deleteModal' onHide={removePromtClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t('global.deleteLinkHeader')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t('global.deleteLinkPrompt')}</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={removePromtClose}>
              {t('global.close')}
            </Button>
            <Button variant='danger' onClick={remove}>
              {t('global.delete')}
            </Button>
          </Modal.Footer>
        </Modal>
        <PersonLinkEdit
          id={id}
          showModal={editModalVisible}
          toId={waitEditNumber}
          closeEditModal={() => showEditModal(false)}
          saveEditModal={saveHandler}
        />
      </>
    );
  }
);
export default PersonLinkList;
