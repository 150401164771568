/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ROUTES } from 'components/App/App';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { useStores } from '../../services/useStore';
import Ava from './Ava';

export interface IElementShow {
  id: number | undefined;
  link: string;
  rout?: string;
  title: string;
  img_id?: number | undefined;
  img_mime?: string | undefined;
  img?: string | undefined;
  size?: number;
  linkInDialog?: boolean;
}

const ElementShow: React.FC<IElementShow> = observer(
  ({ id, link, rout, title, img_id, img_mime, img, size = 50 }) => {
    const { api } = useStores();
    const clickHandler = () => {
      if (id) api.showDialog(link, id);
    };

    if (id) {
      const content = (
        <div style={{ display: 'flex', alignItems: 'center' }} role="link" onClick={clickHandler}>
          {img || img_id ? <Ava size={size} img={img} img_id={img_id} img_mime={img_mime} /> : ''}
          {'  '}
          <div>
            <b>{title}</b>
          </div>
        </div>
      );
      return rout ? <Link to={ROUTES[rout] + '/' + id}>{content}</Link> : content;
    }

    return <></>;
  }
);
export default ElementShow;
