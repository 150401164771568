/* eslint-disable no-nested-ternary */
import { ROUTES } from 'components/App/App';
import CarouselList from 'components/Heplers/CarouselList';
import UserList from 'components/Heplers/UserList';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Alert, Badge, Button, Col, Container, Row } from 'react-bootstrap';
import { Eye, EyeSlash, FileEarmarkText, LockFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { TLocation } from 'stores/LocationsStore';
import { TPerson } from 'stores/PersonsStore';
import { ProjectTypes, TProject, TProjectLARP, TProjectSettings } from 'stores/ProjectsStore';
import moment from 'moment';
import BotCard from 'components/Heplers/BotCard';
import { TText } from 'stores/TextsStore';
import { useStores } from '../../services/useStore';

const Projects: React.FC = observer(() => {
  const history = useNavigate();
  const projectId = +(useParams().params || 0);
  const { t } = useTranslation();
  const { api, locationStore, personStore, sessionStore, projectStore } = useStores();
  const [inited, setInited] = useState(false);
  const [project, setProject] = useState<TProject>();
  const [imgList, setImgList] = useState<string[]>();
  const [locationList, setLocationList] = useState<TLocation[]>();
  const subscribe = () => {
    api.subscribeToProject(projectId).then(() => {
      const newData = { ...project, status: 'subscriber' };
      projectStore.update(newData);
    });
  };

  const unSubscribe = () => {
    api.unSubscribeToProject(projectId).then(() => {
      const newData = { ...project, status: '' };
      projectStore.update(newData);
    });
  };

  useEffect(() => {
    setProject(projectStore.get(projectId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectStore.data]);

  useEffect(() => {
    if (projectId) {
      api.updateProjectData(+projectId).then(async () => {
        const pr = projectStore.get(+projectId);
        if (!pr?.project_open) {
          const access = await api.checkUserToProject(+projectId);
          if (access === 'deny') {
            history(ROUTES.projects);
            return;
          }
        }
        sessionStore.tgHeader(pr.project_name);
        api.currentProject(projectId);

        if (pr.project_images) setImgList(pr.project_images);
        else setImgList([]);

        setLocationList(
          locationStore
            .getForProject(projectId)
            .filter((location: TLocation) => !location.location_virtual)
        );
        setInited(true);
      });
    }
    api.closeDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const personsInLocation = (location_id: number) =>
    personStore
      .getForProject(projectId)
      .filter((person: TPerson) => person.location_id === location_id && !person.person_virtual);

  useEffect(() => {
    if (!inited) return;
    if (personsInLocation(0).length) {
      if (locationList?.length) {
        setLocationList([
          ...locationList,
          { location_id: 0, location_name: t('location.free'), location_title: t('location.free') },
        ]);
      }
    }
    api.useParalax();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, inited]);

  const selectPerson = (person?: number) => {
    if (!projectId) return;
    if (person) window.sessionStorage.person = person;
    api.selectProject(+projectId, () => {
      window.location.href = ROUTES.requestEdit;
    });
  };

  const clickHandler = (type: string, id: number) => {
    api.showDialog(type, id);
  };

  if (!project || !inited) return <div>{t('global.loading')}</div>;
  const userInProject = (
    <>
      {project.user_person?.length ? (
        <Row className='justify-content-md-center mb-5' key='inProject'>
          <Col lg={12}>
            <Alert variant='success'>
              <h2>{t('project.inProject')}</h2>
            </Alert>
            <UserList list={project.user_person} />
          </Col>
        </Row>
      ) : (
        ''
      )}
      {project.user_request?.length ? (
        <Row className='justify-content-md-center mb-5' key='withRequest'>
          <Col lg={12}>
            <Alert variant='success'>
              <h2>{t('project.withRequest')}</h2>
            </Alert>
            <UserList list={project.user_request} />
          </Col>
        </Row>
      ) : (
        ''
      )}
    </>
  );
  return (
    <Container style={{ marginTop: 14 }} className='cont'>
      <Container
        fluid
        style={{ paddingTop: 24, paddingBottom: 24 }}
        className='verticalPadding greenForm projectInfo'
      >
        {imgList?.length ? (
          <Row className='justify-content-md-center' style={{ marginBottom: 14 }}>
            <Col xs lg={12}>
              <CarouselList imgList={imgList} />
            </Col>
          </Row>
        ) : (
          ''
        )}
        <Row className='justify-content-md'>
          <Col>
            <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Col>
                <h2 style={{ width: 'auto' }}>{project.project_name}</h2>
              </Col>

              {sessionStore.session.user_id &&
              project.project_type === ProjectTypes.LARP &&
              (project as TProjectLARP).project_open === 2 &&
              (project.project_settings as TProjectSettings)?.freQuenta ? (
                <Col className='rightText' lg={4} style={{ width: 'auto' }}>
                  <Button variant='primary' size='sm' onClick={() => selectPerson()}>
                    <FileEarmarkText />
                    {'  '}
                    {t('request.sendRequest')}
                  </Button>
                </Col>
              ) : (
                ''
              )}
            </Row>
            <Row className='justify-content-md-center'>
              <Col xs lg={6} className='text-muted'>
                {project.project_type === ProjectTypes.LARP
                  ? t(
                      (project as TProjectLARP).project_open === 1
                        ? 'projects.registerNotStart'
                        : (project as TProjectLARP).project_open === 2
                        ? 'projects.waitForRequests'
                        : 'projects.anounce'
                    )
                  : ''}
              </Col>
              {sessionStore.session.user_id ? (
                <Col xs className='rightText' lg={6}>
                  {project.status === 'subscriber' ? (
                    <Button size='sm' variant='warning' onClick={unSubscribe}>
                      <EyeSlash /> {t('news.unSubscrube')}
                    </Button>
                  ) : !project.status ? (
                    <Button size='sm' variant='info' onClick={subscribe}>
                      <Eye /> {t('news.subscrube')}
                    </Button>
                  ) : (
                    ''
                  )}
                </Col>
              ) : (
                <Col xs lg={6} />
              )}
            </Row>
            {project.project_type === ProjectTypes.LARP &&
            (project as TProjectLARP).project_start ? (
              <Row className='mt-3'>
                <Col style={{ fontWeight: 'bold', marginBottom: 20, width: '100%' }}>
                  {moment((project as TProjectLARP).project_start).format('DD.MM.YY')} -{' '}
                  {moment((project as TProjectLARP).project_end).format('DD.MM.YY')}{' '}
                  <span className='text-muted'>
                    {moment() > moment((project as TProjectLARP).project_start)
                      ? moment() > moment((project as TProjectLARP).project_end)
                        ? t('projects.ended')
                        : t('projects.inProgress')
                      : t('projects.dayBefore', {
                          end: api.dateDiff((project as TProjectLARP).project_start),
                        })}
                  </span>
                </Col>
              </Row>
            ) : (
              ''
            )}
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col xs lg={12}>
            <p>{project.project_title}</p>
            {project.project_type === ProjectTypes.LARP ? (
              <p>{(project as TProjectLARP).project_description}</p>
            ) : (
              ''
            )}
          </Col>
        </Row>
        <Row className='mt-5'>
          <Col>
            <Alert variant='success'>
              <h2>{t('text.mainTitle')}</h2>
            </Alert>
            {project.texts
              ? project.texts.map((text: TText) => (
                  <BotCard
                    id={text.text_id || 0}
                    key={text.text_id}
                    img={text.text_images?.length ? text.text_images[0] : ''}
                    onClick={(id: number) => api.showDialog('text', id)}
                  >
                    <div className='name'>{text.title}</div>
                  </BotCard>
                ))
              : ''}
          </Col>
        </Row>
        {!sessionStore.session.user_id ? (
          <Row className='justify-content-md-center mt-3'>
            <Col xs lg={12}>
              <Alert variant='warning'>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <h5>{t('project.loginAlert')}</h5>

                  <div className='rightText'>
                    <Button variant='warning' onClick={() => api.toggleLoginDialog(true)}>
                      <b>{t('projects.registerForRequest')}</b>
                    </Button>
                  </div>
                </div>
              </Alert>
            </Col>
          </Row>
        ) : (
          ''
        )}
        {locationList?.length ? (
          <Row className='justify-content-md-center mb-5 mt-5'>
            <Col lg={12}>
              <Alert variant='success'>
                <h2>{t('project.locationsAndPlayers')}</h2>
              </Alert>
              {locationList
                .filter((location: TLocation) => !location.location_draft)
                .map((location: TLocation) => (
                  <div key={location.location_id} className='locationBlock'>
                    <button
                      type='button'
                      className='invisibleButton'
                      onClick={() =>
                        location.location_id && clickHandler('location', location.location_id)
                      }
                    >
                      {location.img_id ? (
                        <div
                          style={{
                            backgroundImage: `url(${api.settings.url.server}img/${location.img_id}.${location.img_mime})`,
                          }}
                          className='locationImage'
                        >
                          <h1>{location.location_name}</h1>
                        </div>
                      ) : (
                        <h1>{location.location_name}</h1>
                      )}
                      <p className='pt-1'>{api.cut(location.location_title, 50)}</p>
                    </button>
                    <div style={{ clear: 'both' }} />
                    {personsInLocation(location.location_id || 0).map((person: TPerson) => (
                      <BotCard
                        id={person.person_id || 0}
                        key={person.person_id}
                        img={person.img_id ? `${person.img_id}.${person.img_mime}` : ''}
                        onClick={(id: number) => api.showDialog('person', id)}
                      >
                        <div className='name'>{person.person_name}</div>
                        <div className='info'>{person.person_title}</div>
                        <div className='buttons'>
                          {project.project_type === ProjectTypes.LARP &&
                          (project as TProjectLARP).project_open === 2 &&
                          sessionStore.session.user_id ? (
                            <>
                              {person.user_id === 0 ? (
                                <Button
                                  variant='success'
                                  size='sm'
                                  onClick={() => selectPerson(person.person_id)}
                                >
                                  <FileEarmarkText />
                                  {'  '}
                                  {t('request.orderPerson2')}
                                </Button>
                              ) : (
                                <>
                                  <Badge bg='danger'>
                                    <LockFill /> {t('global.bysyRole')}
                                  </Badge>
                                </>
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </BotCard>
                    ))}
                  </div>
                ))}
            </Col>
          </Row>
        ) : (
          ''
        )}
        {project.project_type === ProjectTypes.LARP && (project as TProjectLARP)?.project_open > 1
          ? userInProject
          : ''}
      </Container>
    </Container>
  );
});
export default Projects;
