import BaseStore from './BaseStore';
import { TStatus } from './StatusesStore';
import { TText } from './TextsStore';

export type TPerson = {
  person_id?: number;
  project_id?: number;
  project_name?: string;
  location_id?: number;
  location_name?: string;
  person_name: string;
  person_title: string;
  person_type?: string;
  person_description?: string;
  person_master?: number;
  user_id?: number;
  user_name?: string;
  user_image?: string;
  person_notice?: string;
  person_status?: string;
  person_statuses?: number[];
  person_images?: string[];
  person_draft?: number;
  person_virtual?: number;
  img_id?: number;
  img_mime?: string;
  request_count?: number;
  texts?: TText[];
  statuses?: TStatus[];
  statuses_list?: string;
  createNews?: number;
};
class PersonsStore extends BaseStore {
  idKey = 'person_id';
  get data(): TPerson[] {
    return this._data as TPerson[];
  }
  remove = (id: number): void => {
    super.remove(id);
    const index = this.rootStore.generatedPersonsStore._data.findIndex(
      (element) => element.person_id === id
    );
    if (index !== -1) this.rootStore.generatedPersonsStore._data.splice(index, 1);
  };
  getForProject = (project_id: number) =>
    this._data.filter(
      (person: TPerson) => person.project_id === project_id && !person.person_draft
    );
}
export default PersonsStore;
