import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { FloatingLabel, Form, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TUserSearch } from 'services/Api';
import { useStores } from '../../services/useStore';
import Ava from './Ava';

export interface IUserFind {
  action: (value: number) => void;
  placeholder?: string;
  exeptions?: number[];
}

const UserFind: React.FC<IUserFind> = observer(({ action, placeholder, exeptions }) => {
  const { t } = useTranslation();
  const { api } = useStores();
  const [name, setName] = useState<string>('');
  const [line, setLine] = useState<number>(-1);
  const [finded, setFinded] = useState<TUserSearch[]>([]);
  const inputSize = api.isBot() ? 'sm' : 'lg';

  const search = (value: string) => {
    setName(value);
    if (value.length > 2) {
      api.searchUser(value).then((list: TUserSearch[]) => {
        const userList = exeptions
          ? list.filter((listElement: TUserSearch) => !exeptions.includes(listElement.id))
          : list;
        setLine(-1);
        setFinded(userList);
        console.log(userList);
      });
    } else setFinded([]);
  };

  const onSelect = (id: number) => {
    setFinded([]);
    setName('');
    action(id);
  };

  const onkeydown = (event: React.KeyboardEvent) => {
    const currentLine = +line;
    if (event.code === 'ArrowUp' && currentLine > 0) setLine(currentLine - 1);
    else if (event.code === 'ArrowDown' && currentLine < finded.length - 1)
      setLine(currentLine + 1);
    else if (event.code === 'Enter') {
      if (currentLine > -1) onSelect(finded[currentLine].id);
      else if (finded.length === 1) onSelect(finded[0].id);
      event.stopPropagation();
      event.preventDefault();
    }
  };

  return (
    <div className='searchUser'>
      <FloatingLabel controlId='searchUser' label={placeholder || t('profileForm.startTypeName')}>
        <Form.Control
          type='text'
          size={inputSize}
          value={name}
          onChange={(e) => search(e.target.value)}
          onKeyDown={(e) => onkeydown(e)}
          placeholder={placeholder || t('profileForm.startTypeName')}
        />
      </FloatingLabel>
      {finded.length ? (
        <ListGroup className='searchUserList'>
          {finded.map((user: TUserSearch, index: number) => (
            <ListGroup.Item
              variant={index === line ? 'primary' : ''}
              key={user.id}
              action
              onClick={() => onSelect(user.id)}
            >
              <Ava img={user.image} size={50} /> {user.name}
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        ''
      )}
    </div>
  );
});
export default UserFind;
