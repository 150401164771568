import { ROUTES } from 'components/App/App';
import { observer } from 'mobx-react';
import React from 'react';
import { Badge, Button, ListGroup, Offcanvas } from 'react-bootstrap';
import { ClipboardCheckFill, Coin, Pencil, Plus, Robot } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useStores } from 'services/useStore';
import { ProjectTypes } from 'stores/ProjectsStore';

const FastActions: React.FC = observer(() => {
  const { t } = useTranslation();
  const stores = useStores();
  const history = useNavigate();
  const canAI = stores.api.canTextAi();
  const hide = () => {
    stores.api.toggleFastActions(false);
  };
  const go = (url: string) => {
    hide();
    history(url);
  };
  if (!stores.api.projectIsActive()) return <></>;
  return (
    <Offcanvas show={stores.sessionStore.appSettings.fastAction} onHide={hide}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{t('global.fastActions')}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ListGroup as='ol'>
          <ListGroup.Item
            as='li'
            key='project'
            className='d-flex justify-content-between align-items-start'
          >
            <Button
              style={{ marginRight: 10, padding: '5px 5px' }}
              variant='primary'
              onClick={() =>
                go(ROUTES.projectEditLARP + '/' + stores.sessionStore.session.user_project)
              }
              size='sm'
            >
              <Pencil fontSize={30} />
            </Button>
            {canAI ? <div style={{ width: 54 }} /> : ''}
            <div className='me-auto fw-bold'>{t('projectForm.edit')}</div>
          </ListGroup.Item>

          <ListGroup.Item
            as='li'
            key='person'
            className='d-flex justify-content-between align-items-start'
          >
            <Button
              style={{ marginRight: 10, padding: '5px 5px' }}
              variant='success'
              onClick={() => go(ROUTES.personEdit)}
              size='sm'
            >
              <Plus fontSize={30} />
            </Button>
            {canAI ? (
              <Button
                style={{ marginRight: 10, padding: '5px 5px' }}
                variant='warning'
                onClick={() => {
                  hide();
                  stores.api.togglePersonGenerateDialog(true);
                }}
                size='sm'
              >
                <Robot fontSize={30} />
              </Button>
            ) : (
              ''
            )}
            <div className='me-auto fw-bold'>{t('person.mainTitle')}</div>
            <Badge bg='primary' pill>
              {stores.personStore._data.length}
            </Badge>
          </ListGroup.Item>

          <ListGroup.Item
            as='li'
            key='location'
            className='d-flex justify-content-between align-items-start'
          >
            <Button
              style={{ marginRight: 10, padding: '5px 5px' }}
              variant='success'
              onClick={() => go(ROUTES.locationEdit)}
              size='sm'
            >
              <Plus fontSize={30} />
            </Button>
            {canAI ? (
              <Button
                style={{ marginRight: 10, padding: '5px 5px' }}
                variant='warning'
                onClick={() => {
                  hide();
                  stores.api.toggleLocationGenerateDialog(true);
                }}
                size='sm'
              >
                <Robot fontSize={30} />
              </Button>
            ) : (
              ''
            )}
            <div className='me-auto fw-bold'>{t('location.mainTitle')}</div>
            <Badge bg='primary' pill>
              {stores.locationStore._data.length}
            </Badge>
          </ListGroup.Item>

          <ListGroup.Item
            as='li'
            key='quest'
            className='d-flex justify-content-between align-items-start'
          >
            <Button
              style={{ marginRight: 10, padding: '5px 5px' }}
              variant='success'
              onClick={() => go(ROUTES.questEdit)}
              size='sm'
            >
              <Plus fontSize={30} />
            </Button>
            {canAI ? (
              <Button
                style={{ marginRight: 10, padding: '5px 5px' }}
                variant='warning'
                onClick={() => {
                  hide();
                  stores.api.toggleQuestGenerateDialog(true);
                }}
                size='sm'
              >
                <Robot fontSize={30} />
              </Button>
            ) : (
              ''
            )}
            <div className='me-auto fw-bold'>{t('quest.mainTitle')}</div>
            {stores.questStore._data ? (
              <Badge bg='primary' pill>
                {stores.questStore._data.length}
              </Badge>
            ) : (
              ''
            )}
          </ListGroup.Item>

          <ListGroup.Item
            as='li'
            key='text'
            className='d-flex justify-content-between align-items-start'
          >
            <Button
              style={{ marginRight: 10, padding: '5px 5px' }}
              variant='success'
              onClick={() => go(ROUTES.textEdit)}
              size='sm'
            >
              <Plus fontSize={30} />
            </Button>
            {canAI ? (
              <Button
                style={{ marginRight: 10, padding: '5px 5px' }}
                variant='warning'
                onClick={() => {
                  hide();
                  stores.api.toggleTextGenerateDialog(true);
                }}
                size='sm'
              >
                <Robot fontSize={30} />
              </Button>
            ) : (
              ''
            )}
            <div className='me-auto fw-bold'>{t('text.mainTitle')}</div>
            {stores.textStore._data ? (
              <Badge bg='primary' pill>
                {stores.textStore._data.length}
              </Badge>
            ) : (
              ''
            )}
          </ListGroup.Item>

          <ListGroup.Item
            as='li'
            key='status'
            className='d-flex justify-content-between align-items-start'
          >
            <Button
              style={{ marginRight: 10, padding: '5px 5px' }}
              variant='success'
              onClick={() => go(ROUTES.statusEdit)}
              size='sm'
            >
              <Plus fontSize={30} />
            </Button>
            {canAI ? <div style={{ width: 54 }} /> : ''}
            <div className='me-auto fw-bold'>{t('status.mainTitle')}</div>
            <Badge bg='primary' pill>
              {stores.statusStore._data.length}
            </Badge>
          </ListGroup.Item>

          <ListGroup.Item
            as='li'
            key='stuff'
            className='d-flex justify-content-between align-items-start'
          >
            <Button
              style={{ marginRight: 10, padding: '5px 5px' }}
              variant='success'
              onClick={() => go(ROUTES.stuffEdit)}
              size='sm'
            >
              <Plus fontSize={30} />
            </Button>
            {canAI ? <div style={{ width: 54 }} /> : ''}
            <div className='me-auto fw-bold'>{t('stuff.mainTitle')}</div>
            {stores.stuffStore._data ? (
              <Badge bg='primary' pill>
                {stores.stuffStore._data.length}
              </Badge>
            ) : (
              ''
            )}
          </ListGroup.Item>
          {stores.sessionStore.session.project_type === ProjectTypes.LARP ? (
            <ListGroup.Item
              as='li'
              key='request'
              className='d-flex justify-content-between align-items-start'
            >
              <Button
                style={{ marginRight: 10, padding: '5px 5px' }}
                variant='success'
                onClick={() => go(ROUTES.requestEdit)}
                size='sm'
              >
                <Plus fontSize={30} />
              </Button>
              {canAI ? <div style={{ width: 54 }} /> : ''}
              <div className='me-auto fw-bold'>{t('request.mainTitle')}</div>
              <Badge bg='primary' pill>
                {stores.requestStore._data.length}
              </Badge>
            </ListGroup.Item>
          ) : (
            ''
          )}
        </ListGroup>

        {stores.sessionStore.session.project_type === ProjectTypes.LARP ? (
          <ListGroup as='ol' className='mt-5'>
            <ListGroup.Item
              as='li'
              key='project'
              className='d-flex justify-content-between align-items-start'
            >
              <Button
                style={{ marginRight: 10, padding: '5px 5px' }}
                size='sm'
                onClick={() => go(ROUTES.taskEdit)}
              >
                <ClipboardCheckFill fontSize={30} />{' '}
              </Button>
              <div style={{ width: 54 }} />
              <div className='me-auto fw-bold'>{t('task.newTask')}</div>
              <Badge pill>{stores.taskStore.data?.length}</Badge>
            </ListGroup.Item>
          </ListGroup>
        ) : (
          ''
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
});
export default FastActions;
