import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useStores } from 'services/useStore';
import { Card, Form, ListGroup, ListGroupItem } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { TLocation } from 'stores/LocationsStore';
import { TStatus } from 'stores/StatusesStore';
import { observer } from 'mobx-react';
import { GraphTypes, TGraphData, TGraphElement, TGraphLink } from './LinksGraph';

export interface IGraphFilter {
  fullData: TGraphData;
  setGraphData: Dispatch<SetStateAction<TGraphData>>;
  showFilters: boolean;
  graphType: string;
}

const GraphFilter: React.FC<IGraphFilter> = observer(
  ({ fullData, setGraphData, showFilters, graphType }) => {
    const { t } = useTranslation();
    const { locationStore, personStore, statusStore, graphFilterStore } = useStores();
    const {
      filterType,
      filterRequest,
      filterText,
      filterLocation,
      filterStatus,
      filterVirtual,
      locations,
      statuses,
    } = graphFilterStore;
    const linkData = (link: string) => link.split('_');
    const useFilters = () => {
      const filteredData: TGraphData = {
        nodes: [],
        links: [],
        nodesNormalize: {},
      };

      if (filterType) {
        filteredData.nodes = [...fullData.nodes].filter((node: TGraphElement) => {
          const found = !![...fullData.links].find(
            (link: TGraphLink) =>
              node.type + '_' + node.id === link.source || node.type + '_' + node.id === link.target
          );
          return filterType === 1 ? found : !found;
        });
      } else {
        filteredData.nodes = [...fullData.nodes];
      }

      if (filterVirtual) {
        filteredData.nodes = [...filteredData.nodes].filter((node: TGraphElement) => {
          if (filterVirtual === 1 && node.isVirtual === 1) return true;
          if (filterVirtual === 2 && node.isVirtual === 0) return true;
          return false;
        });
      }
      if (filterRequest) {
        filteredData.nodes = [...filteredData.nodes].filter((node: TGraphElement) => {
          if (filterRequest < 3)
            return filterRequest === 1 ? node.requests?.length : !node.requests?.length;
          return filterRequest === 4 ? node.user && node.user !== 0 : node.user === 0 || !node.user;
        });
      }

      if (filterText) {
        filteredData.nodes = [...filteredData.nodes].filter(
          (node: TGraphElement) =>
            node.name && node.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1
        );
      }

      if (filterLocation.length) {
        filteredData.nodes = [...filteredData.nodes].filter((node: TGraphElement) =>
          filterLocation.includes(personStore.get(+node.id)?.location_id)
        );
      }

      if (filterStatus.length) {
        filteredData.nodes = [...filteredData.nodes].filter(
          (node: TGraphElement) =>
            filterStatus.find(
              (status: number) =>
                node.statuses?.includes(status) || (status === 0 && node.statuses?.length === 0)
            ) !== undefined
        );
      }

      filteredData.links = [...fullData.links].filter(
        (link: TGraphLink) =>
          !!filteredData.nodes.find((node: TGraphElement) => {
            const [type, id] = linkData(link.source);
            return node.id === +id && node.type === type;
          }) &&
          !!filteredData.nodes.find((node: TGraphElement) => {
            const [type, id] = linkData(link.target);
            return node.id === +id && node.type === type;
          })
      );
      //setData(filteredData);
      setGraphData(filteredData);
    };

    const addFilterLocation = (location: number) => {
      graphFilterStore.setFilterLocation([location, ...filterLocation]);
    };

    const removeFilterLocation = (location: number) => {
      const index = filterLocation.findIndex((l) => l === location);
      const newFilterLocation = [...filterLocation];
      newFilterLocation.splice(index, 1);
      graphFilterStore.setFilterLocation(newFilterLocation);
    };

    const addFilterStatus = (status: number) => {
      graphFilterStore.setFilterStatus([status, ...filterStatus]);
    };

    const removeFilterStatus = (status: number) => {
      const index = filterStatus.findIndex((l) => l === status);
      const newFilterStatus = [...filterStatus];
      newFilterStatus.splice(index, 1);
      graphFilterStore.setFilterStatus(newFilterStatus);
    };

    useEffect(() => {
      setTimeout(() => {
        graphFilterStore.setLocations(locationStore._data);
        graphFilterStore.setStatuses(statusStore._data);
      }, 100);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useFilters();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      filterVirtual,
      filterType,
      fullData,
      filterText,
      filterLocation,
      filterStatus,
      filterRequest,
    ]);

    return (
      <>
        {showFilters ? (
          <Card>
            <Card.Header as='h5'>
              <Search /> {t('graph.searchFilter')}
            </Card.Header>
            <ListGroup className='list-group-flush '>
              <ListGroupItem key='virtual'>
                <Form.Check
                  type='radio'
                  id='custom-switch'
                  key='all'
                  name='virtual'
                  label={t('graph.search.all')}
                  checked={!filterVirtual}
                  onChange={() => graphFilterStore.setVirtualFilter(0)}
                />
                <Form.Check
                  type='radio'
                  id='custom-switch'
                  key='onlyVirtual'
                  name='virtual'
                  label={t('graph.search.onlyVitual')}
                  checked={filterVirtual === 1}
                  onChange={() => graphFilterStore.setVirtualFilter(1)}
                />
                <Form.Check
                  type='radio'
                  id='custom-switch'
                  key='notVirtual'
                  name='virtual'
                  checked={filterVirtual === 2}
                  label={t('graph.search.onlyReal')}
                  onChange={() => graphFilterStore.setVirtualFilter(2)}
                />
              </ListGroupItem>
              <ListGroupItem key='type'>
                <Form.Check
                  type='radio'
                  id='custom-switch'
                  key='all'
                  name='type'
                  label={t('graph.search.all')}
                  checked={!filterType}
                  onChange={() => graphFilterStore.setFilterType(0)}
                />
                <Form.Check
                  type='radio'
                  id='custom-switch'
                  key='notEmpty'
                  name='type'
                  checked={filterType === 1}
                  label={t('graph.search.notEmpty')}
                  onChange={() => graphFilterStore.setFilterType(1)}
                />
                <Form.Check
                  type='radio'
                  id='custom-switch'
                  key='onlyEmpty'
                  name='type'
                  label={t('graph.search.onlyEmpty')}
                  checked={filterType === 2}
                  onChange={() => graphFilterStore.setFilterType(2)}
                />
              </ListGroupItem>
              {graphType === GraphTypes.Persons ? (
                <ListGroupItem key='request'>
                  <Form.Check
                    type='radio'
                    id='custom-switch'
                    key='all'
                    name='request'
                    label={t('graph.search.all')}
                    checked={!filterRequest}
                    onChange={() => graphFilterStore.setFilterRequest(0)}
                  />
                  <Form.Check
                    type='radio'
                    id='custom-switch'
                    key='noRequest'
                    name='request'
                    label={t('graph.search.noRequest')}
                    checked={filterRequest === 2}
                    onChange={() => graphFilterStore.setFilterRequest(2)}
                  />
                  <Form.Check
                    type='radio'
                    id='custom-switch'
                    key='request'
                    name='request'
                    checked={filterRequest === 1}
                    label={t('graph.search.withRequest')}
                    onChange={() => graphFilterStore.setFilterRequest(1)}
                  />
                  <Form.Check
                    type='radio'
                    id='custom-switch'
                    key='noUser'
                    name='request'
                    label={t('graph.search.noUser')}
                    checked={filterRequest === 3}
                    onChange={() => graphFilterStore.setFilterRequest(3)}
                  />
                  <Form.Check
                    type='radio'
                    id='custom-switch'
                    key='user'
                    name='request'
                    checked={filterRequest === 4}
                    label={t('graph.search.withUser')}
                    onChange={() => graphFilterStore.setFilterRequest(4)}
                  />
                </ListGroupItem>
              ) : (
                ''
              )}
              {graphType === GraphTypes.Persons ? (
                <ListGroupItem key='location'>
                  {locations
                    ? locations.map((location: TLocation) => (
                        <Form.Check
                          type='checkbox'
                          id='custom-switch'
                          key={location.location_id}
                          name='type'
                          label={location.location_name}
                          checked={filterLocation?.includes(location.location_id || 0)}
                          onChange={(e) =>
                            e.target.checked
                              ? addFilterLocation(location.location_id || 0)
                              : removeFilterLocation(location.location_id || 0)
                          }
                        />
                      ))
                    : ''}
                  <Form.Check
                    type='checkbox'
                    id='custom-switch'
                    key='all'
                    name='type'
                    label={t('graph.search.noLocation')}
                    checked={filterLocation?.includes(0)}
                    onChange={(e) =>
                      e.target.checked ? addFilterLocation(0) : removeFilterLocation(0)
                    }
                  />
                </ListGroupItem>
              ) : (
                ''
              )}
              {graphType === GraphTypes.Persons ? (
                <ListGroupItem key='status'>
                  {statuses
                    ? statuses.map((status: TStatus) => (
                        <Form.Check
                          type='checkbox'
                          id='custom-switch'
                          key={status.status_id}
                          name='type'
                          label={status.status_name}
                          checked={filterStatus?.includes(status.status_id || 0)}
                          onChange={(e) =>
                            e.target.checked
                              ? addFilterStatus(status.status_id || 0)
                              : removeFilterStatus(status.status_id || 0)
                          }
                        />
                      ))
                    : ''}
                  <Form.Check
                    type='checkbox'
                    id='custom-switch'
                    key='all'
                    name='type'
                    label={t('graph.search.noStatus')}
                    checked={filterStatus?.includes(0)}
                    onChange={(e) =>
                      e.target.checked ? addFilterStatus(0) : removeFilterStatus(0)
                    }
                  />
                </ListGroupItem>
              ) : (
                ''
              )}
            </ListGroup>
          </Card>
        ) : (
          <>123</>
        )}
      </>
    );
  }
);
export default GraphFilter;
