/* eslint-disable react/no-danger */
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Accordion, Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Trash, Plus } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';
import { TLinkInfo } from 'stores/PrototypeLinkStore';
import { TPersonInfo } from 'stores/PrototypePersonStore';

const PrototypeLARPLink: React.FC = observer(() => {
  const { t } = useTranslation();
  const { api, prototypeLinkStore, prototypePersonStore } = useStores();
  const [editLinkWindowShow, setEditLinkWindowShow] = useState(false);
  const [editLink, setEditLink] = useState<TLinkInfo>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateLink = (name1: string, name2: string, event?: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (name1 && name2) {
      const link = prototypeLinkStore.get(name1, name2);
      if (link !== undefined) setEditLink({ ...link });
    } else {
      setEditLink({ description: '', name1: '', name2: '' });
    }
    setEditLinkWindowShow(true);
  };
  const saveLink = () => {
    if (editLink === undefined) return;
    if (editLinkWindowShow) {
      prototypeLinkStore.update(editLink);
    } else {
      prototypeLinkStore.add({ ...editLink });
    }
    setEditLinkWindowShow(false);
  };
  const deleteLink = (name1: string, name2: string, silent: boolean = false) => {
    if (!name1 || !name2) return;
    let confirm = silent;
    if (!confirm) confirm = api.confirm(t('projectLARPAI.deleteLinkConfirm', { name1, name2 }));
    if (confirm) prototypeLinkStore.remove(name1, name2);
  };
  const buildLink = (linkElement: TLinkInfo, index: number) => {
    return (
      <OverlayTrigger
        key={linkElement.name1 + linkElement.name2}
        placement='left'
        overlay={
          <Tooltip id={`tooltip-${linkElement.name1 + linkElement.name2}`}>
            {linkElement.description}
          </Tooltip>
        }
      >
        <Row className='link'>
          <Col onClick={(e) => updateLink(linkElement.name1, linkElement.name2, e)}>
            <b>{linkElement.name1}</b> - <b>{linkElement.name2}</b>
          </Col>
          <Col className='deleteButton'>
            <Button
              variant='danger'
              size='sm'
              type='button'
              onClick={() => deleteLink(linkElement.name1, linkElement.name2)}
            >
              <Trash fontSize={16} />
            </Button>
          </Col>
        </Row>
      </OverlayTrigger>
    );
  };
  return (
    <Accordion.Item eventKey='6'>
      <Accordion.Header>
        <Button
          variant='primary'
          size='sm'
          type='button'
          className='plusButton'
          onClick={(e) => updateLink('', '', e)}
        >
          <Plus fontSize={16} />
        </Button>
        <b>{t('projectLARPAI.links')}</b>
      </Accordion.Header>
      <Accordion.Body>{prototypeLinkStore.data.map(buildLink)}</Accordion.Body>
      <Modal
        show={editLinkWindowShow}
        size='lg'
        key='editLink'
        centered
        onHide={() => setEditLinkWindowShow(false)}
      >
        <Modal.Header closeButton>{t('projectLARPAI.link')}</Modal.Header>
        {editLink ? (
          <Modal.Body style={{ backgroundColor: '#0000001c' }}>
            {editLink.name1 && editLink.name2 && editLink.name1 !== editLink.name2 ? (
              <Row>
                <Col style={{ textAlign: 'right' }}>{editLink.name1} - </Col>
                <Col>{editLink.name2}</Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <Form.Select
                    required
                    size='sm'
                    value={editLink.name1}
                    onChange={(e) => setEditLink({ ...editLink, name1: e.target.value })}
                  >
                    {prototypePersonStore.data.map((person: TPersonInfo) => (
                      <option key={person.id} value={person.name}>
                        {person.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select
                    required
                    size='sm'
                    value={editLink.name2}
                    onChange={(e) => setEditLink({ ...editLink, name2: e.target.value })}
                  >
                    {prototypePersonStore.data.map((person: TPersonInfo) => (
                      <option key={person.id} value={person.name}>
                        {person.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            )}
            <Row className='mt-3'>
              <Col>
                <h5>{t('projectLARPAI.fullDescription')}:</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  as='textarea'
                  rows={5}
                  size='sm'
                  value={editLink.description}
                  onChange={(e) => setEditLink({ ...editLink, description: e.target.value })}
                  style={{ height: '100px' }}
                />
              </Col>
            </Row>
            <Row>
              <Col className='right-text mt-3' style={{ textAlign: 'right' }}>
                <Button variant='success' size='sm' type='button' onClick={() => saveLink()}>
                  {t('projectLARPAI.save')}
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        ) : (
          ''
        )}
      </Modal>
    </Accordion.Item>
  );
});
export default PrototypeLARPLink;
