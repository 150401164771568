import { observer } from 'mobx-react';
import React from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import LocationInfo from './LocationInfo';

const Locations: React.FC = observer(() => {
  const locationId = useParams().params || 0;
  return (
    <Container style={{ marginTop: 14 }}>
      <LocationInfo locationId={+locationId} />
    </Container>
  );
});
export default Locations;
