import { observer } from 'mobx-react';
import React from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import TaskInfo from './TasksInfo';

const Tasks: React.FC = observer(() => {
  const taskId = useParams().params || 0;
  return (
    <Container style={{ background: '#fff', marginTop: 14, marginBottom: 14 }}>
      <TaskInfo taskId={+taskId} />
    </Container>
  );
});
export default Tasks;
