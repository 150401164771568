/* eslint-disable react/no-danger */
import Ava from 'components/Heplers/Ava';
import PersonShow from 'components/Heplers/PersonShow';
import RelationList from 'components/Heplers/RelationList';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { EnvelopeFill, FileEarmarkPerson, Person, TelephoneFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TProfileRequests } from 'services/Api';
import { TProfile } from 'stores/UsersStore';
import { useStores } from '../../services/useStore';

export interface IUserInfo {
  userId: number | undefined;
  imageHeight?: number;
  linkInDialog?: boolean;
}

const UserInfo: React.FC<IUserInfo> = observer(({ userId, imageHeight, linkInDialog }) => {
  const { t } = useTranslation();
  const { api } = useStores();
  api.noLogin();
  const [user, setUser] = useState<TProfile>();
  const [requests, setRequests] = useState<TProfileRequests[]>();
  const [persons, setPersons] = useState<TProfileRequests[]>();
  const clickRequestHandler = (requestId: number | undefined) => {
    if (requestId) api.showDialog('request', requestId);
  };
  const tgImage = `<img src="${api.settings.url.server}img/telegram.svg"/>`;
  useEffect(() => {
    if (!userId) return;
    api.getProfileData(userId).then((data) => {
      const profile = data.profile as unknown as TProfile;
      let link = profile.user_link;
      link = (' ' + link).replace(
        /[^\w]@(\w{5,64})/gm,
        "<a href='http://t.me/$1' target='_blank'>" + tgImage + ' $1</a>'
      );
      profile.user_link = link;
      setUser(profile);
      if (data.requests) setRequests(data.requests as TProfileRequests[]);
      if (data.persons) setPersons(data.persons as TProfileRequests[]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  if (!user || !userId) return <div>{t('global.loading')}</div>;
  return (
    <Container fluid className='verticalPadding greenForm'>
      <Row className='justify-content-md-center'>
        {user.img_id ? (
          <Col xs lg={7} style={{ minWidth: 600 }}>
            <Ava
              size={400}
              width='100%'
              height={450}
              img_id={user.img_id}
              img_mime={user.img_mime}
              className='ava_image big'
            />
          </Col>
        ) : (
          ''
        )}
        <Col xs lg={10}>
          <Row>
            <Col>
              <h3>{user.user_name}</h3>
            </Col>
          </Row>
          <Row>
            <Col>{user.user_fio}</Col>
          </Row>
          <Row>
            <Col>
              <Link to={'mailto:' + user.user_email}>
                <EnvelopeFill /> {user.user_email}
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <TelephoneFill /> {user.user_tel}
            </Col>
          </Row>
          <Row>
            <Col>
              <p className='user_link' dangerouslySetInnerHTML={{ __html: user.user_link || '' }} />
            </Col>
          </Row>
          <Row>
            <Col style={{ height: 50 }} />
          </Row>
          {user.user_contact ? (
            <Row>
              <Col>
                <div className='text-muted'>{t('profileForm.contact')}</div>
                {user.user_contact}
              </Col>
            </Row>
          ) : (
            ''
          )}
          {user.user_deny ? (
            <Row>
              <Col>
                <div className='text-muted' style={{ marginTop: 16 }}>
                  {t('profileForm.deny')}
                </div>
                {user.user_deny}
              </Col>
            </Row>
          ) : (
            ''
          )}
          {user.user_medinfo ? (
            <Row>
              <Col>
                <div className='text-muted' style={{ marginTop: 16 }}>
                  {t('profileForm.medinfo')}
                </div>
                {user.user_medinfo}
              </Col>
            </Row>
          ) : (
            ''
          )}
          {user.user_blacklist ? (
            <Row>
              <Col>
                <div className='text-muted' style={{ marginTop: 16 }}>
                  {t('profileForm.blacklist')}
                </div>
                {user.user_blacklist}
              </Col>
            </Row>
          ) : (
            ''
          )}
          <br />
          <RelationList source={userId} title={t('user.relationsFromUser')} />
          <RelationList
            target={userId}
            title={t('user.relationsToUser')}
            reverse
            controll={false}
          />
          {requests?.length ? (
            <ListGroup>
              <ListGroup.Item
                variant='success'
                key='head'
                as='li'
                className='d-flex justify-content-between align-items-start'
              >
                <h6>
                  <FileEarmarkPerson />
                  &nbsp;{t('request.mainTitle')}
                </h6>
              </ListGroup.Item>
              {requests.map((request: TProfileRequests) => (
                <ListGroup.Item
                  key={request.request_id}
                  onClick={() => clickRequestHandler(request.request_id)}
                >
                  {request.person_id ? (
                    <PersonShow
                      personId={request.person_id}
                      title
                      linkInDialog={false}
                      link={false}
                    />
                  ) : (
                    request.request_name
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            ''
          )}
          {persons?.length ? (
            <ListGroup>
              <ListGroup.Item
                variant='success'
                key='head'
                as='li'
                className='d-flex justify-content-between align-items-start'
              >
                <h6>
                  <Person />
                  &nbsp;{t('person.mainTitle')}
                </h6>
              </ListGroup.Item>
              {persons.map((request: TProfileRequests) => (
                <ListGroup.Item key={request.person_id}>
                  {request.person_id ? (
                    <PersonShow personId={request.person_id} title link linkInDialog />
                  ) : (
                    request.request_name
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            ''
          )}
        </Col>
      </Row>
    </Container>
  );
});
export default UserInfo;
