/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import TopBar from 'components/Headers/TopBar';
import { observer } from 'mobx-react';
import { useStores } from 'services/useStore';
import ProfileEdit from 'components/Profile/ProfileEdit';
import Projects from 'components/Projects/Projects';
import ProjectList from 'components/Projects/ProjectList';
import ProjectListAdmin from 'components/Projects/ProjectListAdmin';
import ProjectEditLARP from 'components/Projects/ProjectEditLARP';
import ProjectEditLORE from 'components/Projects/ProjectEditLORE';
import LocationList from 'components/Locations/LocationList';
import Locations from 'components/Locations/Locations';
import LocationEdit from 'components/Locations/LocationEdit';
import StatusList from 'components/Statuses/StatusList';
import Statuses from 'components/Statuses/Statuses';
import StatusEdit from 'components/Statuses/StatusEdit';
import BotNewsList from 'components/News/BotNewsList';
import NewsList from 'components/News/NewsList';
import News from 'components/News/News';
import NewsEdit from 'components/News/NewsEdit';
import PersonList from 'components/Persons/PersonList';
import Persons from 'components/Persons/Persons';
import PersonEdit from 'components/Persons/PersonEdit';
import QuestList from 'components/Quests/QuestList';
import Quests from 'components/Quests/Quests';
import QuestEdit from 'components/Quests/QuestEdit';
import StuffList from 'components/Stuffs/StuffList';
import Stuffs from 'components/Stuffs/Stuffs';
import StuffEdit from 'components/Stuffs/StuffEdit';
import TaskList from 'components/Tasks/TaskList';
import Tasks from 'components/Tasks/Tasks';
import TaskEdit from 'components/Tasks/TaskEdit';
import TextList from 'components/Texts/TextList';
import Texts from 'components/Texts/Texts';
import TextEdit from 'components/Texts/TextEdit';
import RequestList from 'components/Requests/RequestList';
import Requests from 'components/Requests/Requests';
import RequestEdit from 'components/Requests/RequestEdit';
import UserList from 'components/Users/UserList';
import Users from 'components/Users/Users';
import LinksGraph from 'components/LinksGraph/LinksGraph';
import MainDialog from 'components/Dialogs/MainDialog';
import SearchResult from 'components/Search/SearchResult';
import ImagePopupDialog from 'components/Dialogs/ImagePopupDialog';
import GenerateTextDialog from 'components/Dialogs/GenerateTextDialog';
import GenerateLocationDialog from 'components/Dialogs/GenerateLocationDialog';
import GenerateQuestDialog from 'components/Dialogs/GenerateQuestDialog';
import FastActions from 'components/Heplers/FastActions';
import AlertDialog from 'components/Dialogs/AlertDialog';
import ProfileBilling from 'components/Profile/ProfileBilling';
import PayDialog from 'components/Dialogs/PayDialog';
import LofinFormDialog from 'components/Dialogs/LoginFormDialog';
import NoticeDialog from 'components/Dialogs/NoticeDialog';
import ReportList from 'components/Reports/ReportList';
import RequestCheckDialog from 'components/Dialogs/RequestCheckDialog';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import BugDialog from 'components/Dialogs/BugDialog';
import BotHeader from 'components/Headers/BotHeader';
import BotProjects from 'components/Projects/BotProjects';
import BotRequestList from 'components/Requests/BotRequestList';
import BotProjectList from 'components/Projects/BotProjectList';
import { TProject } from 'stores/ProjectsStore';
import RegisterPage from 'components/LoginRegistration/RegisterPage';
import AlphaVersion from 'components/Heplers/AlphaVersion';
import { UserStatus } from 'stores/SessionStore';
import ProjectNew from 'components/Projects/ProjectNew';
import LocationLOREEdit from 'components/Locations/LocationLOREEdit';
import ProjectAILARP from 'components/Projects/ProjectAILARP';
import MyPersonList from 'components/Persons/MyPersonList';
import BotMyPersonList from 'components/Persons/BotMyPersonList';
import ProfilePayments from 'components/Profile/ProfilePayments';
import TopAlert from 'components/Headers/TopAlert';
import Home from './Home';
import GeneratePersonDialog from '../Dialogs/GeneratePersonDialog';
import BotHome from './BotHome';
import TestPage from './TestPage';
import PaymentsBackPage from './PaymentsBackPage';

export enum ROUTES {
  home = '/',
  bot = '/bot',
  page404 = '/404',
  link = '/link',
  profile = '/profile',
  billing = '/billing',
  projects = '/projects',
  myProjects = '/myProjects',
  projectNew = '/projectNew',
  projectAILARP = '/projectAILARP',
  projectEditLARP = '/projectEditLARP',
  projectEditLORE = '/projectEditLORE',
  locations = '/location',
  locationEdit = '/locationEdit',
  locationLOREEdit = '/locationLOREEdit',
  statuses = '/status',
  statusEdit = '/statusEdit',
  persons = '/person',
  personEdit = '/personEdit',
  quests = '/quest',
  questEdit = '/questEdit',
  plots = '/plot',
  plotEdit = '/plotEdit',
  stuffs = '/stuff',
  stuffEdit = '/stuffEdit',
  tasks = '/task',
  taskEdit = '/taskEdit',
  texts = '/text',
  textEdit = '/textEdit',
  myPersons = '/myPersons',
  requests = '/request',
  myRequests = '/myRequest',
  requestEdit = '/requestEdit',
  links = '/links',
  users = '/user',
  userEdit = '/userEdit',
  userCreate = '/userCreate',
  search = '/search',
  reports = '/reports',
  invite = '/invite',
  news = '/news',
  newsEdit = '/newsEdit',
  registration = '/registration',
  oferta = '/oferta',
  paymentsBack = '/paymentsBack',
  payments = '/payments',
}

const App: React.FC = observer(() => {
  const { api, sessionStore, projectStore } = useStores();
  const { t } = useTranslation();
  const { access } = api;
  const [inited, setInit] = useState(false);
  const [first, setFirst] = useState(true);
  useEffect(() => {
    api.checkSession().then((result: boolean) => {
      if (!result) sessionStore.reset();
      if (first) {
        setFirst(false);
        if (api.isBot()) {
          api.telegram.tg.expand();
          if (api.isBotUser()) {
            Promise.all([
              api.getProjects(),
              /*api.getLocations(),
              api.getStatuses(),
              api.getPersons(),
              api.getRequests(),*/
            ]).then(() => {
              projectStore.data.forEach((project: TProject) => {
                projectStore.update({ ...project, loaded: false });
              });
              setInit(true);
            });
          } else {
            setInit(true);
          }
          return;
        }
        const role = sessionStore.role();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let willLoad: Promise<any>[];

        if (role === UserStatus.Admin || role === UserStatus.Owner || role === UserStatus.Manager) {
          willLoad = [
            api.getUsers(),
            api.getProjects(),
            api.getLocations(),
            api.getStatuses(),
            api.getPersons(),
            api.getRequests(),
            api.getRequests(),
            api.loadRelations(),
            api.getTextFolders(),
            api.getStuffFolders(),
          ];
        } else {
          willLoad = [
            api.getProjects(),
            api.getLocations(),
            api.getStatuses(),
            api.getPersons(),
            api.getRequests(),
          ];
        }
        Promise.all(willLoad).then(async () => {
          await api.getLocationTree();
          setTimeout(() => setInit(true), 300);
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //if (access === undefined) return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access]);

  if (!inited) {
    if (sessionStore.appSettings.isBot) {
      return (
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ margin: '50% auto', display: 'flex' }}>
            <Spinner animation='border' role='status' />
            <span className='spiner-text'>{t('loading')}</span>
          </div>
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ margin: 'auto', display: 'flex' }}>
          <Spinner animation='border' role='status' />
          <span className='spiner-text'>{t('loading')}</span>
        </div>
      </div>
    );
  }
  if (api.isBot()) {
    return (
      <>
        {api.isBotUser() ? <BotHeader /> : ''}
        {api.isBotUser() ? (
          <div style={{ marginTop: 30 }}>
            <AlphaVersion />
          </div>
        ) : (
          ''
        )}
        <Routes>
          <Route path={ROUTES.home} element={<BotHome />} />
          <Route path={`${ROUTES.profile}`} element={<ProfileEdit />} />
          <Route path={`${ROUTES.registration}`} element={<RegisterPage />} />
          <Route path={`${ROUTES.projects}`} element={<BotProjectList my={false} />} />
          <Route path={`${ROUTES.myProjects}`} element={<BotProjectList my />} />
          <Route path={`${ROUTES.projects}/:params`} element={<BotProjects />} />
          <Route path={`${ROUTES.persons}`} element={<PersonList />} />
          <Route path={`${ROUTES.persons}/:params`} element={<Persons />} />
          <Route path={`${ROUTES.myPersons}`} element={<BotMyPersonList />} />
          <Route path={`${ROUTES.texts}/:params`} element={<Texts />} />
          <Route path={`${ROUTES.billing}`} element={<ProfileBilling />} />
          <Route path={`${ROUTES.requests}`} element={<BotRequestList />} />
          <Route path={`${ROUTES.requests}/:params`} element={<Requests />} />
          <Route path={`${ROUTES.invite}/:params`} element={<Requests />} />
          <Route path={`${ROUTES.requestEdit}`} element={<RequestEdit />} />
          <Route path={`${ROUTES.requestEdit}/:params`} element={<RequestEdit />} />
          <Route path={`${ROUTES.news}`} element={<BotNewsList />} />
          <Route path={`${ROUTES.news}/:params`} element={<News />} />
          <Route path={`${ROUTES.bot}`} element={<h1>Bot!!</h1>} />
          <Route path={`${ROUTES.oferta}`} element={<Texts id={13} />} />
          <Route path='/test' element={<TestPage />} />
        </Routes>

        <MainDialog />
        <AlertDialog />
        <PayDialog />
        <BugDialog />
      </>
    );
  }
  return (
    <>
      <TopBar />
      <TopAlert />
      <Routes>
        <Route path={ROUTES.home} element={<Home />} />
        <Route path={ROUTES.link} element={<h1>Link</h1>} />
        <Route path={ROUTES.links} element={<LinksGraph />} />
        {/*Projects*/}
        <Route
          path={ROUTES.projects}
          element={api.isAdmin() ? <ProjectListAdmin /> : <ProjectList />}
        />
        <Route path={`${ROUTES.projects}/:params`} element={<Projects />} />
        <Route path={ROUTES.projectNew} element={<ProjectNew />} />
        <Route path={ROUTES.projectAILARP} element={<ProjectAILARP />} />
        <Route path={ROUTES.projectEditLARP} element={<ProjectEditLARP />} />
        <Route path={`${ROUTES.projectEditLARP}/:params`} element={<ProjectEditLARP />} />
        <Route path={ROUTES.projectEditLORE} element={<ProjectEditLORE />} />
        <Route path={`${ROUTES.projectEditLORE}/:params`} element={<ProjectEditLORE />} />
        {/*Locations*/}
        <Route path={ROUTES.locations} element={<LocationList />} />
        <Route path={`${ROUTES.locations}/:params`} element={<Locations />} />
        <Route path={ROUTES.locationEdit} element={<LocationEdit />} />
        <Route path={`${ROUTES.locationEdit}/:params`} element={<LocationEdit />} />
        <Route path={ROUTES.locationLOREEdit} element={<LocationLOREEdit />} />
        <Route path={`${ROUTES.locationLOREEdit}/:params`} element={<LocationLOREEdit />} />
        {/*Statuses*/}
        <Route path={ROUTES.statuses} element={<StatusList />} />
        <Route path={`${ROUTES.statuses}/:params`} element={<Statuses />} />
        <Route path={ROUTES.statusEdit} element={<StatusEdit />} />
        <Route path={`${ROUTES.statusEdit}/:params`} element={<StatusEdit />} />
        {/*News*/}
        <Route path={ROUTES.news} element={<NewsList />} />
        <Route path={`${ROUTES.news}/:params`} element={<News />} />
        <Route path={ROUTES.newsEdit} element={<NewsEdit />} />
        <Route path={`${ROUTES.newsEdit}/:params`} element={<NewsEdit />} />
        {/*Persons*/}
        <Route path={ROUTES.persons} element={<PersonList />} />
        <Route path={ROUTES.myPersons} element={<MyPersonList />} />
        <Route path={`${ROUTES.persons}/:params`} element={<Persons />} />
        <Route path={ROUTES.personEdit} element={<PersonEdit />} />
        <Route path={`${ROUTES.personEdit}/:params`} element={<PersonEdit />} />
        {/*Quests*/}
        <Route path={ROUTES.quests} element={<QuestList />} />
        <Route path={ROUTES.plots} element={<QuestList plot />} />
        <Route path={`${ROUTES.quests}/:params`} element={<Quests />} />
        <Route path={`${ROUTES.plots}/:params`} element={<Quests plot />} />
        <Route path={ROUTES.questEdit} element={<QuestEdit />} />
        <Route path={`${ROUTES.questEdit}/:params`} element={<QuestEdit />} />
        <Route path={ROUTES.plotEdit} element={<QuestEdit plot />} />
        <Route path={`${ROUTES.plotEdit}/:params`} element={<QuestEdit plot />} />
        {/*Stuffs*/}
        <Route path={ROUTES.stuffs} element={<StuffList />} />
        <Route path={`${ROUTES.stuffs}/:params`} element={<Stuffs />} />
        <Route path={ROUTES.stuffEdit} element={<StuffEdit />} />
        <Route path={`${ROUTES.stuffEdit}/:params`} element={<StuffEdit />} />
        {/*Tasks*/}
        <Route path={ROUTES.tasks} element={<TaskList />} />
        <Route path={`${ROUTES.tasks}/:params`} element={<Tasks />} />
        <Route path={ROUTES.taskEdit} element={<TaskEdit />} />
        <Route path={`${ROUTES.taskEdit}/:params`} element={<TaskEdit />} />
        {/*Texts*/}
        <Route path={ROUTES.texts} element={<TextList />} />
        <Route path={`${ROUTES.texts}/:params`} element={<Texts />} />
        <Route path={ROUTES.textEdit} element={<TextEdit />} />
        <Route path={`${ROUTES.textEdit}/:params`} element={<TextEdit />} />
        <Route path={ROUTES.oferta} element={<Texts id={13} />} />
        {/*Requests*/}
        <Route path={ROUTES.requests} element={<RequestList my={false} />} />
        <Route path={ROUTES.myRequests} element={<RequestList my />} />
        <Route path={`${ROUTES.requests}/:params`} element={<Requests />} />
        <Route path={ROUTES.requestEdit} element={<RequestEdit />} />
        <Route path={`${ROUTES.requestEdit}/:params`} element={<RequestEdit />} />
        {/*Users*/}
        <Route path={ROUTES.users} element={<UserList />} />
        <Route path={`${ROUTES.users}/:params`} element={<Users />} />
        <Route path={ROUTES.profile} element={<ProfileEdit />} />
        <Route path={ROUTES.registration} element={<RegisterPage />} />
        <Route path={ROUTES.billing} element={<ProfileBilling />} />
        <Route path={ROUTES.payments} element={<ProfilePayments />} />
        <Route path={`${ROUTES.userEdit}/:params`} element={<ProfileEdit />} />
        <Route path={ROUTES.userCreate} element={<ProfileEdit />} />
        {/*Reports*/}
        <Route path={ROUTES.reports} element={<ReportList />} />
        {/*Search*/}
        <Route path={ROUTES.search} element={<SearchResult />} />
        <Route path={`${ROUTES.paymentsBack}/:params`} element={<PaymentsBackPage />} />
        <Route path='/test' element={<TestPage />} />
        <Route element={<h1>404 page</h1>} />
      </Routes>
      <div style={{ height: 30 }} />
      <LofinFormDialog />
      <FastActions />
      <MainDialog />
      <GeneratePersonDialog />
      <GenerateLocationDialog />
      <GenerateQuestDialog />
      <ImagePopupDialog />
      <GenerateTextDialog />
      <AlertDialog />
      <PayDialog />
      <NoticeDialog />
      <RequestCheckDialog />
      <BugDialog />
    </>
  );
});

export default App;
