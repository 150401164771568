import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AddButton from 'components/Buttons/AddButton';
import ItemViewer from 'components/Heplers/ItemViewer';
import { TPerson } from 'stores/PersonsStore';
import UserShow from 'components/Heplers/UserShow';
import { Badge, Button } from 'react-bootstrap';
import { Person } from 'react-bootstrap-icons';
import { ProjectTypes } from 'stores/ProjectsStore';
import { useStores } from '../../services/useStore';
import AICreateButton from '../Buttons/AICreateButton';

const PersonList: React.FC = observer(() => {
  const { t } = useTranslation();
  const { sessionStore, personStore, api } = useStores();
  api.noLogin();
  let data = personStore.data.filter(
    (p: TPerson) => p.project_id === sessionStore.session.user_project
  );
  if (!data) data = [];
  const adminActions =
    api.isManager(sessionStore.session.user_project) && api.projectIsActive() ? (
      <>
        <AddButton title={t('person.newPerson')} link='personEdit' />
        &nbsp;
        <AICreateButton type='person' />
      </>
    ) : (
      ''
    );

  const calculatePreFooter = (item: TPerson) => {
    return (
      <div style={{ textAlign: 'right', paddingRight: 16, fontSize: 14, paddingTop: 5 }}>
        {item?.request_count ? (
          <Badge bg='info' style={{ color: 'black' }}>
            {t('request.requests') + ': ' + item.request_count}
          </Badge>
        ) : (
          <>&nbsp;</>
        )}
      </div>
    );
  };
  const calculateFooter = (item: TPerson) => {
    return (
      <>
        {item.user_id ? (
          <div
            style={{
              padding: '0 14px 21px',
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              minHeight: 65,
            }}
          >
            <UserShow userId={item.user_id} hideEmpty />
          </div>
        ) : (
          <div style={{ height: '70px' }} />
        )}
        {api.getProjectType() === ProjectTypes.LARP && item.person_virtual ? (
          <div className='request-badger'>{t('global.virtual')}</div>
        ) : (
          ''
        )}
      </>
    );
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const afterButton = (item: TPerson) =>
    api.isManager(item.project_id) &&
    item &&
    item.person_id &&
    item.person_virtual === 0 &&
    item.person_draft === 0 &&
    item.user_id === 0 ? (
      <>
        {' '}
        <Button
          variant='success'
          size='sm'
          onClick={() => (item.person_id ? api.toggleRolePropositionDialog(item.person_id) : {})}
        >
          <Person />
        </Button>
      </>
    ) : (
      <></>
    );
  const columns = [
    {
      dataField: 'person_name',
      text: t('personForm.name'),
      sort: true,
    },
    {
      dataField: 'person_title',
      text: t('personForm.title'),
      sort: true,
      formatter: (cell: undefined, row: TPerson) => row.person_title.substring(0, 100),
    },
    {
      dataField: 'person_type',
      text: t('personForm.type'),
      sort: true,
      formatter: (cell: undefined, row: TPerson) =>
        row.person_type ? t('personForm.types.' + row.person_type) : '',
    },
    {
      dataField: 'request_count',
      text: t('request.mainTitle'),
      sort: true,
    },
    {
      dataField: 'user_id',
      text: t('global.player'),
      sort: true,
      formatter: (cell: undefined, row: TPerson) => (
        <div style={{ overflow: 'hidden' }}>
          <UserShow userId={row.user_id} hideEmpty />
        </div>
      ),
    },
  ];
  const getStyle = (person: TPerson): React.CSSProperties => {
    if (person.user_id && person.user_id > 0) return { background: '#85ffa482' };
    if (api.getProjectType() === ProjectTypes.LARP && person.person_virtual)
      return { border: '3px solid #555555' };
    return { background: '#fff' };
  };
  return (
    <ItemViewer
      id='person_id'
      store='personStore'
      type='person'
      tableData={data}
      columns={columns}
      showRoute='persons'
      editRoute='personEdit'
      name='person_name'
      title='person_title'
      draftField='person_draft'
      statusField='person_status'
      canEdit
      mainTitle={t('person.mainTitle')}
      adminActions={adminActions}
      getStyle={getStyle}
      calculatePreFooter={calculatePreFooter}
      calculateFooter={calculateFooter}
      afterButton={afterButton}
      minWidth={350}
    />
  );
});
export default PersonList;
