/* eslint-disable react/no-danger */
import CarouselList from 'components/Heplers/CarouselList';
import LinkList from 'components/Heplers/LinkList';
import TextStatusShow from 'components/Heplers/TextStatusShow';
import UserShow from 'components/Heplers/UserShow';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TQuest } from 'stores/QuestsStore';
import { useStores } from '../../services/useStore';

export interface IQuestInfo {
  questId: number | undefined;
  imageHeight?: number;
  linkInDialog?: boolean;
}

const QuestInfo: React.FC<IQuestInfo> = observer(({ questId, imageHeight, linkInDialog }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //const plot = (routerProps as any).plot || false;
  const { api } = useStores();
  api.noLogin();
  const [quest, setQuest] = useState<TQuest>();
  const [imgList, setImgList] = useState<string[]>();

  useEffect(() => {
    if (questId) {
      api.getQuests(questId).then((data) => {
        const lo = data as TQuest;
        setQuest(lo as TQuest);
        if (lo.quest_images) setImgList(lo.quest_images);
        else setImgList([]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questId]);
  if (!quest) return <div>{t('global.loading')}</div>;
  if (!api.isManager(quest.project_id)) return <div>Access deny</div>;
  return (
    <Container fluid className='verticalPadding greenForm' style={{ paddingTop: 24 }}>
      <Row className='justify-content-md-center'>
        <Col xs lg={12}>
          {imgList?.length ? (
            <CarouselList imgList={imgList} label={quest.quest_name} height={imageHeight} />
          ) : (
            <h2>{quest.quest_name}</h2>
          )}
          <Row className='justify-content-md-center mb-3 mt-3'>
            <Col>
              <p
                className='person-notice'
                dangerouslySetInnerHTML={{ __html: quest.quest_description || '' }}
              />
              <TextStatusShow status={quest.quest_status} />
              <UserShow userId={quest.quest_master} isManager />
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={6}>
              <LinkList
                type='quest'
                subType='person'
                id={questId || 0}
                storeName='questLinks'
                controll={false}
                linkInDialog={linkInDialog}
                title={t('person.mainTitle')}
              />
            </Col>
            <Col lg={6}>
              <LinkList
                type='quest'
                subType='stuff'
                id={questId || 0}
                storeName='questLinks'
                title={t('stuff.mainTitle')}
                controll={false}
                muteLoadFromAPI
                linkInDialog={linkInDialog}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
});
export default QuestInfo;
