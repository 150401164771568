import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { ShowTypes } from 'services/Api';
import { useStores } from '../../services/useStore';
import ItemCard from './ItemCard';
import ShowTypeItem from './ShowTypeItem';
import NoContent from './NoContent';
import ItemTable from './ItemTable';

export interface IItemViewer {
  store: string;
  type: string;
  id: string;
  showRoute?: string;
  editRoute?: string;
  name?: string;
  title?: string;
  draftField?: string;
  statusField?: string;
  noProject?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableData: Array<any>;
  columns: ColumnDescription[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  canEdit?: boolean | ((data: any) => boolean);
  mainTitle: string;
  adminActions: string | JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  afterButton?(data: any): JSX.Element | undefined;
  cardHeight?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  calculateFooter?(data: any): JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  calculateImage?(data: any): string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  calculatePreFooter?(data: any): JSX.Element;
  staticShowType?: string;
  afterDelete?(id: number): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getStyle?(data: any): React.CSSProperties;
  noTop?: boolean;
  showTypeValue?: string;
  setShowTypeValue?(newValue: string): void;
  minWidth?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter?(data: any): any;
  filterMarker?: boolean | string;
}

const ItemViewer: React.FC<IItemViewer> = observer(
  ({
    store,
    type,
    id,
    tableData,
    columns,
    adminActions,
    showRoute,
    editRoute,
    mainTitle,
    name = '',
    title = '',
    draftField,
    statusField,
    noProject = false,
    canEdit = false,
    cardHeight,
    calculateFooter,
    calculatePreFooter,
    calculateImage,
    staticShowType,
    afterDelete,
    afterButton,
    getStyle,
    showTypeValue,
    setShowTypeValue,
    noTop = false,
    minWidth,
    filter,
    filterMarker,
  }) => {
    const { t } = useTranslation();
    const allStores = useStores();
    const { api, sessionStore } = allStores;
    const thisStore = allStores[store as keyof typeof allStores];
    let [showType, setShowType] = useState(
      staticShowType || (api.isBot() ? ShowTypes.Card : api.getLocalConfig('showType'))
    );
    if (setShowTypeValue) {
      showType = showTypeValue;
      setShowType = setShowTypeValue;
    }
    const [searchText, setSearchText] = useState('');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [data, setData] = useState<Array<any>>(filter && filter(tableData));
    const updateData = () => {
      if (filter) setData(filter(tableData));
      else setData(tableData);
    };
    useEffect(() => {
      api.getTexts().then(() => {
        if (showType !== ShowTypes.Card || !searchText) {
          setData(tableData);
          return;
        }
        setData(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          tableData.filter((item: any) => {
            if (item[id] === +searchText) return true;
            return !!columns.find(
              (c: ColumnDescription) =>
                (item[c.dataField] + '').toLowerCase().indexOf(searchText.toLowerCase()) > -1
            );
          })
        );
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText, showType]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useEffect(() => updateData(), [(thisStore as any).data, filterMarker]);
    return (
      <>
        {!noTop ? (
          <Container fluid className='verticalPadding greenForm'>
            <Row>
              <Col>
                <h1>{mainTitle}</h1>
              </Col>
              <Col className='rightText' style={{ marginTop: '1rem' }}>
                {staticShowType || api.isBot() ? '' : <ShowTypeItem callback={setShowType} />}{' '}
                {adminActions}
              </Col>
            </Row>
          </Container>
        ) : (
          ''
        )}

        {showType === ShowTypes.Card ? (
          <Container fluid>
            {tableData.length && !noTop ? (
              <Row className='mb-3'>
                <Col xs={6}>
                  <InputGroup className='mb-3'>
                    <InputGroup.Text style={{ background: '#019305', color: '#fff' }}>
                      <Search />
                    </InputGroup.Text>
                    <Form.Control
                      type='text'
                      size='sm'
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      placeholder={t('global.dataSearch')}
                    />
                  </InputGroup>
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row xs={1} md={5} className='g-4'>
              {tableData.length && data
                ? data
                    .filter((item) => {
                      if (
                        draftField &&
                        !api.isManager(sessionStore.session.user_project) &&
                        item[draftField]
                      )
                        return false;
                      return true;
                    })
                    .map((item) => (
                      <ItemCard
                        key={item[id]}
                        store={store}
                        type={type}
                        id={item[id]}
                        showRoute={showRoute}
                        editRoute={editRoute}
                        name={name}
                        title={title}
                        draftField={draftField}
                        statusField={statusField}
                        noProject={noProject}
                        height={cardHeight}
                        afterButton={afterButton}
                        getStyle={getStyle}
                        calculateImage={calculateImage}
                        preFooter={calculatePreFooter ? calculatePreFooter(item) : undefined}
                        footer={calculateFooter ? calculateFooter(item) : undefined}
                        afterDelete={afterDelete}
                        minWidth={minWidth}
                      />
                    ))
                : ''}
              {!tableData.length ? <NoContent /> : ''}
            </Row>
          </Container>
        ) : (
          <div style={{ padding: '0 0.2rem' }}>
            {tableData.length ? (
              <ItemTable
                id={id}
                store={store}
                type={type}
                tableData={
                  data &&
                  data.filter((item) => {
                    if (
                      draftField &&
                      !api.isManager(sessionStore.session.user_project) &&
                      item[draftField]
                    )
                      return false;
                    return true;
                  })
                }
                columns={columns}
                draftField={draftField}
                statusField={statusField}
                showRoute={showRoute}
                editRoute={editRoute}
                canEdit={canEdit}
                afterButton={afterButton}
                getStyle={getStyle}
              />
            ) : (
              ''
            )}
            {!tableData.length ? <NoContent /> : ''}
          </div>
        )}
      </>
    );
  }
);
export default ItemViewer;
