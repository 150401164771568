/* eslint-disable react/no-danger */
import { observer } from 'mobx-react';
import React from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import QuestInfo from './QuestInfo';

export interface IQuest {
  plot?: boolean;
}

const Quests: React.FC<IQuest> = observer((routerProps) => {
  const questId = useParams().params || 0;
  return (
    <Container style={{ background: '#fff', marginTop: 14 }}>
      <QuestInfo questId={+questId} />
    </Container>
  );
});
export default Quests;
