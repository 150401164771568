import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ItemViewer from 'components/Heplers/ItemViewer';
import { TPerson } from 'stores/PersonsStore';
import { Alert, Button } from 'react-bootstrap';
import { ShowTypes } from 'services/Api';
import { InfoCircle, Search } from 'react-bootstrap-icons';
import { useStores } from '../../services/useStore';

const MyPerson: React.FC = observer(() => {
  const { t } = useTranslation();
  const { personStore, api, sessionStore } = useStores();
  api.noLogin();
  const [person, setPerson] = useState<TPerson[]>();
  const [inited, setInit] = useState(false);
  const updatePersonList = () => {
    setPerson(personStore.data.filter((p: TPerson) => p.user_id === sessionStore.session.user_id));
  };
  useEffect(() => {
    api.getMyPersons().then((personsData) => {
      const data = Array.isArray(personsData) ? personsData : [personsData];
      data.forEach((p: TPerson) =>
        personStore.update({ ...p, user_id: sessionStore.session.user_id })
      );
      updatePersonList();
      setInit(true);
    });
  }, []);

  /*useEffect(() => {
    updatePersonList();
  }, [personStore.data]);*/
  if (!inited) {
    return <div>{t('global.loading')}</div>;
  }

  if (!person?.length) {
    return (
      <Alert variant='warning'>
        <Alert.Heading>
          <Search /> {t('global.empty')}
        </Alert.Heading>
        <p>{t('global.emptyData')}</p>
      </Alert>
    );
  }

  const afterButton = (data: TPerson) => {
    return (
      <Button
        variant='primary'
        size='sm'
        onClick={() => api.showDialog('person', data.person_id || 0)}
      >
        <InfoCircle />
      </Button>
    );
  };

  return (
    <ItemViewer
      id='person_id'
      store='personStore'
      type='person'
      tableData={person || []}
      columns={[]}
      showRoute=''
      editRoute=''
      name='person_name'
      title='person_title'
      canEdit
      mainTitle={t('header.myPersons')}
      adminActions=''
      afterButton={afterButton}
      staticShowType={ShowTypes.Card}
      minWidth={350}
    />
  );
});
export default MyPerson;
