import BaseStore from './BaseStore';

export type TStuff = {
  stuff_id?: number;
  stuff_name: string;
  stuff_title: string;
  stuff_description?: string;
  stuff_source?: string;
  stuff_price?: number;
  stuff_status?: string;
  stuff_deadline?: Date;
  stuff_images?: string[];
  stuff_virtual?: number;
  folder_id: number;
  project_id: number;
  img_id?: number;
  img_mime?: string;
  user_id?: number;
  master_id?: number;
  stuff_shared?: string;
};
class StuffsStore extends BaseStore {
  idKey = 'stuff_id';
  get data(): TStuff[] {
    return this._data as TStuff[];
  }
}
export default StuffsStore;
