import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useStores } from 'services/useStore';
// eslint-disable-next-line import/no-extraneous-dependencies
import GooglePayButton, { ReadyToPayChangeResponse } from '@google-pay/button-react';
//import testData from '../../payTest.json';

export interface IGooglePalButton {
  id: string;
  width?: number;
}

const GooglePalButton: React.FC<IGooglePalButton> = observer(({ width, id }) => {
  const { api } = useStores();
  const onDone = async (details: google.payments.api.PaymentData) => {
    console.log(details);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const amount = (document.getElementById(id) as any)?.value;
    await api.pay(JSON.stringify(details), 'GooglePay', +amount);
    await api.checkSession();
    await api.getBillings();
    api.togglePayDialog(false);
  };

  /*const onError = (err: Record<string, unknown>) => {
    console.log(err);
  };*/

  const setAmmount = async () => {
    await api.getPayCode();
  };

  const getSettings = (): google.payments.api.PaymentDataRequest => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const amount = (document.getElementById(id) as any)?.value;
    console.log('>>>', amount);
    return {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: 'CARD',
          parameters: {
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            allowedCardNetworks: ['MASTERCARD', 'VISA'],
          },
          tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            parameters: {
              gateway: 'example',
              gatewayMerchantId: 'exampleGatewayMerchantId',
            },
          },
        },
      ],
      merchantInfo: {
        merchantId: '12345678901234567890',
        merchantName: 'Demo Merchant',
      },
      transactionInfo: {
        totalPriceStatus: 'FINAL',
        totalPriceLabel: 'Total',
        totalPrice: amount + '',
        currencyCode: 'USD',
        countryCode: 'US',
      },
    };
  };

  const style = width ? { width } : {};

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div style={{ border: '1px solid black', borderRadius: 20, ...style }}>
      <GooglePayButton
        environment='PRODUCTION' // или "PRODUCTION" "TEST"
        paymentRequest={getSettings()}
        onLoadPaymentData={onDone}
        buttonColor='white'
        buttonSizeMode='fill'
        buttonType='long'
        style={{ width, border: 0 }}
        onCancel={(reason: google.payments.api.PaymentsError) => console.log('Cancel', reason)}
        onError={(error: Error | google.payments.api.PaymentsError) => console.log('Error', error)}
        onReadyToPayChange={(result: ReadyToPayChangeResponse) =>
          console.log('onReadyToPayChange', result)
        }
        onClick={setAmmount}
      />
    </div>
  );
});

export default GooglePalButton;
