/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'services/useStore';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import WayForPayButton from 'components/Buttons/WayForPayButton';
import moment from 'moment';
import { ProjectPayTypes, TProject } from 'stores/ProjectsStore';
import { ROUTES } from 'components/App/App';
import { Gift } from 'react-bootstrap-icons';

export interface ISubscribeDialog {
  projectId: number;
  show: boolean;
  callback?: () => void;
  hideCallback: () => void;
}

const SubscribeDialog: React.FC<ISubscribeDialog> = observer(
  ({ projectId, show, callback, hideCallback }) => {
    const { t } = useTranslation();
    const [amount, setAmount] = useState(0);
    const [pricingName, setPricingName] = useState('');
    const [promoCode, setPromoCode] = useState<string>();
    const [promoError, setPromoError] = useState<string>();
    const [promoResult, setPromoResult] = useState<string>();
    const [showPromo, setShowPromo] = useState(false);
    const [showFooter, setShowFooter] = useState(true);
    const [firstPayDate, setFirstPayDate] = useState('');
    const [paymentDescription, setPaymentDescription] = useState('');
    const { api, projectStore, sessionStore } = useStores();
    const usePromoCode = () => {
      setPromoError('');
      setPromoResult('');
      if (!promoCode || promoCode.length < 5) return;
      api.usePromoCode(projectId, promoCode).then((data) => {
        if (data.error) {
          setPromoError(t(data.error));
          return;
        }
        if (data.result) {
          setPromoCode('');
          setPromoResult(t(data.result.text));
          if (data.result.fe) {
            data.result.fe.split(';').forEach((element: string) => {
              switch (element) {
                case 'reload':
                  setTimeout(() => window.location.reload(), 1500);
                  setShowFooter(false);
                  break;
                default:
                  break;
              }
            });
          }
        }
      });
    };
    useEffect(() => {
      if (!show || !projectId) return;
      const project: TProject = projectStore.get(projectId);
      const price = project.project_pricing;
      setPaymentDescription(t('billing.subscribeProjectDescription') + project.project_name);
      setPricingName(price);
      setAmount(api.settings.pricing[price as keyof typeof api.settings.pricing]);
      if (project.project_pay_status === ProjectPayTypes.NEW) {
        setFirstPayDate(moment().add(30, 'd').format('DD.MM.YY'));
      } else {
        setFirstPayDate(moment().format('DD.MM.YY'));
      }
    }, [projectId, show]);
    return (
      <Modal show={show} size='sm' key='editModal' onHide={hideCallback}>
        <Modal.Header closeButton>{t('billing.subscribe')}</Modal.Header>
        <Modal.Body>
          <Row className='justify-content-md-center'>
            <Col style={{ fontSize: 20 }}>
              {t('pricing.pricing')}: <b>{t('pricing.' + pricingName + '.name')}</b>
            </Col>
          </Row>
          <Row className='justify-content-md-center'>
            <Col style={{ fontSize: 20 }}>
              {t('billing.amount')}: <b>{amount}</b>
              {t('billing.perMounce')}
            </Col>
          </Row>
          <Row className='mb-1 justify-content-md-center'>
            <Col style={{ fontSize: 20 }}>
              {t('billing.subscribeFirstPay')}: <b>{firstPayDate}</b>
            </Col>
          </Row>
          <Row className='mb-1 justify-content-md-center'>
            <Col style={{ fontSize: 20 }}>
              <Button
                variant='link'
                onClick={() => setShowPromo(!showPromo)}
                style={{ boxShadow: 'none', padding: 0, margin: 0, textDecoration: 'none' }}
              >
                <Gift fontSize={16} style={{ marginRight: 10, position: 'relative', top: -3 }} />
                {t('promocode.haveCode')}
              </Button>
            </Col>
          </Row>
          {showPromo ? (
            <>
              <Row className='mb-1 justify-content-md-center'>
                {!promoResult ? (
                  <>
                    <Col>
                      <Form.Control
                        type='text'
                        required
                        size='sm'
                        value={promoCode || ''}
                        onChange={(e) => setPromoCode(e.target.value.toUpperCase())}
                        placeholder={t('promocode.code')}
                        maxLength={15}
                        style={{ fontFamily: 'monospace', width: 140 }}
                      />
                    </Col>
                    <Col>
                      <Button size='sm' onClick={() => usePromoCode()}>
                        {t('promocode.use')}
                      </Button>
                    </Col>
                  </>
                ) : (
                  ''
                )}
              </Row>
              {promoError ? (
                <Row className='mb-1 justify-content-md-center'>
                  <Col style={{ color: 'red' }}>{promoError}</Col>
                </Row>
              ) : (
                ''
              )}
              {promoResult ? (
                <Row className='mb-1 justify-content-md-center'>
                  <Col style={{ color: 'green' }}>{promoResult}</Col>
                </Row>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
          {/*<Row className='justify-content-md-center text-muted'>
            <Col>{t('billing.chouseBillingSystem')}</Col>
          </Row>*/}
        </Modal.Body>
        {showFooter ? (
          <Modal.Footer>
            <Row>
              <Col>
                <WayForPayButton
                  id={`project_${projectId}_${sessionStore.session.user_id}`}
                  totalPrice={amount}
                  startPrice={
                    projectId
                      ? projectStore.get(projectId).project_pay_status === 'NEW'
                        ? 1
                        : amount
                      : 1
                  }
                  namesString={paymentDescription}
                  width={265}
                  returnUrl={ROUTES.projectEditLARP + '/' + projectId}
                />
              </Col>
            </Row>
          </Modal.Footer>
        ) : (
          ''
        )}
      </Modal>
    );
  }
);

export default SubscribeDialog;
