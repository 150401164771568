import ImageList from 'components/Heplers/ImageList';
import TextStatusShow from 'components/Heplers/TextStatusShow';
import UserShow from 'components/Heplers/UserShow';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TTask, TaskFlow } from 'stores/TasksStore';
import { useStores } from '../../services/useStore';

export interface ITaskInfo {
  taskId: number | undefined;
  linkInDialog?: boolean;
}

const TasksInfo: React.FC<ITaskInfo> = observer(({ taskId, linkInDialog }) => {
  const { t } = useTranslation();
  const { api } = useStores();
  const [task, setTask] = useState<TTask>();
  const [imgList, setImgList] = useState([] as string[]);

  const getGlobal = (flow: string | undefined): string => {
    switch (flow) {
      case TaskFlow.InProgress:
        return 'warning';
      case TaskFlow.Done:
        return 'success';
      default:
        return 'danger';
    }
  };
  useEffect(() => {
    if (taskId) {
      api.getTasks(+taskId).then((data) => {
        const lo = data as TTask;
        setTask(lo);
        if (lo.task_images) setImgList(lo.task_images);
        else setImgList([]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!task) return <div>{t('global.loading')}</div>;
  if (!api.isManager(task.project_id)) return <div>Access deny</div>;
  return (
    <Container fluid className='verticalPadding greenForm '>
      <Row className='justify-content-md-center'>
        <Col className='mb-3'>
          <h2>
            <Badge pill bg={getGlobal(task.task_flow)} style={{ marginRight: 20 }}>
              {t('taskForm.flow.' + task.task_flow)}
            </Badge>
            {task.task_name}
          </h2>
          <p>{task.task_description}</p>
          <ImageList imgList={imgList} noAI />
          <TextStatusShow status={task.task_status} />
          <UserShow userId={task.task_assign} isManager />
        </Col>
      </Row>
    </Container>
  );
});
export default TasksInfo;
