/* eslint-disable react/no-danger */
import CarouselList from 'components/Heplers/CarouselList';
import LinkList from 'components/Heplers/LinkList';
import PersonShow from 'components/Heplers/PersonShow';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Badge, Col, Container, ListGroup, Row } from 'react-bootstrap';
import { Person } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { TLocation } from 'stores/LocationsStore';
import { TPerson } from 'stores/PersonsStore';
import { ProjectTypes } from 'stores/ProjectsStore';
import { useStores } from '../../services/useStore';

export interface ILocationInfo {
  locationId: number | undefined;
  imageHeight?: number;
  imageCover?: boolean;
  linkInDialog?: boolean;
}

const LocationInfo: React.FC<ILocationInfo> = observer(
  ({ locationId, imageHeight, imageCover, linkInDialog }) => {
    const { t } = useTranslation();
    const { api, personStore, sessionStore } = useStores();
    const [location, setLocation] = useState<TLocation>();
    const [imgList, setImgList] = useState<string[]>();
    const [personList, setPerson] = useState<TPerson[]>();
    const [isLARP, setIsLARP] = useState<boolean>(false);

    useEffect(() => {
      if (locationId) {
        api.getLocations(locationId).then(async (data) => {
          const lo = data as TLocation;
          if (lo.project_id) await api.updateProjectData(lo.project_id);
          setLocation(lo);
          if (lo.location_images) setImgList(lo.location_images);
          else setImgList([]);
        });
        setIsLARP(api.getProjectType() === ProjectTypes.LARP);
        setPerson(personStore._data.filter((person: TPerson) => person.location_id === locationId));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId]);
    if (!location) return <div>{t('global.loading')}</div>;
    const adminActions = api.isManager(location.project_id) ? (
      <>
        <p
          className='person-notice'
          dangerouslySetInnerHTML={{ __html: location.location_description || '' }}
        />
        <ListGroup>
          <ListGroup.Item
            variant='success'
            key='head'
            as='li'
            className='d-flex justify-content-between align-items-start'
          >
            <div className='me-auto'>
              <div className='fw-bold'>
                <h6 style={{ marginBottom: 0 }}>
                  <Person />
                  &nbsp;{t('person.mainTitle')} [{personList?.length}]
                </h6>
              </div>
            </div>
          </ListGroup.Item>
          {personList?.length ? (
            personList.map((item: TPerson) => {
              return (
                <ListGroup.Item
                  key={item.person_id}
                  as='li'
                  className='d-flex justify-content-between align-items-start'
                >
                  <Container fluid>
                    <Row>
                      <Col lg={12}>
                        <PersonShow
                          personId={item.person_id}
                          link
                          showUser
                          linkInDialog={linkInDialog}
                          hideEmpty
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <Row>
                          <Col lg={12}>{item.person_title ? item.person_title : ''}</Col>
                        </Row>
                        {item.person_status && item.person_status !== 'undefined' ? (
                          <Row>
                            <Col>
                              <Badge bg='secondary'>{item.person_status}</Badge>
                            </Col>
                          </Row>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Container>
                </ListGroup.Item>
              );
            })
          ) : (
            <ListGroup.Item
              key='empty'
              as='li'
              className='d-flex justify-content-between align-items-start'
            >
              &nbsp;
            </ListGroup.Item>
          )}
        </ListGroup>
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <LinkList
              type='stuff'
              subType='location'
              id={locationId || 0}
              reverse
              storeName='stuffLinks'
              title={t('stuff.mainTitle')}
              muteLoadFromAPI={false}
              autogenerate
              linkInDialog
              controll={false}
              hideEmpty
            />
            <LinkList
              type='text'
              subType='location'
              id={locationId || 0}
              reverse
              storeName='textLinks'
              title={t('text.mainTitle')}
              muteLoadFromAPI={false}
              autogenerate
              linkInDialog
              controll={false}
              hideEmpty
            />
          </Col>
        </Row>
      </>
    ) : (
      ''
    );
    return (
      <Container
        fluid
        className={'verticalPadding greenForm' + (location.location_draft ? ' draftBG' : '')}
      >
        {location.location_draft ? <div className='lable'>{t('global.draft')}</div> : ''}
        <Row className='justify-content-md-center'>
          <Col xs lg={12}>
            {imgList?.length ? (
              <CarouselList
                imgList={imgList}
                label={location.location_name}
                height={api.isBot() ? sessionStore.appSettings.botWidht / 1.2 : imageHeight}
                cover={imageCover}
              />
            ) : (
              <h2>{location.location_name}</h2>
            )}
            {isLARP && location.location_virtual ? (
              <div>
                <Badge bg='dark'>{t('global.virtual')}</Badge>
              </div>
            ) : (
              ''
            )}
            <p className='mt-3'>{location.location_title}</p>
            {api.isBot() ? '' : adminActions}
          </Col>
        </Row>
      </Container>
    );
  }
);
export default LocationInfo;
