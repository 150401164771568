import BaseStore from './BaseStore';

export type TNews = {
  news_id?: number;
  project_id?: number;
  project_name?: string;
  news_description: string;
  news_draft: number;
  news_created?: string;
  news_images?: string[];
  img_id?: number;
  img_mime?: string;
  user_id?: number;
  user_name?: string;
  news_visibility?: string;
  news_link_type?: string;
  news_link?: number;
};
class NewsStore extends BaseStore {
  idKey = 'news_id';
  get data(): TNews[] {
    return this._data as TNews[];
  }
}
export default NewsStore;
