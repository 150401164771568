import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TStuffFolder, TStuffFolderTreeItem } from 'stores/StuffFoldersStore';
import { useStores } from '../../services/useStore';

export interface IStuffFolderSelect {
  folderId: number;
  excludeId?: number;
  excludeIn?: number;
  addRoot?: boolean;
  action: (value: number) => void;
}

const StuffFolderSelect: React.FC<IStuffFolderSelect> = observer(
  ({ folderId, excludeId, excludeIn, action, addRoot = false }) => {
    const { t } = useTranslation();
    const { stuffFoldersStore } = useStores();
    const [folder, setFolder] = useState(folderId);
    const [folders, setFolders] = useState<TStuffFolder[]>();

    useEffect(() => {
      let fId: number = folderId;
      const list: TStuffFolderTreeItem[] = stuffFoldersStore.getFoldersByParent(0);
      const foldersData: TStuffFolder[] = [];
      const getParents = (data: TStuffFolderTreeItem[], level: number = 0) => {
        data.forEach((folderData: TStuffFolderTreeItem) => {
          if (folderData.folder.folder_id === excludeId) return;
          if (!fId) fId = folderData.folder.folder_id;
          foldersData.push({ ...folderData.folder, level });
          if (folderData.folder.folder_id === excludeIn) return;
          if (folderData.childs.length) getParents(folderData.childs, level + 1);
        });
      };
      setFolder(fId);
      getParents(list);
      setFolders(foldersData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [folderId]);

    return (
      <FloatingLabel controlId='stuffFolder' label={t('folder.folder')} className='mb-3'>
        <Form.Select
          aria-label={t('folder.folder')}
          required
          size='lg'
          value={folder}
          onChange={(e) => action(+e.currentTarget.value)}
        >
          {addRoot ? (
            <option key={0} value={0}>
              🗁 {t('folder.root')}
            </option>
          ) : (
            ''
          )}
          {folders &&
            folders.map((folderData: TStuffFolder) => (
              <option key={folderData.folder_id} value={folderData.folder_id}>
                {folderData.level ? ''.padEnd(folderData.level * 2, ' ') + '┗╸🗁 ' : '🗁 '}
                {folderData.folder_name}
              </option>
            ))}
        </Form.Select>
      </FloatingLabel>
    );
  }
);
export default StuffFolderSelect;
