import { TCommandInfo, TDialogElement } from 'components/Projects/ProjectAILARP';
import { action, makeObservable, observable } from 'mobx';
import i18n from 'locales/i18n';
import { RootStore } from './RootStore';

export type TLinkInfo = {
  description: string;
  name1: string;
  name2: string;
};

class PrototypeLinkStore {
  _data!: TLinkInfo[];
  rootStore: RootStore;
  idKey: string = 'id';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this._data = [];
    makeObservable(this, {
      _data: observable,
      update: action,
      set: action,
      add: action,
      remove: action,
      parseCommands: action,
    });
  }

  set(newData: TLinkInfo[]): void {
    this._data = newData;
  }
  get data(): TLinkInfo[] {
    return this._data as TLinkInfo[];
  }

  parseCommands(commands: TCommandInfo[], newDialog: TDialogElement[]) {
    if (!commands?.length) return;
    commands.forEach((command: TCommandInfo) => {
      if (!command?.command) return;
      if (command.command.indexOf('link')) return;
      const { name1, name2 } = command.data as TLinkInfo;
      switch (command.command) {
        case 'link_add':
          this.add({ ...command.data } as TLinkInfo);
          newDialog.push({
            text: i18n.t('projectLARPAI.linkAdd', { name1, name2 }),
            source: 'system',
          });
          break;
        case 'link_remove':
          this.remove(name1, name2);
          this.remove(name2, name1);
          newDialog.push({
            text: i18n.t('projectLARPAI.linkDelete', { name1, name2 }),
            source: 'system',
          });
          break;
        case 'link_edit':
          {
            const { data } = command;
            if (name1 !== undefined && name2 !== undefined) {
              this.update({ name1, name2, description: data.description || '' });
              newDialog.push({
                text: i18n.t('projectLARPAI.linkEdit', {
                  name1: (command.data as TLinkInfo).name1,
                  name2: (command.data as TLinkInfo).name2,
                }),
                source: 'system',
              });
            }
          }
          break;
        default:
          break;
      }
    });
  }

  sendToAI(commands: TCommandInfo[]) {
    this._data.forEach((data: TLinkInfo) => {
      commands.push({
        command: 'link_add',
        data,
      });
    });
  }

  add(newData: TLinkInfo): void {
    const findElement = this.get(newData.name1, newData.name2);
    if (!findElement) {
      if (Array.isArray(newData)) this._data = [...this._data, ...newData];
      else this._data.push(newData);
    } else {
      findElement.description += '\n\n' + newData.description;
      this.update({ ...findElement });
    }
  }

  get(name1: string, name2: string): TLinkInfo | undefined {
    if (!this._data?.length) return undefined;
    return this._data.find(
      (element) =>
        (name1 === element.name1 && name2 === element.name2) ||
        (name1 === element.name2 && name2 === element.name1)
    );
  }
  getIndex(name1: string, name2: string): number {
    if (!this._data?.length) return -1;
    return this._data.findIndex(
      (element) =>
        (name1 === element.name1 && name2 === element.name2) ||
        (name1 === element.name2 && name2 === element.name1)
    );
  }
  update(newData: TLinkInfo): void {
    const index = this.getIndex(newData.name1, newData.name2);
    if (index < 0) {
      this.add(newData);
      return;
    }
    this._data[index] = newData;
    this._data = [...this._data];
  }
  remove(name1: string, name2: string): void {
    const index = this.getIndex(name1, name2);
    if (index !== -1) this._data.splice(index, 1);
  }
}
export default PrototypeLinkStore;
