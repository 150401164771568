import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ShowTypeItem from 'components/Heplers/ShowTypeItem';
import { ShowTypes } from 'services/Api';
import ItemTable from 'components/Heplers/ItemTable';
import AddButton from 'components/Buttons/AddButton';
import { TTask } from 'stores/TasksStore';
import { useStores } from '../../services/useStore';
import TaskCard from './TaskCard';
import styles from './style.module.scss';

const TaskList: React.FC = observer(() => {
  const { t } = useTranslation();
  const { sessionStore, taskStore, api } = useStores();
  api.noLogin();
  const [showType, setShowType] = useState(api.getLocalConfig('showType'));
  let data = taskStore.data.filter(
    (p: TTask) => p.project_id === sessionStore.session.user_project
  );
  if (!data) data = [];
  const [inited, setInit] = useState(false);
  useEffect(() => {
    api.getTasks().then(() => {
      setInit(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!inited) {
    return <div>Загрузка...</div>;
  }
  const adminActions =
    api.isManager(sessionStore.session.user_project) && api.projectIsActive() ? (
      <AddButton title={t('task.newTask')} link='taskEdit' />
    ) : (
      ''
    );
  const columns = [
    {
      dataField: 'task_name',
      text: t('taskForm.name'),
      sort: true,
    },
    {
      dataField: 'task_description',
      text: t('taskForm.description'),
      sort: true,
    },
    {
      dataField: 'task_status',
      text: t('taskForm.status'),
      sort: true,
      headerStyle: { width: 150 },
    },
  ];
  return (
    <Container fluid className='verticalPadding greenForm' style={{ marginTop: 14 }}>
      <Row>
        <Col lg={9}>
          <h1>{t('task.mainTitle')}</h1>
        </Col>
        <Col className='rightText' lg={3}>
          <ShowTypeItem callback={setShowType} /> {adminActions}
        </Col>
      </Row>
      <Row>
        <Container fluid>
          {showType === ShowTypes.Card ? (
            <Row
              xs={1}
              md={5}
              className='g-4 justify-content-md-center mt-2'
              style={{ background: '#d5d5d5', borderRadius: 10 }}
            >
              <Col lg={4} className={styles.bloks} style={{ minWidth: 300 }}>
                <h3>{t('taskForm.flow.open')}</h3>
                <div className={styles.bloksItem}>
                  {data
                    .filter((task) => task.task_flow === 'open')
                    .map((task) => (
                      <TaskCard key={task.task_id} {...task} />
                    ))}
                </div>
              </Col>
              <Col lg={4} className={styles.bloks} style={{ minWidth: 300 }}>
                <h3>{t('taskForm.flow.inProgress')}</h3>
                <div className={styles.bloksItem}>
                  {data
                    .filter((task) => task.task_flow === 'inProgress')
                    .map((task) => (
                      <TaskCard key={task.task_id} {...task} />
                    ))}
                </div>
              </Col>
              <Col lg={4} className={styles.bloks} style={{ minWidth: 300 }}>
                <h3>{t('taskForm.flow.done')}</h3>
                <div className={styles.bloksItem}>
                  {data
                    .filter((task) => task.task_flow === 'done')
                    .map((task) => (
                      <TaskCard key={task.task_id} {...task} />
                    ))}
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg={12}>
                <ItemTable
                  id='task_id'
                  store='taskStore'
                  type='task'
                  tableData={data}
                  columns={columns}
                  showRoute='task'
                  editRoute='taskEdit'
                  canEdit
                />
              </Col>
            </Row>
          )}
        </Container>
      </Row>
    </Container>
  );
});
export default TaskList;
