/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-danger */
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Button, ListGroup, Modal, Row, Col, Form, FloatingLabel, Badge } from 'react-bootstrap';
import { Link45deg, TrashFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { TRelationLink } from 'stores/RelationsStore';
import { useStores } from '../../services/useStore';
import UserFind from './UserFind';
import UserShow from './UserShow';

export interface IRelationList {
  type?: string;
  source?: number;
  target?: number;
  title?: string;
  controll?: boolean;
  linkInDialog?: boolean;
  hideEmpty?: boolean;
  reverse?: boolean;
}

const colors = {
  blackList: 'dark',
  whiteList: 'info',
};

const RelationList: React.FC<IRelationList> = observer(
  ({
    type,
    source,
    target,
    title = '',
    controll = true,
    linkInDialog = true,
    hideEmpty = true,
    reverse = false,
  }) => {
    const { t } = useTranslation();
    const { relationLinks, api } = useStores();
    const [linkData, setLinkData] = useState<TRelationLink[]>();
    const [deleteConfirmVisible, showDeleteConfirm] = useState(false);
    const [waitNumber, setWaitNumber] = useState(0);
    const [editModalVisible, showEditModal] = useState(false);
    const [newType, setNewType] = useState<string>('blackList');
    const [newTarget, setNewTarget] = useState<number>(0);
    const updateLinkData = () => {
      const newLinkData: TRelationLink[] = relationLinks.get({ source, target, type });
      setLinkData(newLinkData);
    };
    useEffect(() => {
      updateLinkData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [relationLinks, source, target, type]);

    const add = () => {
      showEditModal(true);
    };
    const saveHandler = () => {
      if (!newTarget || !newType) return;
      api.setRelation(newTarget, newType, source).then(() => updateLinkData());
      showEditModal(false);
    };
    const remove = () => {
      if (!waitNumber) return;
      showDeleteConfirm(false);
      api.deleteRelation(waitNumber, source, type).then(() => {
        updateLinkData();
        setWaitNumber(0);
      });
    };

    const removePromt = (toId: number) => {
      setWaitNumber(toId);
      showDeleteConfirm(true);
    };

    const removePromtClose = () => {
      setWaitNumber(0);
      showDeleteConfirm(false);
    };

    const getVariant = (element: string): string => {
      if (element === 'blacklist') return 'danger';
      if (element === 'whitelist') return 'success';
      return '';
    };

    if (hideEmpty && !linkData?.length) return <></>;
    return (
      <>
        <ListGroup>
          <ListGroup.Item
            variant='success'
            key='head'
            as='li'
            className='d-flex justify-content-between align-items-start'
          >
            <div className='me-auto'>
              <div className='fw-bold'>
                <h6 style={{ marginBottom: 0 }}>
                  <Link45deg />
                  &nbsp;{title || t('user.relations')} [{linkData?.length}]
                </h6>
              </div>
            </div>
            {controll ? (
              <Button
                variant='success'
                onClick={() => add()}
                size='sm'
                type='button'
                style={{ width: 32 }}
              >
                +
              </Button>
            ) : (
              ''
            )}
          </ListGroup.Item>
          {linkData?.length ? (
            linkData.map((item: TRelationLink) => (
              <ListGroup.Item
                key={reverse ? item.source : item.target}
                as='li'
                className='d-flex justify-content-between align-items-start'
                variant={getVariant(item.type)}
              >
                <div className='me-auto'>
                  <div className='fw-bold'>
                    <UserShow
                      allUsers
                      userId={reverse ? item.source : item.target}
                      linkInDialog={linkInDialog}
                    />
                  </div>
                </div>
                <div>
                  {controll ? (
                    <div style={{ whiteSpace: 'nowrap', textAlign: 'end' }}>
                      <Button
                        variant='danger'
                        onClick={() => removePromt(item.target)}
                        size='sm'
                        type='button'
                      >
                        <TrashFill />
                      </Button>
                    </div>
                  ) : (
                    ''
                  )}
                  <Badge bg={colors[item.type] || 'secondary'}>{t('relations.' + item.type)}</Badge>
                </div>
              </ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item
              key='empty'
              as='li'
              className='d-flex justify-content-between align-items-start'
            >
              &nbsp;
            </ListGroup.Item>
          )}
        </ListGroup>

        <Modal show={deleteConfirmVisible} onHide={removePromtClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t('global.deleteLinkHeader')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t('global.deleteLinkPrompt')}</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={removePromtClose}>
              {t('global.close')}
            </Button>
            <Button variant='danger' onClick={remove}>
              {t('global.delete')}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={editModalVisible} key='editModal' onHide={() => showEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t('user.relations')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <Row className='justify-content-md-center'>
                <Col>
                  <UserFind action={setNewTarget} />
                </Col>
              </Row>
              {newTarget ? <UserShow userId={newTarget} /> : ''}
              <Row className='justify-content-md-left text-muted'>
                <Col style={{ fontSize: 12, padding: title ? 14 : 0 }}>{title || <>&nbsp;</>}</Col>
              </Row>
              <FloatingLabel controlId='type' label={t('global.type')} className='mb-3'>
                <Form.Select
                  aria-label={t('global.type')}
                  required
                  size='lg'
                  value={newType}
                  onChange={(e) => setNewType(e.currentTarget.value)}
                >
                  {['blacklist', 'whitelist'].map((key: string) => (
                    <option value={key} key={key}>
                      {t('relations.' + key)}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => showEditModal(false)}>
              {t('global.close')}
            </Button>
            <Button variant='primary' onClick={saveHandler}>
              {t('global.save')}
            </Button>
          </Modal.Footer>
        </Modal>
        {hideEmpty ? <br /> : ''}
      </>
    );
  }
);
export default RelationList;
