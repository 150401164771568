import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { QuestType, TQuest } from 'stores/QuestsStore';
import ShowTypeItem from 'components/Heplers/ShowTypeItem';
import { ShowTypes } from 'services/Api';
import ItemTable from 'components/Heplers/ItemTable';
import AddButton from 'components/Buttons/AddButton';
import { Search } from 'react-bootstrap-icons';
import AICreateButton from 'components/Buttons/AICreateButton';
import NoContent from 'components/Heplers/NoContent';
import QuestCard from './QuestCard';
import { useStores } from '../../services/useStore';

export interface IQuestList {
  plot?: boolean;
}

const QuestList: React.FC<IQuestList> = observer(({ plot }) => {
  const { t } = useTranslation();
  const { sessionStore, questStore, api } = useStores();
  api.noLogin();
  const [showType, setShowType] = useState(api.getLocalConfig('showType'));

  let data = questStore.data.filter(
    (p: TQuest) => p.project_id === sessionStore.session.user_project
  );
  if (!data) data = [];
  else data = api.simplyHTMLArray(data, 'quest_description');
  const [inited, setInit] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filderedData, setFilterdData] = useState<TQuest[]>(data);
  const columns = [
    {
      dataField: 'quest_name',
      text: t('questForm.name'),
      sort: true,
    },
    {
      dataField: 'quest_description',
      text: t('questForm.description'),
      sort: true,
    },
    {
      dataField: 'quest_status',
      text: t('questForm.status'),
      sort: true,
      headerStyle: { width: 150 },
    },
  ];
  useEffect(() => {
    api.getQuests().then(() => {
      setInit(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!inited) return;
    api.getTexts().then(() => {
      const fData = data.filter(
        (quest) => quest.quest_type === (plot ? QuestType.Plot : QuestType.Quest)
      );
      if (showType !== ShowTypes.Card || !searchText) {
        setFilterdData(fData);
        return;
      }
      setFilterdData(
        fData.filter((item: TQuest) => {
          if (item.quest_id === +searchText) return true;
          return !!columns.find(
            (c) =>
              (item[c.dataField as keyof typeof item] + '')
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1
          );
        })
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, showType, plot, inited, questStore]);
  if (!inited) {
    return <div>{t('loading')}</div>;
  }
  const tr = plot ? 'plot.' : 'quest.';
  const adminActions =
    api.isManager(sessionStore.session.user_project) && api.projectIsActive() ? (
      <>
        <AddButton title={t(tr + 'newQuest')} link={plot ? 'plotEdit' : 'questEdit'} />
        &nbsp;
        {!plot ? <AICreateButton type='quest' /> : ''}
      </>
    ) : (
      ''
    );
  if (!data.length)
    return (
      <Container fluid className='verticalPadding greenForm'>
        <Row>
          <Col lg={9}>
            <h1>{t(tr + 'mainTitle')}</h1>
          </Col>
          <Col className='rightText' style={{ marginTop: '1rem' }} lg={3}>
            <ShowTypeItem callback={setShowType} /> {adminActions}
          </Col>
        </Row>
        <NoContent />
      </Container>
    );
  return (
    <>
      <Container fluid className='verticalPadding greenForm'>
        <Row>
          <Col lg={9}>
            <h1>{t(tr + 'mainTitle')}</h1>
          </Col>
          <Col className='rightText' style={{ marginTop: '1rem' }} lg={3}>
            <ShowTypeItem callback={setShowType} /> {adminActions}
          </Col>
        </Row>
        {showType === ShowTypes.Card ? (
          <Row className='mb-3'>
            <Col xs={6}>
              <InputGroup className='mb-3'>
                <InputGroup.Text style={{ background: '#019305', color: '#fff' }}>
                  <Search />
                </InputGroup.Text>
                <Form.Control
                  type='text'
                  size='sm'
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder={t('global.dataSearch')}
                />
              </InputGroup>
            </Col>
          </Row>
        ) : (
          ''
        )}
      </Container>
      {showType === ShowTypes.Card ? (
        <Container fluid>
          <Row xs={1} md={5} className='g-4'>
            {filderedData.map((quest) => (
              <QuestCard key={quest.quest_id} {...{ plot, ...quest }} />
            ))}
          </Row>
        </Container>
      ) : (
        <div style={{ padding: '0 0.2rem' }}>
          <ItemTable
            id='quest_id'
            store='questStore'
            type='quest'
            tableData={filderedData}
            columns={columns}
            showRoute='quests'
            editRoute='questEdit'
            draftField='quest_draft'
            statusField='quest_status'
            canEdit
          />
        </div>
      )}
    </>
  );
});
export default QuestList;
