import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Button, Col, Container, FloatingLabel, Form, Modal, Row, Stack } from 'react-bootstrap';
//import { useStores } from 'services/useStore';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';

interface IForgotForm {
  setMode: (mode: string) => void;
  hide: () => void;
  email: string;
}

const ForgotForm: React.FC<IForgotForm> = observer(({ setMode, email, hide }) => {
  const { t } = useTranslation();
  const { api, sessionStore } = useStores();
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState('forgot');
  const [fEmail, setEmail] = useState(email);
  const [emailInvalid, setEmailInvalid] = useState('');

  const handleForgot = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const form = document.getElementById('forgorPasswordForm') as HTMLFormElement;
    if (form.checkValidity() === false) {
      setValidated(false);
      setEmailInvalid('emailEmpty');
      return false;
    }
    setValidated(true);
    const result = await api.forgotPassword(fEmail);
    if (result) {
      setState('success');
    } else {
      setEmailInvalid('emailError');
    }

    return false;
  };
  const changeEmail = (newEmail: string) => {
    setEmail(newEmail);
    setEmailInvalid('');
  };

  return (
    <Modal
      show={!!sessionStore.appSettings.loginDialog}
      size='sm'
      key='editModal'
      centered
      onHide={hide}
    >
      <Modal.Header closeButton>{t('forgotForm.header')}</Modal.Header>
      {state === 'forgot' ? (
        <Modal.Body>
          <Container fluid className='verticalPadding'>
            <Form id='forgorPasswordForm' action='#' noValidate validated={validated}>
              <Row>
                <Col>
                  <h6>{t('forgotForm.header')}</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FloatingLabel
                    controlId='floatingInput'
                    label={t('loginForm.email')}
                    className='mb-3'
                  >
                    <Form.Control
                      type='email'
                      size='lg'
                      required
                      autoComplete='username'
                      value={fEmail}
                      onChange={(e) => changeEmail(e.target.value)}
                      placeholder={t('loginForm.email')}
                      isInvalid={!!emailInvalid}
                    />
                    {emailInvalid ? (
                      <Form.Control.Feedback type='invalid'>
                        {t('forgotForm.' + emailInvalid)}
                      </Form.Control.Feedback>
                    ) : (
                      ''
                    )}
                  </FloatingLabel>
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <Container fluid className='verticalPadding'>
            {t('forgotForm.success')}
            <br />
            <b>{fEmail}</b>
          </Container>
        </Modal.Body>
      )}
      <Modal.Footer>
        <Stack direction='horizontal' gap={3}>
          {state === 'forgot' ? (
            <Button variant='primary' size='lg' type='button' onClick={(e) => handleForgot(e)}>
              {t('forgotForm.submit')}
            </Button>
          ) : (
            ''
          )}
          <Button
            variant={state === 'forgot' ? 'secondary' : 'primary'}
            size='lg'
            type='button'
            onClick={() => setMode('login')}
          >
            {t('loginForm.signIn')}
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
});

export default ForgotForm;
