/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from 'mobx-react';
import React from 'react';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export interface IVirtualCheckBox {
  virtual: number;
  callback: (virtual: number) => void;
  title?: string;
}

const VirtualCheckBox: React.FC<IVirtualCheckBox> = observer(({ virtual, callback, title }) => {
  const { t } = useTranslation();
  const popover = (
    <Popover id='popover-basic'>
      <Popover.Body>{t('global.virtualDesc')}</Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger placement='right' overlay={popover}>
      <Form.Check
        type='switch'
        key='virtual'
        name='virtual'
        label={title || t('global.virtual')}
        checked={virtual === 1}
        onChange={(e: any) => callback(e.target.checked ? 1 : 0)}
      />
    </OverlayTrigger>
  );
});
export default VirtualCheckBox;
