import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LinkStore, { TLink } from 'stores/LinkStore';
import { TLocation } from 'stores/LocationsStore';
import { TPerson } from 'stores/PersonsStore';
import { TProject } from 'stores/ProjectsStore';
import { TStatus } from 'stores/StatusesStore';
import { TText } from 'stores/TextsStore';
import { useStores } from '../../services/useStore';

export interface ILinkEdit {
  id: number;
  toId?: number;
  storeName: string;
  subType: string;
  showModal: boolean;
  label: string;
  closeEditModal: () => void;
  saveEditModal: (id: number, description: string, name: string, text_description?: string) => void;
}

type TListData = {
  id: number;
  name: string;
  description?: string;
};

const LinkEdit: React.FC<ILinkEdit> = observer(
  ({ id, toId, storeName, subType, showModal = false, label, closeEditModal, saveEditModal }) => {
    const { t } = useTranslation();
    const stores = useStores();
    const { api } = stores;
    const apiFunctions = {
      //Сюда надо добавить другие типы
      text: (): Promise<TListData[]> =>
        new Promise((resolve, reject) => {
          api.getTexts().then((data) =>
            resolve(
              (data as TText[]).map((item) => {
                return {
                  id: item.text_id || 0,
                  name: item.text_description.substr(0, 30),
                  description: item.text_description,
                };
              })
            )
          );
        }),
      status: (): Promise<TListData[]> =>
        new Promise((resolve, reject) => {
          const statusData: TListData[] = stores.statusStore.data.map((item: TStatus) => {
            return {
              id: item.status_id || 0,
              name: item.status_name,
              description: item.status_description,
            };
          });
          resolve(statusData);
        }),
      person: (): Promise<TListData[]> =>
        new Promise((resolve, reject) => {
          const personData: TListData[] = stores.personStore.data.map((item: TPerson) => {
            return {
              id: item.person_id || 0,
              name: item.person_name,
              description: item.person_description,
            };
          });
          resolve(personData);
        }),
      project: (): Promise<TListData[]> =>
        new Promise((resolve, reject) => {
          const projectData: TListData[] = stores.projectStore.data.map((item: TProject) => {
            return {
              id: item.project_id || 0,
              name: item.project_name,
              description: item.project_title,
            };
          });
          resolve(projectData);
        }),
      location: (): Promise<TListData[]> =>
        new Promise((resolve, reject) => {
          const locationData: TListData[] = stores.locationStore.data.map((item: TLocation) => {
            return {
              id: item.location_id || 0,
              name: item.location_name,
              description: item.location_title,
            };
          });
          resolve(locationData);
        }),
    };
    const linkStore: LinkStore = stores[storeName];
    const [selectData, setSelectData] = useState<TListData[]>();
    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');
    const { data } = linkStore;
    const [to, setToId] = useState(toId);
    const [name, setName] = useState('');
    const updateLinkData = () => {
      if (apiFunctions[subType] !== undefined) {
        apiFunctions[subType]().then((d: TListData[]) => {
          setSelectData(d);
          setTitle(d[0]?.description || '');
          setToId(d[0]?.id);
          setName(d[0]?.name);
        });
      }
      if (toId) {
        const newLinkData: TLink = linkStore
          .get({ fromId: id, type: subType })
          ?.find((item: TLink) => item.toId === toId) as TLink;
        setDescription(newLinkData.description || '');
      }
    };
    useEffect(() => {
      if (showModal) updateLinkData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, id, showModal]);

    useEffect(() => {
      setToId(toId);
    }, [toId]);

    const constSetIdHandler = (target: number) => {
      const item = selectData?.find((i) => i.id === target);
      setTitle(item?.description || '');
      setName(item?.name || '');
      setToId(target);
    };

    const saveHandler = () => {
      if (to) saveEditModal(to, description, name, subType === 'text' ? title : '');
    };

    return (
      <Modal show={showModal} key='editModal' onHide={closeEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t(toId ? 'global.editLinkHeader' : 'global.addLinkHeader')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Row>
              <Col>
                <h3>{label}</h3>
              </Col>
            </Row>
            {!toId ? (
              <Row className='justify-content-md-center'>
                <Col>
                  <Form.Select
                    required
                    size='lg'
                    value={to}
                    onChange={(e) => constSetIdHandler(+e.currentTarget.value)}
                  >
                    {selectData?.map((select: TListData) => (
                      <option key={select.id} value={select.id}>
                        {select.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row className='justify-content-md-left text-muted'>
              <Col style={{ fontSize: 12, padding: title ? 14 : 0 }}>{title || <>&nbsp;</>}</Col>
            </Row>
            <Form.Control
              type='text'
              size='lg'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder={t('global.description')}
            />
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeEditModal}>
            {t('global.close')}
          </Button>
          <Button variant='primary' onClick={saveHandler}>
            {t('global.save')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);
export default LinkEdit;
