import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';

export interface ILinkButton {
  text: string;
  label: string;
}

const LinkButton: React.FC<ILinkButton> = observer(({ label, text }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<number>();
  const { api } = useStores();
  const onclick = () => {
    if (value) api.showDialog('info', value);
  };
  useEffect(() => {
    const textId = t('help.' + text);
    if (!Number.isNaN(+textId)) {
      const id = parseInt(textId, 10);
      setValue(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Button
      variant='link'
      size='lg'
      style={{ boxShadow: 'none', padding: 0, position: 'relative', top: -5 }}
      onClick={onclick}
    >
      {t(label)}
    </Button>
  );
});
export default LinkButton;
