import { observer } from 'mobx-react';
import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../services/useStore';

export interface ILocationSelect {
  locationId: number;
  action: (value: number) => void;
  noVirtual?: boolean;
}

const LocationSelect: React.FC<ILocationSelect> = observer(
  ({ locationId, action, noVirtual = false }) => {
    const { t } = useTranslation();
    const { locationStore } = useStores();

    return (
      <FloatingLabel controlId='location' label={t('location.location')} className='mb-3'>
        <Form.Select
          aria-label={t('location.location')}
          required
          size='lg'
          value={locationId}
          onChange={(e) => action(+e.currentTarget.value)}
        >
          <option value={0}>{t('global.notSelect')}</option>
          {locationStore.data
            ?.filter((location) => (noVirtual ? location.location_virtual === 0 : true))
            .map((location) => (
              <option
                key={location.location_id}
                value={location.location_id}
                title={location.location_title}
              >
                {location.location_name}
              </option>
            ))}
        </Form.Select>
      </FloatingLabel>
    );
  }
);
export default LocationSelect;
