/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { UploadButton } from '@rpldy/upload-button';
import { UploaderListeners, Uploady, UPLOADER_EVENTS } from '@rpldy/uploady';
import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { Download, Robot, Trash } from 'react-bootstrap-icons';
import { useStores } from 'services/useStore';
import styles from './style.module.scss';
import GenerateImageDialog from '../Dialogs/GenerateImageDialog';

export interface IImageList {
  imgList: string[];
  uploadUrl?: string;
  type?: string;
  id?: number;
  noAI?: boolean;
  genImgText?: string;
  updateImagesHandle?: () => void;
  max?: number;
  random?: number;
}

const ImageList: React.FC<IImageList> = ({
  uploadUrl,
  imgList,
  type,
  id,
  noAI = false,
  genImgText,
  updateImagesHandle,
  max,
  random = 0,
}) => {
  const { api } = useStores();
  const [images, setImages] = useState(imgList);
  const [canImageAi, setCanImageAi] = useState<number>(0);
  const getUrl = (img: string) => `url('${api.settings.url.server}img/${img}')`;
  const removeImg = (img: string, event: React.FormEvent) => {
    event.stopPropagation();
    event.preventDefault();
    api.removeImg(img).then((result) => {
      setImages(images.filter((imgf) => imgf !== img));
    });
  };
  const listeners: UploaderListeners = {
    [UPLOADER_EVENTS.ITEM_FINISH]: (data) => {
      const respond: string = data.uploadResponse.data;
      if (respond !== 'error') {
        setImages([...images, respond]);
      }
    },
  };

  useEffect(() => {
    if (type === 'project') setCanImageAi(api.canImageAi());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [random]);
  useEffect(() => {
    setCanImageAi(api.canImageAi());
    setImages(imgList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgList]);

  return (
    <>
      <Row>
        {images.map((img) => (
          <Col key={img} xs={4} md={3} className='mb-3'>
            <div
              className={styles.imgListElement}
              style={{ backgroundImage: getUrl(img) }}
              onClick={() => api.showImagePopup(img)}
            >
              {uploadUrl ? (
                <button
                  type='button'
                  className={styles.imgListElementCross}
                  onClick={(event) => removeImg(img, event)}
                >
                  <Trash />
                </button>
              ) : (
                ''
              )}
            </div>
          </Col>
        ))}
        {((max && images.length < max) || !max) && uploadUrl ? (
          <Col key='uploadImage' xs={4} md={3} className='mb-3'>
            <div className={styles.imgListAdd}>
              <Uploady
                multiple={!api.isBot() && (!max || max > 1)}
                listeners={listeners}
                destination={{
                  url: api.settings.url.server + uploadUrl,
                  headers: { 'X-Token': api.getSession() },
                }}
              >
                <UploadButton>
                  <Download />
                </UploadButton>
              </Uploady>
            </div>
          </Col>
        ) : (
          ''
        )}
        {!noAI && canImageAi > 0 ? (
          <Col key='generateImage' xs={4} md={3} className='mb-3'>
            <div
              className={styles.imgListGenerate}
              title={canImageAi === 1 ? 'Сервер генерації не доступний із за клятих москалів' : ''}
            >
              <Button
                variant={canImageAi === 1 ? 'secondary' : 'primary'}
                className='col-12'
                size='lg'
                disabled={canImageAi === 1}
                type='submit'
                onClick={() =>
                  api.toggleImageGenerateDialog(
                    true,
                    genImgText || '',
                    updateImagesHandle || (() => {})
                  )
                }
              >
                <Robot />
              </Button>
            </div>
          </Col>
        ) : (
          ''
        )}
      </Row>
      <GenerateImageDialog type={type} id={id} />
    </>
  );
};

export default ImageList;
