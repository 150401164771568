/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Link45deg } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { TPersonLink } from 'stores/LinkStore';
import { useStores } from '../../services/useStore';
import BotCard from './BotCard';

export interface IBotPersonLinkList {
  id: number;
  muteLoadFromAPI?: boolean;
  autogenerate?: boolean;
  linkInDialog?: boolean;
}

const BotPersonLinkList: React.FC<IBotPersonLinkList> = observer(
  ({ id, muteLoadFromAPI, autogenerate = false, linkInDialog = false }) => {
    const { t } = useTranslation();
    const { personLinks, api, personStore } = useStores();
    const [linkData, setLinkData] = useState<TPersonLink[]>();
    const [textlinkData, setTextLinkData] = useState<any[]>();
    const updateLinkData = () => {
      let persons = personLinks.get({ fromId: id, type: 'person' });
      persons = persons.filter((person: TPersonLink) => {
        if (person.type === 'hide' || person.type === 'clone') return false;
        return true;
      });
      setLinkData(persons);

      const texts = personLinks.get({ fromId: id, type: 'textAll' });
      setTextLinkData(texts);
    };
    useEffect(() => {
      api.getLinks('person', id).then((linkInfo) => {
        if (linkInfo) personLinks.add(linkInfo, id);
        updateLinkData();
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
      <div>
        <b style={{ marginBottom: 0 }}>
          <Link45deg />
          &nbsp;{t('person.linked')}:
        </b>
        {linkData?.length ? (
          linkData.map((item: TPersonLink) => {
            const reverse = item.toId === id;
            const person = personStore.get(reverse ? item.fromId : item.toId);
            if (!person) return '';
            return (
              <BotCard
                id={person.person_id || 0}
                key={person.person_id}
                img={person.img_id ? `${person.img_id}.${person.img_mime}` : ''}
                onClick={(person_id: number) => api.showDialog('person', person_id)}
              >
                <div className='name'>{person.person_name}</div>
                <div className='info'>
                  {item.description && item.description !== 'undefined'
                    ? item.description
                    : person.person_title}
                </div>
              </BotCard>
            );
          })
        ) : (
          <span style={{ paddingLeft: 5 }}>{t('global.none')}</span>
        )}
        <br />
        {textlinkData?.length ? (
          <div>
            <b style={{ marginBottom: 0 }}>
              <Link45deg />
              &nbsp;{t('text.linked')}:
            </b>
            {textlinkData.map((text: any) => (
              <BotCard
                id={text.text_id || 0}
                key={text.text_id}
                img={text.text_images?.length ? text.text_images[0] : ''}
                onClick={(text_id: number) => api.showDialog('text', text_id)}
              >
                <div className='name'>{text.title || text.text_name}</div>
              </BotCard>
            ))}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
);
export default BotPersonLinkList;
