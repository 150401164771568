import BaseStore from './BaseStore';

export type TTask = {
  task_id?: number;
  project_id?: number;
  task_name: string;
  task_description?: string;
  task_assign?: number;
  task_status?: string;
  task_flow?: string;
  task_images?: string[];
  img_id?: number;
  img_mime?: string;
  user_name?: string;
  task_owner?: number;
};

export enum TaskFlow {
  Open = 'open',
  InProgress = 'inProgress',
  Done = 'done',
}

class TasksStore extends BaseStore {
  idKey = 'task_id';
  get data(): TTask[] {
    return this._data as TTask[];
  }
}
export default TasksStore;
