/* eslint-disable @typescript-eslint/no-explicit-any */
import { action, makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';

class BaseStore {
  _data!: any[];
  rootStore: RootStore;
  idKey: string = 'id';

  constructor(rootStore: RootStore, ext?: any) {
    this.rootStore = rootStore;
    this._data = [];
    makeObservable(this, {
      _data: observable,
      update: action,
      set: action,
      add: action,
      remove: action,
      ...ext,
    });
  }

  set(newData: any[]): void {
    //if (this.idKey === 'request_id') debugger;
    this._data = newData;
  }

  add(newData: any): void {
    if (Array.isArray(newData)) this._data = [...this._data, ...newData];
    else {
      (this._data || []).push(newData);
    }
  }
  get(id: number): any {
    if (!this._data?.length) return undefined;
    return this._data.find((element) => id === element[this.idKey]);
  }

  toObj<T>(arr: T[]): { [key: string]: T } {
    const result: { [key: string]: T } = {};
    arr.forEach((i: T) => {
      const key: keyof typeof i = this.idKey as keyof typeof i;
      result[i[key] as keyof typeof result] = i;
    });
    return result;
  }

  update(newData: any): void {
    //if (this.idKey === 'request_id') debugger;
    if (
      (Array.isArray(newData) && !newData.length) ||
      (!Array.isArray(newData) && !Object.keys(newData).length)
    )
      return;
    const normalizedData = this.toObj(Array.isArray(newData) ? newData : [newData]);
    const merge = { ...this.toObj(this._data), ...normalizedData };
    this._data = Object.values(merge);
    /*const first = this._data?.length === 0;
    this._data = !first
      ? this._data.map((element) => {
          const newElement = normalizedData.find((el) => el[this.idKey] === element[this.idKey]);
          if (newElement) return { ...element, ...newElement };
          return element;
        })
      : [...normalizedData];
    if (first) return;
    normalizedData.forEach((element) => {
      const index = this._data.findIndex((value) => value[this.idKey] === element[this.idKey]);
      if (index < 0) this._data.push(element);
    });*/
  }
  remove(id: number): void {
    const index = this._data.findIndex((element) => element[this.idKey] === id);
    this._data.splice(index, 1);
    if (index !== -1) this._data = [...this._data];
  }
}

export default BaseStore;
