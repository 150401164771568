import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { useStores } from 'services/useStore';
import styles from './style.module.scss';

export interface ICarouselList {
  imgList: string[];
  label?: string;
  title?: string;
  height?: number | string;
  width?: number | string;
  cover?: boolean;
  float?: boolean;
  noCarusel?: boolean;
}

const CarouselList: React.FC<ICarouselList> = ({
  imgList,
  label,
  title,
  height = 500,
  width = '100%',
  cover = false,
  float = false,
  noCarusel = false,
}) => {
  const { api } = useStores();
  const [images, setImages] = useState(imgList);
  const getUrl = (img: string) => `url(${api.settings.url.server}img/${img})`;

  useEffect(() => {
    setImages(imgList);
  }, [imgList]);

  // useEffect(() => {
  //   const image = document.getElementsByClassName(
  //     noCarusel ? 'carouselSingleBlock' : 'carouselBlock'
  //   );
  //   // eslint-disable-next-line no-new
  //   new SimpleParallax(image);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const style = float ? { width } : {};
  if (noCarusel) {
    return (
      <div
        className='carouselSingleBlock paralax'
        style={{
          height,
          width,
          backgroundImage: getUrl(images[0]),
          backgroundSize: cover ? 'cover' : 'contain',
        }}
      />
    );
  }
  return (
    <Carousel style={style} className={float ? styles.carouselContainer : ''}>
      {images.map((img) => (
        <Carousel.Item key={img}>
          <div
            className='carouselBlock paralax'
            style={{
              height,
              width,
              backgroundImage: getUrl(img),
              backgroundSize: cover ? 'cover' : 'contain',
            }}
          />
          {label || label ? (
            <Carousel.Caption>
              {label ? <h3>{label}</h3> : ''}
              {title ? <p>{title}</p> : ''}
            </Carousel.Caption>
          ) : (
            ''
          )}
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CarouselList;
