/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Button, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ROUTES } from 'components/App/App';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';
import { Bug, JournalText, Plus, Robot } from 'react-bootstrap-icons';
import AlphaVersion from 'components/Heplers/AlphaVersion';
import logo from '../../assets/img/owl3.png';
import ProfileBlock from './ProfileBlock';

const ManagerHeader: React.FC = observer(() => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { sessionStore, api } = useStores();
  const project = sessionStore.session.user_project;
  const [searchText, setSearchText] = useState('');
  const goToSearch = () => {
    sessionStore.setSearchQuery(searchText);
    history(ROUTES.search);
  };
  return (
    <Navbar bg='dark' expand='lg' variant='dark'>
      <AlphaVersion />
      <Container>
        <Navbar.Brand>
          <img src={logo} style={{ height: 50, margin: '-20px 0 -12px 0' }} alt='' />
        </Navbar.Brand>
        <Navbar.Brand href={ROUTES.home}>{t('header.managerHeaderLORE')}</Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            <NavDropdown title={t('projects.mainTitle')} id='basic-nav-dropdown'>
              <NavDropdown.Item as={Link} to={ROUTES.projects}>
                {t('header.allProjects')}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={ROUTES.projects + '/' + project}>
                {t('header.myProject')}
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to={ROUTES.tasks}>
              {t('task.mainTitle')}
            </Nav.Link>
            <NavDropdown title={t('header.loreElements')} id='basic-nav-dropdown'>
              <NavDropdown.Item as={Link} to={ROUTES.locations}>
                {t('location.mainTitle')}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={ROUTES.stuffs}>
                {t('stuff.mainTitleLORE')}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={ROUTES.persons}>
                {t('person.mainTitle')}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={ROUTES.statuses}>
                {t('status.mainTitle')}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={ROUTES.links}>
                {t('global.links')}
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t('text.mainTitle')} id='basic-nav-dropdown'>
              <NavDropdown.Item as={Link} to={ROUTES.plots}>
                {t('plot.mainTitle')}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={ROUTES.quests}>
                {t('quest.mainTitle')}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to={ROUTES.texts}>
                {t('text.mainTitle')}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className='justify-content-end'>
            <Button
              style={{ marginRight: 10, padding: 5 }}
              variant='primary'
              onClick={() => api.toggleNoticeDialog(true)}
              size='sm'
            >
              <JournalText fontSize={28} />
            </Button>
            {api.canTextAi() ? (
              <Button
                style={{ marginRight: 10, padding: 5 }}
                variant='warning'
                onClick={() => api.toggleTextGenerateDialog(true)}
                size='sm'
              >
                <Robot fontSize={28} />
              </Button>
            ) : (
              ''
            )}
            <Button
              style={{ marginRight: 10, padding: 5 }}
              variant='success'
              onClick={() => api.toggleFastActions(true)}
              size='sm'
            >
              <Plus fontSize={28} />
            </Button>
            <input
              className='form-control me-2'
              type='text'
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={t('header.searchPlaceholder')}
              aria-label={t('header.searchLabel')}
              onKeyDown={(event) => {
                if (event.keyCode === 13) goToSearch();
              }}
            />
            <button className='btn btn-secondary' onClick={goToSearch} type='button'>
              {t('global.search')}
            </button>
            <div>|</div>
            <ProfileBlock />
          </Nav>
        </Navbar.Collapse>
      </Container>
      <Button
        style={{ marginRight: 10, padding: 5 }}
        variant='dark'
        onClick={() => api.bugDialog(true)}
        size='sm'
      >
        <Bug fontSize={28} color='#ffffff33' />
      </Button>
    </Navbar>
  );
});

export default ManagerHeader;
