import CarouselList from 'components/Heplers/CarouselList';
import LinkList from 'components/Heplers/LinkList';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TStatus } from 'stores/StatusesStore';
import { useStores } from '../../services/useStore';

export interface IStatuseInfo {
  statusId: number | undefined;
  imageHeight?: number;
  linkInDialog?: boolean;
}

const StatuseInfo: React.FC<IStatuseInfo> = observer(({ statusId, imageHeight, linkInDialog }) => {
  const { t } = useTranslation();
  const { api } = useStores();
  api.noLogin();
  const [status, setStatus] = useState<TStatus>();
  const [imgList, setImgList] = useState<string[]>();

  useEffect(() => {
    if (statusId) {
      api.getStatuses(statusId).then((data) => {
        const st = data as TStatus;
        setStatus(st);
        if (st.status_images) setImgList(st.status_images);
        else setImgList([]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusId]);
  if (!status) return <div>{t('global.loading')}</div>;
  return (
    <Container fluid className='verticalPadding greenForm' style={{ paddingTop: 24 }}>
      <Row className='justify-content-md-center'>
        <Col xs lg={12}>
          {imgList?.length ? (
            <CarouselList imgList={imgList} label={status.status_name} height={imageHeight} />
          ) : (
            <h2>{status.status_name}</h2>
          )}
          <p>{status.status_description}</p>
          {api.isManager(status.project_id) && !api.isBot() ? (
            <LinkList
              type='person'
              subType='status'
              id={statusId || 0}
              reverse
              storeName='statusLinks'
              controll={false}
              linkInDialog={linkInDialog}
              title={t('person.mainTitle')}
            />
          ) : (
            ''
          )}
        </Col>
      </Row>
    </Container>
  );
});
export default StatuseInfo;
