/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export interface IDatePicker {
  title?: string;
  date: moment.Moment | false;
  callback: (date: moment.Moment) => void;
  min?: moment.Moment;
  max?: moment.Moment;
}

const DatePicker: React.FC<IDatePicker> = observer(({ title, date, callback, min, max }) => {
  const { t } = useTranslation();
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [day, setDay] = useState(0);
  const [init, setInit] = useState(false);
  const save = (text: string) => {
    callback(moment(text));
  };
  const updateYear = (data: number) => {
    const stringDate = `${data}-${month}-${day}`;
    if (min && moment(stringDate) < min) return;
    if (max && moment(stringDate) > max) return;
    setYear(data);
    save(stringDate);
  };
  const updateMonth = (data: number) => {
    const stringDate = `${year}-${data}-${day}`;
    if (min && moment(stringDate) < min) return;
    if (max && moment(stringDate) > max) return;
    setMonth(data);
    save(stringDate);
  };
  const updateDay = (data: number) => {
    const stringDate = `${year}-${month}-${data}`;
    if (min && moment(stringDate) < min) return;
    if (max && moment(stringDate) > max) return;
    setDay(data);
    save(stringDate);
  };
  const d: number[] = [];
  const m: number[] = [];
  const y: number[] = [+moment().format('YYYY') - 2];
  for (let i = 1; i < 10; i++) y.push(y[0] + i);
  for (let i = 1; i < 13; i++) m.push(i);
  for (let i = 1; i < 32; i++) d.push(i);

  useEffect(() => {
    if (!init) {
      // eslint-disable-next-line no-nested-ternary
      const dat = typeof date === 'object' ? (date.isValid() ? date : false) : false;
      const yT = dat ? +dat.format('YYYY') : y[0];
      const mT = dat ? +dat.format('MM') : +moment().format('MM');
      const dT = dat ? +dat.format('DD') : +moment().format('DD');
      setYear(yT);
      setMonth(mT);
      setDay(dT);
      save(`${yT}-${mT}-${dT}`);
      setInit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container style={{ padding: 0 }} className='mb-3'>
      <Row>
        {title ? (
          <Col
            sm={8}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              fontSize: 20,
              fontWeight: 'bold',
            }}
          >
            {title}
          </Col>
        ) : (
          ''
        )}
        <Col
          sm={4}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            fontSize: 20,
            fontWeight: 'bold',
          }}
        >
          <Form.Select
            style={{ width: 180 }}
            aria-label={t('global.year')}
            required
            size='sm'
            value={year}
            onChange={(e) => updateYear(+e.currentTarget.value)}
          >
            {y.map((yy: number) => (
              <option key={yy} value={yy}>
                {yy}
              </option>
            ))}
          </Form.Select>
          <Form.Select
            style={{ width: 220 }}
            aria-label={t('global.month')}
            required
            size='sm'
            value={month}
            onChange={(e) => updateMonth(+e.currentTarget.value)}
          >
            {m.map((mm: number) => (
              <option key={mm} value={mm}>
                {t('global.months.' + mm)}
              </option>
            ))}
          </Form.Select>
          <Form.Select
            style={{ width: 170 }}
            aria-label={t('global.day')}
            required
            size='sm'
            value={day}
            onChange={(e) => updateDay(+e.currentTarget.value)}
          >
            {d.map((dd: number) => (
              <option key={dd} value={dd}>
                {dd}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
    </Container>
  );
});
export default DatePicker;
