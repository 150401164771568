import { ROUTES } from 'components/App/App';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { People } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TLink } from 'stores/LinkStore';
import { TPerson } from 'stores/PersonsStore';
import { useStores } from '../../services/useStore';
import LocationShow from './LocationShow';
import styles from './style.module.scss';

export interface IPersonDescription {
  personId: number;
  withLink?: boolean;
  linkInDialog?: boolean;
}

const PersonDescription: React.FC<IPersonDescription> = observer(
  ({ personId, withLink, linkInDialog = true }) => {
    const { t } = useTranslation();
    const { personStore, api, personLinks } = useStores();
    const [linkData, setLinkData] = useState<TLink[]>();
    const [personData, setPersonData] = useState<TPerson>();
    const clickPersonHandler = () => {
      api.showDialog('person', personId);
    };
    useEffect(() => {
      if (!personId) return;
      const person = personStore.data.find((i: TPerson) => i.person_id === personId);
      setPersonData(person);
      if (!withLink || !api.isManager(person?.project_id) || api.isBot()) return;
      api.getLinks('person', personId).then((linkInfo) => {
        if (linkInfo) personLinks.add(linkInfo, personId);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personId]);

    useEffect(() => {
      setLinkData(personLinks.get({ fromId: personId, type: 'status' }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personLinks]);
    if (!personData) return <></>;
    return (
      <Row className='justify-content-md-center'>
        {personData.img_id ? (
          <Col xs lg={2} className={styles.personDescription}>
            <div
              style={{
                backgroundImage: `url('${api.settings.url.server}img/${personData.img_id}.${personData.img_mime}')`,
              }}
            />
          </Col>
        ) : (
          ''
        )}
        <Col xs lg={10}>
          {withLink ? (
            <Row>
              <Col>
                {linkInDialog ? (
                  <Button onClick={clickPersonHandler}>{t('requestForm.extendedHeader')}</Button>
                ) : (
                  <Link to={ROUTES.persons + '/' + personId}>
                    {t('requestForm.extendedHeader')}
                  </Link>
                )}
              </Col>
            </Row>
          ) : (
            ''
          )}
          <Row className='justify-content-md-center'>
            <Col xs lg={12}>
              {personData.location_id ? (
                <LocationShow linkInDialog locationId={personData.location_id} />
              ) : (
                ''
              )}
              {linkData?.length ? (
                <div>
                  <People />
                  {'  '}
                  <b>{linkData?.map((statusItem: TLink) => statusItem.name || '').join(', ')}</b>
                </div>
              ) : (
                ''
              )}
              {personData.person_title ? <div>{personData.person_title}</div> : ''}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
);
export default PersonDescription;
