import { action, makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';

export type TLink = {
  fromId: number;
  toId: number;
  name?: string;
  description?: string;
  text_description?: string;
  status?: string;
  type?: string;
  draft?: boolean;
};

export type TPersonLink = {
  fromId: number;
  toId: number;
  nameFrom?: string;
  nameTo?: string;
  description?: string;
  status?: string;
  type?: string;
  draftFrom?: boolean;
  draftTo?: boolean;
};

export type TElementLink = TLink | TPersonLink;

type TData = {
  [key: string]: TElementLink[];
};

export type TLinkData = {
  [key: string]: TElementLink[];
};

type TLinkGetSet = {
  type: string;
  fromId: number;
  toId?: number;
};

class LinkStore {
  data: TData = {};
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      data: observable,
      add: action,
      remove: action,
    });
  }

  add(newData: TData, fromId: number): void {
    Object.entries(newData).forEach(([key, value]) => {
      const newValue: TElementLink[] = value.map((v) => {
        const nv = { ...v };
        if (!nv.fromId) nv.fromId = fromId;
        return nv;
      });
      if (!this.data[key]) this.data = { [key]: newValue, ...this.data };
      else {
        const nv = this.data[key];
        newValue.forEach((item: TElementLink) => {
          const index = nv.findIndex((i) => i.fromId === item.fromId && i.toId === item.toId);
          if (index < 0) nv.push(item);
          else nv[index] = item;
        });
        this.data = { [key]: nv, ...this.data };
      }
    });
  }

  get(setup: TLinkGetSet): TElementLink[] {
    const { fromId, toId, type } = setup;
    if (!this.data[type]) return [];
    if (type === 'person')
      return (this.data[type] as TPersonLink[]).filter((item: TPersonLink) =>
        toId
          ? item.fromId === fromId && item.toId === toId
          : item.fromId === fromId || item.toId === fromId
      );
    return (this.data[type] as TLink[]).filter((item: TLink) =>
      toId
        ? item.fromId === fromId && item.toId === toId
        : item.fromId === fromId || item.toId === fromId
    );
  }

  check(setup: TLinkGetSet): boolean {
    const { fromId, toId, type } = setup;
    if (!this.data[type]) return false;
    if (type === 'person')
      return !!(this.data[type] as TPersonLink[]).find(
        (item: TPersonLink) =>
          (item.fromId === fromId && item.toId === toId) ||
          (item.fromId === toId && item.toId === fromId)
      );
    return !!(this.data[type] as TLink[]).find(
      (item: TLink) =>
        (item.fromId === fromId && item.toId === toId) ||
        (item.fromId === toId && item.toId === fromId)
    );
  }

  remove(setup: TLinkGetSet): void {
    const { fromId, toId, type } = setup;
    if (!this.data[type]) return;
    this.data[type].forEach((item, index) => {
      if (toId ? item.fromId === fromId && item.toId === toId : item.fromId === fromId)
        this.data[type].splice(index, 1);
    });
  }
}
export default LinkStore;
