/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { TUser } from 'stores/UsersStore';
import Ava from './Ava';
import styles from './style.module.scss';

export interface IUserList {
  list: TUser[] | undefined;
}

const UserList: React.FC<IUserList> = observer(({ list }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [popup, setPopup] = useState<{ element: TUser; target: any }>();
  const ref = useRef(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const popoverShow = (event: any) => {
    const { currentTarget } = event;
    const id = +currentTarget.dataset.id;
    const element = list?.find((user: TUser) => user.user_id === id);
    if (!element) return;
    setPopup({
      element,
      target: currentTarget,
    });
  };
  const popoverHide = () => {
    setPopup(undefined);
  };
  if (!list) return <></>;
  return (
    <div className='' ref={ref}>
      {list.map((user: TUser) => (
        <Ava
          className={styles.userListAva}
          key={user.user_id}
          img_id={user.img_id}
          img_mime={user.img_mime}
          size={40}
          width={50}
          height={50}
          id={user.user_id}
          onMouseOver={popoverShow}
          onMouseOut={popoverHide}
        />
      ))}
      <Overlay
        show={!!popup?.target}
        target={popup?.target}
        placement='top'
        container={ref}
        containerPadding={20}
      >
        <Popover id='popover-contained'>
          <Popover.Body>
            <div>
              {popup?.element.img_id ? (
                <Ava size={240} img_id={popup.element.img_id} img_mime={popup.element.img_mime} />
              ) : (
                ''
              )}
            </div>
            <h5>{popup?.element.user_name}</h5>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
});
export default UserList;
