/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useStores } from 'services/useStore';
import { useTranslation } from 'react-i18next';
import { TSuccessResponse } from 'services/Api';
import { observer } from 'mobx-react';
import { TRequest } from 'stores/RequestsStore';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import ImageList from 'components/Heplers/ImageList';
import LocationSelect from 'components/Locations/LocationSelect';
import PersonDescription from 'components/Heplers/PersonDescription';
import UserFind from 'components/Heplers/UserFind';
import UserShow from 'components/Heplers/UserShow';
import InfoButton from 'components/Heplers/InfoButton';
import FormElement from 'components/Heplers/FormElement';
import PersonShow from 'components/Heplers/PersonShow';
import { TPerson } from 'stores/PersonsStore';
import RedirectCheck from 'components/Heplers/RedirectCheck';
import styles from './style.module.scss';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let timer: any;

const RequestEdit: React.FC = observer(() => {
  const history = useNavigate();
  const rId = useParams().params || 0;
  const { t } = useTranslation();
  const { api, requestStore, sessionStore, personStore, projectStore } = useStores();
  //api.noLogin();
  const [requestId, setRequestId] = useState(+rId);
  const [validated, setValidated] = useState(false);
  const [hidePerson, setHidePerson] = useState(false);
  const [person, setPerson] = useState(0);
  const [location, setLocation] = useState(0);
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [job, setJob] = useState('');
  const [age, setAge] = useState('');
  const [description, setDescription] = useState('');
  const [wantPlay, setWantPlay] = useState('');
  const [dontPlay, setDontPlay] = useState('');
  //const [blackList, setBlackList] = useState('');

  const [masterInfo, setMasterInfo] = useState('');
  const [status, setStatus] = useState('');
  const [payment, setPayment] = useState('');
  const [user, setUser] = useState(0);

  const [personType, setPersonType] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [fromProjectInfo, setFromProjectInfo] = useState(false);
  const [imgList, setImgList] = useState([] as string[]);
  const onEdit = () => {
    api.getRequests(requestId).then((data) => {
      const request = data as TRequest;
      setName(request.request_name);
      setTitle(request.request_title);
      setJob(request.request_job || '');
      setAge(request.request_age || '');
      setWantPlay(request.request_wantplay || '');
      setDontPlay(request.request_dontplay || '');
      setMasterInfo(request.request_masterinfo || '');
      setDescription(request.request_description || '');
      setStatus(request.request_status || '');
      setPayment(request.request_payment || '');
      setLocation(request.location_id || 0);
      setPerson(request.person_id || 0);
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { person_id, person_name, person_title, person_image, location_id, location_name } =
        request;
      personStore.update({
        person_id,
        person_name,
        person_title,
        location_id,
        location_name,
        img_id: person_image?.split('.')[0],
        img_mime: person_image?.split('.')[1],
      } as unknown as TPerson);
      if (request.person_id) setHidePerson(true);
      requestStore.update(request);
      if (request.request_images) setImgList(request.request_images);
      else setImgList([]);
    });
  };
  const onNew = () => {
    setHidePerson(true);
    setTimeout(() => {
      setPerson(+window.sessionStorage.person);
      setFromProjectInfo(true);
      delete window.sessionStorage.person;
    }, 100);
  };
  useEffect(() => {
    if (!person || !personStore.data) {
      return;
    }
    setPersonType(personStore.get(person)?.person_type);
    window.sessionStorage.person = person;
  }, [person, personStore]);

  useEffect(() => {
    if (sessionStore.session.user_project) {
      api.updateProjectData(sessionStore.session.user_project).then(() => {
        if (requestId) onEdit();
        else if (window.sessionStorage.person) onNew();
      });
    } else history(ROUTES.projects);

    return () => {
      if (timer) clearTimeout(timer);
      if (window.sessionStorage.person) delete window.sessionStorage.person;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const adminActions =
    !api.isBot() && api.isManager(sessionStore.session.user_project) && api.projectIsActive();
  const allowFreeQuenta = () =>
    sessionStore.session?.user_project
      ? projectStore.settings(sessionStore.session.user_project)?.freQuenta || false
      : false;

  const updateRequest = (event: React.FormEvent) => {
    if (!person && !allowFreeQuenta()) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity()) {
      setValidated(true);
      const data: TRequest = {
        person_id: person,
        request_name: name.trim(),
        request_title: title.trim(),
        request_description: description.trim(),
        request_job: job.trim(),
        request_age: age.trim(),
        request_wantplay: wantPlay.trim(),
        request_dontplay: dontPlay.trim(),
        location_id: location,
        project_id: sessionStore.session.user_project,
      };
      if (requestId) data.request_id = requestId;
      else if (adminActions) {
        if (user) data.user_id = user;
        else {
          event.preventDefault();
          event.stopPropagation();
          setValidated(false);
          return false;
        }
      }
      if (adminActions) {
        data.request_status = status.trim();
        data.request_masterinfo = masterInfo.trim();
        data.request_payment = payment.trim();
      }
      api.setRequest(data).then((res: TSuccessResponse) => {
        const { errors, success } = res;
        if (!errors) {
          if (requestId || (Number.isInteger(success) && success)) {
            let dataId = 0;
            if (requestId) dataId = requestId;
            else dataId = Number.isInteger(success) && success ? +success : 0;
            setRequestId(dataId);
            api.getRequests(dataId).then((requestData) => {
              const re: TRequest = requestData as TRequest;
              if (!requestId) requestStore.add({ request_id: dataId, ...re });
              else requestStore.update(re);
            });
          } else if (success) {
            //requestStore.add({ request_id: +success, ...data });
            setRequestId(+success);
          }
          setSuccessText(requestId ? 'requestForm.editSuccess' : 'requestForm.createSuccess');
          timer = setTimeout(() => {
            setSuccessText('');
            history(ROUTES.requests);
          }, 3000);
        } else {
          let errorKey = '';
          Object.entries(errors).forEach(([key, value]) => {
            errorKey = `requestForm.errors.${key}.${value}`;
          });
          setErrorText(errorKey);
        }
      });
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    return false;
  };
  const inputSize = api.isBot() ? 'sm' : 'lg';
  const successAlert = successText ? <Alert variant='success'>{t(successText)}</Alert> : '';
  const errorAlert = errorText ? <Alert variant='danger'>{t(errorText)}</Alert> : '';
  return (
    <Container fluid className='verticalPadding greenForm'>
      <RedirectCheck url={ROUTES.requests} />
      <Form onSubmit={updateRequest} action='#' validated={validated}>
        <Row className='justify-content-md-center'>
          <Col xs lg={10}>
            <h2>
              {t(requestId ? 'requestForm.edit' : 'requestForm.create')}
              {requestId ? <span className='text-muted'> #{requestId}</span> : ''}
            </h2>
          </Col>
        </Row>
        {adminActions && !requestId ? (
          <>
            <Row className='justify-content-md-center masterField mb-3'>
              <Col xs lg={10}>
                <UserFind action={setUser} placeholder={t('requestForm.player')} />
              </Col>
            </Row>
            {user ? (
              <>
                <Row className='justify-content-md-center masterField'>
                  <Col xs lg={10}>
                    <UserShow userId={user} allUsers />
                  </Col>
                </Row>
                <br />
              </>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
        {person && hidePerson ? (
          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              <h4 style={{ display: 'flex' }}>
                {t('person.person')}:
                <div style={{ marginLeft: 10 }}>
                  <PersonShow personId={person} hideImage />
                </div>
              </h4>
            </Col>
          </Row>
        ) : (
          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              <FormElement controlId='person' label={t('requestForm.person')} className='mb-3'>
                <Form.Select
                  aria-label={t('person.mainTitle')}
                  required
                  size={inputSize}
                  value={person}
                  onChange={(e) => setPerson(+e.currentTarget.value)}
                >
                  <option value={0}>
                    {allowFreeQuenta()
                      ? t('requestForm.customPerson')
                      : t('requestForm.chousePerson')}
                  </option>
                  {personStore.data
                    ?.filter((p: TPerson) => {
                      if (api.isBot() || !api.isManager(sessionStore.session.user_project))
                        return !p.person_draft && !p.person_virtual;
                      return !p.person_virtual;
                    })
                    .map((selectablePerson) => (
                      <option
                        key={selectablePerson.person_id}
                        value={selectablePerson.person_id}
                        title={selectablePerson.person_name}
                      >
                        {selectablePerson.person_name}
                      </option>
                    ))}
                </Form.Select>
              </FormElement>
            </Col>
          </Row>
        )}
        {(!person || (person && personType === 'free')) && allowFreeQuenta() ? (
          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              {!person ? (
                <>
                  <FormElement controlId='name' label={t('requestForm.name')} className='mb-3'>
                    <Form.Control
                      type='text'
                      required
                      size={inputSize}
                      value={name || ''}
                      onChange={(e) => setName(e.target.value)}
                      placeholder={t('requestForm.name')}
                    />
                  </FormElement>
                  <FormElement controlId='title' label={t('requestForm.title')} className='mb-3'>
                    <Form.Control
                      type='text'
                      required
                      size={inputSize}
                      value={title || ''}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder={t('requestForm.title')}
                    />
                  </FormElement>
                  <LocationSelect locationId={location} noVirtual action={setLocation} />
                </>
              ) : person ? (
                <div className='mb-3'>
                  <PersonDescription
                    personId={person}
                    withLink={api.isManager(sessionStore.session.user_project) && !api.isBot()}
                    linkInDialog
                  />
                </div>
              ) : (
                ''
              )}
              <FormElement controlId='job' label={t('requestForm.job')} className='mb-3'>
                <Form.Control
                  type='text'
                  size={inputSize}
                  value={job || ''}
                  onChange={(e) => setJob(e.target.value)}
                  placeholder={t('requestForm.jobDescription')}
                />
              </FormElement>
              <FormElement controlId='age' label={t('requestForm.age')} className='mb-3'>
                <Form.Control
                  type='text'
                  size={inputSize}
                  value={age || ''}
                  onChange={(e) => setAge(e.target.value)}
                  placeholder={t('requestForm.ageDescription')}
                />
              </FormElement>
              <FormElement
                controlId='description'
                label={description ? '' : t('requestForm.description')}
                className='mb-3'
              >
                <Form.Control
                  as='textarea'
                  rows={5}
                  size={inputSize}
                  value={description || ''}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder={t('requestForm.description')}
                  style={{ height: '100px' }}
                />
              </FormElement>
            </Col>
          </Row>
        ) : person ? (
          <Row className='justify-content-md-center'>
            <Col xs lg={10} className='mb-3'>
              <PersonDescription
                personId={person}
                withLink={api.isManager(sessionStore.session.user_project) && !api.isBot()}
              />
            </Col>
          </Row>
        ) : (
          ''
        )}
        <Row className='justify-content-md-center'>
          <Col xs lg={10}>
            <div className={styles.descriptionLabels}>{t('requestForm.dontPlayDescription')}</div>
            <FormElement
              controlId='dontPlay'
              label={dontPlay ? '' : t('requestForm.dontPlay') + '...'}
              className='mb-3'
            >
              <Form.Control
                as='textarea'
                rows={5}
                size={inputSize}
                value={dontPlay || ''}
                onChange={(e) => setDontPlay(e.target.value)}
                style={{ height: '100px' }}
              />
            </FormElement>
          </Col>
        </Row>
        <Row className='justify-content-md-center mt-3'>
          <Col xs lg={10}>
            <div className={styles.descriptionLabels}>{t('requestForm.wantPlayDescription')}</div>
            <FormElement
              controlId='wantPlay'
              label={wantPlay ? '' : t('requestForm.wantPlay') + '...'}
              className='mb-3'
            >
              <Form.Control
                as='textarea'
                rows={5}
                size={inputSize}
                value={wantPlay || ''}
                onChange={(e) => setWantPlay(e.target.value)}
                style={{ height: '100px' }}
              />
            </FormElement>
            <div>{t('requestForm.blackListDescription')}</div>
          </Col>
        </Row>
        {adminActions ? (
          <Row className='justify-content-md-center masterField'>
            <Col xs lg={10}>
              <h2>{t('requestForm.masterPart')}</h2>
              <FormElement
                controlId='masterInfo'
                label={masterInfo ? '' : t('requestForm.masterInfo')}
                className='mb-3'
              >
                <Form.Control
                  as='textarea'
                  rows={5}
                  size={inputSize}
                  value={masterInfo || ''}
                  onChange={(e) => setMasterInfo(e.target.value)}
                  placeholder={t('requestForm.masterInfo')}
                  style={{ height: '100px' }}
                />
              </FormElement>
              <Row>
                <Col xs lg={11}>
                  <FormElement controlId='status' label={t('requestForm.status')} className='mb-3'>
                    <Form.Control
                      type='text'
                      size={inputSize}
                      value={status || ''}
                      onChange={(e) => setStatus(e.target.value)}
                      placeholder={t('requestForm.status')}
                    />
                  </FormElement>
                </Col>
                <Col xs lg={1}>
                  <InfoButton text='requestStatus' />
                </Col>
              </Row>
              <FormElement controlId='payment' label={t('requestForm.payment')} className='mb-3'>
                <Form.Control
                  type='text'
                  size={inputSize}
                  value={payment || ''}
                  onChange={(e) => setPayment(e.target.value)}
                  placeholder={t('requestForm.payment')}
                />
              </FormElement>
            </Col>
          </Row>
        ) : (
          ''
        )}
        <Row className='justify-content-md-center mt-3'>
          <Col xs lg={10}>
            {errorAlert}
            {successAlert}
          </Col>
        </Row>
        <Row className='justify-content-md-center mb-3' style={{ justifyContent: 'center' }}>
          <Col style={{ display: 'inline-flex', justifyContent: 'flex-end' }}>
            <Button
              variant='primary'
              className='col-12'
              size={inputSize}
              type='submit'
              style={{ whiteSpace: 'nowrap', width: 'auto' }}
            >
              {t(requestId ? 'global.save' : 'request.createRequest')}
            </Button>
          </Col>
          <Col style={{ display: 'inline-flex' }}>
            <Link
              to={
                fromProjectInfo
                  ? ROUTES.projects + '/' + sessionStore.session.user_project
                  : ROUTES.requests
              }
            >
              <Button variant='secondary' className='col-12' size={inputSize}>
                {t(fromProjectInfo ? 'global.back' : 'request.requestBack')}
              </Button>
            </Link>
          </Col>
        </Row>
      </Form>
      {requestId ? (
        <>
          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              <h2>{t('requestForm.extendedHeader')}</h2>
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={10}>
              <ImageList imgList={imgList} noAI uploadUrl={'uploadRequestImage/' + requestId} />
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
    </Container>
  );
});

export default RequestEdit;
