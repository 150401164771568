import CarouselList from 'components/Heplers/CarouselList';
import LinkList from 'components/Heplers/LinkList';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UserStatus } from 'stores/SessionStore';
import { TStuff } from 'stores/StuffsStore';
import UserShow from 'components/Heplers/UserShow';
import { useStores } from '../../services/useStore';

export interface IStuffInfo {
  stuffId: number | undefined;
  imageHeight?: number;
  linkInDialog?: boolean;
}

const StuffInfo: React.FC<IStuffInfo> = observer(({ stuffId, imageHeight, linkInDialog }) => {
  const { t } = useTranslation();
  const { api, sessionStore } = useStores();
  api.noLogin();
  const [stuff, setStuff] = useState<TStuff>();
  const [imgList, setImgList] = useState([] as string[]);

  useEffect(() => {
    if (stuffId) {
      api.getStuffs(stuffId).then((data) => {
        const st = data as TStuff;
        setStuff(st);
        if (st.stuff_images) setImgList(st.stuff_images);
        else setImgList([]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stuffId]);
  if (
    !stuff ||
    (sessionStore.role() !== UserStatus.Manager && sessionStore.role() !== UserStatus.Owner)
  )
    return <div>{t('global.loading')}</div>;
  return (
    <Container fluid className='verticalPadding greenForm' style={{ paddingTop: 24 }}>
      <Row className='justify-content-md-center'>
        <Col xs lg={12}>
          {imgList.length ? (
            <CarouselList imgList={imgList} label={stuff.stuff_name} height={imageHeight} />
          ) : (
            <h2>{stuff.stuff_name}</h2>
          )}
          {stuff.stuff_shared ? (
            <div className='stuff-shared'>
              <div className='mb-1 text-muted'>{t('stuffForm.owner')}</div>
              <UserShow userId={stuff.user_id} linkInDialog />
            </div>
          ) : (
            ''
          )}
          <p>{stuff.stuff_title}</p>
        </Col>
      </Row>
      {stuffId ? (
        <Row className='justify-content-md-center mb-3'>
          <Col style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <LinkList
              type='stuff'
              subType='person'
              id={stuffId}
              storeName='stuffLinks'
              title={t('person.mainTitle')}
              autogenerate
              linkInDialog
              controll={false}
              hideEmpty
            />
            <LinkList
              type='stuff'
              subType='location'
              id={stuffId}
              storeName='stuffLinks'
              title={t('location.mainTitle')}
              muteLoadFromAPI
              autogenerate
              linkInDialog
              controll={false}
              hideEmpty
            />
            <LinkList
              type='stuff'
              subType='quest'
              id={stuffId}
              storeName='stuffLinks'
              title={t('quest.mainTitle')}
              muteLoadFromAPI
              autogenerate
              linkInDialog
              controll={false}
              hideEmpty
            />
          </Col>
        </Row>
      ) : (
        ''
      )}
    </Container>
  );
});
export default StuffInfo;
