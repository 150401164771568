/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import CarouselList from 'components/Heplers/CarouselList';
import LinkList from 'components/Heplers/LinkList';
import LocationShow from 'components/Heplers/LocationShow';
import PersonLinkList from 'components/Heplers/PersonLinkList';
import TextList from 'components/Heplers/TextList';
import TextStatusShow from 'components/Heplers/TextStatusShow';
import UserShow from 'components/Heplers/UserShow';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TPerson } from 'stores/PersonsStore';
import RequestByPersonList from 'components/Heplers/RequestByPersonList';
import { ProjectTypes } from 'stores/ProjectsStore';
import { Link } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import { useStores } from '../../services/useStore';

export interface IPersonInfo {
  personId: number | undefined;
  imageHeight?: number;
  linkInDialog?: boolean;
  inWindow?: boolean;
}

const PersonInfo: React.FC<IPersonInfo> = observer(
  ({ personId, imageHeight, linkInDialog, inWindow }) => {
    const { t } = useTranslation();
    const { api, sessionStore, personStore } = useStores();
    const [person, setPerson] = useState<TPerson>();
    const [imgList, setImgList] = useState([] as string[]);
    const [inited, setInit] = useState(false);
    const dataSource = () => {
      setInit(false);
      return new Promise((resolve, reject) => {
        if (!personId) {
          reject();
          return;
        }
        const data: TPerson = personStore.get(personId);
        if (data?.person_id) resolve(data);
        else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          api.getPersons(personId).then((da: any) => {
            setTimeout(() => resolve(da), 200);
          });
        }
      });
    };
    useEffect(() => {
      if (personId) {
        dataSource().then(async (data) => {
          const lo = data as TPerson;
          if (lo.project_id) await api.updateProjectData(lo.project_id);
          if (!sessionStore.session.user_id) api.currentProject(lo.project_id || 0);
          setPerson(lo);
          if (lo.person_images) setImgList(lo.person_images);
          else if (lo.img_id) setImgList([`${lo.img_id}.${lo.img_mime}`]);
          else setImgList([]);
          setInit(true);
        });

        if (inWindow) api.useParalax();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personId]);
    if (!person || !inited) return <div>{t('global.loading')}</div>;
    const adminActions = api.isManager(person.project_id) ? (
      <>
        <p>{person.person_description}</p>
        <p
          className='person-notice'
          dangerouslySetInnerHTML={{ __html: person.person_notice || '' }}
        />
        <TextStatusShow status={person.person_status} />
        {sessionStore.session.project_type === ProjectTypes.LARP ? (
          <>
            <UserShow userId={person.person_master} isManager hideEmpty linkInDialog />
            {person.person_virtual ? (
              <Badge bg='dark'>{t('global.virtual')}</Badge>
            ) : (
              <UserShow userId={person.user_id} hideEmpty linkInDialog />
            )}
          </>
        ) : (
          ''
        )}
        <br />
        <Row className='justify-content-md-center mb-3'>
          <Col style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <LinkList
              type='person'
              subType='status'
              id={personId || 0}
              storeName='personLinks'
              title={t('status.mainTitle')}
              linkInDialog
              controll={false}
              hideEmpty
            />
            <LinkList
              type='person'
              subType='stuff'
              id={personId || 0}
              storeName='personLinks'
              title={t('stuff.mainTitle')}
              muteLoadFromAPI
              controll={false}
              linkInDialog
              hideEmpty
            />
            <LinkList
              type='person'
              subType='quest'
              id={personId || 0}
              storeName='personLinks'
              title={t('quest.mainTitle')}
              muteLoadFromAPI
              autogenerate
              linkInDialog
              hideEmpty
            />
          </Col>
        </Row>
        <Row className='justify-content-md-center mb-3'>
          <Col lg={6}>
            <PersonLinkList id={personId || 0} muteLoadFromAPI autogenerate linkInDialog />
          </Col>
          <Col lg={6}>
            <RequestByPersonList currentUser={person.user_id || 0} personId={personId || 0} />
          </Col>
        </Row>
        {person.texts ? (
          <Row className='justify-content-md-center mb-3'>
            <Col lg={12}>
              <TextList texts={person.texts} asManager />
            </Col>
          </Row>
        ) : (
          ''
        )}
      </>
    ) : personId && api.isUserHavePerson(personId) ? (
      <>
        <p>{person.person_description}</p>
        <LinkList
          type='person'
          subType='status'
          id={personId || 0}
          storeName='personLinks'
          title={t('status.mainTitle')}
          linkInDialog
          controll={false}
        />
        <PersonLinkList id={personId || 0} muteLoadFromAPI autogenerate linkInDialog />
        <br />
        {person.texts ? <TextList texts={person.texts} /> : ''}
      </>
    ) : (
      ''
    );
    return (
      <Container
        fluid
        className={'verticalPadding greenForm' + (person.person_draft ? ' draftBG' : '')}
      >
        {person.person_draft ? <div className='lable'>{t('global.draft')}</div> : ''}
        <Row className='justify-content-md-center'>
          <Col xs lg={12}>
            {imgList.length ? (
              <CarouselList imgList={imgList} label={person.person_name} height={imageHeight} />
            ) : (
              <>
                <h2>{person.person_name}</h2>
              </>
            )}
            {!sessionStore.session.user_id ? (
              <div className='rightText mt-3'>
                <Button variant='warning' onClick={() => api.toggleLoginDialog(true)}>
                  <b>{t('projects.registerForRequest')}</b>
                </Button>
              </div>
            ) : (
              ''
            )}
            <Link to={ROUTES.projects + '/' + person.project_id}>{person.project_name}</Link>
            <div className='mt-3 mb-3'>{person.person_title}</div>
            <LocationShow
              locationId={person.location_id}
              locationName={person.location_name}
              linkInDialog={linkInDialog}
            />
            {adminActions}
          </Col>
        </Row>
      </Container>
    );
  }
);
export default PersonInfo;
