import React, { FC, createContext, ReactNode, ReactElement } from 'react';
import { RootStore } from 'stores/RootStore';

export const StoreContext = createContext<RootStore | null>(null);

export type StoreComponent = FC<{
  store: RootStore;
  children: ReactNode;
}>;

export const StoreProvider: StoreComponent = ({ store, children }): ReactElement => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};
