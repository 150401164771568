import React from 'react';
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ROUTES } from 'components/App/App';
import { useStores } from 'services/useStore';
import { useTranslation } from 'react-i18next';
import { Bug } from 'react-bootstrap-icons';
import AlphaVersion from 'components/Heplers/AlphaVersion';
import logo from '../../assets/img/owl3.png';
import ProfileBlock from './ProfileBlock';

const UserHeader: React.FC = observer(() => {
  const { api } = useStores();
  const { t } = useTranslation();
  return (
    <Navbar bg='dark' expand='lg' variant='dark'>
      <AlphaVersion />
      <Container>
        <Navbar.Brand>
          <img src={logo} style={{ height: 50, margin: '-20px 0 -12px 0' }} alt='' />
        </Navbar.Brand>
        <Navbar.Brand href={ROUTES.home}>{t('header.userHeader')}</Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            <Nav.Link as={Link} key='allNews' to={ROUTES.news}>
              {t('news.mainTitle')}
            </Nav.Link>
            <Nav.Link as={Link} key='allProjects' to={ROUTES.projects}>
              {t('header.allProjects')}
            </Nav.Link>
          </Nav>
          <Nav className='justify-content-end'>
            <ProfileBlock />
          </Nav>
        </Navbar.Collapse>
      </Container>
      <Button
        style={{ marginRight: 10, padding: 5 }}
        variant='dark'
        onClick={() => api.bugDialog(true)}
        size='sm'
      >
        <Bug fontSize={28} color='#ffffff33' />
      </Button>
    </Navbar>
  );
});

export default UserHeader;
