/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/esm/Card';
import { Button, Spinner } from 'react-bootstrap';
import { Image, Pencil, Plus, Trash } from 'react-bootstrap-icons';
import { TCreatePersonReturn } from 'stores/GeneratedPersonsStore';
import { useStores } from 'services/useStore';
import { TCreateNewCharacterSetup } from 'services/Api';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import styles from './style.module.scss';
import LocationShow from './LocationShow';

export interface IGeneratePersonPreset {
  person: TCreatePersonReturn;
  autoGenerateImage?: boolean;
}

const GeneratePersonPreset: React.FC<IGeneratePersonPreset> = observer(
  ({ person, autoGenerateImage = false }) => {
    const { t } = useTranslation();
    const history = useNavigate();
    const { api, sessionStore, generatedPersonsStore } = useStores();
    const [showSpiner, setShowSpiner] = useState<boolean>(false);
    const [buttonsMode, setButtonsMode] = useState(0);
    const [personData, setPersonData] = useState<TCreatePersonReturn>();
    const getUrl = (img: string, type: string = 'generation') =>
      `url('${api.settings.url.server}${type}/${img}?${Math.round(Math.random() * 10000)}')`;
    // eslint-disable-next-line no-param-reassign
    //person.image = '83.png';
    const imgCheck = async (image: string) => {
      const result = await api.checkGenerateImage(image);
      if (result && personData) {
        personData.image = image;
        generatedPersonsStore.update(personData);
        setButtonsMode(0);
      } else setTimeout(() => imgCheck(image), 3000);
    };
    const createImage = async () => {
      setButtonsMode(1);
      const prompt = person.prompt.replace('Draw ', '') + ', (detailed face:1.5)';
      if (!personData) return;
      personData.image = 'loading';
      generatedPersonsStore.update(personData);
      const result = await api.generateImage(
        prompt,
        'person',
        person.aiImgId || 0,
        'realistic',
        'empty city',
        600,
        800
      );
      console.log(result);
      setTimeout(() => imgCheck(result.img), 3000);
    };
    const create = async (editAfterCreate: boolean) => {
      if (!personData) return;
      setShowSpiner(true);
      setButtonsMode(2);
      console.log(personData);
      const setup: TCreateNewCharacterSetup = {
        ...personData,
        project_id: sessionStore.session.user_project,
        stage: 2,
      };
      setup.aiImgId = personData.image ? personData.aiImgId : 0;
      setup.generatedText = personData.text;
      console.log(setup);
      const result = await api.generatePerson(setup);
      console.log(result);
      setShowSpiner(false);
      if (!result) {
        setButtonsMode(0);
        return;
      }
      personData.added = true;
      personData.person_id = result[0].person_id;
      if (personData.image) personData.image = result[0].image;
      generatedPersonsStore.update(personData);
      await api.getPersons();
      if (editAfterCreate) {
        api.togglePersonGenerateDialog(false);
        history(ROUTES.personEdit + '/' + result[0].person_id);
      }
      setButtonsMode(3);
    };
    const add = async () => {
      await create(false);
    };
    const edit = async () => {
      if (person.added && person.person_id) {
        api.togglePersonGenerateDialog(false);
        history(ROUTES.personEdit + '/' + person.person_id);
      } else await create(true);
    };
    const hide = async () => {
      generatedPersonsStore.remove(person.aiImgId || 0);
    };
    useEffect(() => {
      setPersonData(person);
      if (person.added) setButtonsMode(3);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
      if (personData && autoGenerateImage && !personData.image) {
        setTimeout(createImage, 0);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personData]);
    if (!personData) return <></>;
    return (
      <Card className='mb-3'>
        <Card.Body>
          <Card.Title>{personData.person_name}</Card.Title>

          {personData.location_id ? (
            <Card.Subtitle className='mb-2 text-muted'>
              <LocationShow linkInDialog locationId={personData.location_id} />
            </Card.Subtitle>
          ) : (
            ''
          )}

          <>
            {personData.image ? (
              personData.image === 'loading' ? (
                <div className={styles.generationPersonPresetSpiner}>
                  <Spinner style={{ margin: 'auto', scale: 50 }} variant='warning' />
                </div>
              ) : (
                <div
                  role='link'
                  className={styles.generationPersonPresetImage}
                  onClick={() =>
                    api.showImagePopup(
                      personData.image || '',
                      personData.person_id ? 'img' : 'generation'
                    )
                  }
                  style={{
                    backgroundImage: getUrl(
                      personData.image,
                      personData.person_id ? 'img' : 'generation'
                    ),
                  }}
                />
              )
            ) : (
              ''
            )}
            <div dangerouslySetInnerHTML={{ __html: personData.person_title || '' }} />
            <span className='hr' />
            <div
              dangerouslySetInnerHTML={{
                __html: personData.person_description || personData.location || '',
              }}
            />
            <span className='hr' />
            <div dangerouslySetInnerHTML={{ __html: personData.person_notice || '' }} />
            <span className='hr' />
            <div
              dangerouslySetInnerHTML={{
                __html: `<b>${t('global.links')}:</b> ${personData.links || ''}`,
              }}
            />
          </>
        </Card.Body>
        <Card.Footer style={{ textAlign: 'right' }}>
          {showSpiner ? (
            <Spinner style={{ position: 'relative', top: 6, right: 10 }} variant='warning' />
          ) : (
            ''
          )}
          {!buttonsMode ? (
            <Button variant='warning' style={{ marginBottom: 5 }} onClick={createImage}>
              <Image /> {t('generationRobot.genPicture')}
            </Button>
          ) : (
            ''
          )}{' '}
          {!buttonsMode || buttonsMode === 1 ? (
            <Button variant='success' style={{ marginBottom: 5 }} onClick={add}>
              <Plus /> {t('generationRobot.add')}
            </Button>
          ) : (
            ''
          )}{' '}
          {buttonsMode === 3 ? (
            <Button variant='primary' style={{ marginBottom: 5 }} onClick={edit}>
              <Pencil /> {t('generationRobot.edit')}
            </Button>
          ) : (
            ''
          )}{' '}
          <Button variant='danger' style={{ marginBottom: 5 }} onClick={hide}>
            <Trash /> {t('generationRobot.hide')}
          </Button>
        </Card.Footer>
      </Card>
    );
  }
);
export default GeneratePersonPreset;
