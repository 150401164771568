import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'services/useStore';
import { Modal } from 'react-bootstrap';

const ImagePopupDialog: React.FC = observer(() => {
  const { api, sessionStore } = useStores();
  const getUrl = () => api.settings.url.server + sessionStore.appSettings.imageShow;
  const hide = () => {
    sessionStore.showImagePopup('');
  };
  return (
    <Modal show={!!sessionStore.appSettings.imageShow} size='lg' key='editModal' onHide={hide}>
      <Modal.Body style={{ padding: 0, display: 'flex' }}>
        <img src={getUrl()} alt='' style={{ margin: 'auto' }} />
      </Modal.Body>
    </Modal>
  );
});

export default ImagePopupDialog;
