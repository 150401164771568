import { observer } from 'mobx-react';
import React from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';

const AlertDialog: React.FC = observer(() => {
  const { t } = useTranslation();
  const { api, sessionStore } = useStores();
  const hide = () => {
    api.toggleAlertMessage(false);
  };
  return sessionStore.appSettings.alertMessage ? (
    <Modal show size='sm' key='editModal' centered onHide={hide}>
      <Alert variant='danger' style={{ margin: 0, padding: 0 }}>
        <Modal.Body>{t(sessionStore.appSettings.alertMessage)}</Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={hide}>
            {t('global.close')}
          </Button>
        </Modal.Footer>
      </Alert>
    </Modal>
  ) : (
    <></>
  );
});
export default AlertDialog;
