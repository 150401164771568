import BaseStore from './BaseStore';

export type TText = {
  text_id?: number;
  project_id?: number;
  folder_id: number;
  title?: string;
  type?: string;
  text_description: string;
  text_name?: string;
  text_master?: number;
  text_status?: string;
  text_modified?: Date;
  text_images?: string[];
  text_draft?: number;
  img_id?: number;
  img_mime?: string;
  createNews?: number;
};

class TextsStore extends BaseStore {
  idKey = 'text_id';
  get data(): TText[] {
    return this._data as TText[];
  }
}
export default TextsStore;
