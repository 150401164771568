/* eslint-disable import/no-extraneous-dependencies */
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import {
  CreateOrderData,
  CreateOrderActions,
  OnApproveData,
  OnApproveActions,
  OnCancelledActions,
} from '@paypal/paypal-js/types/components/buttons';
import { OrderResponseBody } from '@paypal/paypal-js/types/apis/orders';
import { observer } from 'mobx-react';
import React from 'react';
import { useStores } from 'services/useStore';

//import testData from '../../payTest.json';

export interface IPayPalButton {
  id: string;
  width?: number;
}

const clientId = 'AVucMjmWQQmpeetXXXPYAVa71RLh54Dxf6QHX46j0lBXx8IGfHv2_Zi8rkqvFwJ1Ee5mJdpzW2LRpEJX';
//const clientId = 'Afm9I1lui-RO_zmjnIeFiiObXSoRjSjCmrecw39FHMwjq2CfmrMcF3Pl0ZElFkaS65xX7s3Z_jOy1K_0';

const PayPalButton: React.FC<IPayPalButton> = observer(({ width, id }) => {
  const { api } = useStores();
  const onDone = async (details: OrderResponseBody, data: OnApproveData) => {
    console.log(details, data);
    await api.pay(JSON.stringify(details), 'PayPal', +details.purchase_units[0].amount.value);
    await api.checkSession();
    await api.getBillings();
    api.togglePayDialog(false);
  };

  const createOrder = async (
    data: CreateOrderData,
    actions: CreateOrderActions
  ): Promise<string> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const amount = (document.getElementById(id) as any)?.value;
    if (!+amount)
      return new Promise((resolve, rejects) => {
        resolve(' ');
      });
    console.log(data, actions);
    await api.getPayCode();
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: amount, // Сумма платежа
          },
        },
      ],
    });
  };

  const onApprove = (data: OnApproveData, actions: OnApproveActions): Promise<void> => {
    if (!actions.order) {
      return new Promise((resolve, rejects) => {
        resolve();
      });
    }
    return actions.order?.capture().then((details: OrderResponseBody) => {
      onDone(details, data);
    });
  };

  const onCancel = (data: Record<string, unknown>, actions: OnCancelledActions) => {
    console.log('Cancel');
    //onDone(testData.details as unknown as OrderResponseBody, testData.data as OnApproveData);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return actions.redirect();
  };

  const onError = (err: Record<string, unknown>) => {
    console.log(err);
  };

  const style = width ? { width } : {};
  return (
    <div style={style}>
      <PayPalScriptProvider options={{ clientId: clientId || '', locale: 'ru_UA' }}>
        <PayPalButtons
          createOrder={createOrder}
          onApprove={onApprove}
          onCancel={onCancel}
          onError={onError}
          style={{
            layout: 'horizontal',
            color: 'white',
            shape: 'pill',
            label: 'paypal',
            tagline: false,
          }}
        />
      </PayPalScriptProvider>
    </div>
  );
});

export default PayPalButton;
