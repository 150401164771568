import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UserStatus } from 'stores/SessionStore';
import { TUser } from 'stores/UsersStore';
import { useStores } from '../../services/useStore';
import FormElement from './FormElement';

export interface IUserSelect {
  userId: number;
  isManager?: boolean;
  allUsers?: boolean;
  userList?: TUser[];
  action: (value: number) => void;
}

const UserSelect: React.FC<IUserSelect> = observer(
  ({ userId, action, isManager = false, allUsers = false, userList }) => {
    const name = isManager ? 'global.master' : 'global.player';
    const { t } = useTranslation();
    const { userStore, api } = useStores();
    const [users, setUsers] = useState<TUser[]>();
    const inputSize = api.isBot() ? 'sm' : 'lg';
    if (allUsers) {
      useEffect(() => {
        api.getAllUsers().then((data: TUser[]) => setUsers(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    } else {
      useEffect(() => {
        const u: TUser[] = userList?.length
          ? userList
          : userStore[isManager ? 'getManagers' : 'getUsers']();
        setUsers(u);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    }

    return (
      <FormElement controlId={isManager ? 'master' : 'player'} label={t(name)}>
        <Form.Select
          aria-label={t(name)}
          required
          size={inputSize}
          value={userId}
          onChange={(e) => action(+e.currentTarget.value)}
        >
          <option value={0}>{t('global.notSelect')}</option>
          {users
            ?.filter((user: TUser) => user.user_status !== UserStatus.Admin)
            .map((user: TUser) => (
              <option key={user.user_id} value={user.user_id}>
                {user.user_name}
              </option>
            ))}
        </Form.Select>
      </FormElement>
    );
  }
);
export default UserSelect;
