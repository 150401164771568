import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { UploadButton } from '@rpldy/upload-button';
import { UploaderListeners, Uploady, UPLOADER_EVENTS } from '@rpldy/uploady';
import { Bug, Download } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';
import styles from '../Heplers/style.module.scss';

const BugDialog: React.FC = observer(() => {
  const { t } = useTranslation();
  const { api, sessionStore } = useStores();
  const [bugId, setBugId] = useState<number>(0);
  const [r, setR] = useState<number>(0);
  const [bug, setBug] = useState<string>('');
  const [image, setImage] = useState<string>();
  const getUrl = (img: string) => `url('${api.settings.url.server}bug/${img}?${r}')`;

  const updateR = () => {
    setR(Math.round(Math.random() * 10000));
  };

  const listeners: UploaderListeners = {
    [UPLOADER_EVENTS.ITEM_FINISH]: (data) => {
      const respond: string = data.uploadResponse.data;
      if (respond !== 'error') {
        updateR();
        setImage('');
        setTimeout(() => setImage(respond), 100);
      }
    },
  };

  const saveBug = async () => {
    await api.postBug(bug);
    setBugId(0);
    setImage('');
    setBug('');
    api.bugDialog(false);
  };

  useEffect(() => {
    if (sessionStore.appSettings.bugDialogShow)
      api.getBugId().then((id) => {
        console.log(id);
        setBugId(id);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStore.appSettings.bugDialogShow]);

  return (
    <Modal
      show={sessionStore.appSettings.bugDialogShow}
      size='lg'
      key='editModal'
      centered
      onHide={() => api.bugDialog(false)}
    >
      <Modal.Header closeButton>{t('bug.header')}</Modal.Header>
      <Modal.Body style={{ backgroundColor: '#0000001c' }}>
        <Row className='justify-content-md-center mb-3'>
          <Col xs lg={6}>
            <Uploady
              listeners={listeners}
              destination={{
                url: api.settings.url.server + 'bug/' + bugId,
                headers: { 'X-Token': api.getSession() },
              }}
            >
              <UploadButton className={styles.uploadScreenShot}>
                {image ? (
                  <div
                    style={{
                      backgroundImage: getUrl(image),
                      height: '100%',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                  />
                ) : (
                  <div className='btn btn-success'>
                    <Download /> {t('bug.screenShot')}
                  </div>
                )}
              </UploadButton>
            </Uploady>
          </Col>
          <Col xs lg={6}>
            <Form.Control
              as='textarea'
              rows={15}
              size='lg'
              id='bug'
              value={bug}
              onChange={(e) => setBug(e.target.value)}
              placeholder={t('bug.placeholder')}
              style={{ height: 400, fontSize: 16, lineHeight: '16px', padding: 10 }}
            />
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col xs lg={2}>
            <Button style={{ padding: 5 }} variant='success' onClick={saveBug}>
              <Bug /> {t('bug.send')}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
});
export default BugDialog;
