import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ShowTypeItem from 'components/Heplers/ShowTypeItem';
import { ShowTypes } from 'services/Api';
import ItemTable from 'components/Heplers/ItemTable';
import AddButton from 'components/Buttons/AddButton';
import TextFolderList from 'components/Heplers/TextFolderList';
import { TText } from 'stores/TextsStore';
import { FolderCheck, FolderPlus, Search } from 'react-bootstrap-icons';
import TextFolderSelect from 'components/Heplers/TextFolderSelect';
import NoContent from 'components/Heplers/NoContent';
import MarkdownText from 'components/Heplers/MarkdownText';
import TextCard from './TextCard';
import { useStores } from '../../services/useStore';

const TextList: React.FC = observer(() => {
  const { t } = useTranslation();
  const { sessionStore, textStore, textFolderStore, api } = useStores();
  api.noLogin();
  const [showType, setShowType] = useState(api.getLocalConfig('showType'));
  const [inited, setInit] = useState(false);
  const [folderEdit, setFolderEdit] = useState(false);
  const [folder, setFolder] = useState(0);
  const [texts, setTexts] = useState<TText[]>();
  const [deleteConfirmVisible, showDeleteConfirm] = useState(false);
  const [waitNumber, setWaitNumber] = useState(0);
  const [waitEditNumber, setWaitEditNumber] = useState(0);
  const [folderName, setFolderName] = useState('');
  const [editModalVisible, showEditModal] = useState(false);
  const [moveFolder, setMoveFolder] = useState(0);
  const [random, setRandom] = useState(0);
  const [searchText, setSearchText] = useState('');
  const columns = [
    {
      dataField: 'text_name',
      text: t('textForm.name'),
      sort: true,
    },
    {
      dataField: 'text_description',
      text: t('textForm.description'),
      sort: true,
      style: { fontSize: '0.8rem' },
      formatter: (cell: undefined, row: TText) => (
        <MarkdownText noHTML text={api.cut(row.text_description)} />
      ),
    },
    {
      dataField: 'text_status',
      text: t('textForm.status'),
      sort: true,
      headerStyle: { width: 150 },
    },
    {
      dataField: 'text_modified',
      text: t('global.modified'),
      sort: true,
      headerStyle: { width: 150 },
      style: { fontSize: '0.8rem' },
      formatter: (cell: undefined, row: TText) => api.timeFormat(row.text_modified),
    },
  ];

  const filterTextByFolder = (folderId: number) => {
    let newData: TText[] = textStore.data.filter(
      (text: TText) =>
        text.project_id === sessionStore.session.user_project && text.folder_id === folderId
    );
    if (showType === ShowTypes.Card && searchText) {
      newData = [
        ...newData.filter((item: TText) => {
          if (item.text_id === +searchText) return true;
          return !!columns.find(
            (c) =>
              (item[c.dataField as keyof TText] + '')
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1
          );
        }),
      ];
    }
    setTexts(newData);
  };

  useEffect(() => {
    api.getTexts().then(() => {
      setFolder(+window.localStorage.textFolder || 0);
      setInit(true);
    });
  }, []);

  useEffect(() => {
    if (inited) filterTextByFolder(folder);
  }, [folder, searchText, showType, inited, textStore._data]);

  if (!inited) {
    return <div>{t('global.loading')}</div>;
  }

  const deleteText = (textId: number | undefined) => {
    if (!textId) return;
    api
      .deleteItem('text', textId, t('global.deletePrompt'))
      .then((d) => textStore.remove(textId))
      .catch((e) => {});
  };

  const setFolderAction = (newFolder: number) => {
    setFolder(newFolder);
    window.localStorage.textFolder = newFolder;
  };

  const folderAction = (folderId: number, action: string): boolean => {
    switch (action) {
      case 'create':
        setMoveFolder(0);
        setWaitEditNumber(0);
        setFolderName('');
        showEditModal(true);
        break;

      case 'edit':
        setMoveFolder(0);
        setWaitEditNumber(folderId);
        setFolderName(textFolderStore.get(folderId).folder_name);
        showEditModal(true);
        break;

      case 'delete':
        setMoveFolder(0);
        setWaitNumber(folderId);
        showDeleteConfirm(true);
        break;

      default:
        break;
    }
    return true;
  };
  const adminActions =
    api.isManager(sessionStore.session.user_project) && api.projectIsActive() ? (
      <AddButton title={t('text.newText')} link='textEdit' />
    ) : (
      ''
    );
  const remove = () => {
    if (!waitNumber) return;
    showDeleteConfirm(false);
    api.deleteTextFolders(waitNumber).then(() => {
      setRandom(Math.random() * 1000);
      setWaitNumber(0);
      filterTextByFolder(folder);
    });
  };

  const save = () => {
    showEditModal(false);
    api.editTextFolders(waitEditNumber, folderName, moveFolder).then(() => {
      setRandom(Math.random() * 1000);
      setWaitNumber(0);
    });
  };

  const moveAndRemove = () => {
    if (!waitNumber) return;
    api.moveTextFolders(waitNumber, moveFolder).then(() => {
      setRandom(Math.random() * 1000);
      remove();
    });
  };

  const removePromtClose = () => {
    setWaitNumber(0);
    showDeleteConfirm(false);
  };

  const editClose = () => {
    setWaitEditNumber(0);
    showEditModal(false);
  };
  const getInfo = (id: number, type: string): string | number => {
    const d = textFolderStore.get(id);
    if (!d) return '';
    return d[type];
  };
  const modals = (
    <>
      {waitNumber ? (
        <Modal show={deleteConfirmVisible} onHide={removePromtClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t('folder.removeFolder')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t('folder.removeFolderPrompt')} <b>{getInfo(waitNumber, 'folder_name')}</b>?
            {getInfo(waitNumber, 'folder_count') || getInfo(waitNumber, 'text_count') ? (
              <div className='mt-2'>
                {t('folder.moveFiles1', {
                  text_count: getInfo(waitNumber, 'text_count') || 0,
                  folder_count: getInfo(waitNumber, 'folder_count') || 0,
                })}
                <br />
                {t('folder.moveFiles2')}
                <TextFolderSelect
                  excludeId={waitNumber}
                  folderId={moveFolder}
                  action={setMoveFolder}
                  addRoot
                />
              </div>
            ) : (
              ''
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' size='sm' onClick={removePromtClose}>
              {t('global.close')}
            </Button>
            {getInfo(waitNumber, 'folder_count') || getInfo(waitNumber, 'text_count') ? (
              <>
                <Button variant='danger' size='sm' onClick={moveAndRemove}>
                  {t('folder.removeFolderWithMove')}
                </Button>
                <Button variant='dark' size='sm' onClick={remove}>
                  {t('folder.removeFolderWithContent')}
                </Button>
              </>
            ) : (
              <Button variant='danger' size='sm' onClick={remove}>
                {t('global.delete')}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      ) : (
        ''
      )}
      <Modal show={editModalVisible} onHide={editClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t(waitEditNumber ? 'folder.editFolder' : 'folder.createFolder')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form action='#'>
            <FloatingLabel controlId='folderName' label={t('textForm.name')} className='mb-3'>
              <Form.Control
                type='text'
                size='lg'
                value={folderName || ''}
                onChange={(e) => setFolderName(e.target.value)}
                placeholder={t('textForm.name')}
              />
            </FloatingLabel>
            <TextFolderSelect
              addRoot
              excludeIn={waitEditNumber}
              folderId={waitEditNumber ? +getInfo(waitEditNumber, 'folder_parent') : moveFolder}
              action={setMoveFolder}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' size='sm' onClick={editClose}>
            {t('global.close')}
          </Button>
          <Button variant='success' size='sm' onClick={save}>
            {t('global.save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
  if (!texts?.length)
    return (
      <>
        <Container fluid className='verticalPadding greenForm'>
          <Row>
            <Col lg={9}>
              <h1>{t('text.mainTitle')}</h1>
            </Col>
            <Col className='rightText' style={{ marginTop: '0.75rem' }} lg={3}>
              <Button
                variant={folderEdit ? 'warning' : 'info'}
                size='sm'
                onClick={() => setFolderEdit(!folderEdit)}
              >
                <FolderCheck />
              </Button>{' '}
              <Button variant='info' size='sm' onClick={() => folderAction(folder, 'create')}>
                <FolderPlus />
              </Button>{' '}
              <ShowTypeItem callback={setShowType} /> {adminActions}
            </Col>
          </Row>
          <Row>
            <Col>
              <TextFolderList
                folderId={folder}
                action={setFolderAction}
                editMode={folderEdit}
                folderAction={folderAction}
                random={random}
              />
            </Col>
          </Row>
          <NoContent />
        </Container>
        {modals}
      </>
    );
  return (
    <>
      <Container fluid className='verticalPadding greenForm'>
        <Row>
          <Col lg={9}>
            <h1>{t('text.mainTitle')}</h1>
          </Col>
          <Col className='rightText' style={{ marginTop: '0.75rem' }} lg={3}>
            {api.projectIsActive() ? (
              <>
                <Button
                  variant={folderEdit ? 'warning' : 'info'}
                  size='sm'
                  onClick={() => setFolderEdit(!folderEdit)}
                >
                  <FolderCheck />
                </Button>{' '}
                <Button variant='info' size='sm' onClick={() => folderAction(folder, 'create')}>
                  <FolderPlus />
                </Button>{' '}
              </>
            ) : (
              ''
            )}
            <ShowTypeItem callback={setShowType} /> {adminActions}
          </Col>
        </Row>

        <Row>
          <Col>
            <TextFolderList
              folderId={folder}
              action={setFolderAction}
              editMode={folderEdit}
              folderAction={folderAction}
              random={random}
            />
          </Col>
        </Row>
        {showType === ShowTypes.Card ? (
          <Row className='mb-3'>
            <Col xs={6}>
              <InputGroup className='mb-3'>
                <InputGroup.Text style={{ background: '#019305', color: '#fff' }}>
                  <Search />
                </InputGroup.Text>
                <Form.Control
                  type='text'
                  size='sm'
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder={t('global.dataSearch')}
                />
              </InputGroup>
            </Col>
          </Row>
        ) : (
          ''
        )}
      </Container>
      {showType === ShowTypes.Card ? (
        <Container fluid>
          <Row xs={1} md={5} className='g-4'>
            {texts &&
              texts.map((text) => (
                <TextCard
                  key={(text.text_id || 0) + text.folder_id}
                  text={text}
                  deleteHandler={deleteText}
                />
              ))}
          </Row>
        </Container>
      ) : (
        texts && (
          <div style={{ padding: '0 0.2rem' }}>
            <ItemTable
              id='text_id'
              store='textStore'
              type='text'
              tableData={texts}
              columns={columns}
              showRoute='texts'
              draftField='text_draft'
              statusField='text_status'
              editRoute='textEdit'
              canEdit
            />
          </div>
        )
      )}

      {modals}
    </>
  );
});
export default TextList;
