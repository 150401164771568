import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Alert, Button, Col, FloatingLabel, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { XCircleFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';

const GenerateTextDialog: React.FC = observer(() => {
  const { t } = useTranslation();
  const { api, sessionStore } = useStores();
  const [showSpiner, setShowSpiner] = useState(false);
  const [ask, setAsk] = useState<string>();
  const [result, setResult] = useState<string>('');
  const [error, setError] = useState<string>('');
  const generateText = () => {
    if (!ask) return;
    setShowSpiner(true);
    setError('');
    api.generateText(ask).then((data) => {
      const response = data.result;
      if (data.error) {
        console.warn(data.error);
        setError(data.error);
      } else {
        setResult(response);
      }
      setShowSpiner(false);
    });
    //setTimeout(() => setShowSpiner(false), 5000);
  };
  return (
    <Modal
      show={sessionStore.appSettings.textGenerateShow}
      size='lg'
      key='editModal'
      onHide={() => api.toggleTextGenerateDialog(false)}
    >
      <Modal.Header closeButton>{t('generation.textHeader')}</Modal.Header>
      <Modal.Body style={{ backgroundColor: '#0000001c' }}>
        <Row className='justify-content-md-center'>
          <Col xs lg={12}>
            <FloatingLabel
              controlId='title'
              label={ask ? '' : t('generation.ask')}
              className='mb-3'
            >
              <Form.Control
                as='textarea'
                rows={5}
                size='lg'
                value={ask}
                onChange={(e) => setAsk(e.target.value)}
                placeholder={t('generation.ask')}
                style={{ height: '200px' }}
              />
            </FloatingLabel>
          </Col>
        </Row>
        {!showSpiner && !result && !error ? (
          <Row className='justify-content-md-center'>
            <Col key='generated' lg={12} className='mb-3 text-muted'>
              {t('generation.textInstruction2')}
            </Col>
          </Row>
        ) : (
          ''
        )}
        <Row className='justify-content-md-center'>
          <Col xs lg={12}>
            {!showSpiner ? (
              <Button
                key='generate'
                variant='warning'
                className='col-6 offset-3'
                size='lg'
                type='button'
                disabled={!ask}
                style={{ marginBottom: 20 }}
                onClick={generateText}
              >
                {t('generation.generate')}
              </Button>
            ) : (
              <Alert variant='warning'>
                <Spinner variant='warning' />{' '}
                <span
                  style={{
                    display: 'inline-block',
                    position: 'relative',
                    bottom: 7,
                    left: 10,
                  }}
                >
                  {t('generationRobot.waitNotice')}
                </span>
              </Alert>
            )}
            {error ? (
              <Alert variant='danger'>
                <XCircleFill style={{ fontSize: 30 }} />
                <span style={{ display: 'inline-block', position: 'relative', top: 1, left: 10 }}>
                  {error}
                </span>
              </Alert>
            ) : (
              ''
            )}
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          {result ? (
            <Col key='generated' lg={12}>
              <div
                style={{
                  whiteSpace: 'pre-wrap',
                  background: 'white',
                  padding: 10,
                  borderRadius: 7,
                }}
              >
                {result}
              </div>
            </Col>
          ) : (
            ''
          )}
        </Row>
      </Modal.Body>
    </Modal>
  );
});
export default GenerateTextDialog;
