import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ROUTES } from 'components/App/App';
import { useStores } from 'services/useStore';
import { useTranslation } from 'react-i18next';

const BotHeader: React.FC = observer(() => {
  const { sessionStore, api } = useStores();
  const { t } = useTranslation();
  const { persons } = sessionStore.session;
  const [navsLeft, setNavsLeft] = useState<JSX.Element[] | undefined>();
  const [navsRight, setNavsRight] = useState<JSX.Element[] | undefined>();
  // eslint-disable-next-line react/display-name
  const CustomToggleLeft = React.forwardRef(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (setup: any, ref: React.LegacyRef<HTMLButtonElement>) => {
      const { onClick } = setup;
      return (
        <button
          className='left'
          type='button'
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          🦉
        </button>
      );
    }
  );
  // eslint-disable-next-line react/display-name
  const CustomToggleRight = React.forwardRef(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (setup: any, ref: React.LegacyRef<HTMLButtonElement>) => {
      const { onClick } = setup;
      return (
        <button
          className='right'
          type='button'
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          👤
        </button>
      );
    }
  );
  useEffect(() => {
    setTimeout(() => {
      const itemsLeft = [];
      const itemsRight = [];
      itemsLeft.push(
        <Dropdown.Item as={Link} key='news' to={ROUTES.news}>
          {t('news.mainTitle')}
        </Dropdown.Item>
      );
      itemsLeft.push(
        <Dropdown.Item as={Link} key='allProjects' to={ROUTES.projects}>
          {t('header.allProjects')}
        </Dropdown.Item>
      );
      itemsRight.push(
        <Dropdown.Item key='myProjects' as={Link} to={ROUTES.myProjects}>
          {t('header.myProjects')}
        </Dropdown.Item>
      );
      itemsRight.push(
        <Dropdown.Item key='profile' as={Link} to={ROUTES.profile}>
          {t('header.profile')}
        </Dropdown.Item>
      );
      /*if (project)
        itemsRight.push(
          <Dropdown.Item as={Link} key='myProjects' to={ROUTES.projects + '/' + project}>
            {t('header.myProject')}
          </Dropdown.Item>
        );*/
      //if (requestStore?._data?.length)
      itemsRight.push(
        <Dropdown.Item as={Link} key='myRequests' to={ROUTES.requests}>
          {t('header.myRequest')}
        </Dropdown.Item>
      );
      if (persons)
        itemsRight.push(
          <Dropdown.Item as={Link} key='myPersons' to={ROUTES.myPersons}>
            {t('header.myPersons')}
          </Dropdown.Item>
        );
      itemsRight.push(<Dropdown.Divider key='devider2' />);
      itemsRight.push(
        <Dropdown.Item key='bug' onClick={() => api.bugDialog(true)}>
          {t('bug.header')}
        </Dropdown.Item>
      );

      setNavsLeft(itemsLeft);
      setNavsRight(itemsRight);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className='bot-header-menu'>
      <Dropdown drop='end'>
        <Dropdown.Toggle key='toggle' as={CustomToggleLeft} title='' />
        <Dropdown.Menu key='menu'>{navsLeft}</Dropdown.Menu>
      </Dropdown>
      <div className='bot-header-center'>{sessionStore.appSettings.botHeader}</div>
      <Dropdown drop='start'>
        <Dropdown.Toggle key='toggle' as={CustomToggleRight} title='' />
        <Dropdown.Menu key='menu'>{navsRight}</Dropdown.Menu>
      </Dropdown>
    </div>
  );
});

export default BotHeader;
