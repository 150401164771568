import BaseStore from './BaseStore';

export type TBilling = {
  billing_id: string;
  user_id: number;
  billing_amount: string;
  billing_date: string;
  billing_action: string;
  project_id: number;
  project_name?: string;
  billing_info?: string;
  billing_method?: string;
};
class BillingsStore extends BaseStore {
  idKey = 'person_id';
  get data(): TBilling[] {
    return this._data as TBilling[];
  }
}
export default BillingsStore;
