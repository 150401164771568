import { observer } from 'mobx-react';
import React from 'react';
import { Button, Card, Col } from 'react-bootstrap';
import { TQuest } from 'stores/QuestsStore';
import { Link } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import { InfoCircle, Pencil, Trash } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import StatusesInCard from 'components/Heplers/StatusesInCard';
import { useStores } from '../../services/useStore';
import defaultImage from '../../assets/img/default.png';
import styles from '../Heplers/style.module.scss';

const QuestCard: React.FC<TQuest> = observer((quest: TQuest) => {
  const { t } = useTranslation();
  const { api, questStore } = useStores();
  const plot = quest.plot || false;

  let questImage = quest.img_id
    ? `${api.settings.url.server}img/${quest.img_id}.${quest.img_mime}`
    : '';
  if (!questImage && quest.quest_images) {
    const [imgId, imgMime] = quest.quest_images[0].split('.');
    questImage = `${api.settings.url.server}img/${imgId}.${imgMime}`;
  } else if (!questImage) questImage = defaultImage;

  const deleteQuest = (questId: number | undefined) => {
    if (!questId) return;
    api
      .deleteItem('quest', questId, t('global.deletePrompt'))
      .then((data) => {
        questStore.remove(questId);
      })
      .catch((e) => {});
  };

  const adminActions =
    api.isManager(quest.project_id) && api.projectIsActive() ? (
      <>
        <Button variant='danger' size='sm' onClick={() => deleteQuest(quest.quest_id)}>
          <Trash />
        </Button>
        {'  '}
        <Link to={(plot ? ROUTES.plotEdit : ROUTES.questEdit) + '/' + quest.quest_id}>
          <Button variant='primary' size='sm'>
            <Pencil />
          </Button>
        </Link>
      </>
    ) : (
      ''
    );
  const style = {} as React.CSSProperties;
  const isDraft = quest.quest_draft === 1;
  if (isDraft) style.background = '#ffe8cf';
  return (
    <Col style={{ marginTop: '0.5rem', minWidth: 300 }}>
      <Card style={style}>
        <div className='card_image' style={{ backgroundImage: `url(${questImage})` }} />

        <Card.Body>
          <Card.Title>{quest.quest_name}</Card.Title>
        </Card.Body>
        <Card.ImgOverlay className='rightText' style={{ position: 'static' }}>
          {adminActions}
          {'  '}
          <Link to={(plot ? ROUTES.plots : ROUTES.quests) + '/' + quest.quest_id}>
            <Button variant='primary' size='sm'>
              <InfoCircle />
            </Button>
          </Link>
        </Card.ImgOverlay>
        {isDraft ? <div className={styles.draftCard}>{t('global.draft')}</div> : ''}
        <StatusesInCard status={quest.quest_status} />
      </Card>
    </Col>
  );
});
export default QuestCard;
