/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ROUTES } from 'components/App/App';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TPerson } from 'stores/PersonsStore';
import { Badge } from 'react-bootstrap';
import { ProjectTypes } from 'stores/ProjectsStore';
import { useStores } from '../../services/useStore';
import Ava from './Ava';

export interface IPersonShow {
  personId: number | undefined;
  hideEmpty?: boolean;
  title?: boolean;
  style?: React.CSSProperties;
  link?: boolean;
  hideName?: boolean;
  size?: number;
  showUser?: boolean;
  linkInDialog?: boolean;
  description?: string;
  hideImage?: boolean;
}

const PersonShow: React.FC<IPersonShow> = observer(
  ({
    personId,
    hideEmpty = false,
    title = false,
    style,
    link = false,
    hideName = false,
    size = 50,
    showUser = false,
    linkInDialog = true,
    hideImage = false,
    description,
  }) => {
    const { t } = useTranslation();
    const { api, personStore } = useStores();
    const [person, setPerson] = useState<TPerson>();
    const [isLARP, setIsLARP] = useState<boolean>(false);

    const clickHandler = () => {
      if (linkInDialog && personId) api.showDialog('person', personId);
    };

    useEffect(() => {
      if (!personId) return;
      const personData: TPerson = personStore.get(personId);
      setPerson(personData);
      setIsLARP(api.getProjectType() === ProjectTypes.LARP);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personId]);

    if (person) {
      const content = (
        <div style={{ display: 'flex', alignItems: 'flex-start', ...style }}>
          {hideImage ? (
            ''
          ) : person.user_image && showUser ? (
            <Ava size={size} img={person.user_image} />
          ) : (
            <Ava size={size} img_id={person.img_id} img_mime={person.img_mime} />
          )}

          {'  '}
          {hideName ? (
            ''
          ) : (
            <div>
              <b role='link' onClick={clickHandler}>
                {person.person_name}
              </b>
              {title ? <div>{person.person_title}</div> : ''}
              {description ? <div>{description}</div> : ''}
              {isLARP && person.person_virtual ? (
                <div>
                  <Badge bg='dark'>{t('global.virtual')}</Badge>
                </div>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      );
      return link && !linkInDialog && person?.person_id ? (
        <Link to={ROUTES.persons + '/' + (person?.person_id || 0)}>{content}</Link>
      ) : (
        content
      );
    }
    if (hideEmpty) return <></>;
    return <div>{t('global.notSelect')}</div>;
  }
);
export default PersonShow;
