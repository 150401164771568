/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-danger */
import { observer } from 'mobx-react';
import React from 'react';
import { Badge, ListGroup } from 'react-bootstrap';
import { Link45deg } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { TText } from 'stores/TextsStore';
import { useStores } from '../../services/useStore';
import CarouselList from './CarouselList';

export type TTextDescriptions = { [key: string]: string };

export interface ITextList {
  texts: TText[];
  controll?: boolean;
  linkInDialog?: boolean;
  asManager?: boolean;
}

const TextList: React.FC<ITextList> = observer(
  ({ texts, linkInDialog = true, asManager = false }) => {
    const { t } = useTranslation();
    const { api } = useStores();
    const clickHandler = (clickId: number) => {
      if (clickId && linkInDialog) api.showDialog('text', clickId);
    };

    /*const showDescription = (item: TText) => (
      <p
        className='person-notice'
        dangerouslySetInnerHTML={{ __html: item.text_description || '' }}
      />
    );*/
    if (!texts?.length) return <></>;
    if (!asManager)
      return (
        <>
          {texts?.length
            ? texts.map((item: TText) => (
                <div key={item.text_id} style={{ clear: 'both' }}>
                  {item?.text_images?.length ? (
                    <CarouselList
                      imgList={item.text_images}
                      width={api.isBot() ? '100%' : 400}
                      height={300}
                      float
                      noCarusel={item.text_images.length === 1}
                    />
                  ) : (
                    ''
                  )}
                  <h3>{item?.title}</h3>
                  {/*showDescription(item)*/}
                </div>
              ))
            : ''}
        </>
      );
    return (
      <>
        <ListGroup>
          <ListGroup.Item
            variant='success'
            key='head'
            as='li'
            className='d-flex justify-content-between align-items-start'
          >
            <div className='me-auto'>
              <div className='fw-bold'>
                <h6 style={{ marginBottom: 0 }}>
                  <Link45deg />
                  &nbsp;{t('text.linked')} [{texts?.length}]
                </h6>
              </div>
            </div>
          </ListGroup.Item>
          {texts?.length ? (
            texts.map((item: TText) => (
              <ListGroup.Item
                key={item.text_id}
                as='li'
                className='d-flex justify-content-between align-items-start'
              >
                <div className='me-auto' style={{ width: '100%' }}>
                  <div
                    className='fw-bold'
                    role='link'
                    onClick={() => clickHandler(item.text_id || 0)}
                  >
                    {item?.title}
                  </div>
                  {/*showDescription(item)*/}
                  <div className='me-auto d-flex' style={{ justifyContent: 'space-between' }}>
                    <small className='text-muted'>{api.timeFormat(item.text_modified)}</small>
                    <div>
                      <Badge bg='info'>{t(item.type + '.' + item.type)}</Badge>{' '}
                      <Badge bg='secondary'>{item.text_status}</Badge>
                    </div>
                  </div>
                </div>
              </ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item
              key='empty'
              as='li'
              className='d-flex justify-content-between align-items-start'
            >
              &nbsp;
            </ListGroup.Item>
          )}
        </ListGroup>
      </>
    );
  }
);
export default TextList;
