import { observer } from 'mobx-react';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { TTask } from 'stores/TasksStore';
import { Link } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import {
  ChevronDoubleLeft,
  ChevronDoubleRight,
  InfoCircle,
  Pencil,
  Person,
  Trash,
} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../services/useStore';
import defaultImage from '../../assets/img/default.png';

const TaskCard: React.FC<TTask> = observer((task: TTask) => {
  const { t } = useTranslation();
  const { api, taskStore } = useStores();

  let taskImage = task.img_id
    ? `${api.settings.url.server}img/${task.img_id}.${task.img_mime}`
    : '';
  if (!taskImage && task.task_images) {
    const [imgId, imgMime] = task.task_images[0].split('.');
    taskImage = `${api.settings.url.server}img/${imgId}.${imgMime}`;
  } else if (!taskImage) taskImage = defaultImage;

  const deleteTask = (taskId: number | undefined) => {
    if (!taskId) return;
    api
      .deleteItem('task', taskId, t('global.deletePrompt'))
      .then((data) => {
        taskStore.remove(taskId);
      })
      .catch((e) => {});
  };

  const moveLeft = async () => {
    if (!task.task_id) return;
    const newFlow = task.task_flow === 'done' ? 'inProgress' : 'open';
    await api.taskFlow(newFlow, task.task_id);
    await api.getTasks();
  };

  const moveRight = async () => {
    if (!task.task_id) return;
    const newFlow = task.task_flow === 'open' ? 'inProgress' : 'done';
    await api.taskFlow(newFlow, task.task_id);
    await api.getTasks();
  };

  const adminActions =
    api.isManager(task.project_id) && api.projectIsActive() ? (
      <Row>
        <Col lg={5} style={{ paddingTop: 4 }}>
          <Person /> {task.user_name}
        </Col>
        <Col lg={7} className='text-end' style={{ whiteSpace: 'nowrap' }}>
          {task.task_flow !== 'open' ? (
            <Button variant='info' size='sm' onClick={moveLeft}>
              <ChevronDoubleLeft />
            </Button>
          ) : (
            ''
          )}{' '}
          <Button variant='danger' size='sm' onClick={() => deleteTask(task.task_id)}>
            <Trash />
          </Button>
          {'  '}
          <Link to={ROUTES.taskEdit + '/' + task.task_id}>
            <Button variant='primary' size='sm'>
              <Pencil />
            </Button>
          </Link>
          {'  '}
          <Button
            variant='primary'
            size='sm'
            onClick={() => task.task_id && api.showDialog('task', task.task_id)}
          >
            <InfoCircle />
          </Button>
          {'  '}
          {task.task_flow !== 'done' ? (
            <Button variant='info' size='sm' onClick={moveRight}>
              <ChevronDoubleRight />
            </Button>
          ) : (
            ''
          )}
        </Col>
      </Row>
    ) : (
      ''
    );
  return (
    <Col className='mb-2' style={{ minWidth: 200 }}>
      <Card>
        <Card.Body>
          {task.img_id ? (
            <div className='card_image' style={{ backgroundImage: `url(${taskImage})` }} />
          ) : (
            ''
          )}
          {task.task_name}
        </Card.Body>
        <Card.Footer>{adminActions}</Card.Footer>
      </Card>
    </Col>
  );
});
export default TaskCard;
