/* eslint-disable react/no-danger */
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import {
  Accordion,
  Badge,
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { Trash, Plus } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';
import { TLocationInfo } from 'stores/PrototypeLocationStore';
import { TPersonInfo } from 'stores/PrototypePersonStore';

const PrototypeLARPLocation: React.FC = observer(() => {
  const { t } = useTranslation();
  const { api, prototypeLocationStore, prototypePersonStore } = useStores();
  const [editLocationWindowShow, setEditLocationWindowShow] = useState(0);
  const [editLocation, setEditLocation] = useState<TLocationInfo>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateLocation = (id: number, event?: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (id > 0) {
      setEditLocation({ ...prototypeLocationStore.get(id) });
    } else {
      setEditLocation({ description: '', name: '', title: '', id: 0 });
    }
    setEditLocationWindowShow(id);
  };
  const saveLocation = () => {
    if (editLocationWindowShow > 0) {
      prototypeLocationStore.update(editLocation);
    } else {
      prototypeLocationStore.add({ ...editLocation, id: prototypeLocationStore.ids });
    }
    setEditLocationWindowShow(0);
  };
  const deleteLocation = (id: number, silent: boolean = false): string => {
    if (id < 0) return '';
    const element = prototypeLocationStore.get(id);
    let confirm = silent;
    if (!confirm) confirm = api.confirm(t('projectLARPAI.deleteConfirm') + element.name + '?');
    if (confirm) {
      prototypeLocationStore.remove(id);
      return element.name;
    }
    return '';
  };
  const buildLocation = (locationElement: TLocationInfo, index: number) => {
    return (
      <OverlayTrigger
        key={locationElement.id || index}
        placement='left'
        overlay={
          <Tooltip id={`tooltip-${locationElement.id || index}`}>
            {locationElement.description}
          </Tooltip>
        }
      >
        <Row className='location'>
          <Col onClick={(e) => updateLocation(locationElement.id, e)}>
            <b>{locationElement.name}</b>
            <div>{locationElement.title}</div>
          </Col>
          <Col className='deleteButton'>
            <Button
              variant='danger'
              size='sm'
              type='button'
              onClick={() => deleteLocation(locationElement.id)}
            >
              <Trash fontSize={16} />
            </Button>
          </Col>
        </Row>
      </OverlayTrigger>
    );
  };
  return (
    <Accordion.Item eventKey='2'>
      <Accordion.Header>
        <Button
          variant='primary'
          size='sm'
          type='button'
          className='plusButton'
          onClick={(e) => updateLocation(-1, e)}
        >
          <Plus fontSize={16} />
        </Button>
        <b>{t('projectLARPAI.locations')}</b>
      </Accordion.Header>
      <Accordion.Body>{prototypeLocationStore.data.map(buildLocation)}</Accordion.Body>
      <Modal
        show={editLocationWindowShow !== 0}
        size='lg'
        key='editLocation'
        centered
        onHide={() => setEditLocationWindowShow(0)}
      >
        <Modal.Header closeButton>{t('projectLARPAI.locationName')}</Modal.Header>
        {editLocation ? (
          <Modal.Body style={{ backgroundColor: '#0000001c' }}>
            <Row>
              <Col>
                <h5>{t('projectLARPAI.locationTitle')}:</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  type='text'
                  size='sm'
                  required
                  value={editLocation.name}
                  onChange={(e) => setEditLocation({ ...editLocation, name: e.target.value })}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>{t('projectLARPAI.smallDescription')}:</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  type='text'
                  size='sm'
                  value={editLocation.title}
                  onChange={(e) => setEditLocation({ ...editLocation, title: e.target.value })}
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <h5>{t('projectLARPAI.fullDescription')}:</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  as='textarea'
                  rows={5}
                  size='sm'
                  value={editLocation.description}
                  onChange={(e) =>
                    setEditLocation({ ...editLocation, description: e.target.value })
                  }
                  style={{ height: '100px' }}
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                {prototypePersonStore.data
                  .filter((p: TPersonInfo) => p.location === editLocation.name)
                  .map((p: TPersonInfo) => (
                    <Badge bg='info' key={p.id} style={{ marginRight: 5 }}>
                      {p.name}
                    </Badge>
                  ))}
              </Col>
            </Row>
            <Row>
              <Col className='right-text mt-3' style={{ textAlign: 'right' }}>
                <Button variant='success' size='sm' type='button' onClick={() => saveLocation()}>
                  {t('projectLARPAI.save')}
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        ) : (
          ''
        )}
      </Modal>
    </Accordion.Item>
  );
});
export default PrototypeLARPLocation;
