import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'services/useStore';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import WayForPayButton from '../Buttons/WayForPayButton';
import PayPalButton from '../Buttons/PayPalButton';
import GooglePalButton from '../Buttons/GooglePalButton';

const PayDialog: React.FC = observer(() => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(30);
  const { api, sessionStore } = useStores();
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal
      show={sessionStore.appSettings.payDialogShow}
      size='sm'
      key='editModal'
      onHide={() => api.togglePayDialog(false)}
    >
      <Modal.Header closeButton>{t('billing.addBallance')}</Modal.Header>
      <Modal.Body>
        <Row className='mb-3 justify-content-md-center'>
          <Col xs sm={3} style={{ textAlign: 'right', fontSize: 20 }}>
            {t('billing.amount')}:
          </Col>
          <Col xs sm={3}>
            <Form.Control
              type='number'
              required
              size='sm'
              id='amount'
              style={{ height: 35 }}
              value={amount}
              onChange={(e) => setAmount(+e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col className='mb-2'>
            <GooglePalButton id='amount' width={265} />
          </Col>
          <Col>
            <PayPalButton id='amount' width={265} />
          </Col>
          <Col>{/*<WayForPayButton id='amount' width={265} />*/}</Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
});

export default PayDialog;
