import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TNews } from 'stores/NewsStore';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import BotCard from 'components/Heplers/BotCard';
import MarkdownText from 'components/Heplers/MarkdownText';
import { useStores } from '../../services/useStore';

const BotNewsList: React.FC = observer(() => {
  const { t } = useTranslation();
  const { newsStore, api, sessionStore } = useStores();
  api.noLogin();
  const [inited, setInit] = useState(false);
  let { data } = newsStore;
  if (!data) data = [];

  useEffect(() => {
    sessionStore.tgHeader(t('news.mainTitle'));
    api.getNewsBot().then(() => {
      setInit(true);
      setTimeout(() => {
        if (data.length < 5) api.useParalax();
      }, 200);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showNews = (news_id: number | undefined) => {
    //if (news_id) history(ROUTES.news + '/' + news_id);
    if (news_id) api.showDialog('news', news_id);
  };

  if (!inited) {
    return <div>{t('loading')}</div>;
  }
  return (
    <Container>
      {data.length ? (
        data.map((news: TNews) => (
          <BotCard
            key={news.news_id}
            noParalax={data.length < 5}
            id={news.news_id || 0}
            img={news.img_id ? `${news.img_id}.${news.img_mime}` : ''}
            onClick={showNews}
          >
            <div className='name'>{news.project_name}</div>
            <div className='info'>
              <MarkdownText noHTML text={news.news_description.split(' ').slice(0, 20).join(' ')} />
              ...
            </div>
            <div className='subtitle'>
              <div />
              <div style={{ textAlign: 'right', paddingRight: 15 }}>
                {api.timeFormat(news.news_created)}
              </div>
            </div>
          </BotCard>
        ))
      ) : (
        <Row>
          <Col>
            <Alert variant='warning'>
              <p>{t('news.noNews')}</p>
            </Alert>
          </Col>
        </Row>
      )}
    </Container>
  );
});
export default BotNewsList;
