import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const HomeBotInfoDialog: React.FC = observer(() => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(true);

  const close = () => {
    window.localStorage.showedInfoDialog = true;
    setShow(false);
  };

  useEffect(() => {
    if (window.localStorage?.showedInfoDialog) setShow(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      show={show}
      size='sm'
      style={{ width: '70%', marginLeft: '15%' }}
      key='editModal'
      centered
      onHide={close}
    >
      <Modal.Header closeButton>{t('dialog.howUse')}</Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>{t('dialog.menuInfo1')}</Col>
          </Row>
          <Row className='mt-3 mb-3'>
            <Col>
              <div className='firstDialog'>
                <button className='button1' type='button'>
                  🦉
                </button>
                <div>{'<--'}</div>
                <div style={{ fontSize: 12, whiteSpace: 'nowrap' }}>{t('dialog.buttons')}</div>
                <div>{'-->'}</div>
                <button className='button2' type='button'>
                  👤
                </button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {t('dialog.menuInfo2')}
              <br />
              <br />
              {t('dialog.menuInfo3')}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close} variant='success'>
          {t('dialog.ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});
export default HomeBotInfoDialog;
