import React, { useState } from 'react';
import { Button, Col, Container, FloatingLabel, Form, Modal, Row, Stack } from 'react-bootstrap';
import { useStores } from 'services/useStore';
import { useTranslation } from 'react-i18next';
import { TSessionRequest } from 'services/Api';
import { ROUTES } from 'components/App/App';
import { observer } from 'mobx-react';

interface IRegisterForm {
  setMode?: (mode: string) => void;
  hide: () => void;
}

const RegisterForm: React.FC<IRegisterForm> = observer(({ setMode, hide }) => {
  const { t } = useTranslation();
  const { api, sessionStore } = useStores();
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRePassword] = useState('');
  const [name, setName] = useState('');
  const checkRepass = (): boolean => {
    if (password !== '') {
      if (password === repassword) {
        //setReValid(true);
        return true;
      }
      return false;
    }
    return true;
  };

  const handleRegister = (event: React.FormEvent) => {
    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity()) {
      if (checkRepass()) {
        setValidated(true);
        let fbId = 0;
        if (window.localStorage.fbId) {
          fbId = window.localStorage.fbId;
          delete window.localStorage.fbId;
        }
        api.register({ email, password, name, fbId }).then((res: TSessionRequest) => {
          const { errors } = res;
          if (errors) {
            if (errors.user_email) setEmailInvalid(errors.user_email);
          } else {
            //history(ROUTES.profile);
            hide();
            setTimeout(() => {
              window.localStorage.firstRun = true;
              window.location.href = ROUTES.profile;
            }, 100);
          }
        });
        event.preventDefault();
        event.stopPropagation();
      }
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    return false;
  };
  /*const setRepassword = (re:string) => {
    setRePassword(re); 
    checkRepass();
  }*/
  return (
    <Modal
      show={!!sessionStore.appSettings.loginDialog}
      size='lg'
      key='editModal'
      centered
      onHide={hide}
    >
      <Modal.Header closeButton>{t('registerForm.header')}</Modal.Header>
      <Form onSubmit={handleRegister} action='#' validated={validated}>
        <Modal.Body>
          <Container fluid className='verticalPadding greenForm mt-3'>
            <Row>
              <Col>
                <FloatingLabel
                  controlId='floatingEmail'
                  label={t('loginForm.email')}
                  className='mb-3'
                >
                  <Form.Control
                    type='email'
                    required
                    size='lg'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t('loginForm.email')}
                    isInvalid={!!emailInvalid}
                  />
                  {emailInvalid ? (
                    <Form.Control.Feedback type='invalid'>
                      {t('registerForm.' + emailInvalid)}
                    </Form.Control.Feedback>
                  ) : (
                    <Form.Text muted>{t('registerForm.emailDesc')}</Form.Text>
                  )}
                </FloatingLabel>
                <FloatingLabel
                  controlId='floatingPassword'
                  label={t('loginForm.password')}
                  className='mb-3'
                >
                  <Form.Control
                    type='password'
                    required
                    size='lg'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={t('loginForm.password')}
                  />
                  <Form.Text muted>{t('registerForm.passwordDesc')}</Form.Text>
                </FloatingLabel>
                <FloatingLabel
                  controlId='floatingRePassword'
                  label={t('registerForm.repassword')}
                  className='mb-3'
                >
                  <Form.Control
                    type='password'
                    size='lg'
                    required
                    value={repassword}
                    onChange={(e) => setRePassword(e.target.value)}
                    placeholder={t('registerForm.repassword')}
                    isInvalid={!checkRepass()}
                  />
                  <Form.Text muted>{t('registerForm.repasswordDesc')}</Form.Text>
                </FloatingLabel>
                <FloatingLabel
                  controlId='floatingInput'
                  label={t('registerForm.name')}
                  className='mb-3'
                >
                  <Form.Control
                    type='text'
                    required
                    size='lg'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder={t('registerForm.name')}
                  />
                  <Form.Text muted>{t('registerForm.nameDesc')}</Form.Text>
                </FloatingLabel>
                <Form.Group className='mb-3'>
                  <Form.Check required label={t('registerForm.agree')} />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Stack direction='horizontal' gap={3}>
            <Button variant='primary' size='lg' type='submit'>
              {t('registerForm.submit')}
            </Button>
            {setMode ? (
              <Button variant='secondary' size='lg' type='submit' onClick={() => setMode('login')}>
                {t('loginForm.signIn')}
              </Button>
            ) : (
              ''
            )}
          </Stack>
        </Modal.Footer>
      </Form>
    </Modal>
  );
});

export default RegisterForm;
