import { observer } from 'mobx-react';
import React from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import TextInfo from './TextInfo';

interface ITexts {
  id?: number;
}

const Texts: React.FC<ITexts> = observer(({ id }) => {
  const textId = id || useParams().params || 0;
  return (
    <Container style={{ marginTop: 14 }}>
      <TextInfo textId={+textId} />
    </Container>
  );
});
export default Texts;
