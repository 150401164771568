import BaseStore from './BaseStore';

export type TCreateLocationReturn = {
  location_id?: number;
  location_name: string;
  location_title: string;
  location_description: string;
  prompt: string;
  text?: string;
  image?: string;
  aiImgId?: number;
  imageAutoAdd?: boolean;
  added?: boolean;
};
class GeneratedLocationsStore extends BaseStore {
  idKey = 'aiImgId';
  get data(): TCreateLocationReturn[] {
    return this._data as TCreateLocationReturn[];
  }
}
export default GeneratedLocationsStore;
