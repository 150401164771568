/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import CarouselList from 'components/Heplers/CarouselList';
import LocationShow from 'components/Heplers/LocationShow';
import BotPersonLinkList from 'components/Heplers/BotPersonLinkList';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TPerson } from 'stores/PersonsStore';
import { TLink } from 'stores/LinkStore';
import { People } from 'react-bootstrap-icons';
import { ROUTES } from 'components/App/App';
import { Link } from 'react-router-dom';
import { useStores } from '../../services/useStore';

export interface IBotPersonInfo {
  personId: number | undefined;
  imageHeight?: number;
  linkInDialog?: boolean;
  inWindow?: boolean;
}

const BotPersonInfo: React.FC<IBotPersonInfo> = observer(
  ({ personId, imageHeight, linkInDialog, inWindow }) => {
    const { t } = useTranslation();
    const { api, sessionStore, personStore, statusStore } = useStores();
    const [person, setPerson] = useState<TPerson>();
    const [imgList, setImgList] = useState([] as string[]);
    const [inited, setInit] = useState(false);
    const dataSource = () => {
      return new Promise((resolve, reject) => {
        if (!personId) {
          reject();
          return;
        }
        const data: TPerson = personStore.get(personId);
        if (data?.person_id) resolve(data);
        else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          api.getPersons(personId).then((da: any) => {
            setTimeout(() => resolve(da), 200);
          });
        }
      });
    };

    const groupList = () => {
      let statusesData: TLink[] = [];
      if (person?.statuses_list) {
        const statuseArray = person?.statuses_list.split(',');
        const uniqueArray = statuseArray.filter(
          (item, index) => statuseArray.indexOf(item) === index
        );
        statusesData = uniqueArray.map((i: string) => {
          const fromId = +i;
          const name = statusStore.get(fromId)?.status_name;
          return { fromId, name } as TLink;
        });
        if (!statusesData.length) return <></>;
      } else return <></>;
      return (
        <>
          <b>
            <People /> {t(statusesData.length === 1 ? 'status.status' : 'status.mainTitle')}:
          </b>{' '}
          {statusesData?.map((link: TLink, index: number) => (
            <React.Fragment key={(link.name || 'link') + link.fromId}>
              <a href='#' onClick={() => api.showDialog('status', link.fromId)}>
                {link.name}
              </a>
              {index !== statusesData.length - 1 && ', '}
            </React.Fragment>
          ))}
        </>
      );
    };

    useEffect(() => {
      if (personId) {
        setInit(false);
        dataSource().then(async (data) => {
          const lo = data as TPerson;
          if (lo.project_id) await api.updateProjectData(lo.project_id);
          if (!sessionStore.session.user_id) api.currentProject(lo.project_id || 0);
          setPerson(lo);
          if (lo.person_images) setImgList(lo.person_images);
          else if (lo.img_id) setImgList([`${lo.img_id}.${lo.img_mime}`]);
          else setImgList([]);
          setInit(true);
        });
        if (inWindow) api.useParalax();
      }
    }, [personId]);

    if (!person || !inited) return <div>{t('global.loading')}</div>;

    const adminActions =
      personId && api.isUserHavePerson(personId) ? (
        <>
          <BotPersonLinkList id={personId || 0} muteLoadFromAPI autogenerate linkInDialog />
        </>
      ) : (
        ''
      );
    return (
      <Container fluid className='verticalPadding greenForm'>
        <Row className='justify-content-md-center'>
          <Col xs lg={12} className='person-info'>
            <div className='mb-1'>
              <b>{t('project.project')}:</b>{' '}
              <Link to={ROUTES.projects + '/' + person.project_id}>{person.project_name}</Link>
            </div>
            {imgList.length ? (
              <CarouselList
                imgList={imgList}
                label={person.person_name}
                height={api.isBot() ? sessionStore.appSettings.botWidht / 1.2 : imageHeight}
              />
            ) : (
              <>
                <h2>{person.person_name}</h2>
              </>
            )}
            {!sessionStore.session.user_id ? (
              <div className='rightText'>
                <h4>
                  <Button variant='link' onClick={() => api.toggleLoginDialog(true)}>
                    <Badge bg='danger'>{t('projects.registerForRequest')}</Badge>
                  </Button>
                </h4>
              </div>
            ) : (
              ''
            )}
            <div className='mt-3 mb-3'>{person.person_title}</div>
            {person.person_description ? <p>{person.person_description}</p> : ''}
            <LocationShow
              locationId={person.location_id}
              locationName={person.location_name}
              linkInDialog={linkInDialog}
              hideEmpty
              showDescription
            />
            {groupList()}
            {adminActions}
          </Col>
        </Row>
      </Container>
    );
  }
);
export default BotPersonInfo;
