import { observer } from 'mobx-react';
import React from 'react';
import { CardChecklist } from 'react-bootstrap-icons';

export interface ITextStatusShow {
  status: string | undefined;
}

const TextStatusShow: React.FC<ITextStatusShow> = observer(({ status }) => {
  if (!status) return <></>;
  return (
    <div>
      <CardChecklist />
      {'  '}
      <b>{status}</b>
    </div>
  );
});
export default TextStatusShow;
