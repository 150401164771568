/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Person } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { TUser } from 'stores/UsersStore';
import { TUserName, TUserSearch } from 'services/Api';
import { useStores } from '../../services/useStore';
import Ava from './Ava';

export interface IUserShow {
  userId: number | undefined;
  isManager?: boolean;
  hideEmpty?: boolean;
  allUsers?: boolean;
  usersData?: TUser[];
  linkInDialog?: boolean;
  showAva?: boolean;
  isSearch?: boolean;
  showId?: boolean;
}

const UserShow: React.FC<IUserShow> = observer(
  ({
    userId,
    isManager = false,
    hideEmpty = false,
    allUsers = false,
    usersData,
    linkInDialog,
    showAva = true,
    isSearch = false,
    showId = false,
  }) => {
    const { t } = useTranslation();
    const { userStore, api, sessionStore } = useStores();
    const [users, setUsers] = useState<TUser[]>();
    const [userData, setUserData] = useState<TUser>();
    const clickHandler = () => {
      if (linkInDialog && userId) api.showDialog('user', userId);
    };

    useEffect(() => {
      if (!userId) return;
      if (usersData) {
        setUsers(usersData);
      } else if (allUsers) api.getAllUsers().then((data: TUser[]) => setUsers(data));
    }, []);

    useEffect(() => {
      let u: TUser | undefined;
      let data: TUser = {} as TUser;
      if (!userId) return;
      if (isSearch && sessionStore.appSettings.userSearch) {
        const fuser = sessionStore.appSettings.userSearch.find(
          (user: TUserSearch) => user.id === userId
        );
        if (fuser) {
          data = {
            user_name: fuser.name,
            user_id: fuser.id,
            image: fuser.image,
          } as TUser;
          setUserData(data);
        }
      } else {
        if (users?.length) {
          u = users.find((us: TUser) => us.user_id === userId);
        }
        if (!u) {
          u = userStore.get(userId);
        }
        if (u) {
          const image = u.img_id ? u.img_id + '.' + u.img_mime : u.image;
          data = {
            user_name: u.user_name,
            user_id: u.user_id,
            image,
          } as TUser;
          setUserData(data);
        } else {
          api.getUsersName(userId).then((userName: TUserName) => {
            if (userName.user_name) {
              data = {
                user_name: userName.user_name,
                user_id: userName.user_id,
                image: userName.user_image,
              } as TUser;
              setUserData(data);
            }
          });
        }
      }
    }, [users, userId]);

    if (isManager) {
      if (!userData && hideEmpty) return <></>;
      return (
        <div
          role='link'
          onClick={clickHandler}
          style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}
        >
          {showAva && userData ? (
            userData.image ? (
              <Ava size={50} img={userData.image} />
            ) : (
              <Ava size={50} img_id={userData.img_id} img_mime={userData.img_mime} />
            )
          ) : (
            <Person />
          )}
          {'  '}
          <b>
            {userData ? userData.user_name : t('global.notSelect')}
            {showId && api.isAdmin() ? ` [${userId}]` : ''}
          </b>
        </div>
      );
    }
    if (userData)
      return (
        <div
          role='link'
          onClick={clickHandler}
          style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}
        >
          {showAva && userData ? (
            userData.image ? (
              <Ava size={50} img={userData.image} />
            ) : (
              <Ava size={50} img_id={userData.img_id} img_mime={userData.img_mime} />
            )
          ) : (
            <Person />
          )}
          {'  '}
          <b>{userData.user_name}</b>
        </div>
      );
    if (hideEmpty) return <></>;
    return <div>{t('global.notSelect')}</div>;
  }
);
export default UserShow;
