/* eslint-disable react/no-danger */
import React from 'react';
import { Col, Row } from 'react-bootstrap';

export type TTextHighLight = {
  text?: string;
  findText: string;
  className?: string;
};

const TextHighLight: React.FC<TTextHighLight> = ({
  text,
  findText,
  className = 'search-high-light',
}) => {
  if (text?.toLowerCase().indexOf(findText.toLowerCase()) === -1) return <></>;
  const d = document.createElement('DIV');
  d.innerHTML = text || '';
  const clearText = d.innerText;
  const data = clearText
    .toLowerCase()
    .replaceAll(findText.toLowerCase(), `<span class='${className}'>${findText}</span>`);

  return (
    <Row className="justify-content-md-center mb-1">
      <Col>
        <div
          className="search-high-light-container"
          dangerouslySetInnerHTML={{ __html: data || '' }}
        />
      </Col>
    </Row>
  );
};
export default TextHighLight;
