import { action, makeObservable, observable } from 'mobx';
import { TUserSearch } from 'services/Api';
import { RootStore } from './RootStore';
import { ProjectTypes } from './ProjectsStore';

export enum UserStatus {
  User = 'user',
  Manager = 'manager',
  Editor = 'editor',
  Admin = 'admin',
  Owner = 'owner',
  Participant = 'participant',
  Subscriber = 'subscriber',
}

export type TUserRole = {
  user_id?: number;
  project_id: number;
  project_name?: string;
  user_name?: string;
  status: string;
};

export type TBlackList = {
  user_id: number;
  user_name: string;
  type: string;
};

export type TUserPerson = {
  person_id: number;
  person_name: string;
};

export type TUserSettings = {
  allowLORE?: boolean;
  openAiKey?: string;
  useAI?: boolean;
  useAIOwerProject?: boolean;
};

export type TSession = {
  user_id: number;
  user_session: string;
  user_status: string;
  user_role: TUserRole[];
  user_project: number;
  user_email: string;
  user_name: string;
  user_balance: number;
  user_settings: TUserSettings;
  project_name?: string;
  login_as: string;
  googleId: boolean;
  fbId: boolean;
  persons: number;
  isRegister?: boolean;
  project_owner: number;
  project_type: ProjectTypes;
};

export type TProfile = {
  user_id?: number;
  user_email: string;
  user_pass?: string;
  user_status?: string;
  user_session?: string;
  user_name: string;
  user_tel: string;
  user_link: string;
  user_contact: string;
  user_fio: string;
  user_deny: string;
  user_blacklist: string;
  user_medinfo: string;
  user_project?: number;
  user_role?: TUserRole[];
  user_settings: TUserSettings | string;
  img_id?: string;
  img_mime?: string;
  telegram_check?: string;
  telegram_id?: number;
};

type TDialogHistory = {
  type: string;
  id: number;
};

export type TAppSettings = {
  dialogType: string | false;
  dialogId: number | false;
  dialogHistory: TDialogHistory[];
  imageShow: string;
  imageGenerateShow: boolean;
  textGenerateShow: boolean;
  personGenerateShow: boolean;
  locationGenerateShow: boolean;
  payDialogShow: boolean;
  textNodiceShow: boolean;
  fastAction: boolean;
  requestCheckDialog: number;
  rolePropositionDialog: number;
  alertMessage: string | false;
  loginDialog: boolean;
  notice: string;
  pricingShow:
    | {
        callback: (name: string | false) => void;
        _default?: string | false;
      }
    | false;
  bugDialogShow: boolean;
  questGenerateShow: boolean;
  imageGenerateText: string | undefined;
  imageGenerateCallback: () => void | undefined;
  isBot: boolean;
  botWidht: number;
  botHeader: string;
  searchQuery: string;
  userSearch: TUserSearch[];
};

export interface ISessionStore {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
  session: TSession;
  appSettings: TAppSettings;
  update: (data: TSession) => void;
  reset: () => void;
}

class SessionStore implements ISessionStore {
  session!: TSession;
  appSettings!: TAppSettings;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.appSettings = {
      dialogId: false,
      dialogType: false,
      dialogHistory: [],
      bugDialogShow: false,
      questGenerateShow: false,
      personGenerateShow: false,
      locationGenerateShow: false,
      pricingShow: false,
      fastAction: false,
      alertMessage: false,
      loginDialog: false,
      imageGenerateShow: false,
      textGenerateShow: false,
      payDialogShow: false,
      requestCheckDialog: 0,
      rolePropositionDialog: 0,
      textNodiceShow: false,
      notice: '',
      imageShow: '',
      imageGenerateText: '',
      isBot: false,
      botWidht: 300,
      botHeader: '',
      searchQuery: '',
      userSearch: [],
      imageGenerateCallback: () => {},
    } as TAppSettings;

    makeObservable(this, {
      session: observable,
      appSettings: observable,
      update: action,
      reset: action,
      role: action,
      projectName: action,
      lookProject: action,
      toggleImageGenerateDialog: action,
      togglePersonGenerateDialog: action,
      toggleLocationGenerateDialog: action,
      toggleQuestGenerateDialog: action,
      toggleFastAction: action,
      togglePricingGenerateDialog: action,
      toggleRequestCheckDialog: action,
      togglePayDialog: action,
      toggleAlertMessage: action,
      toggleLoginDialog: action,
      showImagePopup: action,
      openDialog: action,
      closeDialog: action,
      dialogBack: action,
      setNotice: action,
      isBot: action,
      tgWidth: action,
      tgHeader: action,
      setSearchQuery: action,
    });
  }

  update = (data: TSession): void => {
    this.session = data;
  };

  reset = (): void => {
    this.session = {
      user_id: 0,
      user_session: '',
      user_status: '',
      user_project: 0,
      user_email: '',
      user_name: '',
      user_balance: 0,
      user_settings: {},
      user_role: [],
      persons: 0,
      login_as: 'none',
      googleId: false,
      fbId: false,
      project_owner: 0,
      project_type: ProjectTypes.LARP,
    };
  };

  role = (): string => {
    if (!this.session.user_project) return this.session.user_status;
    if (this.session.user_status === UserStatus.Admin) return this.session.user_status;
    return (
      this.session.user_role.find(
        (role: TUserRole) => role.project_id === this.session.user_project
      )?.status || UserStatus.User
    );
  };

  projectName = (): string => {
    if (!this.session.user_project) return '';
    return (
      this.session.user_role.find(
        (role: TUserRole) => role.project_id === this.session.user_project
      )?.project_name || ''
    );
  };

  lookProject = (user_project: number) => {
    this.session = { ...this.session, user_project };
  };

  toggleImageGenerateDialog = (
    newStatus: boolean,
    text: string | undefined,
    callback: () => void | undefined
  ) => {
    this.appSettings.imageGenerateShow = newStatus;
    this.appSettings.imageGenerateText = text;
    this.appSettings.imageGenerateCallback = callback;
  };

  togglePersonGenerateDialog = (newStatus: boolean) => {
    this.appSettings.personGenerateShow = newStatus;
  };

  toggleLocationGenerateDialog = (newStatus: boolean) => {
    this.appSettings.locationGenerateShow = newStatus;
  };

  toggleQuestGenerateDialog = (newStatus: boolean) => {
    this.appSettings.questGenerateShow = newStatus;
  };

  bugDialog = (newStatus: boolean) => {
    this.appSettings.bugDialogShow = newStatus;
  };

  togglePayDialog = (newStatus: boolean) => {
    this.appSettings.payDialogShow = newStatus;
  };

  toggleFastAction = (newStatus: boolean) => {
    this.appSettings.fastAction = newStatus;
  };

  togglePricingGenerateDialog = (
    callback: ((name: string | false) => void) | false,
    _default?: string | false
  ) => {
    this.appSettings.pricingShow = callback ? { callback, _default } : false;
  };

  toggleTextGenerateDialog = (newStatus: boolean) => {
    this.appSettings.textGenerateShow = newStatus;
  };

  toggleNoticeDialog = (newStatus: boolean) => {
    this.appSettings.textNodiceShow = newStatus;
  };

  toggleLoginDialog = (newStatus: boolean) => {
    this.appSettings.loginDialog = newStatus;
  };

  toggleRequestCheckDialog = (requestId: number) => {
    this.appSettings.requestCheckDialog = requestId;
  };

  toggleRolePropositionDialog = (personId: number) => {
    this.appSettings.rolePropositionDialog = personId;
  };

  toggleAlertMessage = (text: string | false) => {
    this.appSettings.alertMessage = text;
  };
  showImagePopup = (image: string) => {
    this.appSettings.imageShow = image;
  };

  openDialog = (type: string, id: number) => {
    this.appSettings.dialogType = type;
    this.appSettings.dialogId = id;
    this.appSettings.dialogHistory = [...this.appSettings.dialogHistory, { type, id }];
  };
  closeDialog = () => {
    this.appSettings.dialogType = false;
    this.appSettings.dialogId = false;
    this.appSettings.dialogHistory = [];
  };
  dialogBack = () => {
    this.appSettings.dialogHistory.pop();
    if (!this.appSettings.dialogHistory.length) return;
    const historyItem: TDialogHistory | undefined =
      this.appSettings.dialogHistory[this.appSettings.dialogHistory.length - 1];
    if (historyItem) {
      this.appSettings.dialogType = historyItem.type;
      this.appSettings.dialogId = historyItem.id;
      this.appSettings.dialogHistory = [...this.appSettings.dialogHistory];
    }
  };
  setNotice = (data: string) => {
    this.appSettings.notice = data;
  };
  getNotice = () => this.appSettings.notice;
  isBot = () => {
    this.appSettings.isBot = true;
  };
  tgWidth = (width: number) => {
    this.appSettings.botWidht = width;
  };
  tgHeader = (text: string) => {
    this.appSettings.botHeader = text;
  };
  setSearchQuery = (text: string) => {
    this.appSettings.searchQuery = text;
  };
}

export default SessionStore;
