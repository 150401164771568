import BaseStore from './BaseStore';

export enum QuestType {
  Plot = 'plot',
  Quest = 'quest',
}

export const QuestTypes: string[] = [QuestType.Plot, QuestType.Quest];

export type TQuest = {
  quest_id?: number;
  project_id?: number;
  quest_name: string;
  quest_type: string;
  quest_description?: string;
  quest_master?: number;
  quest_status?: string;
  quest_images?: string[];
  quest_draft?: number;
  img_id?: number;
  img_mime?: string;
  plot?: boolean;
};

class QuestsStore extends BaseStore {
  idKey = 'quest_id';
  get data(): TQuest[] {
    return this._data as TQuest[];
  }
}
export default QuestsStore;
