/* eslint-disable react/no-danger */
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import {
  Accordion,
  Badge,
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { Trash, Plus } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';
import { TGroupInfo } from 'stores/PrototypeGroupStore';
import { TPersonInfo } from 'stores/PrototypePersonStore';

const PrototypeLARPGroup: React.FC = observer(() => {
  const { t } = useTranslation();
  const { api, prototypeGroupStore, prototypePersonStore } = useStores();
  const [editGroupWindowShow, setEditGroupWindowShow] = useState(0);
  const [editGroup, setEditGroup] = useState<TGroupInfo>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateGroup = (id: number, event?: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (id > 0) {
      setEditGroup({ ...prototypeGroupStore.get(id) });
    } else {
      setEditGroup({ description: '', name: '', title: '', id: 0 });
    }
    setEditGroupWindowShow(id);
  };
  const saveGroup = () => {
    if (editGroupWindowShow > 0) {
      prototypeGroupStore.update(editGroup);
    } else {
      prototypeGroupStore.add({ ...editGroup, id: prototypeGroupStore.ids });
    }
    setEditGroupWindowShow(0);
  };
  const deleteGroup = (id: number, silent: boolean = false): string => {
    if (id < 0) return '';
    const element = prototypeGroupStore.get(id);
    let confirm = silent;
    if (!confirm) confirm = api.confirm(t('projectLARPAI.deleteConfirm') + element.name + '?');
    if (confirm) {
      prototypeGroupStore.remove(id);
      return element.name;
    }
    return '';
  };
  const buildGroup = (groupElement: TGroupInfo, index: number) => {
    return (
      <OverlayTrigger
        key={groupElement.id || index}
        placement='left'
        overlay={
          <Tooltip id={`tooltip-${groupElement.id || index}`}>{groupElement.description}</Tooltip>
        }
      >
        <Row className='group'>
          <Col onClick={(e) => updateGroup(groupElement.id, e)}>
            <b>{groupElement.name}</b>
            <div>{groupElement.title}</div>
          </Col>
          <Col className='deleteButton'>
            <Button
              variant='danger'
              size='sm'
              type='button'
              onClick={() => deleteGroup(groupElement.id)}
            >
              <Trash fontSize={16} />
            </Button>
          </Col>
        </Row>
      </OverlayTrigger>
    );
  };
  return (
    <Accordion.Item eventKey='3'>
      <Accordion.Header>
        <Button
          variant='primary'
          size='sm'
          type='button'
          className='plusButton'
          onClick={(e) => updateGroup(-1, e)}
        >
          <Plus fontSize={16} />
        </Button>
        <b>{t('projectLARPAI.groups')}</b>
      </Accordion.Header>
      <Accordion.Body>{prototypeGroupStore.data.map(buildGroup)}</Accordion.Body>
      <Modal
        show={editGroupWindowShow !== 0}
        size='lg'
        key='editGroup'
        centered
        onHide={() => setEditGroupWindowShow(0)}
      >
        <Modal.Header closeButton>{t('projectLARPAI.groupName')}</Modal.Header>
        {editGroup ? (
          <Modal.Body style={{ backgroundColor: '#0000001c' }}>
            <Row>
              <Col>
                <h5>{t('projectLARPAI.title')}:</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  type='text'
                  size='sm'
                  required
                  value={editGroup.name}
                  onChange={(e) => setEditGroup({ ...editGroup, name: e.target.value })}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>{t('projectLARPAI.smallDescription')}:</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  type='text'
                  size='sm'
                  value={editGroup.title}
                  onChange={(e) => setEditGroup({ ...editGroup, title: e.target.value })}
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <h5>{t('projectLARPAI.fullDescription')}:</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  as='textarea'
                  rows={5}
                  size='sm'
                  value={editGroup.description}
                  onChange={(e) => setEditGroup({ ...editGroup, description: e.target.value })}
                  style={{ height: '100px' }}
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                {prototypePersonStore.data
                  .filter((p: TPersonInfo) => p.group === editGroup.name)
                  .map((p: TPersonInfo) => (
                    <Badge bg='info' key={p.id} style={{ marginRight: 5 }}>
                      {p.name}
                    </Badge>
                  ))}
              </Col>
            </Row>
            <Row>
              <Col className='right-text mt-3' style={{ textAlign: 'right' }}>
                <Button variant='success' size='sm' type='button' onClick={() => saveGroup()}>
                  {t('projectLARPAI.save')}
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        ) : (
          ''
        )}
      </Modal>
    </Accordion.Item>
  );
});
export default PrototypeLARPGroup;
