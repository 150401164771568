/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { observer } from 'mobx-react';
import React from 'react';
import { Button, Card, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import { InfoCircle, Pencil, Trash } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import BaseStore from 'stores/BaseStore';
import { UserStatus } from 'stores/SessionStore';
import { useStores } from '../../services/useStore';
import defaultImage from '../../assets/img/default.png';
import styles from './style.module.scss';
import StatusesInCard from './StatusesInCard';

export interface IItemCard {
  store: string;
  type: string;
  id: number | undefined;
  showRoute?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editRoute?: string | ((data: any) => string);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  name?: string | ((data: any) => JSX.Element);
  title?: string;
  draftField?: string;
  statusField?: string;
  noProject?: boolean;
  height?: number;
  footer?: JSX.Element | undefined;
  preFooter?: JSX.Element | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  afterButton?(data: any): JSX.Element | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  calculateImage?(data: any): string;
  afterDelete?(id: number): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getStyle?(data: any): React.CSSProperties;
  minWidth?: number;
}

const ItemCard: React.FC<IItemCard> = observer(
  ({
    store,
    type,
    id = undefined,
    showRoute,
    editRoute,
    name = '',
    title = '',
    draftField,
    statusField,
    noProject = false,
    height = 50,
    footer,
    preFooter,
    afterDelete,
    afterButton,
    calculateImage,
    getStyle,
    minWidth = 270,
  }) => {
    const history = useNavigate();
    const { t } = useTranslation();
    const stores = useStores();
    if (!id) return <></>;
    const data = (stores[store as keyof typeof stores] as BaseStore).get(id);
    let dataImage = calculateImage ? calculateImage(data) : '';
    if (!dataImage) {
      dataImage = data?.img_id
        ? `${stores.api.settings.url.server}img/${data.img_id}.${data.img_mime}`
        : '';
    }
    if (!dataImage && data && data[type + '_images']) {
      const [imgId, imgMime] = data[type + '_images'][0].split('.');
      dataImage = `${stores.api.settings.url.server}img/${imgId}.${imgMime}`;
    } else if (!dataImage) dataImage = defaultImage;

    const deleteItem = () => {
      stores.api
        .deleteItem(type, id, t('global.deletePrompt'))
        .then((result) => {
          //@ts-ignore
          stores[store as keyof typeof stores].remove(id);
          if (afterDelete) afterDelete(id);
        })
        .catch((e) => {});
    };

    const isRequest = type === 'request';
    const showInfoHandler = () => {
      if (type === 'project') {
        stores.api.selectProject(id, () => {
          window.location.href = ROUTES.projects + '/' + id;
        });
      }
      if (isRequest) {
        stores.api.showDialog(
          data.request_type === 'invite' ? 'invite' : 'request',
          data.request_id || 0
        );
      } else if (showRoute) {
        //history(ROUTES[showRoute] + '/' + id);
        stores.api.showDialog(type, id);
      }
    };

    //только для редактирования собственных заявок
    const personalRequestActions =
      isRequest && data?.user_id === stores.sessionStore.session.user_id;

    const isManager =
      stores.api.isManager(data?.project_id) ||
      (noProject &&
        (stores.sessionStore.role() === UserStatus.Manager ||
          stores.sessionStore.role() === UserStatus.Owner));

    if (!data) return <></>;

    const goToLink = async (link: string, projectId?: number) => {
      if (type === 'project' && projectId) {
        await stores.api.getSelectProject(projectId);
        window.location.href = link;
        return;
      }
      history(link);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const adminActions = (dt: any) => {
      let canEdit = '';
      if (typeof editRoute === 'string')
        canEdit = editRoute && (personalRequestActions || isManager) ? editRoute : '';
      else if (editRoute) canEdit = editRoute(dt);
      if (!canEdit) return '';
      if (type !== 'project' && !stores.api.projectIsActive()) return '';
      return (
        <>
          {type !== 'project' ? (
            <Button variant='danger' size='sm' onClick={() => deleteItem()}>
              <Trash />
            </Button>
          ) : (
            ''
          )}
          {'  '}
          <Button
            variant='primary'
            size='sm'
            onClick={() => goToLink(ROUTES[canEdit as keyof typeof ROUTES] + '/' + id, id)}
          >
            <Pencil />
          </Button>
        </>
      );
    };
    //const titleName = isRequest ? data[title] || data.person_title : data[title];
    let style = {} as React.CSSProperties;
    const isDraft = draftField && data[draftField];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getName = (dta: any) => {
      if (typeof name === 'string') {
        return isRequest ? dta[name] || dta.person_name : dta[name];
      }
      if (name) return name(dta);
      return '';
    };
    if (getStyle) style = { ...style, ...getStyle(data) };
    if (isDraft) style.background = '#ffe8cf';
    return (
      <Col style={{ marginTop: '0.5rem', minWidth }}>
        <Card style={style}>
          <div
            role='link'
            onClick={showInfoHandler}
            className={'card_image' + (stores.api.isBot() ? ' paralax' : '')}
            style={{ backgroundImage: `url(${dataImage})` }}
          >
            <span className='card_image_id'>{id}</span>

            <Card.Body>
              <div>
                {name ? (
                  <Card.Title>
                    <div role='link' onClick={showInfoHandler}>
                      {getName(data)}
                    </div>
                  </Card.Title>
                ) : (
                  ''
                )}
                {/*title ? (
                <div
                  style={{
                    fontSize: '0.9rem',
                  }}
                >
                  {titleName}
                </div>
              ) : (
                ''
              )*/}
              </div>
            </Card.Body>
          </div>
          {preFooter || <div style={{ height: 10 }} />}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {footer || ''}
            <Card.ImgOverlay
              className='rightText'
              style={{ position: 'static', padding: '1rem', paddingTop: 0 }}
            >
              {adminActions(data)}
              {'  '}
              {showRoute ? (
                <Button variant='primary' size='sm' onClick={showInfoHandler}>
                  <InfoCircle />
                </Button>
              ) : (
                ''
              )}
              {afterButton ? afterButton(data) : ''}
            </Card.ImgOverlay>
          </div>
          {isDraft ? <div className={styles.draftCard}>{t('global.draft')}</div> : ''}
          {statusField ? <StatusesInCard status={data[statusField]} /> : ''}
        </Card>
      </Col>
    );
  }
);
export default ItemCard;
