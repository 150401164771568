import { observer } from "mobx-react";
import React from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import StatuseInfo from "./StatuseInfo";

const Statuses: React.FC = observer(() => {
  const statusId = useParams().params || 0;
  return (
    <Container style={{ background: "#fff", marginTop: 14 }}>
      <StatuseInfo statusId={+statusId} />
    </Container>
  );
});
export default Statuses;
