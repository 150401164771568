import { observer } from 'mobx-react';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Robot } from 'react-bootstrap-icons';
import { useStores } from 'services/useStore';

export interface IAICreateButton {
  type: string;
}

const AICreateButton: React.FC<IAICreateButton> = observer(({ type }) => {
  const { api } = useStores();
  const onclick = () => {
    switch (type) {
      case 'person':
        api.togglePersonGenerateDialog(true);
        break;
      case 'location':
        api.toggleLocationGenerateDialog(true);
        break;
      case 'quest':
        api.toggleQuestGenerateDialog(true);
        break;
      default:
        break;
    }
  };
  if (!api.canTextAi()) return <></>;
  return (
    <Button variant='warning' size='sm' onClick={onclick}>
      <Robot />
    </Button>
  );
});
export default AICreateButton;
