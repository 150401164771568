import { observer } from 'mobx-react';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import { useTranslation } from 'react-i18next';
import { /*Download,*/ InfoCircle, Pencil, Trash } from 'react-bootstrap-icons';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import ToolkitProvider /*, {
  Search,
  ExportCSVButtonProps,
}*/ from 'react-bootstrap-table2-toolkit';
import { RootStore } from 'stores/RootStore';
import { useStores } from '../../services/useStore';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import styles from './style.module.scss';

export interface IItemTable {
  store?: string;
  type: string;
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableData: Array<any>;
  columns: ColumnDescription[];
  draftField?: string;
  statusField?: string;
  showRoute?: string;
  editRoute?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  canEdit?: boolean | ((data: any) => boolean);
  needControll?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  afterButton?(data: any): JSX.Element | undefined;
  afterDelete?(id: number): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getStyle?(data: any): React.CSSProperties;
}

/*const ExportCSVButton = (props: ExportCSVButtonProps) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <Button variant="info" size="sm" onClick={handleClick}>
      <Download style={{ height: 27, width: 27 }} />
    </Button>
  );
};*/

const ItemTable: React.FC<IItemTable> = observer(
  ({
    store,
    type,
    tableData,
    columns,
    showRoute,
    draftField,
    statusField,
    id,
    editRoute,
    canEdit = false,
    needControll = true,
    afterDelete,
    afterButton,
    getStyle,
  }) => {
    //const { SearchBar } = Search;
    const { t } = useTranslation();
    const stores = useStores();
    const info = (rowId: number) => {
      if (type === 'project') {
        stores.api.selectProject(rowId, () => {
          window.location.href = ROUTES.projects + '/' + rowId;
        });
      } else if (showRoute) stores.api.showDialog(type, rowId);
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const deleteFormatter = (cell: undefined, row: any) => {
      const isDraft = draftField && row[draftField];
      const statuses = statusField && row[statusField] ? row[statusField].split(',') : [];
      return (
        <>
          {afterButton ? afterButton(row) : ''}&nbsp;
          {showRoute ? (
            <Button variant='info' size='sm' onClick={() => info(row[id])}>
              <InfoCircle />
            </Button>
          ) : (
            ''
          )}
          {(typeof canEdit === 'boolean' ? canEdit : canEdit(row)) && editRoute ? (
            <>
              {' '}
              <Link to={ROUTES[editRoute as keyof typeof ROUTES] + '/' + row[id]}>
                <Button variant='info' size='sm'>
                  <Pencil />
                </Button>
              </Link>{' '}
              <Button
                variant='danger'
                size='sm'
                onClick={() => {
                  if (!store) return;
                  stores.api.deleteItem(type, row[id], t('global.deletePrompt')).then((result) => {
                    const st: keyof RootStore = store as keyof RootStore;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    if (typeof (stores[st] as any)?.remove !== 'function') return;
                    if (row[id]) {
                      //@ts-ignore
                      stores[st].remove(row[id]);
                      if (afterDelete) afterDelete(row[id]);
                    }
                  });
                }}
              >
                <Trash />
              </Button>
            </>
          ) : (
            ''
          )}
          <br />
          {isDraft ? <div className={styles.draftCardTable}>{t('global.draft')}</div> : ''}
          {statuses.map((status: string) => (
            <div
              key={status}
              className={status.trim() === 'AI' ? styles.statusCardTableAI : styles.statusCardTable}
            >
              {status.trim()}
            </div>
          ))}
        </>
      );
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const IdFormatter = (cell: undefined, row: any) =>
      showRoute ? (
        <Link to={ROUTES[showRoute as keyof typeof ROUTES] + '/' + row[id]}>{row[id]}</Link>
      ) : (
        row[id]
      );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rowStyle = (row: any, rowIndex: number) => {
      let style = {} as React.CSSProperties;
      if (window.screen.width < 1000) style.fontSize = '0.7rem';
      const isDraft = draftField && row[draftField];
      if (isDraft) style.background = '#ffe8cf';
      else if (getStyle) style = { ...style, ...getStyle(row) };
      return style;
    };
    const collumnControlls = {
      dataField: 'edit',
      text: '',
      editable: false,
      csvExport: false,
      align: 'center',
      headerStyle: { width: Math.min(window.screen.width * 0.3, 160) },
      formatter: deleteFormatter,
    };
    const idField = {
      dataField: id,
      text: '#',
      sort: true,
      align: 'center',
      csvText: 'ID',
      headerStyle: { width: window.screen.width < 1000 ? 40 : 70 },
      formatter: IdFormatter,
    };

    if (!tableData) return <></>;
    return (
      <ToolkitProvider
        bootstrap4
        keyField={id}
        data={tableData}
        columns={needControll ? [idField, ...columns, collumnControlls] : [idField, ...columns]}
        search
        exportCSV={{
          fileName: type + '.csv',
          separator: ',',
          ignoreHeader: false,
          noAutoBOM: false,
        }}
      >
        {(props) => (
          <Container fluid>
            {/*<Row className="mb-3">
              <Col xs={6}>
                <SearchBar
                  {...{
                    srText: "",
                    placeholder: t("global.tableSearch"),
                    ...props.searchProps,
                  }}
                />
              </Col>
              <Col xs={1}>
                <ExportCSVButton {...props.csvProps}>
                  <Button variant="info" size="sm">
                    <Download />
                  </Button>
                </ExportCSVButton>
              </Col>
            </Row>*/}
            <Row>
              <Col lg={12} style={{ background: '#fff', padding: 0 }}>
                <BootstrapTable {...props.baseProps} rowStyle={rowStyle} />
              </Col>
            </Row>
          </Container>
        )}
      </ToolkitProvider>
    );
  }
);
export default ItemTable;
