/* eslint-disable react/no-danger */
import { observer } from 'mobx-react';
import React from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useStores } from 'services/useStore';
import PersonInfo from './PersonInfo';
import BotPersonInfo from './BotPersonInfo';

const Persons: React.FC = observer(() => {
  const personId = useParams().params || 0;
  const { api } = useStores();
  return (
    <Container style={{ marginTop: 14 }}>
      {api.isBot() ? (
        <BotPersonInfo personId={+personId} linkInDialog inWindow />
      ) : (
        <PersonInfo personId={+personId} linkInDialog inWindow />
      )}
    </Container>
  );
});
export default Persons;
