import React from 'react';
import { useTranslation } from 'react-i18next';
import image from '../../assets/img/noData.png';

export interface INoContent {}

const NoContent: React.FC<INoContent> = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        width: '80%',
        height: 600,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '10%',
        background: 'white',
        borderRadius: 20,
      }}
    >
      <h1>{t('global.emptyData')}</h1>
      <img src={image} style={{}} alt='' />
    </div>
  );
};
export default NoContent;
