/* eslint-disable react/no-danger */
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import {
  Accordion,
  Badge,
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { Trash, Plus } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';
import { TActionInfo } from 'stores/PrototypeActionStore';

const PrototypeLARPAction: React.FC = observer(() => {
  const { t } = useTranslation();
  const { api, prototypeActionStore } = useStores();
  const [editActionWindowShow, setEditActionWindowShow] = useState(0);
  const [editAction, setEditAction] = useState<TActionInfo>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateAction = (id: number, event?: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (id > 0) {
      setEditAction({ ...prototypeActionStore.get(id) });
    } else {
      setEditAction({ description: '', name: '', title: '', id: 0, persons: '', group: '' });
    }
    setEditActionWindowShow(id);
  };
  const saveAction = () => {
    if (editActionWindowShow > 0) {
      prototypeActionStore.update(editAction);
    } else {
      prototypeActionStore.add({ ...editAction, id: prototypeActionStore.ids });
    }
    setEditActionWindowShow(0);
  };
  const deleteAction = (id: number, silent: boolean = false): string => {
    if (id < 0) return '';
    const element = prototypeActionStore.get(id);
    let confirm = silent;
    if (!confirm) confirm = api.confirm(t('projectLARPAI.deleteConfirm') + element.name + '?');
    if (confirm) {
      prototypeActionStore.remove(id);
      return element.name;
    }
    return '';
  };
  const buildAction = (actionElement: TActionInfo, index: number) => {
    return (
      <OverlayTrigger
        key={actionElement.id || index}
        placement='left'
        overlay={
          <Tooltip id={`tooltip-${actionElement.id || index}`}>{actionElement.description}</Tooltip>
        }
      >
        <Row className='action'>
          <Col onClick={(e) => updateAction(actionElement.id, e)}>
            <b>{actionElement.name}</b>
            <div>{actionElement.title}</div>
          </Col>
          <Col className='deleteButton'>
            <Button
              variant='danger'
              size='sm'
              type='button'
              onClick={() => deleteAction(actionElement.id)}
            >
              <Trash fontSize={16} />
            </Button>
          </Col>
        </Row>
      </OverlayTrigger>
    );
  };
  return (
    <Accordion.Item eventKey='5'>
      <Accordion.Header>
        <Button
          variant='primary'
          size='sm'
          type='button'
          className='plusButton'
          onClick={(e) => updateAction(-1, e)}
        >
          <Plus fontSize={16} />
        </Button>
        <b>{t('projectLARPAI.actions')}</b>
      </Accordion.Header>
      <Accordion.Body>{prototypeActionStore.data.map(buildAction)}</Accordion.Body>
      <Modal
        show={editActionWindowShow !== 0}
        size='lg'
        key='editAction'
        centered
        onHide={() => setEditActionWindowShow(0)}
      >
        <Modal.Header closeButton>{t('projectLARPAI.action')}</Modal.Header>
        {editAction ? (
          <Modal.Body style={{ backgroundColor: '#0000001c' }}>
            <Row>
              <Col>
                <h5>{t('projectLARPAI.title')}:</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  type='text'
                  size='sm'
                  required
                  value={editAction.name}
                  onChange={(e) => setEditAction({ ...editAction, name: e.target.value })}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>{t('projectLARPAI.smallDescription')}:</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  type='text'
                  size='sm'
                  value={editAction.title}
                  onChange={(e) => setEditAction({ ...editAction, title: e.target.value })}
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <h5>{t('projectLARPAI.fullDescription')}:</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  as='textarea'
                  rows={5}
                  size='sm'
                  value={editAction.description}
                  onChange={(e) => setEditAction({ ...editAction, description: e.target.value })}
                  style={{ height: '100px' }}
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                {editAction.persons.split(',').map((item: string) => (
                  <Badge bg='info' key={item} style={{ marginRight: 5 }}>
                    {item}
                  </Badge>
                ))}
                <Badge>{editAction.group}</Badge>
              </Col>
            </Row>
            <Row>
              <Col className='right-text mt-3' style={{ textAlign: 'right' }}>
                <Button variant='success' size='sm' type='button' onClick={() => saveAction()}>
                  {t('projectLARPAI.save')}
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        ) : (
          ''
        )}
      </Modal>
    </Accordion.Item>
  );
});
export default PrototypeLARPAction;
