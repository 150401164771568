/* eslint-disable no-nested-ternary */
import { observer } from 'mobx-react';
import React, { CSSProperties, useEffect, useState } from 'react';
import {
  ExclamationTriangle,
  FileEarmarkText,
  Gift,
  InfoCircle,
  Pencil,
  Plus,
  Tree,
} from 'react-bootstrap-icons';
import { Badge, Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'components/App/App';
import moment from 'moment';
import {
  ProjectPayTypes,
  ProjectTypes,
  TProject,
  TProjectLARP,
  TProjectSettings,
} from 'stores/ProjectsStore';
import BootstrapTable from 'react-bootstrap-table-next';
import { type } from 'os';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Link } from 'react-router-dom';
import UserShow from 'components/Heplers/UserShow';
import { useStores } from '../../services/useStore';
import ProjectList from './ProjectList';

const ProjectListAdmin: React.FC = observer(() => {
  const { t } = useTranslation();
  const { projectStore, api } = useStores();
  const getUrl = (img: string) => `url('${api.settings.url.server}img/${img}')`;
  if (!api.isAdmin()) return <ProjectList />;
  const [inited, setInit] = useState(false);
  let { data } = projectStore;
  if (!data) data = [];
  useEffect(() => {
    if (!projectStore?.data?.length) {
      api.getProjects().then(() => {
        setInit(true);
      });
    } else {
      setInit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const info = (projectId: number) => {};

  if (!inited) {
    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ margin: 'auto', display: 'flex' }}>
          <Spinner animation='border' role='status' />
          <span className='spiner-text'>{t('loading')}</span>
        </div>
      </div>
    );
  }

  const projectName = (cell: undefined, row: TProject) => {
    let inviteOnly = false;
    let freQuenta = false;
    if (typeof row.project_settings === 'object') {
      inviteOnly = row.project_settings.inviteOnly;
      freQuenta = row.project_settings.freQuenta;
    }
    return (
      <div title={row.project_title || ''}>
        {row.project_type === ProjectTypes.LORE ? (
          <span style={{ marginRight: 10 }}>
            <FileEarmarkText />
          </span>
        ) : (
          ''
        )}
        {row.project_type === ProjectTypes.LARP ? (
          <span style={{ marginRight: 10 }}>
            <Tree />
          </span>
        ) : (
          ''
        )}
        <b>{row.project_name}</b>
        <div className='admin-small-badge'>
          {moment() > moment(row.project_end) ? (
            <Badge bg='danger'>Завершено</Badge>
          ) : moment() > moment(row.project_start) ? (
            <Badge bg='warning'>Триває</Badge>
          ) : (
            ''
          )}
          {row.project_open ? <Badge bg='secondary'>Відкриті</Badge> : ''}
          {row.project_open > 1 ? <Badge bg='info'>Відкриті заявки</Badge> : ''}
          {inviteOnly ? <Badge bg='black'>Тільки інвайти</Badge> : ''}
          {freQuenta ? <Badge bg='success'>Вільна квента</Badge> : ''}
        </div>
        {row.promocode_id ? (
          <button
            type='button'
            title={row.promocode_description}
            className='admin-small-button btn btn-info btn-sm'
            style={{ display: 'block' }}
          >
            <Gift style={{ marginRight: 5, position: 'relative', top: -2 }} />
            {moment(row.promocode_start).format('DD.MM.YY')}
            {row.promocode_end ? ` - ${moment(row.promocode_end).format('DD.MM.YY')}` : ''}
          </button>
        ) : (
          ''
        )}
      </div>
    );
  };

  const deleteFormatter = (cell: undefined, row: TProject) => {
    return (
      <>
        <Link to={ROUTES.projects + '/' + row.project_id}>
          <button type='button' className='admin-small-button btn btn-info btn-sm'>
            <InfoCircle />
          </button>
        </Link>{' '}
        <Link
          to={
            (row.project_type === ProjectTypes.LORE
              ? ROUTES.projectEditLORE
              : ROUTES.projectEditLARP) +
            '/' +
            row.project_id
          }
        >
          <button type='button' className='admin-small-button btn btn-success btn-sm'>
            <Pencil />
          </button>
        </Link>
      </>
    );
  };
  const date = (dateValue: moment.Moment | string) =>
    dateValue ? moment(dateValue).format('DD.MM.YY') : '';
  const tdStyle: CSSProperties = {
    padding: 5,
  };
  const columns = [
    {
      dataField: 'project_id',
      text: '#',
      sort: true,
      align: 'center',
      headerStyle: { width: 40 },
      formatter: (cell: undefined, row: TProject) => (
        <Link to={ROUTES.projects + '/' + row.project_id}>{row.project_id}</Link>
      ),
      style: tdStyle,
    },
    {
      dataField: 'image',
      text: '',
      sort: false,
      headerStyle: { width: 70 },
      formatter: (cell: undefined, row: TProject) =>
        row.image ? (
          <div className='admin-small-pic' style={{ backgroundImage: getUrl(row.image) }} />
        ) : (
          <></>
        ),
      style: {
        padding: '0 0 1px 0',
      },
    },
    {
      dataField: 'project_name',
      text: t('projectForm.name'),
      sort: true,
      formatter: projectName,
    },
    {
      dataField: 'project_start',
      text: t('projectForm.start'),
      sort: true,
      headerStyle: { width: 120 },
      formatter: (cell: undefined, row: TProject) => {
        if (!row.project_start) return <></>;
        return (
          <>
            {date(row.project_start)} - {date(row.project_end)}
            <div className='text-muted'>
              {moment() > moment(row.project_start)
                ? moment() > moment(row.project_end)
                  ? t('projects.endedTime', {
                      end: -api.dateDiff(row.project_end),
                    })
                  : t('projects.inProgress')
                : t('projects.dayBefore', {
                    end: api.dateDiff(row.project_start),
                  })}
            </div>
          </>
        );
      },
      style: tdStyle,
    },
    {
      dataField: 'project_status',
      text: t('projectForm.status'),
      sort: true,
      headerStyle: { width: 150 },
      align: 'center',
      formatter: (cell: undefined, row: TProject) =>
        t('projectForm.statuses.' + (row.project_status || 'Concept')),
      style: tdStyle,
    },
    {
      dataField: 'user_id',
      text: 'Owner/Managers',
      sort: true,
      headerStyle: { width: 250 },
      style: tdStyle,
      formatter: (cell: undefined, row: TProject) => (
        <>
          <UserShow
            userId={row.user_id}
            showAva={false}
            linkInDialog
            isManager
            allUsers
            hideEmpty
            showId
          />
          <div>
            {t('projects.managers')}: {row.managers}
          </div>
        </>
      ),
    },
    {
      dataField: 'project_pay_status',
      text: 'Pay',
      sort: true,
      headerStyle: { width: 120 },
      formatter: (cell: undefined, row: TProject) => (
        <>
          <div>
            <Badge bg='success' style={{ fontSize: 10 }}>
              {row.project_pricing.toUpperCase()}
            </Badge>
          </div>
          {row.project_pricing.toUpperCase() === ProjectPayTypes.FREE ? (
            ''
          ) : (
            <div>
              {row.project_pay_status}{' '}
              {row.project_pay_status === ProjectPayTypes.FREE
                ? ''
                : `(${date(row.project_pay_time)})`}
            </div>
          )}
        </>
      ),
      style: tdStyle,
    },
    {
      dataField: 'stuffs',
      text: 'Stuff',
      sort: true,
      headerStyle: { width: 70 },
      align: 'center',
      style: tdStyle,
    },
    {
      dataField: 'locations',
      text: 'Loc',
      sort: true,
      headerStyle: { width: 70 },
      align: 'center',
      style: tdStyle,
    },
    {
      dataField: 'persons',
      text: 'Pers',
      sort: true,
      headerStyle: { width: 70 },
      align: 'center',
      style: tdStyle,
    },
    {
      dataField: 'requests',
      text: 'Req',
      sort: true,
      headerStyle: { width: 70 },
      align: 'center',
      style: tdStyle,
    },
    {
      dataField: 'followers',
      text: 'Foll',
      sort: true,
      headerStyle: { width: 70 },
      align: 'center',
      style: tdStyle,
    },

    {
      dataField: 'lang',
      text: 'Lang',
      headerStyle: { width: 70 },
      align: 'center',
      sort: true,
      formatter: (cell: undefined, row: TProject) => (
        <Badge bg='primary' style={{ fontSize: 12 }}>
          {(row.project_settings as TProjectSettings).lang}
        </Badge>
      ),
      style: tdStyle,
    },
    {
      dataField: 'edit',
      text: '',
      editable: false,
      csvExport: false,
      align: 'center',
      headerStyle: { width: 100 },
      formatter: deleteFormatter,
      style: tdStyle,
    },
  ];

  const rowStyle = (row: TProject, rowIndex: number) => {
    const style = {} as React.CSSProperties;
    style.fontSize = '0.7rem';
    if (row.project_open === 1) style.backgroundColor = '#ffff0022';
    else if (row.project_open === 2) style.backgroundColor = '#00ff0022';
    if (moment() > moment(row.project_end)) style.backgroundColor = '#ff000022';
    else if (moment() > moment(row.project_start)) style.backgroundColor = '#00aaff22';
    return style;
  };

  return (
    <Container fluid className='verticalPadding greenForm'>
      <Row className='mb-3 mt-3'>
        <Col sm={6}>
          <h1>{t('projects.mainTitle')}</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={12} style={{ background: '#fff', padding: 0 }}>
          <ToolkitProvider
            bootstrap4
            keyField='project_id'
            data={projectStore?.data}
            columns={columns}
            search
          >
            {(props) => (
              <Container fluid>
                <Row>
                  <Col lg={12} style={{ background: '#fff', padding: 0 }}>
                    <BootstrapTable {...props.baseProps} rowStyle={rowStyle} />
                  </Col>
                </Row>
              </Container>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
      {/*<Row>
        <Container fluid>
          <Row xs={1} md={3} className='g-4'>
            {data.map((project) => (
              <ItemCard
                key={project.project_id}
                store='projectStore'
                type='project'
                id={project.project_id}
                showRoute={api.isBot() ? '' : 'projects'}
                editRoute={projectEdit}
                name={projectName}
                getStyle={getStyle}
                title='project_title'
                footer={calculateFooter(project)}
                preFooter={
                  project.project_type === ProjectTypes.LARP &&
                  (project as TProjectLARP).project_start ? (
                    <div style={{ textAlign: 'right', paddingRight: 15 }}>
                      {moment((project as TProjectLARP).project_start).format('DD.MM.YY')} -{' '}
                      {moment((project as TProjectLARP).project_end).format('DD.MM.YY')}{' '}
                      <span className='text-muted'>
                        {moment() > moment((project as TProjectLARP).project_start)
                          ? moment() > moment((project as TProjectLARP).project_end)
                            ? t('projects.ended')
                            : t('projects.inProgress')
                          : t('projects.dayBefore', {
                              end: api.dateDiff((project as TProjectLARP).project_start),
                            })}
                      </span>
                    </div>
                  ) : (
                    <>&nbsp;</>
                  )
                }
              />
            ))}
          </Row>
        </Container>
      </Row>*/}
    </Container>
  );
});
export default ProjectListAdmin;
