import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'services/useStore';
import { UserStatus } from 'stores/SessionStore';
import { ProjectTypes } from 'stores/ProjectsStore';
import ManagerHeaderLARP from './ManagerHeaderLARP';
import ManagerHeaderLORE from './ManagerHeaderLORE';
import UserHeader from './UserHeader';
import AdminHeader from './AdminHeader';
import NoUserHeader from './NoUserHeader';

const TopBar: React.FC = observer(() => {
  const { sessionStore } = useStores();
  switch (sessionStore.role()) {
    case UserStatus.Manager:
    case UserStatus.Owner:
      if (sessionStore.session.project_type === ProjectTypes.LORE) return <ManagerHeaderLORE />;
      return <ManagerHeaderLARP />;

      break;
    case UserStatus.User:
    case UserStatus.Participant:
    case UserStatus.Subscriber:
      return <UserHeader />;
      break;
    case UserStatus.Admin:
      return <AdminHeader />;
      break;
    default:
      return <NoUserHeader />;
      break;
  }
});

export default TopBar;
