import React from 'react';
import { useStores } from 'services/useStore';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

interface IRedirectCheck {
  url: string;
}

const RedirectCheck: React.FC<IRedirectCheck> = ({ url }) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { api } = useStores();
  if (!api.projectIsActive()) history(url);
  return <></>;
};

export default RedirectCheck;
