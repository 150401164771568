/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import FacebookLogin, {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from 'react-facebook-login';
import { TFacebookAutResponse } from 'services/Api';
import { Facebook } from 'react-bootstrap-icons';

export interface IFacebookLoginButton {
  clientId: string;
  callback: (data: TFacebookAutResponse) => void;
  text?: string;
  active?: boolean;
}

const FacebookLoginButton: React.FC<IFacebookLoginButton> = ({
  clientId,
  callback,
  text = 'loginForm.facebook',
  active = false,
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const responseFacebook = (userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
    if ((userInfo as ReactFacebookLoginInfo)?.userID) {
      callback(userInfo as TFacebookAutResponse);
    }
  };

  return (
    <>
      <button onClick={() => setShow(true)} className='customLoginButton'>
        <div className={active ? 'customLoginButtonActive' : ''}>
          <Facebook fontSize={20} color='#0d6efd' />
        </div>{' '}
        {t(text)}
      </button>
      {show ? (
        <div style={{ position: 'absolute', overflow: 'hidden', width: 1, height: 1, top: -10 }}>
          <FacebookLogin
            appId={clientId}
            autoLoad
            fields='name,email,picture'
            callback={responseFacebook}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};
export default FacebookLoginButton;
