import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Grid, List } from 'react-bootstrap-icons';
import { ShowTypes } from 'services/Api';
import { useStores } from '../../services/useStore';

export interface IShowTypeItem {
  callback: (newType: string) => void;
}

const ShowTypeItem: React.FC<IShowTypeItem> = observer(({ callback }) => {
  const { api } = useStores();
  const [showType, setShowType] = useState(api.getLocalConfig('showType'));
  const changeShowType = () => {
    const newShowType = showType === ShowTypes.Card ? ShowTypes.List : ShowTypes.Card;
    api.setLocalConfig('showType', newShowType);
    setShowType(newShowType);
    callback(newShowType);
  };
  return (
    <Button variant="info" size="sm" onClick={changeShowType}>
      {showType === ShowTypes.Card ? <Grid /> : <List />}
    </Button>
  );
});
export default ShowTypeItem;
