/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-danger */
import { ROUTES } from 'components/App/App';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { Link45deg, Pencil, TrashFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LinkStore, { TLink } from 'stores/LinkStore';
import { useStores } from '../../services/useStore';
import LinkEdit from './LinkEdit';
import PersonShow from './PersonShow';

export interface IBotLinkList {
  type: string;
  subType: string;
  id: number;
  reverse?: boolean;
  storeName: string;
  muteLoadFromAPI?: boolean;
  title?: string;
  controll?: boolean;
  autogenerate?: boolean;
  hideEmpty?: boolean;
  label?: string;
  linkInDialog?: boolean;
}

const BotLinkList: React.FC<IBotLinkList> = observer(
  ({
    type,
    id,
    reverse = false,
    storeName,
    subType,
    muteLoadFromAPI,
    title = '',
    controll = true,
    autogenerate = false,
    hideEmpty = false,
    label,
    linkInDialog = true,
  }) => {
    const { t } = useTranslation();
    const stores = useStores();
    const linkStore: LinkStore = stores[storeName];
    const { api } = stores;
    const { data } = linkStore;
    const [linkData, setLinkData] = useState<TLink[]>();
    const [deleteConfirmVisible, showDeleteConfirm] = useState(false);
    const [waitNumber, setWaitNumber] = useState(0);
    const [waitEditNumber, setWaitEditNumber] = useState(0);
    const [editModalVisible, showEditModal] = useState(false);
    const updateLinkData = () => {
      const config = { fromId: id, type: reverse ? type : subType };
      const newLinkData: TLink[] = linkStore.get(config);
      setLinkData(newLinkData);
    };
    if (!muteLoadFromAPI) {
      useEffect(() => {
        stores.api.getLinks(reverse ? subType : type, id).then((linkInfo) => {
          if (linkInfo) linkStore.add(linkInfo, id);
          updateLinkData();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);
      useEffect(() => {
        updateLinkData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [data, id]);
    } else {
      useEffect(() => {
        updateLinkData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [data, id]);
    }
    const add = () => {
      setWaitEditNumber(0);
      showEditModal(true);
    };
    const edit = (toId: number) => {
      setWaitEditNumber(toId);
      showEditModal(true);
    };
    const saveHandler = (
      toId: number,
      description: string,
      name: string,
      text_description?: string
    ) => {
      stores.api
        .setLink(
          {
            fromId: id,
            toId,
            type,
            description,
            subType,
            name,
          },
          linkStore,
          text_description
        )
        .then(() => updateLinkData());
      showEditModal(false);
    };
    const remove = () => {
      if (!waitNumber) return;
      showDeleteConfirm(false);
      stores.api
        .removeLink(
          {
            fromId: id,
            toId: waitNumber,
            type,
            subType,
          },
          linkStore
        )
        .then(() => {
          updateLinkData();
          setWaitNumber(0);
        });
    };

    const removePromt = (toId: number) => {
      setWaitNumber(toId);
      showDeleteConfirm(true);
    };

    const removePromtClose = () => {
      setWaitNumber(0);
      showDeleteConfirm(false);
    };
    const showDescription = (item: TLink) =>
      subType === 'text' ? (
        <>
          <b>{item.description}</b>
          <br />
          <p
            className='person-notice'
            dangerouslySetInnerHTML={{ __html: item.text_description || '' }}
          />
        </>
      ) : (
        item.description
      );
    const clickHandler = (clickId: number) => {
      if (clickId && linkInDialog) api.showDialog(reverse ? type : subType, clickId);
    };
    if (hideEmpty && !linkData?.length) return <></>;
    return (
      <>
        <ListGroup>
          <ListGroup.Item
            variant='success'
            key='head'
            as='li'
            className='d-flex justify-content-between align-items-start'
          >
            <div className='me-auto'>
              <div className='fw-bold'>
                <h6 style={{ marginBottom: 0 }}>
                  <Link45deg />
                  &nbsp;{title} [{linkData?.length}]
                </h6>
              </div>
            </div>
            {controll && !autogenerate ? (
              <Button
                variant='success'
                onClick={() => add()}
                size='sm'
                type='button'
                style={{ width: 32 }}
              >
                +
              </Button>
            ) : (
              ''
            )}
          </ListGroup.Item>
          {linkData?.length ? (
            linkData.map((item: TLink) => (
              <ListGroup.Item
                key={item.toId}
                as='li'
                className='d-flex justify-content-between align-items-start mb-1'
              >
                <div className='me-auto'>
                  <div className='fw-bold' role='link' onClick={() => clickHandler(item.toId)}>
                    {linkInDialog ? (
                      subType === 'person' || (reverse && type === 'person') ? (
                        <PersonShow personId={item.toId} linkInDialog={linkInDialog} />
                      ) : (
                        item.name
                      )
                    ) : (
                      <Link to={ROUTES[(reverse ? type : subType) + 's'] + '/' + item.toId}>
                        {item.name}
                      </Link>
                    )}
                  </div>
                  {showDescription(item)}
                </div>
                {controll ? (
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <Button
                      variant='primary'
                      onClick={() => edit(item.toId)}
                      size='sm'
                      type='button'
                    >
                      <Pencil />
                    </Button>{' '}
                    {!autogenerate ? (
                      <Button
                        variant='danger'
                        onClick={() => removePromt(item.toId)}
                        size='sm'
                        type='button'
                      >
                        <TrashFill />
                      </Button>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
              </ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item
              key='empty'
              as='li'
              className='d-flex justify-content-between align-items-start'
            >
              &nbsp;
            </ListGroup.Item>
          )}
        </ListGroup>

        <Modal show={deleteConfirmVisible} onHide={removePromtClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t('global.deleteLinkHeader')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t('global.deleteLinkPrompt')}</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={removePromtClose}>
              {t('global.close')}
            </Button>
            <Button variant='danger' onClick={remove}>
              {t('global.delete')}
            </Button>
          </Modal.Footer>
        </Modal>
        <LinkEdit
          id={id}
          showModal={editModalVisible}
          subType={subType}
          storeName={storeName}
          toId={waitEditNumber}
          closeEditModal={() => showEditModal(false)}
          saveEditModal={saveHandler}
          label={label || t(`${subType}.${subType}`)}
        />
        {hideEmpty ? <br /> : ''}
      </>
    );
  }
);
export default BotLinkList;
