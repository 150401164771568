import { TCommandInfo, TDialogElement, TIdInfo } from 'components/Projects/ProjectAILARP';
import { action, makeObservable, observable } from 'mobx';
import i18n from 'locales/i18n';
import BaseStore from './BaseStore';
import { RootStore } from './RootStore';

export type TGroupInfo = {
  id: number;
  description: string;
  name: string;
  title: string;
};

class PrototypeGroupStore extends BaseStore {
  _ids!: number;
  constructor(rootStore: RootStore) {
    super(rootStore, {
      parseCommands: action,
    });
    this._ids = 0;
    makeObservable(this, {
      _ids: observable,
    });
  }
  get data(): TGroupInfo[] {
    return this._data as TGroupInfo[];
  }

  get ids() {
    this._ids += 1;
    return this._ids;
  }

  parseCommands(commands: TCommandInfo[], newDialog: TDialogElement[]) {
    if (!commands?.length) return;
    commands.forEach((command: TCommandInfo) => {
      if (!command?.command) return;
      switch (command.command) {
        case 'group_add':
          this.add({ ...command.data, id: this.ids } as TGroupInfo);
          newDialog.push({
            text: i18n.t('projectLARPAI.groupAdd') + (command.data as TGroupInfo).name,
            source: 'system',
          });
          break;
        case 'group_remove':
          if (command.id) {
            if (Number.isNaN(+command.id)) return;
            const { name } = this.get(+command.id);
            this.remove(+command.id);
            newDialog.push({ text: i18n.t('projectLARPAI.groupDelete') + name, source: 'system' });
          }
          break;
        case 'group_edit':
          {
            const { id = 0, data } = command;
            if (Number.isNaN(+id)) return;
            if (id !== undefined && id > -1) {
              this.update({ ...data, id: +id });
              newDialog.push({
                text: i18n.t('projectLARPAI.groupEdit') + this.get(+id).name,
                source: 'system',
              });
            }
          }
          break;
        default:
          break;
      }
    });
  }

  sendToAI(commands: TCommandInfo[]) {
    this._data.forEach((el: TGroupInfo) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = { ...el };
      delete data.id;
      commands.push({
        command: 'group_add',
        id: el.id,
        data,
      });
    });
  }
  getIds(): TIdInfo {
    const list: TIdInfo = {};
    this._data.forEach((el: TGroupInfo) => {
      list[el.name] = el.id;
    });
    return list;
  }
}
export default PrototypeGroupStore;
