import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddButton from 'components/Buttons/AddButton';
import ItemViewer from 'components/Heplers/ItemViewer';
import UserShow from 'components/Heplers/UserShow';
import { UserStatus } from 'stores/SessionStore';
import { TUser } from 'stores/UsersStore';
import { Link } from 'react-router-dom';
import { Envelope, Telephone } from 'react-bootstrap-icons';
import { useStores } from '../../services/useStore';

let first = true;
const UserList: React.FC = observer(() => {
  const { t } = useTranslation();
  const { sessionStore, userStore, api } = useStores();
  api.noLogin();
  const [users, setUsers] = useState<TUser[]>();
  const isAdmin = sessionStore.session.user_status === UserStatus.Admin;
  const update = () => {
    if (isAdmin) {
      if (!first) return;
      first = false;
      api.getAllUsers().then((data: TUser[]) => {
        console.log('>>>', data);
        userStore.setAll(data);
        setUsers(userStore.getAll());
      });
    } else {
      const usersList = userStore.getUsers();
      console.log('>>>', usersList);
      setUsers(usersList);
    }
  };

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore]);

  useEffect(() => {
    api.getUsers().then(() => update());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const adminActions = isAdmin ? <AddButton title={t('user.create')} link='userCreate' /> : '';

  const columns = [
    {
      dataField: 'user_name',
      text: t('user.name'),
      sort: true,
      formatter: (cell: undefined, row: TUser) => (
        <UserShow usersData={users} userId={row.user_id} hideEmpty />
      ),
    },
    {
      dataField: 'user_fio',
      text: t('profileForm.fio'),
      sort: true,
    },
    {
      dataField: 'user_link',
      text: t('user.link'),
      sort: true,
      formatter: (cell: undefined, row: TUser) => (
        <div style={{ overflow: 'hidden', fontSize: 11 }}>
          <div key='mail' style={{ whiteSpace: 'nowrap' }}>
            <Link to={'mailto:' + row.user_email}>
              <Envelope /> {row.user_email}
            </Link>
          </div>
          {row.user_tel ? (
            <div key='tel'>
              <Telephone /> {row.user_tel}
            </div>
          ) : (
            ''
          )}
          {row.user_link ? <div key='link'>{row.user_link}</div> : ''}
        </div>
      ),
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const calculateFooter = (data: any) => {
    return <></>;
  };

  if (!users) return <>...</>;
  return (
    <ItemViewer
      id='user_id'
      store='userStore'
      type='user'
      tableData={users}
      columns={columns}
      showRoute='users'
      editRoute='userEdit'
      name='user_name'
      title='user_link'
      canEdit={isAdmin}
      mainTitle={t('user.users')}
      adminActions={adminActions}
      calculateFooter={calculateFooter}
      afterDelete={(id: number) => update()}
    />
  );
});
export default UserList;
