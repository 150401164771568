import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'services/useStore';
import {
  Badge,
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  ListGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TRelationnsCheck, TRelationnsCheckResponse, TUserRelationList } from 'services/Api';
import UserShow from 'components/Heplers/UserShow';
import PersonShow from 'components/Heplers/PersonShow';
import UserFind from 'components/Heplers/UserFind';

const RequestCheckDialog: React.FC = observer(() => {
  const { t } = useTranslation();
  const [mode, setMode] = useState<string>('init');
  const [rejectNotice, setRejectNotice] = useState<string>('');
  const [successNotice, setSuccessNotice] = useState<string>('');
  const [inviteNotice, setInviteNotice] = useState<string>('');
  const [rejectOthers, setRejectOthers] = useState<boolean>(true);
  const [info, setInfo] = useState<TRelationnsCheck[]>();
  const [relations, setRealations] = useState<TUserRelationList[]>();
  const [reversRelations, setReversRealations] = useState<TUserRelationList[]>();
  const { api, sessionStore } = useStores();
  const [user, setUser] = useState(0);
  useEffect(() => {
    setMode('init');
    const id = sessionStore.appSettings.requestCheckDialog;
    if (id) {
      api.requestCheck(id).then((data: TRelationnsCheckResponse) => {
        setInfo(data.relationsCheck);
        setRealations(
          data.userRelationsList.filter((element: TUserRelationList) => !!element.user_name)
        );
        setReversRealations(
          data.relationsToUsersList.filter((element: TUserRelationList) => !!element.user_name)
        );
      });
    } else setInfo([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStore.appSettings.requestCheckDialog]);

  useEffect(() => {
    setMode('init');
    const id = sessionStore.appSettings.rolePropositionDialog;
    if (id && user) {
      api.checkUserToPerson(id, user).then((data: TRelationnsCheckResponse) => {
        setInfo(data.relationsCheck);
        setRealations(
          data.userRelationsList.filter((element: TUserRelationList) => !!element.user_name)
        );
        setReversRealations(
          data.relationsToUsersList.filter((element: TUserRelationList) => !!element.user_name)
        );
      });
    } else setInfo([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStore.appSettings.rolePropositionDialog, user]);

  useEffect(() => {
    setUser(0);
    setInviteNotice('');
    setInfo([]);
    setRealations([]);
    setReversRealations([]);
  }, [sessionStore.appSettings.rolePropositionDialog]);

  const getBG = (i: string): string => {
    if (i === 'direct') return 'success';
    if (i === 'location') return 'info';
    if (i === 'quest') return 'primary';
    return 'secondary';
  };
  const getVariant = (element: string): string => {
    if (element === 'blacklist') return 'danger';
    if (element === 'whitelist') return 'success';
    return '';
  };
  const reject = () => {
    setMode('reject');
  };
  const success = () => {
    setMode('success');
  };

  const inviteDone = async () => {
    await api.inviteRequest(sessionStore.appSettings.rolePropositionDialog, user, inviteNotice);
    await api.getRequests();
    await api.getPersons();
    api.toggleRolePropositionDialog(0);
  };

  const rejectDone = async () => {
    await api.rejectRequest(sessionStore.appSettings.requestCheckDialog, rejectNotice);
    await api.getRequests();
    await api.getPersons();
    api.toggleRequestCheckDialog(0);
  };

  const successDone = async () => {
    await api.successRequest(
      sessionStore.appSettings.requestCheckDialog,
      successNotice,
      rejectOthers
    );
    await api.getRequests();
    await api.getPersons();
    api.toggleRequestCheckDialog(0);
  };

  const onHide = () => {
    setInfo([]);
    setRealations([]);
    setReversRealations([]);
    if (sessionStore.appSettings.requestCheckDialog) api.toggleRequestCheckDialog(0);
    if (sessionStore.appSettings.rolePropositionDialog) {
      setUser(0);
      api.toggleRolePropositionDialog(0);
    }
  };

  return (
    <Modal
      show={
        !!sessionStore.appSettings.requestCheckDialog ||
        !!sessionStore.appSettings.rolePropositionDialog
      }
      size='lg'
      key='editModal'
      onHide={onHide}
    >
      {sessionStore.appSettings.requestCheckDialog ? (
        <Modal.Header closeButton>{t('request.checkRequest')}</Modal.Header>
      ) : (
        ''
      )}
      {sessionStore.appSettings.rolePropositionDialog ? (
        <Modal.Header closeButton>{t('request.selectUser')}</Modal.Header>
      ) : (
        ''
      )}
      {mode === 'init' ? (
        <Modal.Body>
          <Row>
            <Col>
              {sessionStore.appSettings.requestCheckDialog ? (
                <h2>{t('request.requestWork')}</h2>
              ) : (
                ''
              )}
              {sessionStore.appSettings.rolePropositionDialog ? (
                <>
                  <Container>
                    <Row className='justify-content-md-center masterField mb-3'>
                      <Col xs lg={12}>
                        <h1>{t('request.inviteUser')}</h1>
                      </Col>
                    </Row>
                    <Row className='justify-content-md-center masterField mb-3'>
                      <Col xs lg={6}>
                        {t('global.role')}:
                      </Col>
                      <Col xs lg={6}>
                        <PersonShow personId={sessionStore.appSettings.rolePropositionDialog} />
                      </Col>
                    </Row>
                    <Row className='justify-content-md-center masterField mb-3'>
                      <Col xs lg={6}>
                        {t('request.selectUserForProposition')}:
                      </Col>
                      <Col xs lg={6}>
                        <UserFind action={setUser} />
                      </Col>
                    </Row>
                    {user ? (
                      <>
                        <Row className='justify-content-md-center masterField'>
                          <Col xs lg={6} />
                          <Col xs lg={6}>
                            <UserShow userId={user} allUsers linkInDialog />
                          </Col>
                        </Row>
                        <br />
                      </>
                    ) : (
                      ''
                    )}
                  </Container>
                </>
              ) : (
                ''
              )}
            </Col>
          </Row>
          <hr />
          <Row style={{ overflowY: 'auto', maxHeight: 400 }} className='justify-content-md-center'>
            {relations?.length ? (
              <Col xs lg={6}>
                <Row className='mb-3'>
                  <Col>{t('request.userRelationsDescription')}</Col>
                </Row>
                <ListGroup as='ol'>
                  {relations
                    ?.filter((element: TUserRelationList) => !!element.user_name)
                    .map((element: TUserRelationList) => (
                      <ListGroup.Item
                        key={element.user_id}
                        as='li'
                        className='d-flex justify-content-between align-items-start'
                        variant={getVariant(element.relation)}
                      >
                        <div className='ms-2 me-auto'>
                          <div className='fw-bold'>{element.user_name}</div>
                        </div>
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </Col>
            ) : (
              ''
            )}
            {reversRelations?.length ? (
              <Col xs lg={6}>
                <Row className='mb-3'>
                  <Col>{t('request.userReversRelationsDescription')}</Col>
                </Row>
                <ListGroup as='ol'>
                  {reversRelations?.map((element: TUserRelationList) => (
                    <ListGroup.Item
                      key={element.user_id}
                      as='li'
                      className='d-flex justify-content-between align-items-start'
                      variant={getVariant(element.relation)}
                    >
                      <div className='ms-2 me-auto'>
                        <div className='fw-bold'>{element.user_name}</div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Col>
            ) : (
              ''
            )}
          </Row>
          {sessionStore.appSettings.requestCheckDialog || user ? (
            <Row className='mb-3'>
              <Col>{t('request.checkRequestDescription')}</Col>
            </Row>
          ) : (
            ''
          )}
          <Row style={{ overflowY: 'auto', maxHeight: 400 }} className='justify-content-md-center'>
            <Col xs lg={9}>
              <ListGroup as='ol'>
                {info?.map((element: TRelationnsCheck) => (
                  <ListGroup.Item
                    key={element.name}
                    as='li'
                    className='d-flex justify-content-between align-items-start'
                    variant={getVariant(element.problem)}
                  >
                    <div className='ms-2 me-auto'>
                      <div className='fw-bold'>{element.name}</div>
                      {element.user_name}&nbsp;
                    </div>
                    {element.type.split(',').map((i: string) => (
                      <>
                        <Badge bg={getBG(i)} key={i} pill>
                          {t('request.' + i)}
                        </Badge>
                        &nbsp;
                      </>
                    ))}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Row>
        </Modal.Body>
      ) : (
        ''
      )}
      {mode === 'reject' ? (
        <Modal.Body>
          <Row>
            <Col>
              <h2>{t('request.rejectWork')}</h2>
            </Col>
          </Row>
          <FloatingLabel
            controlId='rejectNotice'
            label={rejectNotice ? '' : t('request.rejectNotice')}
            className='mb-3'
          >
            <Form.Control
              as='textarea'
              rows={5}
              size='lg'
              value={rejectNotice || ''}
              onChange={(e) => setRejectNotice(e.target.value)}
              placeholder={t('request.rejectNotice')}
              style={{ height: '250px' }}
            />
          </FloatingLabel>
        </Modal.Body>
      ) : (
        ''
      )}

      {mode === 'success' ? (
        <Modal.Body>
          <Row>
            <Col>
              <h2>{t('request.successWork')}</h2>
            </Col>
          </Row>
          <FloatingLabel
            controlId='successNotice'
            label={successNotice ? '' : t('request.successNotice')}
            className='mb-3'
          >
            <Form.Control
              as='textarea'
              rows={5}
              size='lg'
              value={successNotice || ''}
              onChange={(e) => setSuccessNotice(e.target.value)}
              placeholder={t('request.successNotice')}
              style={{ height: 250 }}
            />
          </FloatingLabel>
          <Form.Check
            type='checkbox'
            id='custom-switch'
            key='rejectOthers'
            name='rejectOthers'
            label={t('request.rejectOthers')}
            checked={rejectOthers}
            onChange={(e) => setRejectOthers(e.target.checked)}
          />
          {/*<Form.Check
            type='checkbox'
            id='custom-switch'
            key='deleteOthers'
            name='deleteOthers'
            label={t('request.deleteOthers')}
            checked={deleteOthers}
            onChange={(e) => setDeleteOthers(e.target.checked)}
      />*/}
        </Modal.Body>
      ) : (
        ''
      )}

      {mode === 'invite' ? (
        <Modal.Body>
          <Row>
            <Col>
              <h2>{t('request.inviteUser')}</h2>
            </Col>
          </Row>
          <FloatingLabel
            controlId='inviteNotice'
            label={inviteNotice ? '' : t('request.inviteNotice')}
            className='mb-3'
          >
            <Form.Control
              as='textarea'
              rows={5}
              size='lg'
              value={inviteNotice || ''}
              onChange={(e) => setInviteNotice(e.target.value)}
              placeholder={t('request.inviteNotice')}
              style={{ height: '250px' }}
            />
          </FloatingLabel>
        </Modal.Body>
      ) : (
        ''
      )}

      {sessionStore.appSettings.requestCheckDialog && mode === 'init' ? (
        <Modal.Footer>
          <Button variant='danger' onClick={() => reject()}>
            {t('request.reject')}
          </Button>{' '}
          <Button variant='success' onClick={() => success()}>
            {t('request.accept')}
          </Button>
        </Modal.Footer>
      ) : (
        ''
      )}
      {sessionStore.appSettings.requestCheckDialog && mode === 'reject' ? (
        <Modal.Footer>
          <Button onClick={() => setMode('init')}>{t('global.back')}</Button>{' '}
          <Button variant='danger' onClick={() => rejectDone()}>
            {t('request.reject')}
          </Button>
        </Modal.Footer>
      ) : (
        ''
      )}

      {sessionStore.appSettings.requestCheckDialog && mode === 'success' ? (
        <Modal.Footer>
          <Button onClick={() => setMode('init')}>{t('global.back')}</Button>{' '}
          <Button variant='success' onClick={() => successDone()}>
            {t('request.accept')}
          </Button>
        </Modal.Footer>
      ) : (
        ''
      )}

      {sessionStore.appSettings.rolePropositionDialog && mode === 'init' && user ? (
        <Modal.Footer>
          <Button variant='success' onClick={() => setMode('invite')}>
            {t('request.invite')}
          </Button>
        </Modal.Footer>
      ) : (
        ''
      )}
      {sessionStore.appSettings.rolePropositionDialog && mode === 'invite' ? (
        <Modal.Footer>
          <Button onClick={() => setMode('init')}>{t('global.back')}</Button>{' '}
          <Button variant='success' onClick={() => inviteDone()}>
            {t('request.inviteComplete')}
          </Button>
        </Modal.Footer>
      ) : (
        ''
      )}
    </Modal>
  );
});

export default RequestCheckDialog;
