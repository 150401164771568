/* eslint-disable react/no-danger */
import ItemTable from 'components/Heplers/ItemTable';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { TPayment } from 'services/Api';
import { useStores } from 'services/useStore';

const ProfilePayments: React.FC = observer(() => {
  const { t } = useTranslation();
  const [inited, setInit] = useState(false);
  const { api } = useStores();
  const [list, setList] = useState<TPayment[]>([]);
  const columns = [
    {
      dataField: 'payment_date',
      text: t('billing.creaded'),
      sort: true,
      headerStyle: { width: 150 },
      style: { fontSize: '0.8rem' },
      formatter: (cell: undefined, row: TPayment) => api.timeFormat(row.payment_date),
    },
    {
      dataField: 'payment_ammount',
      headerStyle: { width: 100 },
      text: t('billing.amount'),
      sort: true,
      formatter: (cell: undefined, row: TPayment) => (
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <span
            style={{
              fontWeight: 'bold',
              color: +row.payment_ammount < 0 ? 'red' : 'green',
            }}
          >
            {row.payment_ammount}
          </span>
          <span
            className='text-muted'
            style={{
              fontSize: 10,
              paddingLeft: 5,
            }}
          >
            {row.payment_currency}
          </span>
        </div>
      ),
    },
    {
      dataField: 'payment_target',
      text: t('billing.action'),
      sort: false,
      formatter: (cell: undefined, row: TPayment) => {
        let projectInfo = <></>;
        let text = '';
        if (row.project_name) {
          if (row.is_subscribe) text = t('billing.subscribePay');
          projectInfo = (
            <>
              {text} <b>{row.project_name}</b>
            </>
          );
        }
        return <div>{projectInfo}</div>;
      },
    },
  ];

  useEffect(() => {
    api.getPaymentsList().then((data: TPayment[]) => {
      setInit(true);
      setList(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container style={{ background: '#fff', marginTop: 14 }}>
      <Row>
        <Col>
          <h1>{t('billing.header')}</h1>
        </Col>
      </Row>
      <Row style={{ marginTop: 50 }}>
        <Col>
          <h4>{t('billing.billingHistory')}</h4>
        </Col>
      </Row>
      <Row>
        <Col className='mb-4'>
          {inited ? (
            <ItemTable
              id='payment_id'
              type='billing'
              tableData={list || []}
              columns={columns}
              canEdit={false}
              needControll={false}
            />
          ) : (
            t('loading')
          )}
        </Col>
      </Row>
    </Container>
  );
});
export default ProfilePayments;
