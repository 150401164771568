/* eslint-disable no-nested-ternary */
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ExclamationTriangle, FileEarmarkText, Plus, Tree } from 'react-bootstrap-icons';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import ItemCard from 'components/Heplers/ItemCard';
import moment from 'moment';
import { ProjectTypes, TProject, TProjectLARP } from 'stores/ProjectsStore';
import { useStores } from '../../services/useStore';

const ProjectList: React.FC = observer(() => {
  const { t } = useTranslation();
  const { sessionStore, projectStore, api } = useStores();
  const [inited, setInit] = useState(false);
  let { data } = projectStore;
  if (!data) data = [];
  useEffect(() => {
    if (api.isBot() && !projectStore?.data?.length) {
      api.getProjects().then(() => {
        setInit(true);
      });
    } else {
      setInit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const adminActions = (
    <Col className='rightText' sm={6}>
      <Link to={ROUTES.projectNew}>
        <Button variant='primary' className='mb-3' size='sm'>
          <div style={{ display: 'inline-block', position: 'relative', top: -1 }}>
            <Plus fontSize={16} />
          </div>
          {'  '}
          {t('projects.newProject')}
        </Button>
      </Link>
    </Col>
  );
  if (!inited) {
    if (api.isBot()) {
      return (
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ margin: '50% auto', display: 'flex' }}>
            <Spinner animation='border' role='status' />
            <span className='spiner-text'>{t('loading')}</span>
          </div>
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ margin: 'auto', display: 'flex' }}>
          <Spinner animation='border' role='status' />
          <span className='spiner-text'>{t('loading')}</span>
        </div>
      </div>
    );
  }

  const projectName = (pr: TProject) => (
    <>
      {pr.project_type === ProjectTypes.LORE ? (
        <span style={{ marginRight: 10 }}>
          <FileEarmarkText />
        </span>
      ) : (
        ''
      )}
      {pr.project_type === ProjectTypes.LARP ? (
        <span style={{ marginRight: 10 }}>
          <Tree />
        </span>
      ) : (
        ''
      )}
      {pr.project_name}
    </>
  );

  const projectEdit = (pr: TProject) => {
    if (api.isBot() || !sessionStore.role()) return '';
    if (pr.project_type === ProjectTypes.LORE) {
      if (pr.user_id === sessionStore.session.user_id) return 'projectEditLORE';
      return '';
    }

    if (api.isManager(pr.project_id, true)) return 'projectEditLARP';
    return '';
  };

  const getStyle = (item: TProject) => {
    return api.projectIsActive(item.project_id) ? {} : { backgroundColor: '#f3abab' };
  };
  const calculateFooter = (item: TProject) => {
    if (api.projectIsActive(item.project_id)) return <></>;
    return (
      <div
        style={{
          padding: '0 14px 21px',
          display: 'flex',
          justifyContent: 'flex-start',
          width: '100%',
          color: 'red',
        }}
      >
        <ExclamationTriangle fontSize={30} />
      </div>
    );
  };
  return (
    <Container fluid className='verticalPadding greenForm'>
      <Row className='mb-3 mt-3'>
        <Col sm={6}>
          <h1>{t('projects.mainTitle')}</h1>
        </Col>
        {sessionStore.session.user_id && !api.isBot() ? adminActions : ''}
      </Row>
      <Row>
        <Container fluid>
          <Row xs={1} md={3} className='g-4'>
            {data.map((project) => (
              <ItemCard
                key={project.project_id}
                store='projectStore'
                type='project'
                id={project.project_id}
                showRoute={api.isBot() ? '' : 'projects'}
                editRoute={projectEdit}
                name={projectName}
                getStyle={getStyle}
                title='project_title'
                footer={calculateFooter(project)}
                preFooter={
                  project.project_type === ProjectTypes.LARP &&
                  (project as TProjectLARP).project_start ? (
                    <div style={{ textAlign: 'right', paddingRight: 15 }}>
                      {moment((project as TProjectLARP).project_start).format('DD.MM.YY')} -{' '}
                      {moment((project as TProjectLARP).project_end).format('DD.MM.YY')}{' '}
                      <span className='text-muted'>
                        {moment() > moment((project as TProjectLARP).project_start)
                          ? moment() > moment((project as TProjectLARP).project_end)
                            ? t('projects.ended')
                            : t('projects.inProgress')
                          : t('projects.dayBefore', {
                              end: api.dateDiff((project as TProjectLARP).project_start),
                            })}
                      </span>
                    </div>
                  ) : (
                    <>&nbsp;</>
                  )
                }
              />
            ))}
          </Row>
        </Container>
      </Row>
    </Container>
  );
});
export default ProjectList;
