/* eslint-disable react/no-danger */
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import {
  Accordion,
  Badge,
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { Trash, Plus } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';
import { TActionInfo } from 'stores/PrototypeActionStore';
import { TGroupInfo } from 'stores/PrototypeGroupStore';
import { TLinkInfo } from 'stores/PrototypeLinkStore';
import { TLocationInfo } from 'stores/PrototypeLocationStore';
import { TPersonInfo } from 'stores/PrototypePersonStore';

const PrototypeLARPPerson: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    api,
    prototypePersonStore,
    prototypeActionStore,
    prototypeLocationStore,
    prototypeGroupStore,
    prototypeLinkStore,
  } = useStores();
  const [editPersonWindowShow, setEditPersonWindowShow] = useState(0);
  const [editPerson, setEditPerson] = useState<TPersonInfo>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updatePerson = (id: number, event?: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (id > 0) {
      setEditPerson({ ...prototypePersonStore.get(id) });
    } else {
      setEditPerson({ description: '', name: '', title: '', id: 0, group: '', location: '' });
    }
    setEditPersonWindowShow(id);
  };
  const savePerson = () => {
    if (editPersonWindowShow > 0) {
      prototypePersonStore.update(editPerson);
    } else {
      prototypePersonStore.add({ ...editPerson, id: prototypePersonStore.ids });
    }
    setEditPersonWindowShow(0);
  };
  const deletePerson = (id: number, silent: boolean = false): string => {
    if (id < 0) return '';
    const element = prototypePersonStore.get(id);
    let confirm = silent;
    if (!confirm) confirm = api.confirm(t('projectLARPAI.deleteConfirm') + element.name + '?');
    if (confirm) {
      prototypePersonStore.remove(id);
      return element.name;
    }
    return '';
  };
  const buildPerson = (personElement: TPersonInfo, index: number) => {
    return (
      <OverlayTrigger
        key={personElement.id || index}
        placement='left'
        overlay={
          <Tooltip id={`tooltip-${personElement.id || index}`}>{personElement.description}</Tooltip>
        }
      >
        <Row className='person'>
          <Col onClick={(e) => updatePerson(personElement.id, e)}>
            <b>{personElement.name}</b>
            <div>{personElement.title}</div>
          </Col>
          <Col className='deleteButton'>
            <Button
              variant='danger'
              size='sm'
              type='button'
              onClick={() => deletePerson(personElement.id)}
            >
              <Trash fontSize={16} />
            </Button>
          </Col>
        </Row>
      </OverlayTrigger>
    );
  };

  const changeGroup = (groupObj: TGroupInfo, state: boolean) => {
    const currentGroups: string[] = [];
    prototypeGroupStore.data.forEach((g: TGroupInfo) => {
      if (g.id === groupObj.id) {
        if (state) currentGroups.push(g.name);
        return;
      }
      const cb = document.getElementById('group-' + g.id);
      if (cb && (cb as HTMLInputElement).checked) {
        currentGroups.push(g.name);
      }
    });
    return currentGroups.join(', ');
  };

  return (
    <Accordion.Item eventKey='4'>
      <Accordion.Header>
        <Button
          variant='primary'
          size='sm'
          type='button'
          className='plusButton'
          onClick={(e) => updatePerson(-1, e)}
        >
          <Plus fontSize={16} />
        </Button>
        <b>{t('projectLARPAI.persons')}</b>
      </Accordion.Header>
      <Accordion.Body>{prototypePersonStore.data.map(buildPerson)}</Accordion.Body>
      <Modal
        show={editPersonWindowShow !== 0}
        size='lg'
        key='editPerson'
        centered
        onHide={() => setEditPersonWindowShow(0)}
      >
        <Modal.Header closeButton>{t('projectLARPAI.person')}</Modal.Header>
        {editPerson ? (
          <Modal.Body style={{ backgroundColor: '#0000001c' }}>
            <Row>
              <Col>
                <h5>{t('projectLARPAI.name')}:</h5>
              </Col>
              <Col>
                <Form.Control
                  type='text'
                  size='sm'
                  required
                  value={editPerson.name}
                  onChange={(e) => setEditPerson({ ...editPerson, name: e.target.value })}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>{t('projectLARPAI.smallDescription')}:</h5>
              </Col>
              <Col>
                <Form.Control
                  type='text'
                  size='sm'
                  value={editPerson.title}
                  onChange={(e) => setEditPerson({ ...editPerson, title: e.target.value })}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>{t('projectLARPAI.location')}:</h5>
              </Col>
              <Col>
                <Form.Select
                  required
                  size='sm'
                  value={editPerson.location}
                  onChange={(e) => setEditPerson({ ...editPerson, location: e.target.value })}
                >
                  <option key={0} value=''>
                    -- {t('projectLARPAI.homeless')} --
                  </option>
                  {prototypeLocationStore.data.map((location: TLocationInfo) => (
                    <option key={location.id} value={location.name}>
                      {location.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>{t('projectLARPAI.group')}:</h5>
              </Col>
              <Col>
                {prototypeGroupStore.data.map((group: TGroupInfo) => (
                  <Form.Check
                    type='checkbox'
                    key={group.id}
                    id={`group-${group.id}`}
                    label={group.name}
                    value={1}
                    checked={editPerson.group.indexOf(group.name) > -1}
                    onChange={(e) =>
                      setEditPerson({ ...editPerson, group: changeGroup(group, e.target.checked) })
                    }
                  />
                )) || ''}
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>{t('projectLARPAI.fullDescription')}:</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  as='textarea'
                  rows={5}
                  size='sm'
                  value={editPerson.description}
                  onChange={(e) => setEditPerson({ ...editPerson, description: e.target.value })}
                  style={{ height: '100px' }}
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <Badge bg='success' style={{ marginRight: 5 }}>
                  {editPerson.location}
                </Badge>
                {editPerson.group.split(',').map((item: string) => (
                  <Badge key={item} style={{ marginRight: 5 }}>
                    {item}
                  </Badge>
                ))}
                {prototypeLinkStore.data
                  .filter(
                    (p: TLinkInfo) => p.name1 === editPerson.name || p.name2 === editPerson.name
                  )
                  .map((p: TLinkInfo) => (
                    <Badge bg='info' key={p.name1 + p.name2} style={{ marginRight: 5 }}>
                      {p.name1 === editPerson.name ? p.name2 : p.name1}
                    </Badge>
                  ))}
                {prototypeActionStore.data
                  .filter((p: TActionInfo) => p.persons.indexOf(editPerson.name) > -1)
                  .map((p: TActionInfo) => (
                    <Badge bg='secondary' key={p.id} style={{ marginRight: 5 }}>
                      {p.name}
                    </Badge>
                  ))}
              </Col>
            </Row>
            <Row>
              <Col className='right-text mt-3' style={{ textAlign: 'right' }}>
                <Button variant='success' size='sm' type='button' onClick={() => savePerson()}>
                  {t('projectLARPAI.save')}
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        ) : (
          ''
        )}
      </Modal>
    </Accordion.Item>
  );
});
export default PrototypeLARPPerson;
