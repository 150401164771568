import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRequest } from 'stores/RequestsStore';
import AddButton from 'components/Buttons/AddButton';
import ItemViewer from 'components/Heplers/ItemViewer';
import Stack from 'react-bootstrap/Stack';
import { Alert, Badge, Button, Card } from 'react-bootstrap';
import UserShow from 'components/Heplers/UserShow';
import { ROUTES } from 'components/App/App';
import { CheckAll, CheckLg, Eye, InfoCircle, Pencil, Search, Trash } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { ShowTypes } from 'services/Api';
import PersonShow from 'components/Heplers/PersonShow';
import { useStores } from '../../services/useStore';

export interface IRequestList {
  my?: boolean;
}

const RequestList: React.FC<IRequestList> = observer(({ my }) => {
  const { t } = useTranslation();
  const { sessionStore, requestStore, api } = useStores();
  api.noLogin();
  const isM = (projectId: number) => api.isManager(projectId);
  const isAdmin = isM(sessionStore.session.user_project);
  const [request, setRequest] = useState<TRequest[]>();
  const [inited, setInit] = useState(false);
  const [fresh, setFresh] = useState(false);
  const updateRequestList = () => {
    setRequest(
      requestStore.data.filter((p: TRequest) => {
        if (my) return p.user_id === sessionStore.session.user_id;
        return p.project_id === sessionStore.session.user_project;
      })
    );
  };
  const reload = () => {
    const onlyMyRequests = isAdmin ? my : true;
    console.log('>>', onlyMyRequests);
    setInit(false);
    (onlyMyRequests
      ? api.getRequestsForUser(sessionStore.session.user_id)
      : api.getRequests()
    ).then((requestsData) => {
      updateRequestList();
      setInit(true);
    });
  };
  useEffect(() => {
    reload();
  }, [my]);

  const dataFilter = (data: TRequest[]): TRequest[] => {
    if (fresh) return data.filter((d: TRequest) => d.request_flow === 'init');
    return data;
  };

  useEffect(() => {
    updateRequestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestStore.data]);
  if (!inited) {
    return <div>{t('global.loading')}</div>;
  }

  if (!request?.length) {
    return (
      <Alert variant='warning'>
        <Alert.Heading>
          <Search /> {t('request.empty1')}
        </Alert.Heading>
        <p>{t('request.empty2')}</p>
        <hr />
        <div className='d-flex justify-content-end'>
          <Link to={ROUTES.projects} className='btn btn-primary'>
            {t('projects.mainTitle')}
          </Link>
        </div>
      </Alert>
    );
  }

  const badgeStyle = (status: string): string => {
    switch (status) {
      case 'success':
        return 'success';
      case 'reject':
        return 'danger';
      case 'autoReject':
        return 'danger';
      case 'init':
        return 'warning';
      default:
        return 'secondary';
    }
  };

  const adminActions =
    isAdmin && api.projectIsActive() ? (
      <>
        <Button variant='info' size='sm' onClick={() => setFresh(!fresh)}>
          {fresh ? <CheckAll /> : <Eye />}
        </Button>{' '}
        <AddButton title={t('request.newRequest')} link='requestEdit' />
      </>
    ) : (
      ''
    );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const calculateFooter = (data: TRequest) => {
    return (
      <>
        {isM(data.project_id) ? (
          <div
            style={{
              padding: 14,
              paddingTop: 0,
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              minHeight: 65,
            }}
          >
            <UserShow userId={data.user_id} linkInDialog hideEmpty />
          </div>
        ) : (
          ''
        )}
      </>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const calculatePreFooter = (data: TRequest) => {
    let status = t('request.requestWork');
    if (data.request_flow === 'reject') status = t('request.rejected');
    if (data.request_flow === 'autoReject') status = t('request.rejected2');
    if (data.person_user === data.user_id) status = t('request.accepted');
    if (data.person_user === data.user_id)
      status = t(data.request_type === 'invite' ? 'request.accepted2' : 'request.accepted');
    return (
      <>
        <Card.Footer style={{ fontSize: '0.8rem' }}>
          <Stack direction='horizontal' style={{ justifyContent: 'space-between' }}>
            <small className='text-muted'>{api.timeFormat(data.request_updated)}</small>
            {data.request_flow ? <Badge bg={badgeStyle(data.request_flow)}>{status}</Badge> : ''}
          </Stack>
          {data.request_type === 'invite' ? (
            <div className='invite-badger'>{t('request.inviteUser2')}</div>
          ) : (
            <div className='request-badger'>{t('request.request')}</div>
          )}
        </Card.Footer>
      </>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const calculateImage = (data: TRequest) => {
    if (data?.img_id) return `${api.settings.url.server}img/${data.img_id}.${data.img_mime}`;
    if (data?.person_image) {
      return `${api.settings.url.server}img/${data.person_image}`;
    }
    return '';
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const afterButton = (data: TRequest) => {
    const deleteItem = () => {
      api
        .deleteItem('request', data.request_id, t('global.deletePrompt'))
        .then((result) => {
          requestStore.remove(data.request_id || 0);
        })
        .catch((e) => {});
    };
    if (!api.projectIsActive()) return <></>;
    return (
      <>
        {'  '}
        {data.request_type === 'invite' && !isM(data.project_id) ? (
          <></>
        ) : (
          <>
            <Button variant='danger' size='sm' onClick={deleteItem}>
              <Trash />
            </Button>{' '}
            {data.request_type !== 'invite' ? (
              <Link to={ROUTES.requestEdit + '/' + data.request_id}>
                <Button variant='primary' size='sm'>
                  <Pencil />
                </Button>
              </Link>
            ) : (
              ''
            )}
          </>
        )}
        {'  '}
        <Button
          variant='primary'
          size='sm'
          onClick={() =>
            api.showDialog(
              data.request_type === 'invite' ? 'invite' : 'request',
              data.request_id || 0
            )
          }
        >
          <InfoCircle />
        </Button>
        {data.request_type === 'invite' || !isM(data.project_id) ? (
          <></>
        ) : (
          <>
            {' '}
            <Button
              variant='success'
              size='sm'
              onClick={() => api.toggleRequestCheckDialog(data.request_id || 0)}
            >
              <CheckLg />
            </Button>
          </>
        )}
      </>
    );
  };
  const columns = [
    {
      dataField: 'person_id',
      text: t('requestForm.person'),
      sort: true,
      formatter: (cell: undefined, row: TRequest) => (
        <PersonShow personId={row.person_id} hideEmpty />
      ),
    },
    {
      dataField: 'user_name',
      text: t('requestForm.user'),
      sort: true,
      formatter: (cell: undefined, row: TRequest) => (
        <UserShow userId={row.user_id} linkInDialog hideEmpty />
      ),
    },
    {
      dataField: 'location_name',
      text: t('location.location'),
      sort: true,
    },
    {
      dataField: 'request_name',
      text: t('requestForm.name'),
      sort: true,
    },
    {
      dataField: 'request_title',
      text: t('requestForm.title'),
      sort: true,
    },
  ];

  const getStyle = (data: TRequest): React.CSSProperties => {
    const style: React.CSSProperties = { background: '#fff', border: '3px solid #555555bf' };
    if (data.request_flow === 'reject') style.background = '#ffa3a382';
    if (data.request_flow === 'autoReject') style.background = '#b3b3b3';
    if (data.person_user === data.user_id) style.background = '#85ffa482';
    if (data.request_type === 'invite') style.border = '3px solid #ff00ff';
    return style;
  };

  return (
    <ItemViewer
      id='request_id'
      store='requestStore'
      type='request'
      filter={dataFilter}
      filterMarker={fresh}
      tableData={request || []}
      columns={columns}
      showRoute=''
      editRoute=''
      name='request_name'
      title='request_title'
      canEdit
      mainTitle={t(my ? 'header.myRequest' : 'request.mainTitle')}
      adminActions={adminActions}
      afterButton={afterButton}
      calculateFooter={my ? undefined : calculateFooter}
      calculatePreFooter={calculatePreFooter}
      calculateImage={calculateImage}
      getStyle={getStyle}
      staticShowType={!isAdmin ? ShowTypes.Card : undefined}
      minWidth={350}
    />
  );
});
export default RequestList;
