import { observer } from 'mobx-react';
import React from 'react';
import { Button, Card, Col, Stack } from 'react-bootstrap';
import { TText } from 'stores/TextsStore';
import { Link } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import { InfoCircle, Pencil, Trash } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import StatusesInCard from 'components/Heplers/StatusesInCard';
import { useStores } from '../../services/useStore';
import defaultImage from '../../assets/img/default.png';
import styles from '../Heplers/style.module.scss';

export interface ITextCard {
  text: TText;
  deleteHandler: (textId: number | undefined) => void;
}

const TextCard: React.FC<ITextCard> = observer(({ text, deleteHandler }) => {
  const { t } = useTranslation();
  const { api } = useStores();

  let textImage = text.img_id
    ? `${api.settings.url.server}img/${text.img_id}.${text.img_mime}`
    : '';
  if (!textImage && text.text_images) {
    const [imgId, imgMime] = text.text_images[0].split('.');
    textImage = `${api.settings.url.server}img/${imgId}.${imgMime}`;
  } else if (!textImage) textImage = defaultImage;

  const adminActions =
    api.isManager(text.project_id) && api.projectIsActive() ? (
      <>
        <Button variant='danger' size='sm' onClick={() => deleteHandler(text.text_id)}>
          <Trash />
        </Button>
        {'  '}
        <Link to={ROUTES.textEdit + '/' + text.text_id}>
          <Button variant='primary' size='sm'>
            <Pencil />
          </Button>
        </Link>
      </>
    ) : (
      ''
    );

  const style = {} as React.CSSProperties;
  const isDraft = text.text_draft === 1;
  if (isDraft) style.background = '#ffe8cf';
  return (
    <Col style={{ marginTop: '0.5rem', minWidth: 300 }}>
      <Card style={style}>
        <div className='card_image' style={{ backgroundImage: `url(${textImage})` }} />

        <Card.ImgOverlay className='rightText' style={{ top: 'auto' }}>
          {adminActions}
          {'  '}
          <Link to={ROUTES.texts + '/' + text.text_id}>
            <Button variant='primary' size='sm'>
              <InfoCircle />
            </Button>
          </Link>
        </Card.ImgOverlay>
        <Card.Body>
          <Card.Title>{text.text_name}&nbsp;</Card.Title>
          <Card.Text
            style={{
              maxHeight: 170,
              overflow: 'auto',
              fontSize: '0.8rem',
              marginBottom: 34,
            }}
          >
            {text.text_description.substr(0, 400)}
          </Card.Text>
        </Card.Body>
        <Card.Footer style={{ fontSize: '0.8rem' }}>
          <Stack direction='horizontal' style={{ justifyContent: 'space-between' }}>
            <small className='text-muted'>{api.timeFormat(text.text_modified)}&nbsp;</small>
          </Stack>
          <div style={{ height: 40 }} />
        </Card.Footer>

        {isDraft ? <div className={styles.draftCard}>{t('global.draft')}</div> : ''}
        <StatusesInCard status={text.text_status} />
      </Card>
    </Col>
  );
});
export default TextCard;
/*
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const calculateFooter = (data: any) => {
    const item = data as TRequest;
    return (
      <>
        {isManager ? (
          <div style={{ padding: 14, paddingTop: 0 }}>
            <UserShow userId={item.user_id} hideEmpty />
          </div>
        ) : (
          ''
        )}
        <Card.Footer style={{ fontSize: '0.8rem' }}>
          <Stack direction="horizontal" style={{ justifyContent: 'space-between' }}>
            {item.project_name}
          </Stack>
          <Stack direction="horizontal" style={{ justifyContent: 'space-between' }}>
            <small className="text-muted">{api.timeFormat(item.request_updated)}</small>
            {isManager && item.request_status ? (
              <Badge bg={badgeStyle(item.request_status)}>{item.request_status}</Badge>
            ) : (
              ''
            )}
          </Stack>
          <div style={{ height: 40 }} />
        </Card.Footer>
      </>
    );
  };
*/
