/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';
import PricingDialog from 'components/Dialogs/PricingDialog';
import image from '../../assets/img/welcome-image2.png';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const { sessionStore } = useStores();
  const [showPricing, setShowPricing] = useState(false);
  return (
    <>
      <Container style={{ paddingTop: 24 }}>
        <div className='shadow welcome-container' style={{ margin: 'auto' }}>
          <h1 style={{ fontSize: 30 }}>
            {t('global.hello')}
            {sessionStore.session.user_name ? ' ' + sessionStore.session.user_name : ''}.{' '}
            {t('global.hello1')}
          </h1>

          <div style={{ backgroundImage: `url(${image})` }} className='welcome-image' />
          <p
            style={{ paddingTop: 24, paddingBottom: 24 }}
            dangerouslySetInnerHTML={{ __html: t('home.welcome').replaceAll('\n', '<br/><br/>') }}
          />
          <h4>{t('home.aboutPrices')}</h4>
          <p
            style={{ margin: 0 }}
            dangerouslySetInnerHTML={{
              __html: t('home.aboutPricesDescriptions').replaceAll('\n', '<br/><br/>'),
            }}
          />
          <Button
            style={{ textAlign: 'right', boxShadow: 'none' }}
            variant='link'
            onClick={() => setShowPricing(true)}
          >
            {t('home.priceLink')}
          </Button>
        </div>
      </Container>
      <PricingDialog onlyView show={showPricing} hideCallback={() => setShowPricing(false)} />
    </>
  );
};
export default Home;
//<img src={logo} style={{ height: 50, margin: '-20px 0 -12px 0' }} alt='' />
