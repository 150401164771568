import CarouselList from 'components/Heplers/CarouselList';
import LinkList from 'components/Heplers/LinkList';
import TextStatusShow from 'components/Heplers/TextStatusShow';
import UserShow from 'components/Heplers/UserShow';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TText } from 'stores/TextsStore';
import MarkdownText from 'components/Heplers/MarkdownText';
import { useStores } from '../../services/useStore';

export interface ITextInfo {
  textId: number | undefined;
  imageHeight?: number;
  linkInDialog?: boolean;
}

const TextInfo: React.FC<ITextInfo> = observer(({ textId, imageHeight, linkInDialog = true }) => {
  const { t } = useTranslation();
  const { api, sessionStore } = useStores();
  const [text, setText] = useState<TText>();
  const [imgList, setImgList] = useState([] as string[]);

  useEffect(() => {
    if (textId) {
      api.getTexts(textId).then((data) => {
        const lo = data as TText;
        setText(lo);
        if (lo.text_images) setImgList(lo.text_images);
        else setImgList([]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textId]);
  if (!text) return <div>{t('global.loading')}</div>;
  if (api.isBot()) {
    if (
      (text.project_id !== sessionStore.session.user_project && text.text_status !== 'open') ||
      text.text_draft
    )
      return <div>Access deny</div>;
  } else if (!api.isManager(text.project_id) && text.text_status !== 'open')
    return <div>Access deny</div>;
  return (
    <Container
      fluid
      className='verticalPadding greenForm'
      style={{ paddingTop: linkInDialog ? 24 : 0 }}
    >
      <Row className='justify-content-md-center'>
        <Col xs lg={12}>
          {imgList.length ? <CarouselList imgList={imgList} height={imageHeight} /> : ''}
          {text.text_name ? <h2 className={imgList.length ? 'mt-3' : ''}>{text.text_name}</h2> : ''}
          <div className={text.text_name ? '' : 'mt-3'}>
            <MarkdownText text={text.text_description} />
          </div>
          {text.project_id && !api.isBot() ? (
            <>
              {api.isManager(text.project_id) ? <TextStatusShow status={text.text_status} /> : ''}
              <UserShow userId={text.text_master} />
            </>
          ) : (
            ''
          )}
        </Col>
      </Row>
      {!api.isBot() && linkInDialog ? (
        <>
          <br />
          <Row className='justify-content-md-center mb-3'>
            <Col style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <LinkList
                type='text'
                subType='person'
                id={textId || 0}
                storeName='textLinks'
                title={t('person.mainTitle')}
                linkInDialog
                controll={false}
                hideEmpty
              />
              <LinkList
                type='text'
                subType='location'
                id={textId || 0}
                storeName='textLinks'
                title={t('location.mainTitle')}
                muteLoadFromAPI
                controll={false}
                linkInDialog
                hideEmpty
              />
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <LinkList
                type='text'
                subType='status'
                id={textId || 0}
                storeName='textLinks'
                title={t('status.mainTitle')}
                muteLoadFromAPI
                controll={false}
                linkInDialog
                hideEmpty
              />
              <LinkList
                type='text'
                subType='project'
                id={textId || 0}
                storeName='textLinks'
                title={t('projects.mainTitle')}
                muteLoadFromAPI
                controll={false}
                linkInDialog
                hideEmpty
              />
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
    </Container>
  );
});
export default TextInfo;
