/* eslint-disable react/button-has-type */
import React from 'react';
import { Google } from 'react-bootstrap-icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { useTranslation } from 'react-i18next';
import { TGoogleAutResponse } from 'services/Api';

export interface IGoogleLoginButton {
  clientId: string;
  callback: (data: TGoogleAutResponse) => void;
  text?: string;
  active?: boolean;
}

const GoogleLoginButton: React.FC<IGoogleLoginButton> = ({
  clientId,
  callback,
  text = 'loginForm.google',
  active = false,
}) => {
  const { t } = useTranslation();

  const responseGoogle = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    const data: TGoogleAutResponse = (response as GoogleLoginResponse).profileObj;
    callback(data);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseErrorGoogle = (error: any) => {
    console.warn(error);
  };

  return (
    <GoogleLogin
      clientId={clientId}
      buttonText={t('loginForm.google')}
      onSuccess={responseGoogle}
      onFailure={responseErrorGoogle}
      cookiePolicy='single_host_origin'
      theme='dark'
      render={(renderProps) => (
        <button onClick={renderProps.onClick} className='customLoginButton'>
          <div className={active ? 'customLoginButtonActive' : ''}>
            <Google fontSize={20} color='#0d6efd' />
          </div>{' '}
          {t(text)}
        </button>
      )}
      isSignedIn={false}
    />
  );
};
export default GoogleLoginButton;
