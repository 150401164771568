/* eslint-disable react/no-danger */
import ItemTable from 'components/Heplers/ItemTable';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { CreditCardFill, Paypal, Plus } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';
import { TBilling } from 'stores/BillingsStore';

const ProfileBilling: React.FC = observer(() => {
  const { t } = useTranslation();
  const [inited, setInit] = useState(false);
  const { api, sessionStore, billingsStore } = useStores();
  const columns = [
    {
      dataField: 'billing_date',
      text: t('billing.creaded'),
      sort: true,
      headerStyle: { width: 150 },
      style: { fontSize: '0.8rem' },
      formatter: (cell: undefined, row: TBilling) => api.timeFormat(row.billing_date),
    },
    {
      dataField: 'billing_amount',
      headerStyle: { width: 100 },
      text: t('billing.amount'),
      sort: true,
      formatter: (cell: undefined, row: TBilling) => (
        <div
          style={{
            textAlign: 'right',
            fontWeight: 'bold',
            color: +row.billing_amount < 0 ? 'red' : 'green',
          }}
        >
          {row.billing_amount}
        </div>
      ),
    },
    {
      dataField: 'billing_action',
      text: t('billing.action'),
      sort: false,
      formatter: (cell: undefined, row: TBilling) => {
        let projectInfo;
        if (row.project_id && row.project_name) {
          projectInfo = <>{row.project_name}</>;
        } else {
          projectInfo = <></>;
        }
        const icon = row.billing_method === 'PayPal' ? <Paypal /> : <CreditCardFill />;
        return (
          <div>
            {t('billing.' + row.billing_action.toLowerCase())} <b>{projectInfo}</b> {icon}{' '}
            {row.billing_method}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    api.getBillings().then((list: TBilling[]) => {
      setInit(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container style={{ background: '#fff', marginTop: 14 }}>
      <Row>
        <Col>
          <h1>{t('billing.header')}</h1>
        </Col>
        <Col className='rightText' style={{ marginTop: '1rem' }}>
          <Button variant='primary' size='sm' onClick={() => api.togglePayDialog(true)}>
            <div style={{ display: 'inline-block', position: 'relative', top: -1 }}>
              <Plus fontSize={17} />
            </div>
            {'  '}
            {t('billing.addBallance')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>
            <span className='text-muted'>{t('billing.currentBallance')}:</span>{' '}
            {sessionStore.session.user_balance}
          </h4>
        </Col>
      </Row>
      <Row style={{ marginTop: 50 }}>
        <Col>
          <h4>{t('billing.billingHistory')}</h4>
        </Col>
      </Row>
      <Row>
        <Col className='mb-4'>
          {inited ? (
            <ItemTable
              id='billing_id'
              store='billingsStore'
              type='billing'
              tableData={billingsStore.data || []}
              columns={columns}
              canEdit={false}
              needControll={false}
            />
          ) : (
            t('loading')
          )}
        </Col>
      </Row>
    </Container>
  );
});
export default ProfileBilling;
