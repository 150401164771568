import React, { useEffect, useState, useCallback } from 'react';
import { Alert, Button, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useStores } from 'services/useStore';
import { useTranslation } from 'react-i18next';
import { TSuccessResponse } from 'services/Api';
import { observer } from 'mobx-react';
import { TNews } from 'stores/NewsStore';
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import ImageList from 'components/Heplers/ImageList';
import AITextButton from 'components/Buttons/AITextButton';
import DraftCheckBox from 'components/Heplers/DraftCheckBox';
import RedirectCheck from 'components/Heplers/RedirectCheck';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let timer: any;

const NewsEdit: React.FC = observer(() => {
  const pId = useParams().params || 0;
  const { t } = useTranslation();
  const { api, newsStore, sessionStore } = useStores();
  api.noLogin();
  const [newsId, setNewsId] = useState(+pId);
  const [validated, setValidated] = useState(false);
  const [draft, setDraft] = useState(1);
  const [description, setDescription] = useState('');
  const [visibilityAll, setVisibilityAll] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [imgList, setImgList] = useState([] as string[]);

  const onDraft = useCallback((value: number) => {
    setDraft(value);
  }, []);

  const onDescription = useCallback((value: string) => {
    setDescription(value);
  }, []);

  useEffect(() => {
    if (newsId) {
      api.getNews(newsId).then((data) => {
        const news = data as TNews;
        setDescription(news.news_description || '');
        setDraft(news.news_draft);
        setVisibilityAll(news.news_visibility === 'all');
        newsStore.update(news);
        if (news.news_images) setImgList(news.news_images);
        else setImgList([]);
      });
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateImages = useCallback(() => {
    api.getNews(newsId).then((data) => {
      const news = data as TNews;
      if (news.news_images) setImgList(news.news_images);
      else setImgList([]);
    });
  }, []);
  const updateNews = (event: React.FormEvent) => {
    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity()) {
      setValidated(true);
      const data: TNews = {
        news_description: description.trim(),
        news_draft: draft ? 1 : 0,
        user_id: sessionStore.session.user_id,
        news_visibility: visibilityAll ? 'all' : 'project',
      };
      if (newsId) data.news_id = newsId;
      api.setNews(data).then((res: TSuccessResponse) => {
        const { errors, success } = res;
        if (!errors) {
          if (newsId || (Number.isInteger(success) && success)) {
            let dataId = 0;
            if (newsId) dataId = newsId;
            else dataId = Number.isInteger(success) && success ? +success : 0;
            setNewsId(dataId);
            api.getNews(dataId).then((newsData) => {
              const st: TNews = newsData as TNews;
              if (!newsId) newsStore.add({ news_id: dataId, ...st });
              else newsStore.update(st);
            });
          } else if (success) {
            //newsStore.add({ news_id: +success, ...data });
            setNewsId(+success);
          }
          console.log('OK');
          setSuccessText(newsId ? 'newsForm.editSuccess' : 'newsForm.createSuccess');
          timer = setTimeout(() => {
            setSuccessText('');
          }, 5000);
        }
      });
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    return false;
  };
  const successAlert = successText ? <Alert variant='success'>{t(successText)}</Alert> : '';
  return (
    <Container fluid className='verticalPadding greenForm'>
      <RedirectCheck url={ROUTES.news} />
      <Form onSubmit={updateNews} action='#' validated={validated}>
        <Row className='justify-content-md-center'>
          <Col xs lg={10}>
            <h2>
              {t(newsId ? 'newsForm.edit' : 'newsForm.create')}
              {newsId ? <span className='text-muted'> #{newsId}</span> : ''}
            </h2>
            <FloatingLabel
              controlId='description'
              label={description ? '' : t('newsForm.description')}
              className='mb-3'
            >
              <Form.Control
                as='textarea'
                required
                rows={5}
                size='lg'
                value={description || ''}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={t('newsForm.description')}
                style={{ height: '400px' }}
              />
              <AITextButton handler={onDescription} text={description} />
            </FloatingLabel>
            <DraftCheckBox draft={draft} callback={onDraft} />
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col xs lg={10}>
            <Form.Check
              type='switch'
              key='visibilityAll'
              name='type'
              style={{ marginBottom: 20 }}
              label={t('newsForm.visibilityAll')}
              checked={visibilityAll || false}
              onChange={(e) => setVisibilityAll(e.target.checked)}
            />
            {successAlert}
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col xs={2}>
            <div className='d-grid gap-2'>
              <Button variant='primary' className='col-12' size='lg' type='submit'>
                {t('global.save')}
              </Button>
            </div>
          </Col>
          <Col xs={2}>
            <div className='d-grid gap-2'>
              <Link to={ROUTES.news}>
                <Button variant='secondary' className='col-12' size='lg'>
                  {t('global.back')}
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Form>
      {newsId ? (
        <>
          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              <h2>{t('newsForm.extendedHeader')}</h2>
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={10}>
              <ImageList
                id={newsId}
                type='news'
                genImgText={description}
                imgList={imgList}
                updateImagesHandle={updateImages}
                uploadUrl={'uploadNewsImage/' + newsId}
              />
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
    </Container>
  );
});
export default NewsEdit;
