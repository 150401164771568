/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Folder, FolderFill, Pencil, Trash } from 'react-bootstrap-icons';
import { TTextFolder, TTextFolderTreeItem } from 'stores/TextFoldersStore';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../services/useStore';
import styles from './style.module.scss';

export interface ITextFolderList {
  folderId: number;
  editMode: boolean;
  random?: number;
  action: (value: number) => void;
  folderAction: (folderId: number, action: string) => boolean;
}

const TextFolderList: React.FC<ITextFolderList> = observer(
  ({ folderId, action, editMode, folderAction, random }) => {
    const { t } = useTranslation();
    const { textFolderStore } = useStores();
    const [folders, setFolders] = useState<TTextFolder[]>();

    const updateData = () => {
      const list: TTextFolderTreeItem[] = textFolderStore.getFoldersByParent(0);
      const foldersData: TTextFolder[] = [];
      const getParents = (data: TTextFolderTreeItem[], level: number = 0) => {
        data.forEach((folderData: TTextFolderTreeItem) => {
          foldersData.push({ ...folderData.folder, level });
          if (folderData.childs.length) getParents(folderData.childs, level + 1);
        });
      };
      getParents(list);
      setFolders(foldersData);
    };

    useEffect(() => {
      updateData();
    }, [folderId, random]);

    /*useEffect(() => {
        api.getTextFolders().then(updateData);
    }, []);*/

    return (
      <div className='mb-4' style={{ background: '#fff', borderRadius: 5, padding: 14 }}>
        <div key={0}>
          <span
            role='link'
            onClick={() => action(0)}
            className={folderId === 0 ? styles.activeFolder : ''}
          >
            <FolderFill /> {t('folder.root')}
          </span>
        </div>
        {folders &&
          folders.map((folderData: TTextFolder) => (
            <div key={folderData.folder_id}>
              <span
                role='link'
                onClick={() => action(folderData.folder_id)}
                className={folderId === folderData.folder_id ? styles.activeFolder : ''}
              >
                {folderData.level ? ''.padEnd(folderData.level * 2, ' ') + '┗╸' : ''}
                {folderData.folders_count || folderData.text_count ? (
                  <FolderFill />
                ) : (
                  <Folder />
                )}{' '}
                {folderData.folder_name}
              </span>
              <span style={{ marginLeft: 14, opacity: 0.4 }}>{folderData.text_count}</span>
              {editMode && (
                <span style={{ marginLeft: 24 }}>
                  <Button
                    variant='info'
                    size='sm'
                    onClick={() => folderAction(folderData.folder_id, 'edit')}
                  >
                    <Pencil />
                  </Button>{' '}
                  <Button
                    variant='danger'
                    size='sm'
                    onClick={() => folderAction(folderData.folder_id, 'delete')}
                  >
                    <Trash />
                  </Button>{' '}
                </span>
              )}
            </div>
          ))}
      </div>
    );
  }
);
export default TextFolderList;
