import { observer } from 'mobx-react';
import React from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import NewsInfo from './NewsInfo';

const Newss: React.FC = observer(() => {
  const newsId = useParams().params || 0;
  return (
    <Container style={{ marginTop: 14 }}>
      <NewsInfo newsId={+newsId} />
    </Container>
  );
});
export default Newss;
