import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ItemViewer from 'components/Heplers/ItemViewer';
import AddButton from 'components/Buttons/AddButton';
import AICreateButton from 'components/Buttons/AICreateButton';
import { ProjectTypes } from 'stores/ProjectsStore';
import { TLocation } from 'stores/LocationsStore';
import { useStores } from '../../services/useStore';

const LocationList: React.FC = observer(() => {
  const { t } = useTranslation();
  const { sessionStore, locationStore, api } = useStores();
  api.noLogin();
  const editLink =
    sessionStore.session.project_type === ProjectTypes.LORE ? 'locationLOREEdit' : 'locationEdit';
  let data = locationStore.data.filter(
    (p: TLocation) => p.project_id === sessionStore.session.user_project
  );
  if (!data) data = [];

  const adminActions =
    api.isManager(sessionStore.session.user_project) && api.projectIsActive() ? (
      <>
        <AddButton title={t('location.newLocation')} link={editLink} />
        &nbsp;
        <AICreateButton type='location' />
      </>
    ) : (
      ''
    );

  const columns = [
    {
      dataField: 'location_name',
      text: t('locationForm.name'),
      sort: true,
    },
    {
      dataField: 'location_title',
      text: t('locationForm.title'),
      sort: true,
    },
  ];

  const getStyle = (location: TLocation): React.CSSProperties => {
    if (api.getProjectType() === ProjectTypes.LARP && location.location_virtual)
      return { border: '3px solid #555555' };
    return { background: '#fff' };
  };

  const calculateFooter = (item: TLocation) => {
    return (
      <>
        {api.getProjectType() === ProjectTypes.LARP && item.location_virtual ? (
          <div className='request-badger'>{t('global.virtual')}</div>
        ) : (
          ''
        )}
      </>
    );
  };

  return (
    <ItemViewer
      id='location_id'
      store='locationStore'
      type='location'
      tableData={data}
      columns={columns}
      showRoute='locations'
      editRoute={editLink}
      draftField='location_draft'
      statusField='location_status'
      name='location_name'
      title='location_title'
      canEdit
      mainTitle={t('location.mainTitle')}
      adminActions={adminActions}
      getStyle={getStyle}
      calculateFooter={calculateFooter}
    />
  );
});
export default LocationList;
