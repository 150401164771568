import React, { useState } from 'react';
import { Button, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useStores } from 'services/useStore';
import { useTranslation } from 'react-i18next';
import { TSessionRequest } from 'services/Api';
import { ROUTES } from 'components/App/App';

interface IRegisterPage {}

const RegisterPage: React.FC<IRegisterPage> = () => {
  const { t } = useTranslation();
  const { api } = useStores();
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRePassword] = useState('');
  const [name, setName] = useState('');
  const hide = () => {
    api.toggleLoginDialog(false);
  };
  const checkRepass = (): boolean => {
    if (password !== '') {
      if (password === repassword) {
        //setReValid(true);
        return true;
      }
      return false;
    }
    return true;
  };

  const handleRegister = (event: React.FormEvent) => {
    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity()) {
      if (checkRepass()) {
        setValidated(true);
        const telegramId = api.isBot() ? api.telegram.user.id : 0;
        api.register({ email, password, name, telegramId }).then((res: TSessionRequest) => {
          const { errors } = res;
          if (errors) {
            if (errors.user_email) setEmailInvalid(errors.user_email);
          } else {
            //history(ROUTES.profile);
            hide();
            setTimeout(() => {
              window.localStorage.firstRun = true;
              window.location.href = ROUTES.profile;
            }, 100);
          }
        });
        event.preventDefault();
        event.stopPropagation();
      }
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    return false;
  };
  /*const setRepassword = (re:string) => {
    setRePassword(re); 
    checkRepass();
  }*/
  return (
    <Container>
      <Form onSubmit={handleRegister} action='#' validated={validated}>
        <Container fluid className='verticalPadding greenForm mt-3'>
          <Row>
            <Col>
              <h1>{t('registerForm.header')}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                controlId='floatingEmail'
                label={t('loginForm.email')}
                className='mb-3'
              >
                <Form.Control
                  type='email'
                  required
                  size='lg'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t('loginForm.email')}
                  isInvalid={!!emailInvalid}
                />
                {emailInvalid ? (
                  <Form.Control.Feedback type='invalid'>
                    {t('registerForm.' + emailInvalid)}
                  </Form.Control.Feedback>
                ) : (
                  <Form.Text muted>{t('registerForm.emailDesc')}</Form.Text>
                )}
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                controlId='floatingPassword'
                label={t('loginForm.password')}
                className='mb-3'
              >
                <Form.Control
                  type='password'
                  required
                  size='lg'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={t('loginForm.password')}
                />
                <Form.Text muted>{t('registerForm.passwordDesc')}</Form.Text>
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                controlId='floatingRePassword'
                label={t('registerForm.repassword')}
                className='mb-3'
              >
                <Form.Control
                  type='password'
                  size='lg'
                  required
                  value={repassword}
                  onChange={(e) => setRePassword(e.target.value)}
                  placeholder={t('registerForm.repassword')}
                  isInvalid={!checkRepass()}
                />
                <Form.Text muted>{t('registerForm.repasswordDesc')}</Form.Text>
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                controlId='floatingInput'
                label={t('registerForm.name')}
                className='mb-3'
              >
                <Form.Control
                  type='text'
                  required
                  size='lg'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder={t('registerForm.name')}
                />
                <Form.Text muted>{t('registerForm.nameDesc')}</Form.Text>
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Check required label={t('registerForm.agree')} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant='primary' size='lg' type='submit'>
                {t('registerForm.submit')}
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </Container>
  );
};

export default RegisterPage;
