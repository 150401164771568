import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';

const NoticeDialog: React.FC = observer(() => {
  const { t } = useTranslation();
  const { api, sessionStore } = useStores();
  const [notice, setNotice] = useState<string>('');
  const [time, setTime] = useState<NodeJS.Timeout | false>();

  const saveNotice = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const noticeData = (document.getElementById('notice') as any)?.value;
    api.setNotice(noticeData);
    setTime(false);
  };

  const set = (text: string) => {
    setNotice(text || '');
    if (time) clearTimeout(time);
    setTime(setTimeout(saveNotice, 5000));
  };

  useEffect(() => {
    api.getNotice().then((data: string) => {
      setNotice(data || '');
    });
    return () => {
      saveNotice();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      show={sessionStore.appSettings.textNodiceShow}
      size='lg'
      key='editModal'
      centered
      onHide={() => api.toggleNoticeDialog(false)}
    >
      <Modal.Header closeButton>{t('notice.header')}</Modal.Header>
      <Modal.Body style={{ backgroundColor: '#0000001c' }}>
        <Row className='justify-content-md-center'>
          <Col xs lg={12}>
            <Form.Control
              as='textarea'
              rows={15}
              size='lg'
              id='notice'
              value={notice}
              onChange={(e) => set(e.target.value)}
              placeholder={t('notice.placeholder')}
              style={{ height: 600, fontSize: 16, lineHeight: '16px', padding: 10 }}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
});
export default NoticeDialog;
