import BaseStore from './BaseStore';

export type TCreateQuestReturn = {
  quest_id?: number;
  quest_name: string;
  quest_description: string;
  person_quest: number[];
  prompt: string;
  links?: string;
  text?: string;
  aiImgId?: number;
  imageAutoAdd?: boolean;
  image?: string;
  added?: boolean;
};

class GeneratedQuestsStore extends BaseStore {
  idKey = 'aiImgId';
  get data(): TCreateQuestReturn[] {
    return this._data as TCreateQuestReturn[];
  }
}
export default GeneratedQuestsStore;
