import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Markdown from 'react-markdown';

export type TMarkdownText = {
  text: string;
  noHTML?: boolean;
};

const MarkdownText: React.FC<TMarkdownText> = ({ text, noHTML }) => {
  if (noHTML)
    return (
      <>
        {text
          .replaceAll('*', '')
          .replaceAll('~', '')
          .replaceAll('#', '')
          .replaceAll('>', '')
          .replaceAll('<', '')
          .replaceAll('[', '')
          .replaceAll(']', '')}
      </>
    );
  return <Markdown className='markdown'>{text}</Markdown>;
};
export default MarkdownText;
