import BaseStore from './BaseStore';
import { TStatus } from './StatusesStore';

export type TRequest = {
  request_id?: number;
  project_id: number;
  user_id?: number;
  user_name?: string;
  person_id?: number;
  person_user?: number;
  location_id?: number;
  request_name: string;
  request_title: string;
  request_job?: string;
  request_age?: string;
  request_description?: string;
  request_wantplay?: string;
  request_dontplay?: string;
  request_flow?: string;
  request_masterinfo?: string;
  request_status?: string;
  request_payment?: string;
  request_created?: Date;
  request_updated?: Date;
  request_type?: string;
  request_bl?: string;
  project_name?: string;
  person_image?: string;
  person_name?: string;
  person_title?: string;
  location_name?: string;
  request_images?: string[];
  person_images?: string[];
  location_images?: string[];
  statuses?: TStatus[];
  img_id?: number;
  img_mime?: string;
};

class RequestsStore extends BaseStore {
  idKey = 'request_id';
  get data(): TRequest[] {
    return this._data as TRequest[];
  }

  getByPerson = (person_id: number) =>
    this.data.filter((request: TRequest) => request.person_id === person_id);
}
export default RequestsStore;
