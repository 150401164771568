/* eslint-disable @typescript-eslint/no-explicit-any */
import { action, makeObservable, observable } from 'mobx';
import { TLocation } from './LocationsStore';
import { RootStore } from './RootStore';
import { TStatus } from './StatusesStore';

class GraphFilterStore {
  filterType!: number;
  filterRequest!: number;
  filterVirtual!: number;
  filterText!: string;
  filterLocation!: number[];
  filterStatus!: number[];
  locations!: TLocation[];
  statuses!: TStatus[];
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.filterType = 0;
    this.filterRequest = 0;
    this.filterVirtual = 0;
    this.filterText = '';
    this.filterLocation = [];
    this.filterStatus = [];
    this.locations = [];
    this.statuses = [];

    makeObservable(this, {
      filterType: observable,
      filterRequest: observable,
      filterText: observable,
      filterLocation: observable,
      filterStatus: observable,
      filterVirtual: observable,
      locations: observable,
      statuses: observable,
      setFilterType: action,
      setFilterRequest: action,
      setFilterText: action,
      setFilterLocation: action,
      setFilterStatus: action,
      setLocations: action,
      setStatuses: action,
      setVirtualFilter: action,
    });
  }

  setFilterType(data: number): void {
    this.filterType = data;
  }
  setFilterRequest(data: number): void {
    this.filterRequest = data;
  }
  setFilterText(data: string): void {
    this.filterText = data;
  }
  setFilterLocation(data: number[]): void {
    this.filterLocation = data;
  }
  setFilterStatus(data: number[]): void {
    this.filterStatus = data;
  }
  setLocations(data: TLocation[]): void {
    this.locations = data;
  }
  setStatuses(data: TStatus[]): void {
    this.statuses = data;
  }
  setVirtualFilter(data: number): void {
    this.filterVirtual = data;
  }
}

export default GraphFilterStore;
