import React, { useEffect, useState } from 'react';
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ROUTES } from 'components/App/App';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';
import { Bug } from 'react-bootstrap-icons';
import AlphaVersion from 'components/Heplers/AlphaVersion';
import PricingDialog from 'components/Dialogs/PricingDialog';
import logo from '../../assets/img/owl3.png';

const NoUserHeader: React.FC = observer(() => {
  const { t } = useTranslation();
  const { api } = useStores();
  const [navs, setNavs] = useState<JSX.Element[] | undefined>();
  const [showPricing, setShowPricing] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      const items = [];
      items.push(
        <Nav.Link as={Link} key='allProjects' to={ROUTES.projects}>
          {t('header.allProjects')}
        </Nav.Link>
      );
      items.push(
        <Nav.Link as={Link} key='oferta' to={ROUTES.texts + '/' + t('help.oferta')}>
          {t('header.oferta')}
        </Nav.Link>
      );
      items.push(
        <Nav.Link as={Button} variant='dark' key='prices' onClick={() => setShowPricing(true)}>
          {t('header.prices')}
        </Nav.Link>
      );
      setNavs(items);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Navbar bg='dark' expand='lg' variant='dark'>
        <AlphaVersion />
        <Container>
          <Navbar.Brand>
            <img src={logo} style={{ height: 50, margin: '-20px 0 -12px 0' }} alt='' />
          </Navbar.Brand>
          <Navbar.Brand href={ROUTES.home}>{t('header.userHeader')}</Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me-auto'>{navs}</Nav>
            <Nav className='justify-content-end'>
              <Button variant='light' onClick={() => api.toggleLoginDialog(true)}>
                {t('loginForm.signIn')} / {t('loginForm.register')}
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
        <Button
          style={{ marginRight: 10, padding: 5 }}
          variant='dark'
          onClick={() => api.bugDialog(true)}
          size='sm'
        >
          <Bug fontSize={28} color='#ffffff33' />
        </Button>
      </Navbar>
      <PricingDialog onlyView show={showPricing} hideCallback={() => setShowPricing(false)} />
    </>
  );
});

export default NoUserHeader;
