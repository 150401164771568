import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AddButton from 'components/Buttons/AddButton';
import ItemViewer from 'components/Heplers/ItemViewer';
import { TStatus } from 'stores/StatusesStore';
import { useStores } from '../../services/useStore';

const StatusList: React.FC = observer(() => {
  const { t } = useTranslation();
  const { sessionStore, statusStore, api } = useStores();
  api.noLogin();
  let data = statusStore.data.filter(
    (p: TStatus) => p.project_id === sessionStore.session.user_project
  );
  if (!data) data = [];

  const adminActions =
    api.isManager(sessionStore.session.user_project) && api.projectIsActive() ? (
      <AddButton title={t('status.newStatus')} link='statusEdit' />
    ) : (
      ''
    );

  const columns = [
    {
      dataField: 'status_name',
      text: t('statusForm.name'),
      sort: true,
    },
  ];
  return (
    <ItemViewer
      id='status_id'
      store='statusStore'
      type='status'
      tableData={data}
      columns={columns}
      showRoute='statuses'
      editRoute='statusEdit'
      name='status_name'
      title='status_title'
      draftField='status_draft'
      canEdit
      mainTitle={t('status.mainTitle')}
      adminActions={adminActions}
    />
  );
});
export default StatusList;
