/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TRequest } from 'stores/RequestsStore';

export type TRequestStatusBadger = {
  data: TRequest;
};
export const badgeStyle = (status: string): string => {
  switch (status) {
    case 'success':
      return 'success';
    case 'reject':
      return 'danger';
    case 'autoReject':
      return 'danger';
    case 'init':
      return 'warning';
    default:
      return 'secondary';
  }
};

const RequestStatusBadger: React.FC<TRequestStatusBadger> = ({ data }) => {
  const { t } = useTranslation();
  let status = t('request.requestWork');
  if (data.request_flow === 'reject') status = t('request.rejected');
  if (data.request_flow === 'autoReject') status = t('request.rejected2');
  if (data.request_flow === 'init' && data.request_type === 'invite')
    status = t('request.waitForYou');
  if (data.person_user === data.user_id)
    status = t(data.request_type === 'invite' ? 'request.accepted2' : 'request.accepted');
  return (
    <>
      {data.request_status ? <Badge bg={badgeStyle(data.request_flow || '')}>{status}</Badge> : ''}
      {data.request_type === 'invite' ? (
        <div className='invite-badger'>{t('request.inviteUser2')}</div>
      ) : (
        <div className='request-badger'>{t('request.request')}</div>
      )}
    </>
  );
};
export default RequestStatusBadger;
