import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useStores } from 'services/useStore';
import { useTranslation } from 'react-i18next';
import { TSuccessResponse } from 'services/Api';
import { observer } from 'mobx-react';
import { StatusVisibility, TStatus } from 'stores/StatusesStore';
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import ImageList from 'components/Heplers/ImageList';
import LinkList from 'components/Heplers/LinkList';
import AITextButton from 'components/Buttons/AITextButton';
import DraftCheckBox from 'components/Heplers/DraftCheckBox';
import RedirectCheck from 'components/Heplers/RedirectCheck';
//import styles from './style.module.scss';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let timer: any;

const StatusEdit: React.FC = observer(() => {
  const pId = useParams().params || 0;
  const { t } = useTranslation();
  const { api, statusStore } = useStores();
  api.noLogin();
  const [statusId, setStatusId] = useState(+pId);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState('');
  const [draft, setDraft] = useState(1);
  const [description, setDescription] = useState('');
  const [successText, setSuccessText] = useState('');
  const [visibility, setVisibility] = useState<string>(StatusVisibility.Open);
  const [imgList, setImgList] = useState([] as string[]);

  const onDescription = useCallback((value: string) => {
    setDescription(value);
  }, []);

  const onDraft = useCallback((value: number) => {
    setDraft(value);
  }, []);

  useEffect(() => {
    if (statusId) {
      api.getStatuses(statusId).then((data) => {
        const status = data as TStatus;
        setName(status.status_name);
        setDescription(status.status_description || '');
        setDraft(status.status_draft || 0);
        setVisibility(status.status_visibility || StatusVisibility.Open);
        statusStore.update(status);
        if (status.status_images) setImgList(status.status_images);
        else setImgList([]);
      });
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateImages = useCallback(() => {
    api.getStatuses(statusId).then((data) => {
      const status = data as TStatus;
      if (status.status_images) setImgList(status.status_images);
      else setImgList([]);
    });
  }, []);
  const updateStatus = (event: React.FormEvent) => {
    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity()) {
      setValidated(true);
      const data: TStatus = {
        status_name: name.trim(),
        status_description: description.trim(),
        status_draft: draft,
      };
      if (statusId) data.status_id = statusId;
      api.setStatus(data).then((res: TSuccessResponse) => {
        const { errors, success } = res;
        if (!errors) {
          if (statusId || (Number.isInteger(success) && success)) {
            let dataId = 0;
            if (statusId) dataId = statusId;
            else dataId = Number.isInteger(success) && success ? +success : 0;
            setStatusId(dataId);
            api.getStatuses(dataId).then((statusData) => {
              const st: TStatus = statusData as TStatus;
              if (!statusId) statusStore.add({ status_id: dataId, ...st });
              else statusStore.update(st);
            });
          } else if (success) {
            //statusStore.add({ status_id: +success, ...data });
            setStatusId(+success);
          }
          console.log('OK');
          setSuccessText(statusId ? 'statusForm.editSuccess' : 'statusForm.createSuccess');
          timer = setTimeout(() => {
            setSuccessText('');
          }, 5000);
        }
      });
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    return false;
  };
  const successAlert = successText ? <Alert variant='success'>{t(successText)}</Alert> : '';
  return (
    <Container fluid className='verticalPadding greenForm'>
      <RedirectCheck url={ROUTES.statuses} />
      <Form onSubmit={updateStatus} action='#' validated={validated}>
        <Row className='justify-content-md-center'>
          <Col xs lg={10}>
            <h2>
              {t(statusId ? 'statusForm.edit' : 'statusForm.create')}
              {statusId ? <span className='text-muted'> #{statusId}</span> : ''}
            </h2>
            <FloatingLabel controlId='name' label={t('statusForm.name')} className='mb-3'>
              <Form.Control
                type='text'
                required
                size='lg'
                value={name || ''}
                onChange={(e) => setName(e.target.value)}
                placeholder={t('statusForm.name')}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId='description'
              label={description ? '' : t('statusForm.description')}
              className='mb-3'
            >
              <Form.Control
                as='textarea'
                required
                rows={5}
                size='lg'
                value={description || ''}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={t('statusForm.description')}
                style={{ height: '100px' }}
              />
              <AITextButton handler={onDescription} text={description} />
            </FloatingLabel>
            <Form.Check
              type='switch'
              key='useAI'
              name='type'
              style={{ marginBottom: 20 }}
              label={t('statusForm.visibility')}
              checked={visibility === StatusVisibility.Open}
              onChange={(e) =>
                setVisibility(e.target.checked ? StatusVisibility.Open : StatusVisibility.Close)
              }
            />
            <DraftCheckBox draft={draft} callback={onDraft} />
            {successAlert}
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col xs={2}>
            <div className='d-grid gap-2'>
              <Button variant='primary' className='col-12' size='lg' type='submit'>
                {t('global.save')}
              </Button>
            </div>
          </Col>
          <Col xs={2}>
            <div className='d-grid gap-2'>
              <Link to={ROUTES.statuses}>
                <Button variant='secondary' className='col-12' size='lg'>
                  {t('global.back')}
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Form>
      {statusId ? (
        <>
          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              <h2>{t('statusForm.extendedHeader')}</h2>
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={5}>
              <LinkList
                type='status'
                subType='person'
                id={statusId}
                storeName='statusLinks'
                title={t('person.mainTitle')}
              />
            </Col>
            <Col lg={5}>
              <LinkList
                type='status'
                subType='request'
                id={statusId}
                storeName='statusLinks'
                title={t('request.mainTitle')}
                muteLoadFromAPI
              />
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={10}>
              <LinkList
                type='status'
                subType='text'
                id={statusId}
                storeName='statusLinks'
                title={t('text.mainTitle')}
                muteLoadFromAPI
              />
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={10}>
              <ImageList
                id={statusId}
                type='status'
                genImgText={description}
                imgList={imgList}
                updateImagesHandle={updateImages}
                uploadUrl={'uploadStatusImage/' + statusId}
              />
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
    </Container>
  );
});

export default StatusEdit;
