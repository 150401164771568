import BaseStore from './BaseStore';

export type TStuffFolder = {
  project_id: number;
  folder_id: number;
  folder_name: string;
  folder_parent: number;
  stuff_count?: number;
  folders_count?: number;
  level?: number;
};

export type TStuffFolderTreeItem = {
  folder: TStuffFolder;
  childs: TStuffFolderTreeItem[];
};

class StuffFoldersStore extends BaseStore {
  idKey = 'folder_id';
  get data(): TStuffFolder[] {
    return this._data as TStuffFolder[];
  }

  getFoldersByParent = (folderParent: number): TStuffFolderTreeItem[] => {
    const result: TStuffFolderTreeItem[] = [];
    if (this._data?.length) {
      this._data.forEach((folder: TStuffFolder) => {
        if (folder.folder_parent !== folderParent) return;
        result.push({
          folder,
          childs: this.getFoldersByParent(folder.folder_id),
        });
      });
    }
    return result;
  };
}
export default StuffFoldersStore;
