import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useStores } from 'services/useStore';
import { Col, Container, Row } from 'react-bootstrap';
import { TPerson } from 'stores/PersonsStore';
import { TQuest } from 'stores/QuestsStore';
import { TStatus } from 'stores/StatusesStore';
import { TText } from 'stores/TextsStore';
import { TRequest } from 'stores/RequestsStore';
import { TStuff } from 'stores/StuffsStore';
import { TLocation } from 'stores/LocationsStore';
import PersonShow from 'components/Heplers/PersonShow';
import TextHighLight from 'components/Heplers/TextHighLight';
import ElementShow from 'components/Heplers/ElementShow';

type TSearchResultData = {
  searchText: string;
  location?: TLocation[];
  person?: TPerson[];
  quest?: TQuest[];
  status?: TStatus[];
  stuff?: TStuff[];
  text?: TText[];
  request?: TRequest[];
};

export type TSearchResult = {
  result: TSearchResultData;
};

const SearchResult: React.FC = observer(() => {
  const { t } = useTranslation();
  const { api, sessionStore } = useStores();
  const { searchQuery } = sessionStore.appSettings;
  api.noLogin();
  const [inited, setInit] = useState(false);
  const [resultData, setResultData] = useState<TSearchResultData>();
  useEffect(() => {
    if (!searchQuery) return;
    api.search(searchQuery).then((data: TSearchResult) => {
      setInit(true);
      setResultData(data.result);
      console.log(data.result);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  if (!searchQuery) {
    return <div>Search query error!</div>;
  }
  if (!inited) {
    return <div>{t('loading')}</div>;
  }
  return (
    <Container style={{ background: '#fff', marginTop: 14, padding: 24 }}>
      <Row className='justify-content-md-center mb-1'>
        <Col>
          <h2>
            <b>{t('search.searchBy')} </b>
            <span className='text-muted'>{resultData?.searchText}</span>
          </h2>
        </Col>
      </Row>
      <hr />
      {resultData?.person?.length ? (
        <>
          <Row className='justify-content-md-center'>
            <Col>
              <h4>
                {t('person.mainTitle')}{' '}
                <span className='text-muted'>[{resultData?.person?.length}]</span>
              </h4>
            </Col>
          </Row>
          <Row className='justify-content-md-center' style={{ marginLeft: '2rem' }}>
            <Col>
              {resultData?.person?.map((person: TPerson) => (
                <div key={person.person_id} className='mb-3'>
                  <Row key={person.person_id + 'name'} className='justify-content-md-center mb-1'>
                    <Col>
                      <PersonShow personId={person.person_id} />
                    </Col>
                  </Row>
                  <TextHighLight
                    findText={resultData?.searchText}
                    key={person.person_id + 'description'}
                    text={person.person_description}
                  />
                  <TextHighLight
                    findText={resultData?.searchText}
                    key={person.person_id + 'title'}
                    text={person.person_title}
                  />
                </div>
              ))}
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
      {resultData?.text?.length ? (
        <>
          <Row className='justify-content-md-center'>
            <Col>
              <h4>
                {t('text.mainTitle')}{' '}
                <span className='text-muted'>[{resultData?.text?.length}]</span>
              </h4>
            </Col>
          </Row>
          <Row className='justify-content-md-center' style={{ marginLeft: '2rem' }}>
            <Col>
              {resultData?.text?.map((text: TText) => (
                <div key={text.text_id} className='mb-3'>
                  <Row key={text.text_id + 'name'} className='justify-content-md-center mb-1'>
                    <Col>
                      <ElementShow
                        id={text.text_id}
                        link='text'
                        title={text.text_name || text.text_description.substring(0, 90)}
                        img_id={text.img_id}
                        img_mime={text.img_mime}
                      />
                    </Col>
                  </Row>
                  <TextHighLight
                    findText={resultData?.searchText}
                    key={text.text_id + 'description'}
                    text={text.text_description}
                  />
                </div>
              ))}
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
      {resultData?.quest?.length ? (
        <>
          <Row className='justify-content-md-center'>
            <Col>
              <h4>
                {t('quest.mainTitle')}{' '}
                <span className='text-muted'>[{resultData?.quest?.length}]</span>
              </h4>
            </Col>
          </Row>
          <Row className='justify-content-md-center' style={{ marginLeft: '2rem' }}>
            <Col>
              {resultData?.quest?.map((quest: TQuest) => (
                <div key={quest.quest_id} className='mb-3'>
                  <Row key={quest.quest_id + 'name'} className='justify-content-md-center mb-1'>
                    <Col>
                      <ElementShow
                        id={quest.quest_id}
                        link='quest'
                        title={quest.quest_name}
                        img_id={quest.img_id}
                        img_mime={quest.img_mime}
                      />
                    </Col>
                  </Row>
                  <TextHighLight
                    findText={resultData?.searchText}
                    key={quest.quest_id + 'description'}
                    text={quest.quest_description}
                  />
                </div>
              ))}
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
      {resultData?.location?.length ? (
        <>
          <Row className='justify-content-md-center'>
            <Col>
              <h4>
                {t('location.mainTitle')}{' '}
                <span className='text-muted'>[{resultData?.location?.length}]</span>
              </h4>
            </Col>
          </Row>
          <Row className='justify-content-md-center' style={{ marginLeft: '2rem' }}>
            <Col>
              {resultData?.location?.map((location: TLocation) => (
                <div key={location.location_id} className='mb-3'>
                  <Row
                    key={location.location_id + 'name'}
                    className='justify-content-md-center mb-1'
                  >
                    <Col>
                      <ElementShow
                        id={location.location_id}
                        link='location'
                        title={location.location_name}
                        img_id={location.img_id}
                        img_mime={location.img_mime}
                      />
                    </Col>
                  </Row>
                  <TextHighLight
                    findText={resultData?.searchText}
                    key={location.location_id + 'title'}
                    text={location.location_title}
                  />
                </div>
              ))}
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
      {resultData?.status?.length ? (
        <>
          <Row className='justify-content-md-center'>
            <Col>
              <h4>
                {t('status.mainTitle')}{' '}
                <span className='text-muted'>[{resultData?.status?.length}]</span>
              </h4>
            </Col>
          </Row>
          <Row className='justify-content-md-center' style={{ marginLeft: '2rem' }}>
            <Col>
              {resultData?.status?.map((status: TStatus) => (
                <div key={status.status_id} className='mb-3'>
                  <Row key={status.status_id + 'name'} className='justify-content-md-center mb-1'>
                    <Col>
                      <ElementShow
                        id={status.status_id}
                        link='status'
                        title={status.status_name}
                        img_id={status.img_id}
                        img_mime={status.img_mime}
                      />
                    </Col>
                  </Row>
                  <TextHighLight
                    findText={resultData?.searchText}
                    key={status.status_id + 'description'}
                    text={status.status_description}
                  />
                </div>
              ))}
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
      {resultData?.stuff?.length ? (
        <>
          <Row className='justify-content-md-center'>
            <Col>
              <h4>
                {t('stuff.mainTitle')}{' '}
                <span className='text-muted'>[{resultData?.stuff?.length}]</span>
              </h4>
            </Col>
          </Row>
          <Row className='justify-content-md-center' style={{ marginLeft: '2rem' }}>
            <Col>
              {resultData?.stuff?.map((stuff: TStuff) => (
                <div key={stuff.stuff_id} className='mb-3'>
                  <Row key={stuff.stuff_id + 'name'} className='justify-content-md-center mb-1'>
                    <Col>
                      <ElementShow
                        id={stuff.stuff_id}
                        link='stuff'
                        title={stuff.stuff_name}
                        img_id={stuff.img_id}
                        img_mime={stuff.img_mime}
                      />
                    </Col>
                  </Row>
                  <TextHighLight
                    findText={resultData?.searchText}
                    key={stuff.stuff_id + 'title'}
                    text={stuff.stuff_title}
                  />
                  <TextHighLight
                    findText={resultData?.searchText}
                    key={stuff.stuff_id + 'description'}
                    text={stuff.stuff_description}
                  />
                </div>
              ))}
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
      {resultData?.request?.length ? (
        <>
          <Row className='justify-content-md-center'>
            <Col>
              <h4>
                {t('request.mainTitle')}{' '}
                <span className='text-muted'>[{resultData?.request?.length}]</span>
              </h4>
            </Col>
          </Row>
          <Row className='justify-content-md-center' style={{ marginLeft: '2rem' }}>
            <Col>
              {resultData?.request?.map((request: TRequest) => (
                <div key={request.request_id} className='mb-3'>
                  <Row key={request.request_id + 'name'} className='justify-content-md-center mb-1'>
                    <Col>
                      <ElementShow
                        id={request.request_id}
                        link='request'
                        title={request.request_name}
                        img_id={request.img_id}
                        img_mime={request.img_mime}
                      />
                    </Col>
                  </Row>
                  <TextHighLight
                    findText={resultData?.searchText}
                    key={request.request_id + 'title'}
                    text={request.request_title}
                  />
                  <TextHighLight
                    findText={resultData?.searchText}
                    key={request.request_id + 'description'}
                    text={request.request_description}
                  />
                </div>
              ))}
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
    </Container>
  );
});

export default SearchResult;
