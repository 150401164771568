import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from './App';

const PaymentsBackPage: React.FC = () => {
  const { t } = useTranslation();
  const backType = useParams().params || '';
  const history = useNavigate();
  useEffect(() => {
    if (!backType) return;
    const bt = backType.split('_');
    const type = bt[0] || '';
    const id = +(bt[1] || 0);
    let redirectUrl: string = ROUTES.home;

    switch (type) {
      case 'project':
        redirectUrl = `${ROUTES.projectEditLARP}/${id}`;
        break;
      default:
        break;
    }
    setTimeout(() => {
      history(redirectUrl);
    }, 3000);
  }, []);
  return (
    <Container style={{ paddingTop: 24 }}>
      <div className='shadow welcome-container mb-4' style={{ margin: 'auto' }}>
        <Row>
          <Col className='mb-3'>
            <h1 style={{ textAlign: 'center' }}>{t('billing.thankYouForSubscrition')}</h1>
          </Col>
        </Row>
      </div>
    </Container>
  );
};
export default PaymentsBackPage;
