import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TLocationTree } from 'stores/LocationsStore';
import { useStores } from '../../services/useStore';

export interface ILocationTreeSelect {
  locationId: number;
  action: (value: number) => void;
  placeholder?: string;
  noParentText?: string;
}

const LocationTreeSelect: React.FC<ILocationTreeSelect> = observer(
  ({ locationId, action, placeholder, noParentText }) => {
    const { t } = useTranslation();
    const { locationStore } = useStores();
    const [locationTree, setLocationTree] = useState<TLocationTree[]>([]);

    useEffect(() => {
      setLocationTree(locationStore.getLocationFlat(0));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId]);

    return (
      <FloatingLabel
        controlId='location'
        label={placeholder || t('location.location')}
        className='mb-3'
      >
        <Form.Select
          aria-label={placeholder || t('location.location')}
          required
          size='lg'
          value={locationId}
          className='treeSelect'
          onChange={(e) => action(+e.currentTarget.value)}
        >
          <option value={0}>{noParentText || t('global.notSelect')}</option>
          {locationTree?.map((location) => (
            <option
              key={location.location_id}
              value={location.location_id}
              title={location.location_name}
            >
              {location.lvl > 0 ? '  '.repeat(location.lvl - 1) + ' └─ ' : ''}
              {location.location_name}
            </option>
          ))}
        </Form.Select>
      </FloatingLabel>
    );
  }
);
export default LocationTreeSelect;
