import React from 'react';
import { useStores } from 'services/useStore';
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import { TPerson } from 'stores/PersonsStore';
import { ArrowRight, Link45deg } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { TGraphLink } from './LinksGraph';

const PersonGraphLink: React.FC<TGraphLink> = (element) => {
  const { personStore } = useStores();
  const { t } = useTranslation();
  const targetPerson: TPerson = personStore.get(+element.target);
  const sourcePerson: TPerson = personStore.get(+element.source);

  return (
    <Card>
      <Card.Header as="h3">
        <Link45deg />
        {t('global.link')}
      </Card.Header>
      <ListGroup className="list-group-flush ">
        <ListGroupItem>
          <div style={{ fontWeight: 'bold' }}>
            {sourcePerson.person_name} <ArrowRight /> {targetPerson.person_name}
          </div>
          <div>{element.description}</div>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};
export default PersonGraphLink;
