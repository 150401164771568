/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { GeoAlt } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { TLocation } from 'stores/LocationsStore';
import { useStores } from '../../services/useStore';
import Ava from './Ava';

export interface ILocationShow {
  locationId: number | undefined;
  hideEmpty?: boolean;
  hideTitle?: boolean;
  style?: React.CSSProperties;
  linkInDialog?: boolean;
  locationName?: string;
  showDescription?: boolean;
  imageShow?: boolean;
}

const LocationShow: React.FC<ILocationShow> = observer(
  ({
    locationId,
    hideEmpty = false,
    hideTitle = false,
    style,
    linkInDialog,
    locationName,
    showDescription,
    imageShow = false,
  }) => {
    const { t } = useTranslation();
    const { locationStore, api } = useStores();
    const clickHandler = () => {
      if (linkInDialog && locationId) api.showDialog('location', locationId);
    };
    const [location, setLocation] = useState<TLocation>();
    useEffect(() => {
      if (!locationId) return;
      const data = locationStore.get(locationId);
      setLocation(data);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId]);
    if (!location && hideEmpty) return <></>;
    return (
      <div style={style}>
        <b>
          {imageShow ? (
            <Ava size={50} img_id={location?.img_id} img_mime={location?.img_mime} />
          ) : (
            <GeoAlt />
          )}
          {'  '}
          {showDescription ? t('location.location') + ': ' : ''}
        </b>
        <b role='link' onClick={clickHandler}>
          {location ? location.location_name : locationName || t('global.notSelect')}
        </b>
        {location && !hideTitle ? <p>{api.cut(location.location_title, 15)}</p> : ''}
      </div>
    );
  }
);
export default LocationShow;
