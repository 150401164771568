/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState, KeyboardEvent } from 'react';
import { Col, FloatingLabel, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';
import { TLocation } from 'stores/LocationsStore';
import { TPerson } from 'stores/PersonsStore';
import { TStuff } from 'stores/StuffsStore';
import ContentEditable from 'react-contenteditable';
//import ReactDOM from 'react-dom/client';
import ReactDOM from 'react-dom';
import LinkStore from 'stores/LinkStore';
import styles from './style.module.scss';

interface ILinkelement {
  id: number;
  type: string;
}
export interface ITextEditor {
  style?: object;
  text: string;
  placeholder: string;
  type: string;
  fromId: number | undefined;
  linkStoreName: string;
  onChange: (value: string) => void;
  linksType?: string[];
  height?: number;
  createLinks?: boolean;
}

interface IList {
  id: number;
  name: string;
  description: string;
}

const leters = {
  person: {
    symbol: '@',
    name: 'person.mainTitle',
    init: 'getPersons',
    pattern: (data: IList) => (
      <span
        className='personElement'
        title={data.description}
        data-id={data.id}
        data-type='person'
        data-element='link'
      >
        <span>{data.name}</span>
      </span>
    ),
  },
  location: {
    symbol: '~',
    name: 'location.location',
    init: 'getLocations',
    pattern: (data: IList) => (
      <span
        className='locationElement'
        title={data.description}
        data-id={data.id}
        data-type='location'
        data-element='link'
      >
        <span>{data.name}</span>
      </span>
    ),
  },
  stuff: {
    symbol: '#',
    name: 'stuff.stuff',
    init: 'getStuffs',
    pattern: (data: IList) => (
      <span
        className='stuffElement'
        title={data.description}
        data-id={data.id}
        data-type='stuff'
        data-element='link'
      >
        <span>{data.name}</span>
      </span>
    ),
  },
};

let nameListTMP: IList[] = [];
let selectedNumTMP: number = 0;

const TextEditor: React.FC<ITextEditor> = ({
  style,
  text,
  placeholder,
  onChange,
  linkStoreName,
  fromId,
  type,
  linksType = [],
  height = 400,
  createLinks = true,
}) => {
  const { t } = useTranslation();
  const store = useStores();
  const linkStore: LinkStore = store[linkStoreName as keyof typeof store] as LinkStore;
  const { personStore, locationStore, stuffStore, api } = store;
  const [value, setValue] = useState('');
  const [firstRun, setFirstRun] = useState(true);
  const [mode, setMode] = useState('');
  const [nameList, setNameList] = useState<IList[]>([]);
  const [nameListHTML, setNameListHTML] = useState<JSX.Element[]>([]);
  const [selectedNum, setSelectedNum] = useState(0);
  const [modalValue, setModalValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const QuillRef = useRef<HTMLDivElement>(null);
  const InputRef = useRef(null);
  const startLinks = useRef<ILinkelement[] | boolean>(false);

  //let startLinks: ILinkelement[] | boolean = false;
  let textData: string = text;
  let keyFunction = (e: KeyboardEvent) => {};

  const linksHandler = (list: ILinkelement[]): void => {
    /// console.log(list);
    if (!fromId || !createLinks) return;
    const personIds: number[] = [];
    const stuffIds: number[] = [];
    const locationIds: number[] = [];
    list.forEach((item) => {
      if (item.type === 'person' && linksType.includes('person')) personIds.push(item.id);
      else if (item.type === 'stuff' && linksType.includes('stuff')) stuffIds.push(item.id);
      else if (item.type === 'location' && linksType.includes('location'))
        locationIds.push(item.id);
    });

    if (linksType.includes('person')) {
      linkStore.get({ fromId, type: 'person' }).forEach((item) => {
        const index = personIds.indexOf(item.toId);
        if (index > -1) personIds.splice(index, 1);
        else api.removeLink({ fromId, toId: item.toId, type, subType: 'person' }, linkStore);
      });
      personIds.forEach((id) => {
        if (linkStore.check({ fromId, toId: id, type })) return;
        if (type === 'person') {
          api.setPersonLink({
            fromId,
            toId: id,
            description: '',
            status: 'new',
            nameFrom: store.personStore.get(fromId)?.person_name,
            nameTo: store.personStore.get(id)?.person_name,
          });
        } else {
          api.setLink(
            {
              fromId,
              toId: id,
              type,
              subType: 'person',
              description: '',
              name: store.personStore.get(id).person_name,
            },
            linkStore
          );
        }
      });
    }
    if (linksType.includes('stuff')) {
      linkStore.get({ fromId, type: 'stuff' }).forEach((item) => {
        const index = stuffIds.indexOf(item.toId);
        if (index > -1) stuffIds.splice(index, 1);
        else api.removeLink({ fromId, toId: item.toId, type, subType: 'stuff' }, linkStore);
      });
      stuffIds.forEach((id) => {
        api.setLink(
          {
            fromId,
            toId: id,
            type,
            subType: 'stuff',
            description: '',
            name: store.stuffStore.get(id)?.stuff_name,
          },
          linkStore
        );
      });
    }
    if (linksType.includes('location')) {
      linkStore.get({ fromId, type: 'location' }).forEach((item) => {
        const index = locationIds.indexOf(item.toId);
        if (index > -1) locationIds.splice(index, 1);
        else api.removeLink({ fromId, toId: item.toId, type, subType: 'location' }, linkStore);
      });
      locationIds.forEach((id) => {
        api.setLink(
          {
            fromId,
            toId: id,
            type,
            subType: 'location',
            description: '',
            name: store.locationStore.get(id).location_name,
          },
          linkStore
        );
      });
    }
    setTimeout(
      () =>
        api.getLinks(type, fromId, true).then((linkInfo) => {
          if (linkInfo) linkStore.add(linkInfo, fromId);
        }),
      100
    );
  };

  const recalculateLinks = () => {
    if (!QuillRef.current) return;
    const list: ILinkelement[] = [];
    QuillRef.current.querySelectorAll('[data-element=link]').forEach((element) => {
      const { attributes } = element;
      list.push({
        id: +(attributes['data-id' as keyof typeof attributes] as any).value,
        type: (attributes['data-type' as keyof typeof attributes] as any).value,
      });
    });
    if (startLinks.current === false) {
      startLinks.current = list;
      return;
    }
    if (JSON.stringify(list) === JSON.stringify(startLinks.current)) return;
    startLinks.current = list;
    linksHandler(list);
  };

  const replaceHandler = (val: JSX.Element) => {
    const tmp = document.createElement('DIV');
    //ReactDOM.createRoot(tmp as HTMLElement).render(val);
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(val, tmp);
    console.log(tmp, value, mode);
    textData = value.replace(leters[mode as keyof typeof leters].symbol, tmp.innerHTML + '&nbsp;');
    //console.log(textData, val);
    setValue(textData);
    onChange(textData);
    setMode('');
    (InputRef.current as any).removeEventListener('keydown', keyFunction);
    setShowModal(false);
    setTimeout(() => {
      if (QuillRef.current) {
        const range = document.createRange();
        const sel = window.getSelection();
        try {
          range.setStart(QuillRef.current.childNodes[QuillRef.current.childNodes.length - 2], 1);
          range.collapse(true);
        } catch (e) {
          console.log(e);
        }

        sel?.removeAllRanges();
        sel?.addRange(range);
      }
    }, 0);
  };
  keyFunction = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (nameListTMP.length) {
        replaceHandler(leters[mode as keyof typeof leters].pattern(nameListTMP[selectedNumTMP]));
      }
    } else if (e.key === 'Esc') {
      replaceHandler(<></>);
    } else if (e.key === 'ArrowDown') {
      if (selectedNumTMP < nameListTMP.length - 1) {
        selectedNumTMP += 1;
        setSelectedNum(selectedNumTMP);
      }
    } else if (e.key === 'ArrowUp') {
      if (selectedNumTMP > 0) {
        selectedNumTMP -= 1;
        setSelectedNum(selectedNumTMP);
      }
    }
    /// console.log(e.key);
  };
  const clickHendler = (index: number) => {
    replaceHandler(leters[mode as keyof typeof leters].pattern(nameListTMP[index]));
  };
  const setValueData = (data: string) => {
    console.log(data);
    setValue(data);
    onChange(data);
  };

  const setNewValue = (newName: string) => {
    setModalValue(newName);
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLDivElement>) => {
    /// Тут костыли, потому что сама библиотека ContentEditable перехватывает управление позицией курсора
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const { parentElement } = range.endContainer;
      if (parentElement && parentElement.tagName === 'SPAN') {
        const grandParentElement = parentElement.parentElement;
        if (grandParentElement && grandParentElement.className.indexOf('Element') > 0) {
          const endSpan = grandParentElement.nextSibling;
          if (endSpan) {
            if (endSpan.nodeType === Node.TEXT_NODE) {
              if (endSpan.textContent === '') endSpan.textContent = '.';
            }
          }
          if (QuillRef.current && (!endSpan || endSpan.nodeName === 'SPAN')) {
            const textNode = document.createTextNode(endSpan ? '  ' : '.');
            QuillRef.current.insertBefore(textNode, endSpan);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (!linksType.length) return;
    let newMode = '';
    Object.entries(leters).forEach(([key, item]) => {
      if (value.indexOf(item.symbol) > -1 && linksType.includes(key)) newMode = key;
    });
    if (newMode) setMode(newMode);
    if (firstRun) setFirstRun(false);
    else recalculateLinks();
  }, [value]);

  useEffect(() => {
    if (!mode) return;
    setModalValue('');
    const functionName: keyof typeof api = leters[mode as keyof typeof leters]
      .init as keyof typeof api;

    (api[functionName] as () => Promise<void>)().then(() => {
      setShowModal(true);
      setTimeout(() => {
        const inp: any = InputRef.current;
        inp.addEventListener('keydown', keyFunction);
        inp.focus();
      }, 0);
    });
  }, [mode]);

  useEffect(() => {
    setValue(text);
  }, [text]);

  useEffect(() => {
    const list: IList[] = [];
    setSelectedNum(0);
    selectedNumTMP = 0;
    if (modalValue.length > 1 || modalValue === '?') {
      switch (mode) {
        case 'person':
          personStore.data.forEach((item: TPerson) => {
            /// console.log(item.person_name, item.person_title);
            if (
              modalValue === '?' ||
              item.person_name.toLowerCase().indexOf(modalValue.toLowerCase()) > -1 ||
              item.person_title.toLowerCase().indexOf(modalValue.toLowerCase()) > -1
            ) {
              list.push({
                name: item.person_name,
                description: item.person_title,
                id: item.person_id || 0,
              });
            }
          });
          break;
        case 'location':
          locationStore.data.forEach((item: TLocation) => {
            /// console.log(item.location_name, item.location_title);
            if (
              modalValue === '?' ||
              item.location_name.toLowerCase().indexOf(modalValue.toLowerCase()) > -1 ||
              item.location_title.toLowerCase().indexOf(modalValue.toLowerCase()) > -1
            ) {
              list.push({
                name: item.location_name,
                description: item.location_title,
                id: item.location_id || 0,
              });
            }
          });
          break;
        case 'stuff':
          stuffStore.data.forEach((item: TStuff) => {
            /// console.log(item.stuff_name, item.stuff_title);
            if (
              modalValue === '?' ||
              item.stuff_name.toLowerCase().indexOf(modalValue.toLowerCase()) > -1 ||
              item.stuff_title.toLowerCase().indexOf(modalValue.toLowerCase()) > -1
            ) {
              list.push({
                name: item.stuff_name,
                description: item.stuff_title,
                id: item.stuff_id || 0,
              });
            }
          });
          break;
        default:
          break;
      }
    }
    setNameList(list);
    nameListTMP = list;
    /// console.log(nameListTMP);
  }, [modalValue]);

  useEffect(() => {
    if (!nameList.length) {
      setNameListHTML([]);
      return;
    }
    /// console.log('>>', selectedNum);
    setNameListHTML(
      nameList.map((item: IList, index: number) => {
        return (
          <ListGroup.Item
            action
            key={item.id}
            as='li'
            className='d-flex justify-content-between align-items-start'
            onClick={() => clickHendler(index)}
            active={index === selectedNum}
          >
            <div className='ms-2 me-auto'>
              <div className='fw-bold'>{item.name}</div>
              {item.description}
            </div>
          </ListGroup.Item>
        );
      })
    );
  }, [nameList, selectedNum]);

  return (
    <>
      {linksType.length ? (
        <div style={{ opacity: 0.5, fontSize: 14 }}>
          {t('textEditor.description.main')}
          {linksType.includes('person') ? t('textEditor.description.person') : ''}
          {linksType.includes('stuff') ? t('textEditor.description.stuff') : ''}
          {linksType.includes('location') ? t('textEditor.description.location') : ''}.
        </div>
      ) : (
        ''
      )}
      <div className={'mb-3 form-floating form-control ' + styles.textEditor} style={{ height }}>
        <label>{placeholder}</label>
        <br />
        <div className={styles.container}>
          <ContentEditable
            style={{
              height: height - 50,
              overflow: 'auto',
              outline: 'none',
              paddingTop: 4,
              ...style,
            }}
            innerRef={QuillRef}
            html={value}
            onBlur={recalculateLinks}
            onChange={(e) => setValueData(e.target.value)}
            onKeyDown={handleKeyUp}
          />
          <Modal show={showModal} onHide={() => replaceHandler(<></>)}>
            <Modal.Header closeButton>
              <Modal.Title>{mode ? t(leters[mode as keyof typeof leters].name) : ''}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className='justify-content-md-center'>
                <Col xs lg={10}>
                  <div>
                    {t('textEditor.title')}
                    <br />
                    &nbsp;
                  </div>
                  <FloatingLabel controlId='name' label='label' className='mb-3'>
                    <Form.Control
                      type='text'
                      ref={InputRef}
                      size='lg'
                      value={modalValue}
                      onChange={(e) => setNewValue(e.target.value)}
                      placeholder='label'
                      autoComplete='off'
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              {nameList.length ? (
                <Row className='justify-content-md-center'>
                  <Col xs lg={10}>
                    <ListGroup>{nameListHTML}</ListGroup>
                  </Col>
                </Row>
              ) : (
                ''
              )}
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default TextEditor;
