/* eslint-disable no-nested-ternary */
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'components/App/App';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { Eye } from 'react-bootstrap-icons';
import { ProjectTypes, TProject, TProjectLARP, TProjectSettings } from 'stores/ProjectsStore';
import BotCard from 'components/Heplers/BotCard';
import { useStores } from '../../services/useStore';

export interface IBotProjectList {
  my?: boolean;
}

const BotProjectList: React.FC<IBotProjectList> = observer(({ my }) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { sessionStore, projectStore, api } = useStores();
  const [inited, setInit] = useState(false);
  const [projectData, setProjectData] = useState<TProjectLARP[]>([]);
  let { data } = projectStore;
  if (!data) data = [];
  const selectProject = (projectId: number | undefined) => {
    if (projectId) history(ROUTES.projects + '/' + projectId);
  };
  useEffect(() => {
    sessionStore.tgHeader(t(my ? 'header.myProjects' : 'header.actualProjects'));
    if (!projectStore?.data?.length) {
      api.getProjects().then(() => {
        setInit(true);
      });
    } else {
      setInit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [my]);

  const filter = (project: TProject) => {
    if (project.project_type !== ProjectTypes.LARP) return false;
    if (my === true) {
      return !!project.status;
    }
    return (
      moment() < moment((project as TProjectLARP).project_end) ||
      !(project as TProjectLARP).project_start
    );
  };
  useEffect(() => {
    const newData: TProjectLARP[] = [...data].filter(filter) as TProjectLARP[];
    setProjectData(newData);
    if (data.length < 5) api.useParalax();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, my]);

  const dateInfo = (project: TProjectLARP) => {
    if (project.project_start)
      return `${moment(project.project_start).format('DD.MM.YY')} -${' '}
    ${moment(project.project_end).format('DD.MM.YY')}${' '}`;
    return '';
  };
  const timelineInfo = (project: TProjectLARP) => {
    if (!project.project_start) return '';
    if (moment() > moment(project.project_start)) {
      if (moment() > moment(project.project_end)) return t('projects.ended');
      return t('projects.inProgress');
    }
    return t('projects.dayBefore', { end: api.dateDiff(project.project_start) });
  };
  const projectInfo = (project: TProjectLARP) => {
    return project.project_status ? t('projectForm.statuses.' + project.project_status) : '';
    /*if (project.project_open === 1 || !project.project_start) return t('projects.anounce');
    if (project.project_open === 2 && moment() < moment(project.project_start))
      return t('projects.waitForRequests');
    return t('projects.closeRequests');*/
  };
  const ended = (project: TProjectLARP) => {
    if (project.project_end && moment() > moment(project.project_end)) return true;
    return false;
  };
  if (!inited) {
    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ margin: '50% auto', display: 'flex' }}>
          <Spinner animation='border' role='status' />
          <span className='spiner-text'>{t('loading')}</span>
        </div>
      </div>
    );
  }
  return (
    <Container fluid className='verticalPadding greenForm' style={{ marginTop: 24 }}>
      <Row xs={1} md={3} className='g-4'>
        <Col>
          {projectData.length ? (
            projectData.map((project) => (
              <BotCard
                key={project.project_name}
                id={project.project_id || 0}
                noParalax={projectData.length < 5}
                className={
                  'bot-list' +
                  ((project.project_settings as TProjectSettings).inviteOnly ? ' elite' : '') +
                  (ended(project) ? ' ended' : '')
                }
                img={project.img_id ? `${project.img_id}.${project.img_mime}` : ''}
                onClick={selectProject}
              >
                <div className='name'>{project.project_name}</div>
                <div className='info text-muted'>{projectInfo(project)}</div>
                <div className='subtitle'>
                  {project.followers ? (
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <Eye style={{ marginTop: -2 }} /> {project.followers}
                    </div>
                  ) : (
                    ''
                  )}
                  <div style={{ textAlign: 'right', paddingRight: 15 }}>
                    {dateInfo(project)}
                    <span className='text-muted'>{timelineInfo(project)}</span>
                  </div>
                </div>
              </BotCard>
            ))
          ) : (
            <Alert variant='warning' className='mt-3'>
              <p>{t('projects.noProjects')}</p>
            </Alert>
          )}
        </Col>
      </Row>
    </Container>
  );
});
export default BotProjectList;
