/* eslint-disable react/no-danger */
import { ROUTES } from 'components/App/App';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Robot } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useStores } from 'services/useStore';
import larpImage from '../../assets/img/larp.png';
import loreImage from '../../assets/img/lore.png';

const ProjectNew: React.FC = observer(() => {
  const { api, sessionStore } = useStores();
  const history = useNavigate();
  const { t } = useTranslation();
  const [inited, setInit] = useState(false);
  const [allowLORE, setAllowLORE] = useState(false);
  const useAI = api.canTextAi(0, true);
  const createLARP = () => {
    history(ROUTES.projectEditLARP);
  };
  const createAILARP = () => {
    history(ROUTES.projectAILARP);
  };
  const createLORE = () => {
    history(ROUTES.projectEditLORE);
  };
  const hide = () => {
    history(ROUTES.projects);
  };

  useEffect(() => {
    if (!inited) {
      api.getProjects().then(() => {
        setInit(true);
        setAllowLORE(sessionStore.session.user_settings?.allowLORE || false);
      });
    } else {
      setInit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!inited) return <></>;
  return (
    <Modal
      show
      size='lg'
      key='editModal'
      className='projectSelectTypeWindow'
      centered
      onHide={hide}
    >
      <Modal.Body className='chouse-type' style={allowLORE ? {} : { maxWidth: 400 }}>
        <Row>
          <Col xs lg={allowLORE ? 6 : 12}>
            <div className='method-page'>
              <img
                src={larpImage}
                alt={t('projectLARPAI.wizard.simple')}
                style={{ width: 300, alignSelf: 'center', borderRadius: 5 }}
              />
              <h5 style={{ textAlign: 'center' }} className='mt-3'>
                LARP
              </h5>
              <p>{t('projects.newProjectLARP')}</p>
              <Row>
                <Col>
                  <Button variant='primary' onClick={createLARP}>
                    {t('projects.newProject')}
                  </Button>
                </Col>
                {useAI ? (
                  <Col>
                    <Button variant='warning' onClick={createAILARP} style={{ marginLeft: 10 }}>
                      <Robot /> {t('projects.newProject')}
                    </Button>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </div>
          </Col>
          {allowLORE ? (
            <Col xs lg={6}>
              <div className='method-page'>
                <img
                  src={loreImage}
                  alt={t('projectLARPAI.wizard.simple')}
                  style={{ width: 300, alignSelf: 'center', borderRadius: 5 }}
                />
                <h5 style={{ textAlign: 'center' }} className='mt-3'>
                  LORE
                </h5>
                <p>{t('projects.newProjectLORE')}</p>
                <Button variant='primary' onClick={createLORE}>
                  {t('projects.newProject')}
                </Button>
              </div>
            </Col>
          ) : (
            ''
          )}
        </Row>
      </Modal.Body>
    </Modal>
  );
});
export default ProjectNew;
