/* eslint-disable react/no-danger */
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useStores } from 'services/useStore';

export interface IWayForPayButton {
  id: string;
  width?: number;
  totalPrice: number;
  startPrice?: number;
  namesString: string;
  isRegular?: boolean;
  returnUrl: string;
}

const WayForPayButton: React.FC<IWayForPayButton> = observer(
  ({ width, id, totalPrice, startPrice = 1, namesString, isRegular = true, returnUrl }) => {
    // eslint-disable-next-line no-param-reassign
    const { api, sessionStore } = useStores();
    const [html, setHTML] = useState('');
    useEffect(() => {
      if (!id || !totalPrice || !namesString) return;
      const date = Date.now() + '';
      const fullId = `${id}-${moment().format('YYYYMMDDHHmmss')}`;
      const { host } = window.location;
      api
        .liqSubscribePayButton({
          id: fullId,
          totalPrice: startPrice,
          namesString,
          pricesString: startPrice,
          date,
        })
        .then((content) => {
          const htmlContent = `<form id="wayforpayForm" action="https://secure.wayforpay.com/pay" method="POST">
      <input type="hidden" name="merchantAccount" value="${content[0]}" />
      <input type="hidden" name="merchantDomainName" value="larp.tools" />
      <input type="hidden" name="merchantSignature" value="${content[1]}" />
      <input type="hidden" name="orderReference" value="${fullId}" />
      <input type="hidden" name="orderDate" value="${date}" />
      <input type="hidden" name="amount" value="${startPrice}" />
      <input type="hidden" name="currency" value="UAH" />
      <input type="hidden" name="orderTimeout" value="1" />
      <input type="hidden" name="clientAccountId" value="${sessionStore.session.user_id}" />
      <input type="hidden" name="clientEmail" value="${sessionStore.session.user_email}" />
      <input type="hidden" name="paymentSystems" value="card;googlePay;applePay;privat24;masterPass" />
      <input type="hidden" name="defaultPaymentSystem" value="googlePay" />
      ${
        isRegular
          ? `
        <input type="hidden" name="regularBehavior" value="preset" />
        <input type="hidden" name="regularMode" value="monthly" />
        <input type="hidden" name="regularAmount" value="${totalPrice}" />
        <input type="hidden" name="dateNext" value="${moment().add(1, 'M').format('DD.MM.YYYY')}" />
        <input type="hidden" name="dateEnd" value="${moment()
          .add(120, 'M')
          .format('DD.MM.YYYY')}" />
        <input type="hidden" name="regularOn" value="1" />
      `
          : ''
      }
      <input type="hidden" name="returnUrl" value="${api.settings.url.server}${
        api.settings.url.wayForPay
      }redirect?url=${returnUrl}" />
      <input type="hidden" name="serviceUrl" value="${api.settings.url.server}${
        api.settings.url.wayForPay
      }service" />
      
      <input type="hidden" name="productName" value="${namesString}" />
      <input type="hidden" name="productPrice" value="${startPrice}" />
      <input type="hidden" name="productCount" value="1" />
      <input type="submit" class="wayForPayButton" value=""/></form>`;
          setHTML(htmlContent);
        });
    }, [id, totalPrice, namesString]);
    return (
      <div
        className='wayForPayButton-container'
        style={{ width }}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    );
  }
);

export default WayForPayButton;
