/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from 'mobx-react';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export interface ICreateNewsCheckBox {
  draft: number;
  createNews: number;
  callback: (createNews: number) => void;
  title?: string;
}

const CreateNewsCheckBox: React.FC<ICreateNewsCheckBox> = observer(
  ({ draft, createNews, callback, title }) => {
    const { t } = useTranslation();
    return (
      <Form.Check
        type='switch'
        key='createNews'
        name='createNews'
        label={title || t('news.createNews')}
        disabled={!!draft}
        checked={createNews === 1}
        onChange={(e: any) => callback(e.target.checked ? 1 : 0)}
      />
    );
  }
);
export default CreateNewsCheckBox;
