/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable object-curly-spacing */
import BaseStore from './BaseStore';

export type TLocation = {
  location_id?: number;
  parent_id?: number;
  project_id?: number;
  location_name: string;
  location_title: string;
  location_description?: string;
  location_settings?: string;
  location_images?: string[];
  location_draft?: number;
  location_virtual?: number;
  img_id?: number;
  img_mime?: string;
  createNews?: number;
};

export type TLocationTree = {
  location_id: number;
  parent_id: number;
  location_name: string;
  child?: TLocationTree[];
  lvl: number;
};

class LocationsStore extends BaseStore {
  idKey = 'location_id';
  locationTree: TLocationTree[] = [];
  get data(): TLocation[] {
    return this._data as TLocation[];
  }
  getForProject = (project_id: number) =>
    this._data.filter(
      (location: TLocation) => location.project_id === project_id && !location.location_draft
    );
  setLocationTree = (locationTreeData: TLocationTree[]) => {
    this.locationTree = locationTreeData;
  };
  getLocationTree = (location_id: number, stopId: number = 0): TLocationTree[] => {
    const getChild = (parent_id: number, lvl: number): TLocationTree[] => {
      return this.locationTree
        .filter(
          (item: TLocationTree) => item.parent_id === parent_id && item.location_id !== stopId
        )
        .map((item: TLocationTree) => {
          return { ...item, child: lvl < 20 ? getChild(item.location_id, lvl + 1) : [] };
        });
    };
    return getChild(location_id, 0);
  };
  getLocationFlat = (locationId: number, stopId: number = 0): TLocationTree[] => {
    const tree: TLocationTree[] = this.getLocationTree(locationId, stopId);
    const result: TLocationTree[] = [];
    const getChild = (treeList: TLocationTree[], lvl: number) => {
      treeList.forEach((item: TLocationTree) => {
        const { location_id, parent_id, location_name, child } = item;
        result.push({ location_id, parent_id, location_name, lvl });
        if (child) getChild(child, lvl + 1);
      });
    };
    getChild(tree, 0);
    return result;
  };
}
export default LocationsStore;
