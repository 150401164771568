import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Button, Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FiletypePdf } from 'react-bootstrap-icons';
import { useStores } from '../../services/useStore';

const ReportList: React.FC = observer(() => {
  const { t } = useTranslation();
  const { api } = useStores();
  const [personListSort, setPersonList] = useState('location_name');
  const [personStuffListSort, setPersonStuffList] = useState('person_name');
  const [locationStuffListSort, setLocationStuffList] = useState('location_name');
  const [stuffListSort, setStuffList] = useState('stuff_name');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getReport = async (type: string, data: any) => {
    const file = await api.getReport(type, data);
    console.log(file);
    if (file) window.open(api.getPDFURL(file));
  };
  api.noLogin();
  return (
    <Container fluid className='verticalPadding greenForm' style={{ paddingTop: 24 }}>
      <Row className='justify-content-md-center'>
        <Col xs lg={9} style={{ background: 'white', padding: 20, borderRadius: 10 }}>
          <Row>
            <Col>
              <h1>{t('reports.mainTitle')}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <ListGroup>
                <ListGroup.Item>
                  <Row>
                    <Col style={{ alignSelf: 'center' }} xs lg={6}>
                      <h4>{t('reports.personList')}</h4>
                    </Col>
                    <Col className='text-end' style={{ alignSelf: 'center' }}>
                      {t('reports.sort')}
                    </Col>
                    <Col style={{ alignSelf: 'center' }}>
                      <Form.Select
                        aria-label={t('reports.sort')}
                        required
                        size='sm'
                        value={personListSort}
                        onChange={(e) => setPersonList(e.currentTarget.value)}
                      >
                        <option key='locationName' value='location_name'>
                          {t('reports.locationName')}
                        </option>
                        <option key='personName' value='person_name'>
                          {t('reports.personName')}
                        </option>
                        <option key='userName' value='user_name'>
                          {t('reports.userName')}
                        </option>
                        <option key='id' value='person_id'>
                          {t('reports.id')}
                        </option>
                      </Form.Select>
                    </Col>
                    <Col className='text-end' style={{ alignSelf: 'center' }}>
                      <Button
                        size='sm'
                        onClick={() => getReport('persons_list', { sort: personListSort })}
                      >
                        <FiletypePdf fontSize={25} /> {t('global.download')}
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col style={{ alignSelf: 'center' }} xs lg={6}>
                      <h4>{t('reports.personStuffList')}</h4>
                    </Col>
                    <Col className='text-end' style={{ alignSelf: 'center' }}>
                      {t('reports.sort')}
                    </Col>
                    <Col style={{ alignSelf: 'center' }}>
                      <Form.Select
                        aria-label={t('reports.sort')}
                        required
                        size='sm'
                        value={personStuffListSort}
                        onChange={(e) => setPersonStuffList(e.currentTarget.value)}
                      >
                        <option key='personName' value='person_name'>
                          {t('reports.personName')}
                        </option>
                        <option key='userName' value='user_name'>
                          {t('reports.userName')}
                        </option>
                        <option key='id' value='person_id'>
                          {t('reports.id')}
                        </option>
                      </Form.Select>
                    </Col>
                    <Col className='text-end' style={{ alignSelf: 'center' }}>
                      <Button
                        size='sm'
                        onClick={() => getReport('persons_stuff', { sort: personStuffListSort })}
                      >
                        <FiletypePdf fontSize={25} /> {t('global.download')}
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col style={{ alignSelf: 'center' }} xs lg={6}>
                      <h4>{t('reports.locationStuffList')}</h4>
                    </Col>
                    <Col className='text-end' style={{ alignSelf: 'center' }}>
                      {t('reports.sort')}
                    </Col>
                    <Col style={{ alignSelf: 'center' }}>
                      <Form.Select
                        aria-label={t('reports.sort')}
                        required
                        size='sm'
                        value={locationStuffListSort}
                        onChange={(e) => setLocationStuffList(e.currentTarget.value)}
                      >
                        <option key='locationName' value='location_name'>
                          {t('reports.locationName')}
                        </option>
                        <option key='id' value='location_id'>
                          {t('reports.id')}
                        </option>
                      </Form.Select>
                    </Col>
                    <Col className='text-end' style={{ alignSelf: 'center' }}>
                      <Button
                        size='sm'
                        onClick={() =>
                          getReport('locations_stuff', { sort: locationStuffListSort })
                        }
                      >
                        <FiletypePdf fontSize={25} /> {t('global.download')}
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col style={{ alignSelf: 'center' }} xs lg={6}>
                      <h4>{t('reports.stuffList')}</h4>
                    </Col>
                    <Col className='text-end' style={{ alignSelf: 'center' }}>
                      {t('reports.sort')}
                    </Col>
                    <Col style={{ alignSelf: 'center' }}>
                      <Form.Select
                        aria-label={t('reports.sort')}
                        required
                        size='sm'
                        value={stuffListSort}
                        onChange={(e) => setStuffList(e.currentTarget.value)}
                      >
                        <option key='stuffName' value='stuff_name'>
                          {t('reports.stuffName')}
                        </option>
                        <option key='id' value='stuff_id'>
                          {t('reports.id')}
                        </option>
                      </Form.Select>
                    </Col>
                    <Col className='text-end' style={{ alignSelf: 'center' }}>
                      <Button
                        size='sm'
                        onClick={() => getReport('stuffs_list', { sort: stuffListSort })}
                      >
                        <FiletypePdf fontSize={25} /> {t('global.download')}
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
});
export default ReportList;
