import React, { useState } from 'react';
import { useStores } from 'services/useStore';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { ProjectPayTypes } from 'stores/ProjectsStore';
import { useTranslation } from 'react-i18next';
import { ExclamationTriangle } from 'react-bootstrap-icons';
import { useLocation } from 'react-router';
import { ROUTES } from 'components/App/App';
import SubscribeDialog from 'components/Dialogs/SubscribeDialog';

const TopAlert: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [showSubscribeDialog, setShowSubscribeDialog] = useState(false);
  const [, url, id] = pathname.split('/');
  const { api, projectStore } = useStores();
  if (
    ('/' + url === ROUTES.projects && !id) ||
    '/' + url === ROUTES.projectEditLARP ||
    '/' + url === ROUTES.home
  )
    return <></>;
  const projectId = api.getProjectId();
  if (!api.isManager(projectId)) return <></>;
  const { project_pay_status } = projectStore.get(projectId);
  if (project_pay_status === ProjectPayTypes.FREE || project_pay_status === ProjectPayTypes.PAYED)
    return <></>;
  let text = 'stopAlert';
  if (project_pay_status === ProjectPayTypes.END) text = 'endAlert';
  else if (project_pay_status === ProjectPayTypes.NEW) text = 'subscribeAlert';
  return (
    <Row className='justify-content-md-center' style={{ background: '#dfcccc' }}>
      <Col xs lg='6'>
        <Alert
          variant='danger'
          className='d-flex'
          style={{
            justifyContent: 'space-around',
            alignItems: 'center',
            padding: '10px',
          }}
        >
          <ExclamationTriangle fontSize={30} />
          <div
            style={{
              margin: '0 10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <b style={{ whiteSpace: 'nowrap' }}>{t('projectForm.' + text)}</b>
            <span>{t('projectForm.alertDescription')}</span>
          </div>
          {project_pay_status !== ProjectPayTypes.END && api.isManager(projectId, true) ? (
            <Button
              variant='success'
              style={{ whiteSpace: 'nowrap' }}
              onClick={() => setShowSubscribeDialog(true)}
            >
              {t('projectForm.buttonSubscr')}
            </Button>
          ) : (
            <div style={{ width: 20 }} />
          )}
        </Alert>
      </Col>
      <SubscribeDialog
        projectId={projectId}
        show={showSubscribeDialog}
        hideCallback={() => setShowSubscribeDialog(false)}
      />
    </Row>
  );
};

export default TopAlert;
