import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'services/useStore';
import { Modal, FloatingLabel, Form, Button, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
//import { TLocation } from 'stores/LocationsStore';
import { TCreateNewCharacterSetup } from 'services/Api';
import { TCreateLocationReturn } from 'stores/GeneratedLocationsStore';
//import LocationSelect from '../Locations/LocationSelect';
import GenerateLocationPreset from '../Heplers/GenerateLocationPreset';

const maxGenerationCount = 3;

const GenerateLocationDialog: React.FC = observer(() => {
  const { t } = useTranslation();
  const { api, sessionStore, /*locationStore,*/ generatedLocationsStore } = useStores();
  const checkManager = (): boolean => api.isManager(sessionStore.session.user_project);
  const [result, setResult] = useState<TCreateLocationReturn[]>();
  const [notice, setNotice] = useState<string | undefined>('');
  //const [locationId, setLocationId] = useState<number>(0);
  const [count, setCount] = useState<number>(1);
  //const [location, setLocationData] = useState<TLocation | false>(false);
  const [showSpiner, setShowSpiner] = useState<boolean>(false);
  //const [autoGenerateImage, setAutoGenerateImage] = useState<boolean>(false);
  /*const setLocation = async (loc: number) => {
    setLocationId(loc);
    if (loc === 0) {
      setLocationData(false);
      return;
    }
    const locationData: TLocation = locationStore.get(loc);
    setLocationData(locationData);
  };*/
  const generateLocation = async (event: React.FormEvent) => {
    setShowSpiner(true);
    const setup: TCreateNewCharacterSetup = {
      project_id: sessionStore.session.user_project,
      newLocation: false,
      stage: 1,
    };
    if (count > 1) setup.count = count;
    if (notice) setup.notice = notice;
    //if (location) setup.location = location.location_name;
    console.log(setup);
    const resultData = await api.generateLocation(setup);
    console.log(resultData);
    if (generatedLocationsStore.data?.length)
      resultData.forEach((r) => generatedLocationsStore.add(r));
    else generatedLocationsStore.set(resultData);
    setShowSpiner(false);
  };
  const setLocationCount = (c: number) => {
    if (c <= maxGenerationCount && c > 0) setCount(c);
  };
  useEffect(() => {
    setResult(generatedLocationsStore.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedLocationsStore.data]);
  if (!checkManager) return <></>;
  return (
    <Modal
      show={sessionStore.appSettings.locationGenerateShow}
      size='lg'
      key='editModal'
      onHide={() => api.toggleLocationGenerateDialog(false)}
    >
      <Modal.Header closeButton>{t('generationRobot.location.header')}</Modal.Header>
      <Modal.Body style={{ backgroundColor: '#0000001c' }}>
        <Form action='#'>
          <Row className='justify-content-md-center'>
            <Col xs lg={12} style={{ position: 'relative' }}>
              <FloatingLabel
                controlId='notice'
                label={notice ? '' : t('generationRobot.location.noticeDesc')}
                className='mb-3'
              >
                <Form.Control
                  as='textarea'
                  rows={5}
                  size='lg'
                  value={notice || ''}
                  onChange={(e) => setNotice(e.target.value)}
                  placeholder={t('generationRobot.location.noticeDesc')}
                  style={{ height: '200px' }}
                />
              </FloatingLabel>
            </Col>
          </Row>
          {/*<Row className='justify-content-md-center'>
            <Col xs lg={12} style={{ position: 'relative' }}>
              <Form.Check
                type='checkbox'
                id='custom-switch'
                key='all'
                name='type'
                label={t('generationRobot.autoGenerateImage')}
                checked={autoGenerateImage}
                onChange={(e) => setAutoGenerateImage(e.target.checked)}
              />
            </Col>
  </Row>*/}
          {!showSpiner ? (
            <Row className='justify-content-md-center'>
              <Col xs lg={2}>
                <FloatingLabel
                  controlId='count'
                  label={t('generationRobot.count')}
                  className='mb-3 masterField'
                >
                  <Form.Control
                    type='number'
                    size='lg'
                    value={count}
                    onChange={(e) => setLocationCount(+e.target.value)}
                    placeholder={t('generationRobot.count')}
                  />
                </FloatingLabel>
              </Col>
              <Col xs lg={6}>
                {/*<LocationSelect locationId={locationId} action={setLocation} />*/}
              </Col>
              <Col xs lg={4}>
                <Button
                  key='generate'
                  variant='warning'
                  className='col-12'
                  size='lg'
                  type='button'
                  style={{ padding: '13px 0', fontWeight: 'bold' }}
                  onClick={generateLocation}
                >
                  {t('generation.generate')}
                </Button>
              </Col>
            </Row>
          ) : (
            <Row className='justify-content-md-center'>
              <Col>
                <Alert variant='warning'>
                  <Spinner variant='warning' />{' '}
                  <span
                    style={{ display: 'inline-block', position: 'relative', bottom: 7, left: 10 }}
                  >
                    {t('generationRobot.waitNotice')}
                  </span>
                </Alert>
              </Col>
            </Row>
          )}
          <Row className='justify-content-md-center'>
            {result ? (
              <Col key='generated' md={12}>
                {result.map((loc, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <GenerateLocationPreset
                    /*autoGenerateImage={autoGenerateImage}*/
                    key={loc.aiImgId}
                    location={loc}
                  />
                ))}
              </Col>
            ) : (
              ''
            )}
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
});

export default GenerateLocationDialog;
