import React, { useEffect, useState } from 'react';
import { useStores } from 'services/useStore';
import { Badge, Button, Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import { TPerson } from 'stores/PersonsStore';
import { Link } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import { FileEarmarkPerson, InfoCircle, Pencil } from 'react-bootstrap-icons';
import UserShow from 'components/Heplers/UserShow';
import LinkList from 'components/Heplers/LinkList';
import { useTranslation } from 'react-i18next';
import LocationShow from 'components/Heplers/LocationShow';
import PersonLinkList from 'components/Heplers/PersonLinkList';
import CarouselList from 'components/Heplers/CarouselList';
import { TGraphElement } from './LinksGraph';

const PersonGraphInfo: React.FC<TGraphElement> = (element) => {
  const { api, personStore, requestStore } = useStores();
  const [person, setPerson] = useState<TPerson>();
  const [personId, setPersonId] = useState(0);
  const { t } = useTranslation();
  //const [imgList, setImgList] = useState([] as string[]);

  useEffect(() => {
    if (!personId) return;
    api.getPersons(personId).then((data) => {
      const lo = data as TPerson;
      personStore.update(lo);
      setPerson(lo);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId]);

  const clickRequestHandler = (requestId: number) => {
    api.showDialog('request', requestId);
  };

  useEffect(() => {
    setPersonId(+element.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);
  if (!person) return <div>{t('global.loading')}</div>;
  return (
    <>
      <Card className='personalGraphInfoElement'>
        {person.person_images?.length ? (
          <CarouselList imgList={person.person_images} height={300} />
        ) : (
          ''
        )}
        <Card.Body>
          <Link to={ROUTES.persons + '/' + personId}>
            <Button variant='info' size='sm'>
              <InfoCircle />
            </Button>
          </Link>{' '}
          {api.projectIsActive() ? (
            <Link to={ROUTES.personEdit + '/' + personId}>
              <Button variant='info' size='sm'>
                <Pencil />
              </Button>
            </Link>
          ) : (
            ''
          )}
          <div style={{ float: 'right' }}>
            {person.person_virtual ? (
              <>
                <Badge bg='dark'>{t('global.virtual')}</Badge>{' '}
              </>
            ) : (
              ''
            )}
            {person.person_type ? (
              <>
                <Badge bg='warning'>{person.person_type}</Badge>{' '}
              </>
            ) : (
              ''
            )}

            {person.person_status ? <Badge bg='secondary'>{person.person_status}</Badge> : ''}
          </div>
          <Card.Title>{person.person_name}</Card.Title>
          <LocationShow
            style={{ textAlign: 'right' }}
            locationId={person.location_id}
            hideEmpty
            linkInDialog
          />
          <Card.Text>{person.person_title}</Card.Text>
        </Card.Body>
        <ListGroup className='list-group-flush '>
          {person.person_description ? (
            <ListGroupItem>{person.person_description}</ListGroupItem>
          ) : (
            ''
          )}
          {person.person_notice ? (
            <ListGroupItem
              className='notice'
              dangerouslySetInnerHTML={{ __html: person.person_notice || '' }}
            />
          ) : (
            ''
          )}
          {person.user_id ? (
            <ListGroupItem>
              <UserShow userId={person.user_id} isManager={false} hideEmpty linkInDialog allUsers />
            </ListGroupItem>
          ) : (
            ''
          )}
        </ListGroup>
      </Card>
      {personId ? (
        <>
          {element.requests?.length ? (
            <>
              <br />
              <ListGroup>
                <ListGroup.Item
                  variant='success'
                  key='head'
                  as='li'
                  className='d-flex justify-content-between align-items-start'
                >
                  <div className='me-auto'>
                    <div className='fw-bold'>
                      <h6 style={{ marginBottom: 0 }}>
                        <FileEarmarkPerson />
                        &nbsp;{t('request.mainTitle')} [{element.requests?.length}]
                      </h6>
                    </div>
                  </div>
                </ListGroup.Item>
                {element.requests?.map((item: number) => (
                  <ListGroup.Item
                    key={item}
                    as='li'
                    className='d-flex justify-content-between align-items-start'
                    onClick={() => clickRequestHandler(item)}
                  >
                    <UserShow
                      userId={requestStore.get(item)?.user_id}
                      isManager={false}
                      hideEmpty
                      linkInDialog={false}
                    />
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </>
          ) : (
            ''
          )}
          <br />
          <PersonLinkList id={personId} muteLoadFromAPI={false} autogenerate={false} linkInDialog />
          <LinkList
            type='person'
            subType='status'
            id={personId}
            storeName='personLinks'
            hideEmpty
            linkInDialog
            title={t('status.mainTitle')}
          />
          <LinkList
            type='person'
            subType='quest'
            id={personId}
            storeName='personLinks'
            title={t('quest.mainTitle')}
            hideEmpty
            linkInDialog
          />
          <LinkList
            type='person'
            subType='stuff'
            id={personId}
            storeName='personLinks'
            title={t('stuff.mainTitle')}
            hideEmpty
            linkInDialog
          />
          <LinkList
            type='person'
            subType='text'
            id={personId}
            storeName='personLinks'
            title={t('text.mainTitle')}
            muteLoadFromAPI
            hideEmpty
            linkInDialog
          />
        </>
      ) : (
        ''
      )}
    </>
  );
};
export default PersonGraphInfo;
