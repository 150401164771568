/* eslint-disable no-nested-ternary */
import { ROUTES } from 'components/App/App';
import CarouselList from 'components/Heplers/CarouselList';
import LocationShow from 'components/Heplers/LocationShow';
import PersonShow from 'components/Heplers/PersonShow';
import UserShow from 'components/Heplers/UserShow';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Alert, Badge, Button, ButtonGroup, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { TRequest } from 'stores/RequestsStore';
import { CheckLg, PersonLinesFill, Robot } from 'react-bootstrap-icons';
import { useStores } from '../../services/useStore';

export interface IRequestInfo {
  requestId: number | undefined;
  imageHeight?: number;
  linkInDialog?: boolean;
}

const RequestInfo: React.FC<IRequestInfo> = observer(({ requestId, imageHeight, linkInDialog }) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { api, sessionStore, personStore, requestStore } = useStores();
  api.noLogin();
  const [request, setRequest] = useState<TRequest>();
  const [imgList, setImgList] = useState([] as string[]);
  const [isInvite, setIsInvite] = useState(false);
  const [showSpiner, setShowSpiner] = useState(false);
  const dataSource = () => {
    return new Promise((resolve, reject) => {
      if (!requestId) {
        reject();
        return;
      }
      const data: TRequest = requestStore.get(requestId);
      if (data?.request_id) resolve(data);
      else {
        api.getRequests(requestId).then(resolve);
      }
    });
  };
  useEffect(() => {
    return () => setIsInvite(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (requestId) {
      dataSource().then(async (data) => {
        const lo = data as TRequest;
        if (lo.project_id) await api.updateProjectData(lo.project_id);
        setRequest(lo);
        if (lo.request_type === 'invite') setIsInvite(true);
        else setIsInvite(false);
        const images: string[] = [];
        if (lo.img_id) images.push(`${lo.img_id}.${lo.img_mime}`);
        if (lo.person_image) images.push(lo.person_image);
        setImgList(images);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId]);
  if (!request) return <div>{t('global.loading')}</div>;
  const isMy = request.user_id === sessionStore.session.user_id;
  const isAdmin = api.isManager(request.project_id);
  const rejectInvite = async () => {
    if (!isInvite || sessionStore.session.user_id !== request.user_id || !requestId) return;
    await api.inviteFlow(requestId, 'reject');
    api.closeDialog();
    window.location.reload(); ///костыль, иначе статус в карточке не обновляется
  };
  const acceptInvite = async () => {
    if (!isInvite || sessionStore.session.user_id !== request.user_id || !requestId) return;
    await api.inviteFlow(requestId, 'success');
    api.closeDialog();
    window.location.reload(); ///костыль, иначе статус в карточке не обновляется
  };
  const statusName = (data: TRequest) => {
    let status = t('request.requestWork');
    if (data.request_flow === 'reject') status = t('request.rejected');
    if (data.request_flow === 'autoReject') status = t('request.rejected2');
    if (data.person_user === data.user_id) status = t('request.accepted');
    if (data.person_user === data.user_id)
      status = t(data.request_type === 'invite' ? 'request.accepted2' : 'request.accepted');
    return status;
  };
  const requestConvertToPerson = (useAi: boolean) => {
    if (!requestId) return;
    setShowSpiner(true);
    api
      .requestConvertToPerson(requestId, useAi)
      .then((personId: number) => {
        setShowSpiner(false);
        api.closeDialog();
        history(ROUTES.personEdit + '/' + personId);
      })
      .catch(() => setShowSpiner(false));
  };
  const badgeStyle = (status: string): string => {
    switch (status) {
      case 'success':
        return 'success';
      case 'reject':
        return 'danger';
      case 'autoReject':
        return 'danger';
      default:
        return 'secondary';
    }
  };
  if (!isMy && !isAdmin) {
    return (
      <Alert variant='warning'>
        <p>{t('global.empty')}</p>
        <hr />
        <div className='d-flex justify-content-end'>
          <Link to={ROUTES.requests} className='btn btn-primary'>
            {t('request.mainTitle')}
          </Link>
        </div>
      </Alert>
    );
  }
  return (
    <Container fluid className='verticalPadding greenForm' style={{ paddingBottom: 24 }}>
      {isInvite ? (
        <Row className='justify-content-md-center' key='invite'>
          <Col xs lg={10}>
            <h2>{t('request.invite2')}</h2>
          </Col>
        </Row>
      ) : (
        ''
      )}
      <Row className='justify-content-md-center'>
        <Col xs lg={10}>
          <p>
            <Link to={ROUTES.projects + '/' + request.project_id}>{request.project_name}</Link>
          </p>
          {imgList.length ? (
            <div className='mb-3'>
              <CarouselList
                imgList={imgList}
                title={request.request_title}
                label={request.request_name}
                height={imageHeight}
              />
            </div>
          ) : isInvite ? (
            <p>{request.request_description}</p>
          ) : (
            <>
              <h2>{request.request_name}</h2>
              <p>
                {request.request_age
                  ? Number.isNaN(request.request_age)
                    ? request.request_age
                    : `${request.request_age} ${t('request.years')}`
                  : ''}{' '}
                {request.request_job || ''}{' '}
              </p>
              <p>{request.request_title}</p>
            </>
          )}
          {request.person_id ? (
            <PersonShow
              personId={request.person_id}
              title
              style={{ marginBottom: 14 }}
              link
              linkInDialog
            />
          ) : (
            ''
          )}
          {isAdmin ? (
            <>
              <p>{request.request_description}</p>
              <p>{request.request_masterinfo}</p>
              <Badge bg='secondary'>{request.request_status} </Badge>
            </>
          ) : (
            ''
          )}
          {request.location_id ? (
            <LocationShow locationId={request.location_id} linkInDialog />
          ) : (
            <LocationShow
              locationId={request.person_id ? personStore.get(request.person_id)?.location_id : 0}
              linkInDialog
            />
          )}
          {request.request_dontplay ? <p>{request.request_dontplay}</p> : ''}
          {request.request_wantplay ? <p>{request.request_wantplay}</p> : ''}
          {isInvite ? (
            <>
              <hr />
              <p>{request.request_description}</p>
            </>
          ) : (
            ''
          )}
          <br />
          {isAdmin ? <UserShow userId={request.user_id} hideEmpty linkInDialog /> : ''}
        </Col>
      </Row>
      <Row className='inviteButtons mt-3'>
        <Col style={{ display: 'flex', justifyContent: 'space-around' }}>
          {isInvite &&
          sessionStore.session.user_id === request.user_id &&
          request.request_flow === 'init' ? (
            <>
              <Button variant='success' style={{ margin: 5 }} onClick={acceptInvite}>
                {t('request.acceptInvite')}
              </Button>
              <Button variant='danger' style={{ margin: 5 }} onClick={rejectInvite}>
                {t('request.rejectInvite')}
              </Button>
            </>
          ) : isAdmin && requestId && request.request_flow === 'init' && api.projectIsActive() ? (
            <>
              <Button
                variant='success'
                size='sm'
                onClick={() => api.toggleRequestCheckDialog(requestId)}
              >
                <CheckLg /> {t('request.checkRequest')}
              </Button>
              {!request.person_id ? (
                showSpiner ? (
                  <Spinner variant='warning' />
                ) : (
                  <ButtonGroup aria-label='Convert to person'>
                    <Button
                      variant='primary'
                      size='sm'
                      onClick={() => requestConvertToPerson(false)}
                    >
                      <PersonLinesFill /> {t('request.convertToPerson')}
                    </Button>
                    {api.canTextAi() ? (
                      <Button
                        variant='warning'
                        size='sm'
                        onClick={() => requestConvertToPerson(true)}
                      >
                        <Robot />
                      </Button>
                    ) : (
                      ''
                    )}
                  </ButtonGroup>
                )
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
        </Col>
      </Row>
      {request.request_flow !== 'init' ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Badge bg={badgeStyle(request.request_flow || '')}>{statusName(request)}</Badge>
        </div>
      ) : (
        ''
      )}
    </Container>
  );
});
export default RequestInfo;
