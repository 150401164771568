import { useContext } from 'react';
import { RootStore } from 'stores/RootStore';
import { StoreContext } from 'stores/StoreContext';

export const useStores = (): RootStore => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.');
  }
  return store;
};
