/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/esm/Card';
import { Button, Spinner } from 'react-bootstrap';
import { Image, Pencil, Plus, Trash } from 'react-bootstrap-icons';
import { useStores } from 'services/useStore';
import { TCreateNewCharacterSetup } from 'services/Api';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import { TCreateQuestReturn } from 'stores/GeneratedQuestsStore';
import styles from './style.module.scss';

export interface IGenerateQuestPreset {
  quest: TCreateQuestReturn;
  autoGenerateImage?: boolean;
}

const GenerateQuestPreset: React.FC<IGenerateQuestPreset> = observer(
  ({ quest, autoGenerateImage = false }) => {
    const { t } = useTranslation();
    const history = useNavigate();
    const { api, sessionStore, generatedQuestsStore } = useStores();
    const [showSpiner, setShowSpiner] = useState<boolean>(false);
    const [buttonsMode, setButtonsMode] = useState(0);
    const [questData, setQuestData] = useState<TCreateQuestReturn>();
    const getUrl = (img: string, type: string = 'generation') =>
      `url('${api.settings.url.server}${type}/${img}?${Math.round(Math.random() * 10000)}')`;
    // eslint-disable-next-line no-param-reassign
    //quest.image = '83.png';
    const imgCheck = async (image: string) => {
      const result = await api.checkGenerateImage(image);
      if (result && questData) {
        questData.image = image;
        generatedQuestsStore.update(questData);
        setButtonsMode(0);
      } else setTimeout(() => imgCheck(image), 3000);
    };
    const createImage = async () => {
      setButtonsMode(1);
      const prompt = quest.prompt.replace('Draw ', '');
      if (!questData) return;
      questData.image = 'loading';
      generatedQuestsStore.update(questData);
      const result = await api.generateImage(
        prompt,
        'quest',
        quest.aiImgId || 0,
        'painting',
        'empty city',
        600,
        800
      );
      console.log(result);
      setTimeout(() => imgCheck(result.img), 3000);
    };
    const create = async (editAfterCreate: boolean) => {
      if (!questData) return;
      setShowSpiner(true);
      setButtonsMode(2);
      console.log(questData);
      const setup: TCreateNewCharacterSetup = {
        ...questData,
        project_id: sessionStore.session.user_project,
        stage: 2,
      };
      setup.aiImgId = questData.image ? questData.aiImgId : 0;
      setup.generatedText = questData.text;
      console.log(setup);
      const result = await api.generateQuest(setup);
      console.log(result);
      setShowSpiner(false);
      questData.added = true;
      questData.quest_id = result[0].quest_id;
      if (questData.image) questData.image = result[0].image;
      generatedQuestsStore.update(questData);
      await api.getQuests();
      if (editAfterCreate) {
        api.toggleQuestGenerateDialog(false);
        history(ROUTES.questEdit + '/' + result[0].quest_id);
      }
    };
    const add = async () => {
      await create(false);
      setButtonsMode(3);
    };
    const edit = async () => {
      if (quest.added && quest.quest_id) {
        api.toggleQuestGenerateDialog(false);
        history(ROUTES.questEdit + '/' + quest.quest_id);
      } else await create(true);
    };
    const hide = async () => {
      generatedQuestsStore.remove(quest.aiImgId || 0);
    };
    useEffect(() => {
      setQuestData(quest);
      if (quest.added) setButtonsMode(3);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
      if (questData && autoGenerateImage && !questData.image) {
        setTimeout(createImage, 0);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questData]);
    if (!questData) return <></>;
    return (
      <Card className='mb-3'>
        <Card.Body>
          <Card.Title>{questData.quest_name}</Card.Title>
          <>
            {questData.image ? (
              questData.image === 'loading' ? (
                <div className={styles.generationQuestPresetSpiner}>
                  <Spinner style={{ margin: 'auto', scale: 50 }} variant='warning' />
                </div>
              ) : (
                <div
                  role='link'
                  className={styles.generationQuestPresetImage}
                  onClick={() =>
                    api.showImagePopup(
                      questData.image || '',
                      questData.quest_id ? 'img' : 'generation'
                    )
                  }
                  style={{
                    backgroundImage: getUrl(
                      questData.image,
                      questData.quest_id ? 'img' : 'generation'
                    ),
                  }}
                />
              )
            ) : (
              ''
            )}
            <div dangerouslySetInnerHTML={{ __html: questData.quest_description || '' }} />
          </>
        </Card.Body>
        <Card.Footer style={{ textAlign: 'right' }}>
          {showSpiner ? (
            <Spinner style={{ position: 'relative', top: 6, right: 10 }} variant='warning' />
          ) : (
            ''
          )}
          {!buttonsMode ? (
            <Button variant='warning' style={{ marginBottom: 5 }} onClick={createImage}>
              <Image /> {t('generationRobot.genPicture')}
            </Button>
          ) : (
            ''
          )}{' '}
          {!buttonsMode || buttonsMode === 1 ? (
            <Button variant='success' style={{ marginBottom: 5 }} onClick={add}>
              <Plus /> {t('generationRobot.add')}
            </Button>
          ) : (
            ''
          )}{' '}
          {buttonsMode === 3 ? (
            <Button variant='primary' style={{ marginBottom: 5 }} onClick={edit}>
              <Pencil /> {t('generationRobot.edit')}
            </Button>
          ) : (
            ''
          )}{' '}
          <Button variant='danger' style={{ marginBottom: 5 }} onClick={hide}>
            <Trash /> {t('generationRobot.hide')}
          </Button>
        </Card.Footer>
      </Card>
    );
  }
);
export default GenerateQuestPreset;
