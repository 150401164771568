import CarouselList from 'components/Heplers/CarouselList';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TNews } from 'stores/NewsStore';
import MarkdownText from 'components/Heplers/MarkdownText';
import { Link } from 'react-router-dom';
import { useStores } from '../../services/useStore';

export interface INewsInfo {
  newsId: number | undefined;
  imageHeight?: number;
  linkInDialog?: boolean;
}

const NewsInfo: React.FC<INewsInfo> = observer(({ newsId, imageHeight, linkInDialog }) => {
  const { t } = useTranslation();
  const { api } = useStores();
  api.noLogin();
  const [news, setNews] = useState<TNews>();
  const [imgList, setImgList] = useState<string[]>();

  useEffect(() => {
    if (newsId) {
      api.getNews(newsId).then(async (data) => {
        if (api.isBot()) await api.getUsers();
        const st = data as TNews;
        setNews(st);
        if (st.project_id) await api.updateProjectData(st.project_id);
        if (st.news_images) setImgList(st.news_images);
        else setImgList([]);
        api.useParalax();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsId]);
  if (!news) return <div>{t('global.loading')}</div>;
  return (
    <Container
      fluid
      className='verticalPadding greenForm'
      style={{ paddingTop: api.isBot() ? 14 : 24 }}
    >
      {api.isBot() ? (
        <Row>
          <Col className='mb-2'>
            <h3>
              <a href={window.location.origin + '/projects/' + news.project_id}>
                {news.project_name}
              </a>
            </h3>
            {api.timeFormat(news.news_created)}
          </Col>
        </Row>
      ) : (
        ''
      )}
      <Row className='justify-content-md-center'>
        <Col xs lg={12}>
          {imgList?.length ? <CarouselList imgList={imgList} height={imageHeight} /> : ''}
          <div className='mt-3'>
            <MarkdownText text={news.news_description} />
          </div>
        </Col>
      </Row>
      {api.isBot() ? (
        <Row>
          <Col style={{ display: 'flex', marginBottom: 10, justifyContent: 'right' }}>
            <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}>
              <b>{news.user_name}</b>
            </div>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {news.news_link && news.news_link_type ? (
        <Link to={`/${news.news_link_type}/${news.news_link}`}>
          {t(`${news.news_link_type}.readAll`)}
        </Link>
      ) : (
        ''
      )}
    </Container>
  );
});
export default NewsInfo;
