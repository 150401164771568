import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { ArrowLeftRight } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { TPersonLink } from 'stores/LinkStore';
import { TPerson } from 'stores/PersonsStore';
import { useStores } from '../../services/useStore';
import InfoButton from '../Heplers/InfoButton';

export interface IPersonLinkEdit {
  id: number;
  toId?: number;
  showModal: boolean;
  closeEditModal: () => void;
  saveEditModal: (id: number, description: string, status: string, type: string) => void;
  selectUser?: boolean;
}

const PersonLinkEdit: React.FC<IPersonLinkEdit> = observer(
  ({ id, toId, showModal = false, closeEditModal, saveEditModal, selectUser = false }) => {
    const { t } = useTranslation();
    const { personLinks, api, personStore } = useStores();
    const currentPerson = personStore.get(id);
    const [to, setToId] = useState(toId);
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState('');
    const [type, setType] = useState('');
    useEffect(() => {
      api.getPersons().then((personData) => {
        personStore.update(personData);
      });
    }, []);

    useEffect(() => {
      setDescription('');
      setStatus('');
      setType('');
      setToId(toId);
    }, [id, toId]);

    useEffect(() => {
      if (!showModal) return;
      if (toId) {
        const d = personLinks.data.person.find(
          (item) =>
            (item.fromId === id && item.toId === toId) || (item.toId === id && item.fromId === toId)
        ) as TPersonLink;
        //setData(d);
        if (d?.description) setDescription(d.description);
        if (d?.status) setStatus(d.status);
        setType(d.type || '');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal, to]);

    useEffect(() => {
      setToId(toId);
    }, [toId]);

    const saveHandler = () => {
      if (to) {
        saveEditModal(+to, description, status, type);
        setToId(0);
      }
    };

    const generateOptions = () => {
      const options = [
        <option key='nonde' value={0} disabled>
          {t('global.notSelect')}
        </option>,
      ];
      // eslint-disable-next-line consistent-return
      personStore.data?.forEach((person: TPerson) => {
        if (!person.person_id || person.person_id === id) return;
        let disabled = false;
        if (personLinks.get({ type: 'person', toId: id, fromId: person.person_id }).length)
          disabled = true;
        else if (personLinks.get({ type: 'person', fromId: id, toId: person.person_id }).length)
          disabled = true;
        options.push(
          <option key={person.person_id} value={person.person_id} disabled={disabled}>
            {person.person_name}
          </option>
        );
      });
      return options;
    };

    if (!showModal) return <></>;

    return (
      <Modal show={showModal} key='editModal' onHide={closeEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t(toId ? 'global.editLinkHeader' : 'global.addLinkHeader')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Row className='justify-content-md-center'>
              {selectUser || !toId ? (
                <Col xs={6}>
                  <Form.Select
                    required
                    value={to}
                    onChange={(e) => setToId(+e.currentTarget.value)}
                  >
                    {generateOptions()}
                  </Form.Select>
                </Col>
              ) : (
                <Col xs={5} style={{ textAlign: 'right', alignSelf: 'center', fontWeight: 'bold' }}>
                  {toId ? personStore.get(toId).person_name : ''}
                </Col>
              )}
              <Col xs={6}>
                <Row className='justify-content-md-left'>
                  <Col xs={1} style={{ fontSize: 25, marginTop: 0 }}>
                    <ArrowLeftRight />
                  </Col>
                  <Col xs={1}>&nbsp;</Col>
                  <Col xs={9} style={{ alignSelf: 'center', fontWeight: 'bold', marginTop: 5 }}>
                    {currentPerson?.person_name}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className='justify-content-md-left'>
              <Col>&nbsp;</Col>
            </Row>
            <Form.Control
              type='text'
              size='lg'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder={t('global.link')}
            />
            <Row className='justify-content-md-left'>
              <Col>&nbsp;</Col>
            </Row>
            <FloatingLabel controlId='type' label={t('personForm.linkType')} className='mb-3'>
              <Form.Select
                aria-label={t('personForm.linkType')}
                required
                size='lg'
                value={type}
                onChange={(e) => setType(e.currentTarget.value)}
              >
                <option value=''>{t('personForm.linkTypes.show')}</option>
                <option value='hide'>{t('personForm.linkTypes.hide')}</option>
                <option value='clone'>{t('personForm.linkTypes.clone')}</option>
              </Form.Select>
            </FloatingLabel>
            <Row>
              <Col xs lg={10}>
                <FloatingLabel controlId='status' label={t('personForm.status')} className='mb-3'>
                  <Form.Control
                    type='text'
                    required
                    size='lg'
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    placeholder={t('personForm.status')}
                  />
                </FloatingLabel>
              </Col>
              <Col xs lg={1}>
                <InfoButton text='personLinkStatus' />
              </Col>
            </Row>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeEditModal}>
            {t('global.close')}
          </Button>
          <Button variant='primary' onClick={saveHandler}>
            {t('global.save')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);
export default PersonLinkEdit;
