/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from 'mobx-react';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export interface IDraftCheckBox {
  draft: number;
  callback: (draft: number) => void;
  title?: string;
}

const DraftCheckBox: React.FC<IDraftCheckBox> = observer(({ draft, callback, title }) => {
  const { t } = useTranslation();
  return (
    <Form.Check
      type='switch'
      key='draft'
      name='draft'
      label={title || t('global.draft')}
      checked={draft === 1}
      onChange={(e: any) => callback(e.target.checked ? 1 : 0)}
    />
  );
});
export default DraftCheckBox;
