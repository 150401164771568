import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { RootStore } from 'stores/RootStore';
import { BrowserRouter as Router } from 'react-router-dom';
import TestSupport from 'services/test.js';
import { StoreProvider } from 'stores/StoreContext';
import App from 'components/App/App';
import './locales/i18n';
import packageInfo from '../package.json';
//import reportWebVitals from './reportWebVitals';

console.log(
  '%cVersion: ' + packageInfo.version.replaceAll(' ', '.'),
  'font-size: 20px; color: green;'
);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const store = new RootStore();
root.render(
  <Router>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </Router>
);
window.TEST = new TestSupport(store);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
