import { ROUTES } from 'components/App/App';
import { observer } from 'mobx-react';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

export interface IAddButton {
  title: string;
  link: string;
}

const AddButton: React.FC<IAddButton> = observer(({ title, link }) => {
  return (
    <Link to={ROUTES[link as keyof typeof ROUTES]}>
      <Button variant='primary' size='sm'>
        <div style={{ display: 'inline-block', position: 'relative', top: -1 }}>
          <Plus fontSize={17} />
        </div>
        {'  '}
        {title}
      </Button>
    </Link>
  );
});
export default AddButton;
