import { action, makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';

export type TUser = {
  user_id: number;
  user_name: string;
  user_status: string;
  user_link?: string;
  user_fio?: string;
  user_email?: string;
  user_tel?: string;
  img_id?: number;
  img_mime?: string;
  image?: string;
  person_id?: number;
};

export type TProfile = {
  user_blacklist: string;
  user_contact: string;
  user_deny: string;
  user_email: string;
  user_fio: string;
  user_id: number;
  user_link: string;
  user_medinfo: string;
  user_name: string;
  user_project: number;
  user_session?: string;
  user_status: string;
  user_tel: string;
  user_pass?: string;
  img_id?: number;
  img_mime?: string;
  image?: string;
};

type TUserData = {
  users: TUser[];
  managers: TUser[];
  all: TUser[];
};

export interface IUsersStore {
  _data: TUserData;
  updateUser: (data: TUser) => void;
  add: (data: TUser) => void;
  setPlayers: (data: TUser[]) => void;
  setManagers: (data: TUser[]) => void;
}

class UsersStore implements IUsersStore {
  _data!: TUserData;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this._data = {
      users: [],
      managers: [],
      all: [],
    };
    makeObservable(this, {
      _data: observable,
      updateUser: action,
      setPlayers: action,
      setManagers: action,
      setAll: action,
      remove: action,
    });
  }

  setPlayers = (data: TUser[]): void => {
    this._data.users = data;
  };

  setManagers = (data: TUser[]): void => {
    this._data.managers = data;
  };

  setAll = (data: TUser[]): void => {
    this._data.all = data;
  };

  add = (data: TUser): void => {
    this._data.users.push(data);
  };

  updateUser = (data: TUser): void => {
    this._data.users = this._data.users.map((user: TUser) => {
      if (user.user_id === data.user_id) return data;
      return user;
    });
  };

  getManager = (managerId: number): TUser | undefined =>
    this._data.managers.find((manager: TUser) => manager.user_id === managerId);

  getUser = (userId: number): TUser | undefined =>
    this._data.users.find((user: TUser) => user.user_id === userId);

  getManagers = (): TUser[] => this._data.managers;
  getUsers = (): TUser[] => this._data.users;
  getAll = (): TUser[] => this._data.all;

  get(userId: number): TUser | undefined {
    return (this._data.all.length ? this._data.all : this._data.users).find(
      (user: TUser) => user.user_id === userId
    );
  }

  remove = (id: number): void => {
    let index = this._data.all.findIndex((element) => element.user_id === id);
    if (index !== -1) this._data.all = [...this._data.all.splice(index, 1)];
    index = this._data.users.findIndex((element) => element.user_id === id);
    if (index !== -1) this._data.users = [...this._data.users.splice(index, 1)];
    index = this._data.managers.findIndex((element) => element.user_id === id);
    if (index !== -1) this._data.managers = [...this._data.managers.splice(index, 1)];
  };
}

export default UsersStore;
