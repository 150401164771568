import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TPerson } from 'stores/PersonsStore';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { ROUTES } from 'components/App/App';
import { Search } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import BotCard from 'components/Heplers/BotCard';
import { useStores } from '../../services/useStore';

const BotMyPersonList: React.FC = observer(() => {
  const { t } = useTranslation();
  const { sessionStore, personStore, api } = useStores();
  api.noLogin();
  const [persons, setPerson] = useState<TPerson[]>();
  const [inited, setInit] = useState(false);
  const updatePersonList = () => {
    setPerson(personStore.data.filter((p: TPerson) => p.user_id === sessionStore.session.user_id));
  };
  useEffect(() => {
    setInit(false);
    api.getMyPersons().then((personsData) => {
      const data = Array.isArray(personsData) ? personsData : [personsData];
      data.forEach((p: TPerson) =>
        personStore.update({ ...p, user_id: sessionStore.session.user_id })
      );
      updatePersonList();
      setInit(true);
    });
  }, []);

  /*useEffect(() => {
    updatePersonList();
  }, [personStore.data]);*/
  if (!inited) {
    return <div>{t('global.loading')}</div>;
  }

  if (!persons?.length) {
    return (
      <Container className='greenForm' style={{ marginTop: 54 }}>
        <Row>
          <Col>
            <Alert variant='warning'>
              <Alert.Heading style={{ color: '#000' }}>
                <Search /> {t('person.empty1')}
              </Alert.Heading>
              <p>{t('person.empty2')}</p>
              <hr />
              <div className='d-flex justify-content-end'>
                <Link to={ROUTES.projects} className='btn btn-primary' style={{ color: '#000' }}>
                  {t('projects.mainTitle')}
                </Link>
              </div>
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  }

  const calculateImage = (data: TPerson) => {
    if (data.img_id) return `${data.img_id}.${data.img_mime}`;
    return '';
  };

  return (
    <Container fluid className='verticalPadding greenForm' style={{ marginTop: 24 }}>
      <Row>
        <Col className='mt-4'>
          {persons.length ? (
            persons.map((person) => (
              <BotCard
                key={person.person_name + '_' + person.person_id}
                id={person.person_id || 0}
                noParalax={persons.length < 5}
                className={'bot-list' + (person.person_type === 'invite' ? ' invite' : '')}
                img={calculateImage(person)}
                onClick={() =>
                  api.showDialog(
                    person.person_type === 'invite' ? 'invite' : 'person',
                    person.person_id || 0
                  )
                }
              >
                <div className='name'>{person.person_name}</div>
              </BotCard>
            ))
          ) : (
            <></>
          )}

          <div style={{ height: 10 }} />
        </Col>
      </Row>
    </Container>
  );
});
export default BotMyPersonList;
