import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { Link45deg } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { TLocationTree } from 'stores/LocationsStore';
import { useStores } from '../../services/useStore';
import LocationShow from './LocationShow';

export interface ILocationChildList {
  locationId: number;
  linkInDialog?: boolean;
}

const LocationChildList: React.FC<ILocationChildList> = observer(
  ({ locationId, linkInDialog = false }) => {
    const { t } = useTranslation();
    const { locationStore } = useStores();

    const [childList, setChildList] = useState<TLocationTree[]>();

    useEffect(() => {
      const data = locationStore.getLocationTree(locationId);
      setChildList(data);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId]);

    return (
      <>
        <ListGroup>
          <ListGroup.Item
            variant='success'
            key='head'
            as='li'
            className='d-flex justify-content-between align-items-start'
          >
            <div className='me-auto'>
              <div className='fw-bold'>
                <h6 style={{ marginBottom: 0 }}>
                  <Link45deg />
                  &nbsp;{t('location.mainTitle')} [{childList?.length}]
                </h6>
              </div>
            </div>
          </ListGroup.Item>
          {childList?.length ? (
            childList.map((item: TLocationTree) => {
              return (
                <ListGroup.Item
                  key={item.location_id}
                  as='li'
                  className='d-flex justify-content-between align-items-start'
                >
                  <Container fluid>
                    <Row>
                      <Col lg={12}>
                        <LocationShow
                          locationId={item.location_id}
                          linkInDialog={linkInDialog}
                          imageShow
                        />
                      </Col>
                    </Row>
                  </Container>
                </ListGroup.Item>
              );
            })
          ) : (
            <ListGroup.Item
              key='empty'
              as='li'
              className='d-flex justify-content-between align-items-start'
            >
              &nbsp;
            </ListGroup.Item>
          )}
        </ListGroup>
      </>
    );
  }
);
export default LocationChildList;
