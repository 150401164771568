import i18n from 'i18next';
import moment from 'moment';

import LangDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import ua from './ua.json';
import en from './en.json';

import 'moment/locale/ru';
import 'moment/locale/uk';

i18n
  .use(LangDetector)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: {
        default: ['ua'],
      },
      whitelist: ['ua', 'en'],
      debug: true,
      detection: {
        order: ['localStorage'],
        caches: ['localStorage'],
      },
      interpolation: {
        escapeValue: false,
      },
      resources: { ua, en },
    },
    () => {
      const locale = i18n.language;
      moment.locale(locale === 'ua' ? 'uk' : locale);
    }
  );

export default i18n;
