/* eslint-disable no-underscore-dangle */
import { toJS } from 'mobx';
//import i18n from 'i18next';

export default class TestSupport {
  _store = {};
  constructor(store) {
    this.store = store;
    //this.i18n = i18n;
    // eslint-disable-next-line no-constructor-return
    return this;
  }

  get store() {
    return toJS(this._store);
  }

  set store(store) {
    this._store = store;
  }
}
