import { observer } from "mobx-react";
import React from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";

import UserInfo from "./UserInfo";

const Users: React.FC = observer(() => {
  const userId = useParams().params || 0;
  return (
    <Container style={{ background: "#fff", marginTop: 14 }}>
      <UserInfo userId={+userId} />
    </Container>
  );
});
export default Users;
