import React, { useEffect, useState } from 'react';
import { useStores } from 'services/useStore';
import {
  Button,
  ButtonGroup,
  Form,
  InputGroup,
  Offcanvas,
  OverlayTrigger,
  ToggleButton,
  Tooltip,
} from 'react-bootstrap';
import {
  GeoAlt,
  Person,
  PeopleFill,
  Search,
  LockFill,
  Link45deg,
  People,
  FileEarmark,
  Funnel,
} from 'react-bootstrap-icons';
import Stack from 'react-bootstrap/esm/Stack';
import LocationInfo from 'components/Locations/LocationInfo';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import PersonGraphInfo from './PersonGraphInfo';
import PersonGraphLink from './PersonGraphLink';
import GraphFilter from './GraphFilter';
import ShowGraph from './ShowGraph';

export type TGraphElement = {
  id: number;
  type?: string;
  name?: string;
  image?: string;
  description?: string;
  statuses?: number[];
  requests?: number[];
  user?: number;
  location_id?: number;
  sorter: number;
  isVirtual?: number;
};

export type TGraphLink = {
  source: string;
  target: string;
  description?: string;
  type?: string;
  linkType?: string;
};

export type TGraphData = {
  nodes: TGraphElement[];
  links: TGraphLink[];
  nodesNormalize: { [key: string]: number };
};

export enum DisplayTypes {
  Default = 'default',
  Location = 'location',
  Links = 'links',
}

export enum GraphTypes {
  Persons = 'person',
  User = 'user',
}

const showTypes = [
  { value: DisplayTypes.Links, icon: <PeopleFill />, tooltip: 'showTypesLinks' },
  { value: DisplayTypes.Location, icon: <GeoAlt />, tooltip: 'showTypesLocation' },
];

const graphTypes = [
  { value: GraphTypes.Persons, icon: <Person />, tooltip: 'graphTypesPerson' },
  { value: GraphTypes.User, icon: <LockFill />, tooltip: 'graphTypesUser' },
];

const LinksGraph: React.FC = observer(() => {
  const { t } = useTranslation();
  const { api, graphFilterStore } = useStores();
  const { filterText } = graphFilterStore;
  api.noLogin();
  const [data, setData] = useState<TGraphData>({ nodes: [], links: [], nodesNormalize: {} });
  const [fullData, setFullData] = useState<TGraphData>({
    nodes: [],
    links: [],
    nodesNormalize: {},
  });
  const [info, setInfo] = useState<TGraphElement | false>();
  const [locationInfo, setLocationInfo] = useState<number | false>();
  const [linkInfo, setLinkInfo] = useState<TGraphLink | false>();
  const [showFilters, setShowFilters] = useState(false);
  const [showType, setShowType] = useState<string>(DisplayTypes.Links);
  const [graphType, setGraphType] = useState<string>(GraphTypes.Persons);
  const [showLinks, setShowLinks] = useState(true);
  const [showRelations, setShowRelations] = useState(false);
  const [showQuests, setShowQuests] = useState(false);

  const onClickNode = (nodeId: number, type: string) => {
    if (type === 'location') {
      setLocationInfo(nodeId);
      setLinkInfo(false);
      setShowFilters(false);
      setInfo(false);
      return;
    }
    const el = data.nodes.find((node: TGraphElement) => node.id === nodeId);
    if (el) {
      setLinkInfo(false);
      setShowFilters(false);
      setInfo(el);
      setLocationInfo(false);
    }
  };

  /*const onClickLink = (source: number, target: number) => {
    const el = data.links.find(
      (link: TGraphLink) => link.source === source && link.target === target
    );
    if (el) {
      setInfo(false);
      setShowFilters(false);
      setLinkInfo(el);
      setLocationInfo(false);
    }
  };*/

  const onClickGraph = () => {
    setLinkInfo(false);
    setInfo(false);
    setShowFilters(false);
    setLocationInfo(false);
  };

  const reloadData = () => {
    api.getGraph(graphType).then((graphData) => {
      if (graphData && graphData?.links) {
        const links = [...graphData.links];
        const newGraphData: TGraphData = {
          nodes: graphData.nodes.map((n: TGraphElement) => {
            return { ...n, sorter: 0 } as TGraphElement;
          }),
          links,
          nodesNormalize: {},
        };

        newGraphData.nodes.forEach((element, index) => {
          newGraphData.nodesNormalize[element.id + ''] = index;
        });
        setFullData(newGraphData);
        setData(newGraphData);
      }
    });
  };

  useEffect(() => {
    //reloadData();
    const q = window.document.querySelector('body');
    if (q) q.style.overflow = 'hidden';
    api.reloader = reloadData;
    return () => {
      const qu = window.document.querySelector('body');
      if (qu) qu.style.overflow = 'auto';
      api.reloader = () => {};
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowType(DisplayTypes.Links);
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphType]);

  return (
    <div style={{ position: 'relative' }}>
      <Stack direction='horizontal' gap={3} style={{ margin: 14 }}>
        <ButtonGroup key='graphTypes'>
          {graphTypes.map((variant, idx) => (
            <OverlayTrigger
              key={variant.value}
              placement='bottom'
              overlay={
                <Tooltip id={`tooltip-${variant.value}`}>{t('graph.' + variant.tooltip)}</Tooltip>
              }
            >
              <ToggleButton
                id={`graphTypes-${idx}`}
                type='radio'
                variant='outline-secondary'
                name='graphTypes'
                value={variant.value}
                checked={graphType === variant.value}
                onChange={(e) => {
                  setGraphType(e.currentTarget.value);
                  if (!showRelations) setShowRelations(true);
                }}
              >
                {variant.icon}
              </ToggleButton>
            </OverlayTrigger>
          ))}
        </ButtonGroup>

        <ButtonGroup key='showLinks'>
          {graphType === GraphTypes.Persons ? (
            <OverlayTrigger
              key='links'
              placement='bottom'
              overlay={<Tooltip id='tooltip-links'>{t('graph.showLinks')}</Tooltip>}
            >
              <ToggleButton
                id='links'
                type='checkbox'
                variant='outline-success'
                name='linkTypes'
                value={1}
                checked={showLinks}
                onChange={(e) => setShowLinks(e.currentTarget.checked)}
              >
                <Link45deg />
              </ToggleButton>
            </OverlayTrigger>
          ) : (
            ''
          )}
          <OverlayTrigger
            key='relations'
            placement='bottom'
            overlay={<Tooltip id='tooltip-relations'>{t('graph.showRelations')}</Tooltip>}
          >
            <ToggleButton
              id='relations'
              type='checkbox'
              variant='outline-success'
              name='relationTypes'
              value={1}
              checked={showRelations}
              onChange={(e) => setShowRelations(e.currentTarget.checked)}
            >
              <People />
            </ToggleButton>
          </OverlayTrigger>
          {graphType === GraphTypes.Persons ? (
            <OverlayTrigger
              key='quest'
              placement='bottom'
              overlay={<Tooltip id='tooltip-quests'>{t('graph.showQuests')}</Tooltip>}
            >
              <ToggleButton
                id='quests'
                type='checkbox'
                variant='outline-success'
                name='questTypes'
                value={1}
                checked={showQuests}
                onChange={(e) => setShowQuests(e.currentTarget.checked)}
              >
                <FileEarmark />
              </ToggleButton>
            </OverlayTrigger>
          ) : (
            ''
          )}
        </ButtonGroup>
        {graphType === GraphTypes.Persons ? (
          <ButtonGroup key='showTypes'>
            {showTypes.map((variant, idx) => (
              <OverlayTrigger
                key={variant.value}
                placement='bottom'
                overlay={
                  <Tooltip id={`tooltip-list-${variant.value}`}>
                    {t('graph.' + variant.tooltip)}
                  </Tooltip>
                }
              >
                <ToggleButton
                  id={`showTypes-${idx}`}
                  type='radio'
                  variant='outline-success'
                  name='showTypes'
                  value={variant.value}
                  checked={showType === variant.value}
                  onChange={(e) => setShowType(e.currentTarget.value)}
                >
                  {variant.icon}
                </ToggleButton>
              </OverlayTrigger>
            ))}
          </ButtonGroup>
        ) : (
          ''
        )}
        <InputGroup>
          <InputGroup.Text>
            <Search />
          </InputGroup.Text>
          <Form.Control
            type='text'
            value={filterText}
            onChange={(e) => graphFilterStore.setFilterText(e.target.value)}
          />
        </InputGroup>
        <OverlayTrigger
          key='search'
          placement='bottom'
          overlay={<Tooltip id='tooltip-search'>{t('graph.searchFilter')}</Tooltip>}
        >
          <Button variant='secondary' onClick={() => setShowFilters(true)} className='ms-auto'>
            <Funnel />
          </Button>
        </OverlayTrigger>
      </Stack>

      <ShowGraph
        showType={showType}
        graphType={graphType}
        showLinks={showLinks}
        showRelations={showRelations}
        showQuests={showQuests}
        data={data}
        onClickNode={onClickNode}
        onClickGraph={onClickGraph}
      />
      <Offcanvas
        show={linkInfo || locationInfo || (info && info.type === 'person')}
        onHide={onClickGraph}
        placement='end'
        backdrop={false}
        scroll
      >
        <Offcanvas.Body>
          {locationInfo ? (
            <LocationInfo locationId={locationInfo} imageHeight={300} imageCover linkInDialog />
          ) : (
            ''
          )}
          {info && info.type === 'person' ? <PersonGraphInfo {...info} /> : ''}
          {linkInfo ? <PersonGraphLink {...linkInfo} /> : ''}
        </Offcanvas.Body>
      </Offcanvas>
      <div className={'myOffCanvas' + (showFilters ? ' show' : '')}>
        <div>
          <GraphFilter
            showFilters={showFilters}
            fullData={fullData}
            setGraphData={setData}
            graphType={graphType}
          />
        </div>
      </div>
    </div>
  );
});
export default LinksGraph;
