import { observer } from "mobx-react";
import React from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import StuffInfo from "./StuffInfo";

const Stuffs: React.FC = observer(() => {
  const stuffId = useParams().params || 0;

  return (
    <Container style={{ background: "#fff", marginTop: 14 }}>
      <StuffInfo stuffId={+stuffId} />
    </Container>
  );
});
export default Stuffs;
