import BaseStore from './BaseStore';

export enum StatusVisibility {
  Open = 'open',
  Group = 'group',
  Close = 'close',
}

export type TStatus = {
  status_id?: number;
  project_id?: number;
  status_name: string;
  status_description?: string;
  status_images?: string[];
  status_draft?: number;
  status_visibility?: string;
  img_id?: number;
  img_mime?: string;
};
class StatusesStore extends BaseStore {
  idKey = 'status_id';
  get data(): TStatus[] {
    return this._data as TStatus[];
  }
  getForProject = (project_id: number) =>
    this._data.filter(
      (status: TStatus) => status.project_id === project_id && !status.status_draft
    );
}
export default StatusesStore;
