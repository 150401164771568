import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRequest } from 'stores/RequestsStore';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { ROUTES } from 'components/App/App';
import { Search } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import BotCard from 'components/Heplers/BotCard';
import RequestStatusBadger from 'components/Heplers/RequestStatusBadger';
import { useStores } from '../../services/useStore';

const BotRequestList: React.FC = observer(() => {
  const { t } = useTranslation();
  const { sessionStore, requestStore, api, personStore } = useStores();
  api.noLogin();
  const [requests, setRequests] = useState<TRequest[]>();
  const [inited, setInit] = useState(false);
  const updateRequestList = () => {
    setRequests(requestStore.data);
  };
  useEffect(() => {
    sessionStore.tgHeader(t('request.mainTitle'));
    api.getRequestsForUser(sessionStore.session.user_id).then((requestsData) => {
      const data = Array.isArray(requestsData) ? requestsData : [requestsData];
      data.forEach((p: TRequest) =>
        requestStore.update({ ...p, user_id: sessionStore.session.user_id })
      );
      updateRequestList();
      setInit(true);
    });
    api.useParalax();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateRequestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestStore.data]);
  if (!inited) {
    return <div>{t('global.loading')}</div>;
  }

  if (!requests?.length) {
    return (
      <Container className='greenForm' style={{ marginTop: 54 }}>
        <Row>
          <Col>
            <Alert variant='warning'>
              <Alert.Heading style={{ color: '#000' }}>
                <Search /> {t('request.empty1')}
              </Alert.Heading>
              <p>{t('request.empty2')}</p>
              <hr />
              <div className='d-flex justify-content-end'>
                <Link to={ROUTES.projects} className='btn btn-primary' style={{ color: '#000' }}>
                  {t('projects.mainTitle')}
                </Link>
              </div>
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  }

  const calculateImage = (data: TRequest) => {
    if (data.img_id) return `${data.img_id}.${data.img_mime}`;
    if (data.person_image) return data.person_image;
    if (data.person_id && personStore.data.length) {
      const person = personStore.get(data.person_id);
      return person ? `${person.img_id}.${person.img_mime}` : '';
    }
    return '';
  };

  const getStyle = (data: TRequest): React.CSSProperties => {
    const style: React.CSSProperties = {};
    if (data.request_flow === 'reject') style.background = '#ffa3a382';
    if (data.request_flow === 'autoReject') style.background = '#b3b3b3';
    if (data.person_user === data.user_id) style.background = '#85ffa482';
    return style;
  };

  return (
    <Container fluid className='verticalPadding greenForm' style={{ marginTop: 24 }}>
      <Row>
        <Col className='mt-4'>
          {requests.length ? (
            requests.map((request) => (
              <BotCard
                style={getStyle(request)}
                key={(request.request_name || request.person_name) + '_' + request.request_id}
                id={request.request_id || 0}
                noParalax={requests.length < 5}
                className={'bot-list' + (request.request_type === 'invite' ? ' invite' : '')}
                img={calculateImage(request)}
                onClick={() =>
                  api.showDialog(
                    request.request_type === 'invite' ? 'invite' : 'request',
                    request.request_id || 0
                  )
                }
              >
                <div className='name'>{request.request_name || request.person_name}</div>
                <div className='info'>{api.timeFormat(request.request_updated)}</div>
                <div className='subtitle'>
                  <div>
                    <RequestStatusBadger data={request} />
                  </div>
                </div>
              </BotCard>
            ))
          ) : (
            <></>
          )}

          <div style={{ height: 10 }} />
        </Col>
      </Row>
    </Container>
  );
});
export default BotRequestList;
