import AddButton from 'components/Buttons/AddButton';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserStatus } from 'stores/SessionStore';
import { ShowTypes } from 'services/Api';
import { TStuff } from 'stores/StuffsStore';
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import { FolderCheck, FolderPlus, Search } from 'react-bootstrap-icons';
import ShowTypeItem from 'components/Heplers/ShowTypeItem';
import StuffFolderList from 'components/Heplers/StuffFolderList';
import NoContent from 'components/Heplers/NoContent';
import { useStores } from 'services/useStore';
import ItemViewer from 'components/Heplers/ItemViewer';
import StuffFolderSelect from 'components/Heplers/StuffFolderSelect';
import { ProjectTypes } from 'stores/ProjectsStore';

const StuffList: React.FC = observer(() => {
  const { t } = useTranslation();
  const { sessionStore, stuffStore, stuffFoldersStore, api } = useStores();
  api.noLogin();
  const [showType, setShowType] = useState(api.getLocalConfig('showType'));
  const [inited, setInit] = useState(false);
  const [showed, setShow] = useState(false);
  const [folderEdit, setFolderEdit] = useState(false);
  const [folder, setFolder] = useState(0);
  const [stuffs, setStuffs] = useState<TStuff[]>();
  const [deleteConfirmVisible, showDeleteConfirm] = useState(false);
  const [waitNumber, setWaitNumber] = useState(0);
  const [waitEditNumber, setWaitEditNumber] = useState(0);
  const [folderName, setFolderName] = useState('');
  const [editModalVisible, showEditModal] = useState(false);
  const [moveFolder, setMoveFolder] = useState(0);
  const [random, setRandom] = useState(0);
  const [searchStuff, setSearchStuff] = useState('');

  const columns = [
    {
      dataField: 'stuff_name',
      text: t('stuffForm.name'),
      sort: true,
    },
    {
      dataField: 'stuff_title',
      text: t('stuffForm.title'),
      sort: true,
    },
    {
      dataField: 'stuff_price',
      text: t('stuffForm.price'),
      sort: true,
    },
    {
      dataField: 'stuff_status',
      text: t('stuffForm.status'),
      sort: true,
    },
  ];

  const filterStuffByFolder = (folderId: number) => {
    setShow(false);
    let newData: TStuff[] = (stuffStore.data || []).filter(
      (stuff) =>
        stuff.project_id === sessionStore.session.user_project && stuff.folder_id === folderId
    );
    if (showType === ShowTypes.Card && searchStuff) {
      newData = [
        ...newData.filter((item: TStuff) => {
          if (item.stuff_id === +searchStuff) return true;
          return !!columns.find(
            (c) =>
              (item[c.dataField as keyof TStuff] + '')
                .toLowerCase()
                .indexOf(searchStuff.toLowerCase()) > -1
          );
        }),
      ];
    }
    setStuffs([...newData]);
    setTimeout(() => {
      if (newData.length) setShow(true);
    }, 0);
  };

  useEffect(() => {
    api.getStuffs().then(() => {
      const currentFolder = +window.localStorage.stuffFolder || 0;
      setFolder(currentFolder);
      filterStuffByFolder(currentFolder);
      setInit(true);
      setShow(true);
    });
  }, []);

  useEffect(() => {
    if (inited) filterStuffByFolder(folder);
  }, [folder, searchStuff, showType, stuffStore._data]);

  if (!inited) {
    return <div>{t('global.loading')}</div>;
  }

  if (sessionStore.role() !== UserStatus.Manager && sessionStore.role() !== UserStatus.Owner)
    return <div>{t('global.loading')}</div>;

  const setFolderAction = (newFolder: number) => {
    setFolder(newFolder);
    window.localStorage.stuffFolder = newFolder;
  };

  const folderAction = (folderId: number, action: string): boolean => {
    switch (action) {
      case 'create':
        setMoveFolder(0);
        setWaitEditNumber(0);
        setFolderName('');
        showEditModal(true);
        break;

      case 'edit':
        setMoveFolder(0);
        setWaitEditNumber(folderId);
        setFolderName(stuffFoldersStore.get(folderId).folder_name);
        showEditModal(true);
        break;

      case 'delete':
        setMoveFolder(0);
        setWaitNumber(folderId);
        showDeleteConfirm(true);
        break;

      default:
        break;
    }
    return true;
  };
  const adminActions =
    api.isManager(sessionStore.session.user_project) && api.projectIsActive() ? (
      <AddButton title={t('stuff.newStuff')} link='stuffEdit' />
    ) : (
      ''
    );

  const remove = () => {
    if (!waitNumber) return;
    showDeleteConfirm(false);
    api.deleteStuffFolders(waitNumber).then(() => {
      setRandom(Math.random() * 1000);
      setWaitNumber(0);
      filterStuffByFolder(folder);
    });
  };

  const save = () => {
    showEditModal(false);
    api.editStuffFolders(waitEditNumber, folderName, moveFolder).then(() => {
      setRandom(Math.random() * 1000);
      setWaitNumber(0);
    });
  };

  const moveAndRemove = () => {
    if (!waitNumber) return;
    api.moveStuffFolders(waitNumber, moveFolder).then(() => {
      setRandom(Math.random() * 1000);
      remove();
    });
  };

  const removePromtClose = () => {
    setWaitNumber(0);
    showDeleteConfirm(false);
  };

  const editClose = () => {
    setWaitEditNumber(0);
    showEditModal(false);
  };
  const getInfo = (id: number, type: string): string | number => {
    const d = stuffFoldersStore.get(id);
    if (!d) return '';
    return d[type];
  };

  const getStyle = (stuff: TStuff): React.CSSProperties => {
    if (api.getProjectType() === ProjectTypes.LARP && stuff.stuff_virtual)
      return { border: '3px solid #555555' };
    return { background: '#fff' };
  };

  const calculateFooter = (item: TStuff) => {
    return (
      <>
        {api.getProjectType() === ProjectTypes.LARP && item.stuff_virtual ? (
          <div className='request-badger'>{t('global.virtual')}</div>
        ) : (
          ''
        )}
      </>
    );
  };

  const modals = (
    <>
      {waitNumber ? (
        <Modal show={deleteConfirmVisible} onHide={removePromtClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t('folder.removeFolder')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t('folder.removeFolderPrompt')} <b>{getInfo(waitNumber, 'folder_name')}</b>?
            {getInfo(waitNumber, 'folder_count') || getInfo(waitNumber, 'stuff_count') ? (
              <div className='mt-2'>
                {t('folder.moveStufs1', {
                  stuff_count: getInfo(waitNumber, 'stuff_count') || 0,
                  folder_count: getInfo(waitNumber, 'folder_count') || 0,
                })}
                <br />
                {t('folder.moveFiles2')}
                <StuffFolderSelect
                  excludeId={waitNumber}
                  folderId={moveFolder}
                  action={setMoveFolder}
                  addRoot
                />
              </div>
            ) : (
              ''
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' size='sm' onClick={removePromtClose}>
              {t('global.close')}
            </Button>
            {getInfo(waitNumber, 'folder_count') || getInfo(waitNumber, 'stuff_count') ? (
              <>
                <Button variant='danger' size='sm' onClick={moveAndRemove}>
                  {t('folder.removeFolderWithMove')}
                </Button>
                <Button variant='dark' size='sm' onClick={remove}>
                  {t('folder.removeFolderWithContent')}
                </Button>
              </>
            ) : (
              <Button variant='danger' size='sm' onClick={remove}>
                {t('global.delete')}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      ) : (
        ''
      )}
      <Modal show={editModalVisible} onHide={editClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t(waitEditNumber ? 'folder.editFolder' : 'folder.createFolder')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form action='#'>
            <FloatingLabel controlId='folderName' label={t('textForm.name')} className='mb-3'>
              <Form.Control
                type='text'
                size='lg'
                value={folderName || ''}
                onChange={(e) => setFolderName(e.target.value)}
                placeholder={t('textForm.name')}
              />
            </FloatingLabel>
            <StuffFolderSelect
              addRoot
              excludeIn={waitEditNumber}
              folderId={waitEditNumber ? +getInfo(waitEditNumber, 'folder_parent') : moveFolder}
              action={setMoveFolder}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' size='sm' onClick={editClose}>
            {t('global.close')}
          </Button>
          <Button variant='success' size='sm' onClick={save}>
            {t('global.save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

  if (!stuffStore.data.length)
    return (
      <>
        <Container fluid className='verticalPadding greenForm'>
          <Row>
            <Col lg={9}>
              <h1>{t('stuff.mainTitle')}</h1>
            </Col>
            <Col className='rightText' style={{ marginTop: '0.75rem' }} lg={3}>
              <Button
                variant={folderEdit ? 'warning' : 'info'}
                size='sm'
                onClick={() => setFolderEdit(!folderEdit)}
              >
                <FolderCheck />
              </Button>{' '}
              <Button variant='info' size='sm' onClick={() => folderAction(folder, 'create')}>
                <FolderPlus />
              </Button>{' '}
              <ShowTypeItem callback={setShowType} /> {adminActions}
            </Col>
          </Row>
          <Row>
            <Col>
              <StuffFolderList
                folderId={folder}
                action={setFolderAction}
                editMode={folderEdit}
                folderAction={folderAction}
                random={random}
              />
            </Col>
          </Row>
          <NoContent />
        </Container>
        {modals}
      </>
    );
  return (
    <>
      <Container fluid className='verticalPadding greenForm'>
        <Row>
          <Col lg={9}>
            <h1>{t('stuff.mainTitle')}</h1>
          </Col>
          <Col className='rightText' style={{ marginTop: '0.75rem' }} lg={3}>
            <Button
              variant={folderEdit ? 'warning' : 'info'}
              size='sm'
              onClick={() => setFolderEdit(!folderEdit)}
            >
              <FolderCheck />
            </Button>{' '}
            <Button variant='info' size='sm' onClick={() => folderAction(folder, 'create')}>
              <FolderPlus />
            </Button>{' '}
            <ShowTypeItem callback={setShowType} /> {adminActions}
          </Col>
        </Row>

        <Row>
          <Col>
            <StuffFolderList
              folderId={folder}
              action={setFolderAction}
              editMode={folderEdit}
              folderAction={folderAction}
              random={random}
            />
          </Col>
        </Row>
        {showType === ShowTypes.Card ? (
          <Row className='mb-3'>
            <Col xs={6}>
              <InputGroup className='mb-3'>
                <InputGroup.Text style={{ background: '#019305', color: '#fff' }}>
                  <Search />
                </InputGroup.Text>
                <Form.Control
                  type='text'
                  size='sm'
                  value={searchStuff}
                  onChange={(e) => setSearchStuff(e.target.value)}
                  placeholder={t('global.dataSearch')}
                />
              </InputGroup>
            </Col>
          </Row>
        ) : (
          ''
        )}
      </Container>
      {showed ? (
        <ItemViewer
          id='stuff_id'
          store='stuffStore'
          type='stuff'
          tableData={stuffs || []}
          columns={columns}
          showRoute='stuffs'
          editRoute='stuffEdit'
          name='stuff_name'
          title='stuff_title'
          canEdit
          mainTitle={t('stuff.mainTitle')}
          adminActions={adminActions}
          noTop
          showTypeValue={showType}
          setShowTypeValue={setShowType}
          getStyle={getStyle}
          calculateFooter={calculateFooter}
        />
      ) : (
        ''
      )}

      {modals}
    </>
  );
  /*return (
    <ItemViewer
      id='stuff_id'
      store='stuffStore'
      type='stuff'
      tableData={data}
      columns={columns}
      showRoute='stuffs'
      editRoute='stuffEdit'
      name='stuff_name'
      title='stuff_title'
      canEdit
      mainTitle={t('stuff.mainTitle')}
      adminActions={adminActions}
    />
  );*/
});
export default StuffList;
