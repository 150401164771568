/* eslint-disable react/no-danger */
import { ROUTES } from 'components/App/App';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import {
  Accordion,
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';
import {
  ArrowRightCircle,
  CashCoin,
  CloudDownload,
  FileEarmarkArrowUp,
  Gear,
  InfoCircle,
  Pencil,
  QuestionCircle,
  Send,
  Trash,
} from 'react-bootstrap-icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useStores } from 'services/useStore';
import { TLocationInfo } from 'stores/PrototypeLocationStore';
import { TActionInfo } from 'stores/PrototypeActionStore';
import { TGroupInfo } from 'stores/PrototypeGroupStore';
import { TPersonInfo } from 'stores/PrototypePersonStore';
import { TLinkInfo } from 'stores/PrototypeLinkStore';
import ProjectLARPInitDialog, { TInitData } from 'components/Dialogs/ProjectLARPInitDialog';
import DatePicker from 'components/Heplers/DatePicker';
import PricingDialog from 'components/Dialogs/PricingDialog';
import PrototypeLARPLocation from './PrototypeLARP/PrototypeLARPLocation';
import PrototypeLARPAction from './PrototypeLARP/PrototypeLARPAction';
import PrototypeLARPGroup from './PrototypeLARP/PrototypeLARPGroup';
import PrototypeLARPPerson from './PrototypeLARP/PrototypeLARPPerson';
import PrototypeLARPLink from './PrototypeLARP/PrototypeLARPLink';
//import startData from './book2_init.json';

export type TDialogElement = {
  source: 'user' | 'ai' | 'system' | 'error';
  text: string;
};

export type TCommentInfo = {
  description: string;
};

export type TGameInfo = {
  description: string;
  time: string;
  pricing: string;
};

export type TIdInfo = {
  [key: string]: number;
};

export type TProjectInfo = {
  name: string;
  start: string;
  end: string;
  description?: string;
};

export type TCommandInfo = {
  command: string;
  id?: number;
  data:
    | TCommentInfo
    | TGameInfo
    | TLocationInfo
    | TGroupInfo
    | TPersonInfo
    | TActionInfo
    | TProjectInfo
    | TLinkInfo;
};

const ProjectAILARP: React.FC = observer(() => {
  const {
    api,
    prototypeLocationStore,
    prototypeActionStore,
    prototypeGroupStore,
    prototypePersonStore,
    prototypeLinkStore,
  } = useStores();
  const history = useNavigate();
  const { t } = useTranslation();
  const useAI = api.canTextAi(0, true);
  if (!useAI) {
    history(ROUTES.home);
  }
  const inputRef = useRef<HTMLInputElement>(null);
  const endOfListRef = useRef<HTMLDivElement>(null);
  const [init, setInit] = useState(false);
  const [initModal, setInitModal] = useState(true);
  const [initSpiner, setInitSpiner] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showSpiner, setShowSpiner] = useState(false);
  const [showNamePrompt, setShowNamePrompt] = useState(false);
  const [showUploadSpiner, setShowUploadSpiner] = useState(false);
  const [uploadedFile, setUploadedFile] = useState('');
  const [projectName, setProjectName] = useState('');
  const [editProjectWindowShow, setEditProjectWindowShow] = useState(false);
  const [textToAI, setTextToAI] = useState<string>('');
  const [projectStart, setProjectStart] = useState<moment.Moment>();
  const [projectEnd, setProjectEnd] = useState<moment.Moment>();
  const [dialog, setDialog] = useState<TDialogElement[]>([]);
  const [gameElements, setGameElements] = useState<TGameInfo>({} as TGameInfo);
  const [file, setFile] = useState<File | null>(null);

  const onFileUpload = async () => {
    if (file) {
      setShowUploadSpiner(true);
      const url = api.settings.url.server + api.settings.url.prototypeLARPVectorize;
      const formData = new FormData();
      formData.append('file', file, file.name);
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          'X-Token': api.getSession(),
        },
      });
      setUploadedFile(file.name);
      setShowUploadSpiner(false);
      console.log(response);
    }
  };

  const buildDialog = (dialogElement: TDialogElement, index: number) => {
    return (
      <div className={'dialogElement-' + dialogElement.source} key={index}>
        {dialogElement.text}
      </div>
    );
  };

  const parseCommands = (commands: TCommandInfo[], newDialog: TDialogElement[]) => {
    if (!commands?.length) return;
    prototypeLocationStore.parseCommands(commands, newDialog);
    prototypeActionStore.parseCommands(commands, newDialog);
    prototypeGroupStore.parseCommands(commands, newDialog);
    prototypePersonStore.parseCommands(commands, newDialog);
    prototypeLinkStore.parseCommands(commands, newDialog);
    commands.forEach((command: TCommandInfo) => {
      if (!command?.command) {
        newDialog.push({ text: JSON.stringify(command), source: 'ai' });
        return;
      }
      switch (command.command) {
        case 'game_info':
          {
            const data: TGameInfo = command.data as TGameInfo;
            if (data.description) gameElements.description = data.description;
            if (data.time) gameElements.time = data.time;
            gameElements.pricing = data.pricing || 'full';
            setGameElements({ ...gameElements });
            newDialog.push({ text: t('projectLARPAI.editedData'), source: 'system' });
          }
          break;
        case 'comment':
          {
            const data: TCommentInfo = command.data as TCommentInfo;
            newDialog.push({ text: data.description, source: 'ai' });
          }
          break;
        case 'ask':
          {
            const data: TCommentInfo = command.data as TCommentInfo;
            newDialog.push({ text: data.description, source: 'user' });
          }
          break;
        default:
          break;
      }
    });
  };

  const buildState = (dialogs: boolean = true): TCommandInfo[] => {
    const commands: TCommandInfo[] = [
      {
        command: 'game_info',
        data: { ...gameElements },
      },
    ];
    prototypeLocationStore.sendToAI(commands);
    prototypeActionStore.sendToAI(commands);
    prototypeGroupStore.sendToAI(commands);
    prototypePersonStore.sendToAI(commands);
    prototypeLinkStore.sendToAI(commands);
    if (dialogs) {
      dialog.forEach((el: TDialogElement) => {
        if (el.source === 'ai') {
          commands.push({
            command: 'comment',
            data: { description: el.text },
          });
        }
        if (el.source === 'user') {
          commands.push({
            command: 'ask',
            data: { description: el.text },
          });
        }
      });
    }
    return commands;
  };

  const buildId = () => {
    return {
      locations: prototypeLocationStore.getIds(),
      actions: prototypeActionStore.getIds(),
      groups: prototypeGroupStore.getIds(),
      persons: prototypePersonStore.getIds(),
    };
  };

  const save = async () => {
    await api.prototypeLARPSave(buildState());
    const newDialog = [...dialog];
    newDialog.push({ text: t('projectLARPAI.savedData'), source: 'system' });
    setDialog(newDialog);
  };

  const compile = async () => {
    setShowSpiner(true);
    const commands = buildState(false);
    const projectInfo: TCommandInfo = {
      command: 'project_info',
      data: {
        name: projectName,
        start: projectStart?.format('YYYY-MM-DD') || '',
        end: projectEnd?.format('YYYY-MM-DD') || '',
      },
    };
    commands.push(projectInfo);
    const { result } = await api.prototypeLARPCompile(commands);
    setShowSpiner(false);
    if (result) window.location.href = '/projects/' + result;
    console.log(result);
  };

  const sendToAI = async (
    text?: string,
    oldDialog: TDialogElement[] = []
  ): Promise<TDialogElement[]> => {
    if (!textToAI && !text) return [];
    const message = text || textToAI;
    setShowSpiner(true);
    const newDialog = oldDialog.length ? [...oldDialog] : [...dialog];
    newDialog.push({ text: message, source: 'user' });
    setDialog(newDialog);
    setTextToAI('');
    const commands = buildState();
    const idList = buildId();
    const data = await api.prototypeLARPSendQuestion(message, commands, idList);
    if (data.error) {
      newDialog.push({ text: data.error, source: 'error' });
    } else if (data.result && data.result.commands) {
      parseCommands(data.result.commands as TCommandInfo[], newDialog);
      const isComment = data.result.commands.find((i: TCommandInfo) => i.command === 'comment');
      if (!isComment) newDialog.push({ text: t('projectLARPAI.done'), source: 'ai' });
    }
    setShowSpiner(false);
    if (!oldDialog.length) setDialog(newDialog);
    return newDialog;
  };

  const initAction = async ({ initText, bookFile, type, texts }: TInitData) => {
    if (!initText && !bookFile) return;
    if (type === 'simple') {
      const newDialog = [...dialog];
      setInitSpiner(true);
      newDialog.push({ text: initText, source: 'user' });
      //await onFileUpload();
      const data = await api.prototypeLARPSendQuestion(initText, false, false);
      if (data.error) {
        newDialog.push({ text: data.error, source: 'error' });
      } else {
        //const { result } = startData;
        parseCommands(data.result.commands as TCommandInfo[], newDialog);
        newDialog.push({ text: t('projectLARPAI.done'), source: 'ai' });
      }
      console.log(data);
      setInitSpiner(false);
      setDialog(newDialog);
      setInitModal(false);
    } else {
      let newDialog = [...dialog];
      setInitModal(false);
      sendToAI(texts?.main).then((d0: TDialogElement[]) => {
        console.log('projectDescriptions complete');
        setDialog(d0);
        newDialog = [...d0];
        sendToAI(texts?.location, newDialog).then((d1: TDialogElement[]) => {
          console.log('locations complete');
          setDialog(d1);
          newDialog = [...d1];
          sendToAI(texts?.group, newDialog).then((d2: TDialogElement[]) => {
            setDialog(d2);
            newDialog = [...d2];
            console.log('groups complete');
            sendToAI(texts?.person, newDialog).then((d3: TDialogElement[]) => {
              setDialog(d3);
              newDialog = [...d3];
              console.log('persons complete');
              sendToAI(texts?.link, newDialog).then((d4: TDialogElement[]) => {
                setDialog(d4);
                newDialog = [...d4];
                console.log('links complete');
                sendToAI(texts?.action, newDialog).then((d5: TDialogElement[]) => {
                  setDialog(d5);
                  console.log('actions complete');
                });
              });
            });
          });
        });
      });
    }
  };

  /*const testCommand = () => {
    const commands = [
      {
        command: 'person_remove',
        id: 7,
      },
      {
        command: 'link_remove',
        data: {
          name1: 'Брум',
          name2: 'Матаяс Гелвар',
        },
      },
      {
        command: 'action_edit',
        id: 3,
        data: {
          persons: 'Джаспер Фей, Матаяс Гелвар',
          description:
            'Джаспер і Матаяс забезпечують безпеку корабля під час усієї операції. Важливе завдання, щоб забезпечити успішну втечу команди й захистити їх від потенційних зрадників чи ворогів.',
        },
      },
    ];
    setTimeout(() => {
      const newDialog = [...dialog];
      newDialog.push({ text: 'Test', source: 'system' });
      parseCommands(commands as TCommandInfo[], newDialog);
      setDialog(newDialog);
    }, 1000);
  };**/

  const eraseAll = async () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(t('projectLARPAI.confirm'))) {
      await api.prototypeLARPEraseProject();
      window.location.reload();
    }
  };

  const selectExample = (text: string) => {
    setTextToAI(text);
    setShowHelp(false);
    setTimeout(() => {
      if (inputRef.current) {
        console.log('>>');
        inputRef.current.focus();
        // Выделяем весь текст в инпуте
        inputRef.current.select();
      }
    }, 300);
  };

  const buildExamples = () => {
    const result = [];
    const count = +t('projectLARPAI.examplesListCount');
    for (let i = 0; i < count; i++) {
      result.push(
        <Dropdown.Item
          role='button'
          key={i}
          eventKey={i + 1}
          onClick={() => selectExample(t('projectLARPAI.examplesList.' + i))}
        >
          {t('projectLARPAI.examplesList.' + i)}
        </Dropdown.Item>
      );
    }
    return result;
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  };

  const selectPricing = (priceName: string | false) => {
    console.log(priceName);
    if (priceName) setGameElements({ ...gameElements, pricing: priceName });
  };

  const showPricingWindow = () => {
    api.togglePricingGenerateDialog(selectPricing, gameElements.pricing);
  };

  useEffect(() => {
    if (!init) {
      api.prototypeLARPCheckProject().then((checkResult) => {
        console.log(checkResult);
        if (checkResult.result.status === 'false') {
          api.togglePricingGenerateDialog(selectPricing);
          setInit(true);
        } else if (checkResult.result.status === 'present') {
          if (checkResult.result.state) {
            const newDialog = [...dialog];
            parseCommands(checkResult.result.state as TCommandInfo[], newDialog);
            setDialog(newDialog);
            setInitModal(false);
          }
          setUploadedFile('book.txt');
        }
      });

      window.document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }
    return () => {
      window.document.getElementsByTagName('body')[0].style.overflow = 'auto';
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (endOfListRef.current) {
        endOfListRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  }, [dialog, showSpiner]);

  return (
    <Container className='larpPrototype' style={{ marginTop: 14, height: '80vh' }}>
      <Row>
        <Col>
          <h3>{t('projectLARPAI.mainTitle')}</h3>
        </Col>
      </Row>
      <Row className='justify-content-md-center' style={{ height: '100%' }}>
        <Col className='dialogBlock'>
          <div className='dialogContainer'>
            <div className='dialogList'>
              {dialog.map(buildDialog)}
              <div ref={endOfListRef} key='anhor' />
            </div>
          </div>
          <div style={{ display: 'flex', marginTop: 10 }}>
            <Button
              variant='info'
              className='col-1'
              size='sm'
              type='button'
              style={{ marginRight: 5 }}
              onClick={() => setShowHelp(true)}
            >
              <QuestionCircle />
            </Button>
            <Form.Control
              type='text'
              size='sm'
              value={textToAI || ''}
              onChange={(e) => setTextToAI(e.target.value)}
              placeholder={t('projectLARPAI.ask')}
              style={{ marginRight: 5 }}
              ref={inputRef}
              onKeyUp={(e) => (e.key === 'Enter' ? sendToAI() : false)}
            />
            {showSpiner ? (
              <Spinner variant='warning' style={{ width: 27, height: 25 }} />
            ) : (
              <Button
                variant='warning'
                disabled={!textToAI}
                className='col-1'
                size='sm'
                type='button'
                onClick={() => sendToAI()}
              >
                <Send />
              </Button>
            )}
          </div>
        </Col>
        <Col className='elementsBlock'>
          <div className='elementItems'>
            <Accordion defaultActiveKey={['-1']} alwaysOpen style={{ width: '100%' }}>
              <Accordion.Item eventKey='-1'>
                <Accordion.Header>
                  <div className='plusButton'>
                    <Gear fontSize={16} />
                  </div>
                  <b>{t('projectLARPAI.someSettings')}</b>
                </Accordion.Header>
                <Accordion.Body style={{ background: '#fff' }}>
                  <div className='ms-auto'>
                    <Button variant='info' onClick={showPricingWindow}>
                      <CashCoin fontSize={20} />
                      &nbsp;&nbsp;{t(`pricing.pricing`)}{' '}
                      <b>{t(`pricing.${gameElements.pricing || false}.name`)}</b>
                    </Button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>
                  <div className='plusButton'>
                    <InfoCircle fontSize={16} />
                  </div>
                  <b>{t('projectLARPAI.source')}</b>
                </Accordion.Header>
                <Accordion.Body>
                  {uploadedFile ? (
                    <Row className='action'>
                      <Col>
                        <b>{uploadedFile}</b>
                      </Col>
                      <Col className='deleteButton'>
                        <Button
                          variant='danger'
                          size='sm'
                          type='button'
                          onClick={() => setUploadedFile('')}
                        >
                          <Trash fontSize={16} />
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <div style={{ display: 'flex', margin: 10 }}>
                      <Form.Control
                        type='file'
                        size='sm'
                        onChange={onFileChange}
                        placeholder={t('projectLARPAI.book')}
                        style={{ marginRight: 5 }}
                      />
                      {showUploadSpiner ? (
                        <Spinner variant='primary' style={{ width: 28, height: 26 }} />
                      ) : (
                        <Button
                          variant='primary'
                          disabled={!file}
                          className='col-1'
                          size='sm'
                          type='button'
                          onClick={() => onFileUpload()}
                          style={{ width: 31, lineHeight: 0, padding: 2 }}
                        >
                          <FileEarmarkArrowUp fontSize={20} />
                        </Button>
                      )}
                    </div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='1'>
                <Accordion.Header>
                  <Button
                    variant='primary'
                    size='sm'
                    type='button'
                    className='plusButton'
                    onClick={() => setEditProjectWindowShow(true)}
                  >
                    <Pencil fontSize={14} />
                  </Button>
                  <b>{t('projectLARPAI.about')}</b>
                </Accordion.Header>
                <Accordion.Body style={{ background: '#fff' }}>
                  <div className='mb-2'>{gameElements.time}</div>
                  <div>{gameElements.description}</div>
                </Accordion.Body>
              </Accordion.Item>
              <PrototypeLARPLocation />
              <PrototypeLARPGroup />
              <PrototypeLARPPerson />
              <PrototypeLARPAction />
              <PrototypeLARPLink />
            </Accordion>
          </div>
          <div style={{ marginTop: 10, alignSelf: 'end' }}>
            <Button variant='danger' size='sm' type='button' onClick={() => eraseAll()}>
              <Trash fontSize={16} /> {t('projectLARPAI.reset')}
            </Button>{' '}
            <Button size='sm' type='button' onClick={() => save()}>
              <CloudDownload fontSize={16} /> {t('projectLARPAI.save')}
            </Button>{' '}
            <Button
              variant='success'
              size='sm'
              type='button'
              onClick={() => setShowNamePrompt(true)}
            >
              {t('projectLARPAI.compile')} <ArrowRightCircle />
            </Button>
          </div>
        </Col>
      </Row>
      <Modal
        show={editProjectWindowShow}
        size='lg'
        key='editProject'
        centered
        onHide={() => setEditProjectWindowShow(false)}
      >
        <Modal.Header closeButton>{t('projectLARPAI.about')}</Modal.Header>
        <Modal.Body style={{ backgroundColor: '#0000001c' }}>
          <Row>
            <Col>
              <h5>{t('projectLARPAI.period')}:</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                as='textarea'
                rows={5}
                size='sm'
                value={gameElements.time || ''}
                onChange={(e) => setGameElements({ ...gameElements, time: e.target.value })}
                style={{ height: '200px' }}
              />
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col>
              <h5>{t('projectLARPAI.projectDescription')}:</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                as='textarea'
                rows={5}
                size='sm'
                value={gameElements.description || ''}
                onChange={(e) => setGameElements({ ...gameElements, description: e.target.value })}
                style={{ height: '200px' }}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal show={showHelp} size='lg' key='samples' centered onHide={() => setShowHelp(false)}>
        <Modal.Header closeButton>{t('projectLARPAI.helps')}</Modal.Header>
        <Modal.Body style={{ backgroundColor: '#0000001c' }}>
          <Row>
            <Col style={{ whiteSpace: 'pre-line' }}>{t('projectLARPAI.examples')}:</Col>
          </Row>
          <Row>
            <Col className='mt-3 help-variants'>
              <Dropdown.Menu show style={{ position: 'static' }}>
                {buildExamples()}
              </Dropdown.Menu>
              <div />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        show={showNamePrompt}
        size='lg'
        key='projectName'
        centered
        onHide={() => setShowNamePrompt(false)}
      >
        <Modal.Header closeButton>{t('projectLARPAI.lastStep')}</Modal.Header>
        <Modal.Body style={{ backgroundColor: '#0000001c' }}>
          <Row>
            <Col style={{ whiteSpace: 'pre-line' }}>{t('projectLARPAI.lastStepDescription')}</Col>
          </Row>
          <Row>
            <Col className='mt-3'>
              <Form.Control
                type='text'
                size='sm'
                value={projectName || ''}
                onChange={(e) => setProjectName(e.target.value)}
                placeholder={t('projectForm.name')}
                style={{ marginRight: 5 }}
              />
            </Col>
          </Row>
          <Row>
            <Col className='mt-3'>
              <DatePicker
                title={t('projectForm.start')}
                date={projectStart || false}
                callback={setProjectStart}
              />
              <DatePicker
                title={t('projectForm.end')}
                date={projectEnd || false}
                callback={setProjectEnd}
              />
            </Col>
          </Row>

          <Row>
            <Col style={{ display: 'flex' }} className='justify-content-md-center mt-3'>
              <Button
                variant='success'
                size='sm'
                disabled={!projectName}
                type='button'
                onClick={() => compile()}
              >
                {t('projectLARPAI.compile2')} <ArrowRightCircle />
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <ProjectLARPInitDialog show={initModal} initSpiner={initSpiner} initAction={initAction} />
      <PricingDialog />
    </Container>
  );
});
export default ProjectAILARP;
