/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Badge, Col, Container, ListGroup, Row } from 'react-bootstrap';
import { Person } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { TRequest } from 'stores/RequestsStore';
import { useStores } from '../../services/useStore';

export interface IRequestByPersonList {
  personId: number;
  currentUser: number;
}

const RequestByPersonList: React.FC<IRequestByPersonList> = observer(
  ({ personId, currentUser }) => {
    const { t } = useTranslation();
    const { requestStore, api } = useStores();
    const [linkData, setLinkData] = useState<TRequest[]>();
    const updateLinkData = () => {
      setLinkData(requestStore.getByPerson(personId));
    };
    useEffect(() => {
      updateLinkData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clickHandler = (person: number) => {
      api.showDialog('request', person);
    };

    const statuses = (status: string) => {
      return status.split(', ').map((i) => (
        <Badge key={i} bg='secondary' as='span' style={{ marginRight: 5 }}>
          {i}
        </Badge>
      ));
    };

    return (
      <>
        <ListGroup>
          <ListGroup.Item
            variant='success'
            key='head'
            as='li'
            className='d-flex justify-content-between align-items-start'
          >
            <div className='me-auto'>
              <div className='fw-bold'>
                <h6 style={{ marginBottom: 0 }}>
                  <Person />
                  &nbsp;{t('request.mainTitle')} [{linkData?.length}]
                </h6>
              </div>
            </div>
          </ListGroup.Item>
          {linkData?.length ? (
            linkData.map((item: TRequest) => {
              return (
                <ListGroup.Item
                  key={`${item.request_id}`}
                  as='li'
                  className='d-flex justify-content-between align-items-start'
                >
                  <Container fluid>
                    <Row>
                      <Col lg={12}>
                        <Row>
                          <Col lg={6}>
                            <div
                              style={{ display: 'flex', alignItems: 'flex-start' }}
                              role='link'
                              onClick={() => clickHandler(item.request_id || 0)}
                            >
                              <Person fontSize={20} />
                              <b style={{ marginLeft: 5 }}>{item.user_name}</b>
                            </div>
                          </Col>
                          <Col className='text-end'>
                            {statuses(item.request_status || '')}
                            {item.user_id === currentUser ? (
                              <Badge as='span' bg='success'>
                                UPROVED
                              </Badge>
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </ListGroup.Item>
              );
            })
          ) : (
            <ListGroup.Item
              key='empty'
              as='li'
              className='d-flex justify-content-between align-items-start'
            >
              &nbsp;
            </ListGroup.Item>
          )}
        </ListGroup>
      </>
    );
  }
);
export default RequestByPersonList;
