import React, { ReactElement } from 'react';
import { FloatingLabel } from 'react-bootstrap';
import { useStores } from 'services/useStore';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormElement: React.FC<any> = (data): ReactElement => {
  const { children, ...store } = data;
  const { api } = useStores();
  // eslint-disable-next-line jsx-a11y/label-has-for
  if (api.isBot()) return <label {...store}>{children}</label>;
  return <FloatingLabel {...store}>{children}</FloatingLabel>;
};

export default FormElement;
