import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { ArrowLeftCircle, ArrowRightCircle, Robot } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';
import simpleImage from '../../assets/img/simple.png';
import detailedImage from '../../assets/img/detailed.png';

type TExtendedData = {
  main: string;
  location: string;
  group: string;
  person: string;
  link: string;
  action: string;
};

export type TInitData = {
  bookFile: File | null;
  initText: string;
  type: 'simple' | 'advanced';
  texts?: TExtendedData;
};

export interface IProjectLARPInitDialog {
  show: boolean;
  initSpiner: boolean;
  initAction: (data: TInitData) => void;
}

enum Pages {
  FileUpload = 'fileUpload',
  Method = 'method',
  Simple = 'simple',
  Detail = 'detail',
}

const ProjectLARPInitDialog: React.FC<IProjectLARPInitDialog> = observer(
  ({ show = false, initAction, initSpiner }) => {
    const { t } = useTranslation();
    const { api } = useStores();
    const [showUploadSpiner, setShowUploadSpiner] = useState(false);
    const [bookFile, setFile] = useState<File | null>(null);
    const [initText, setInitText] = useState('');
    const [type, setType] = useState<'simple' | 'advanced'>('simple');
    const [initMainText, setInitMainText] = useState('');
    const [initLocationText, setInitLocationText] = useState('');
    const [initGroupText, setInitGroupText] = useState('');
    const [initPersonText, setInitPersonText] = useState('');
    const [initLinkText, setInitLinkText] = useState('');
    const [initActionText, setInitActionText] = useState('');
    const [page, setPage] = useState<Pages>(Pages.FileUpload);

    const onFileUpload = async () => {
      if (bookFile) {
        setShowUploadSpiner(true);
        const url = api.settings.url.server + api.settings.url.prototypeLARPVectorize;
        const formData = new FormData();
        formData.append('file', bookFile, bookFile.name);
        const response = await fetch(url, {
          method: 'POST',
          body: formData,
          headers: {
            'X-Token': api.getSession(),
          },
        });
        //setUploadedFile(bookFile.name);
        setShowUploadSpiner(false);
        console.log(response);
        setPage(Pages.Method);
      }
    };

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (files && files.length > 0) {
        setFile(files[0]);
      }
    };

    const sendAction = () => {
      if (type === 'simple') {
        initAction({ initText, bookFile, type });
      } else {
        initAction({
          initText,
          bookFile,
          type,
          texts: {
            main: initMainText,
            location: initLocationText,
            group: initGroupText,
            person: initPersonText,
            link: initLinkText,
            action: initActionText,
          },
        });
      }
    };

    const method = (genType: Pages) => {
      setType(genType === Pages.Detail ? 'advanced' : 'simple');
      setPage(genType);
    };

    useEffect(() => {
      setInitText(t('projectLARPAI.wizard.prototypeMainExample'));
      setInitMainText(t('projectLARPAI.wizard.prototypeMainRequest'));
      setInitLocationText(t('projectLARPAI.wizard.prototypeLocationRequest'));
      setInitGroupText(t('projectLARPAI.wizard.prototypeGroupRequest'));
      setInitPersonText(t('projectLARPAI.wizard.prototypePersonRequest'));
      setInitLinkText(t('projectLARPAI.wizard.prototypeLinkRequest'));
      setInitActionText(t('projectLARPAI.wizard.prototypeActionRequest'));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pageFileUpload = () => (
      <>
        <Row>
          <Col style={{ whiteSpace: 'break-spaces' }}>{t('projectLARPAI.wizard.welcomeText')}</Col>
        </Row>
        <Row>
          <Col className='mt-3' style={{ whiteSpace: 'break-spaces' }}>
            {t('projectLARPAI.wizard.prototypeSourceDescription')}
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            <Form.Control
              type='file'
              accept='.txt'
              size='sm'
              onChange={onFileChange}
              placeholder={t('projectLARPAI.source')}
              style={{ marginRight: 5 }}
            />
          </Col>
        </Row>
        <Row>
          <Col className='right-text mt-3' style={{ textAlign: 'center' }}>
            {showUploadSpiner ? (
              <Spinner variant='warning' />
            ) : (
              <Button size='sm' disabled={!bookFile} type='button' onClick={() => onFileUpload()}>
                {t('projectLARPAI.wizard.next')} <ArrowRightCircle />
              </Button>
            )}
          </Col>
        </Row>
      </>
    );
    const pageMethod = () => (
      <>
        <Row>
          <Col style={{ whiteSpace: 'break-spaces' }}>
            {t('projectLARPAI.wizard.methodDescription')}
          </Col>
        </Row>
        <Row>
          <Col xs lg={6}>
            <div className='method-page'>
              <img
                src={simpleImage}
                alt={t('projectLARPAI.wizard.simple')}
                style={{ width: 300, alignSelf: 'center', borderRadius: 5 }}
              />
              <h5 style={{ textAlign: 'center' }} className='mt-3'>
                {t('projectLARPAI.wizard.simple')}
              </h5>
              <p>{t('projectLARPAI.wizard.simpleDescription')}</p>
              <Button size='sm' type='button' onClick={() => method(Pages.Simple)}>
                {t('projectLARPAI.wizard.select')} <ArrowRightCircle />
              </Button>
            </div>
          </Col>
          <Col xs lg={6}>
            <div className='method-page'>
              <img
                src={detailedImage}
                alt={t('projectLARPAI.wizard.detail')}
                style={{ width: 300, alignSelf: 'center', borderRadius: 5 }}
              />
              <h5 style={{ textAlign: 'center' }} className='mt-3'>
                {t('projectLARPAI.wizard.detail')}
              </h5>
              <p>{t('projectLARPAI.wizard.detailDescription')}</p>
              <Button size='sm' type='button' onClick={() => method(Pages.Detail)}>
                {t('projectLARPAI.wizard.select')} <ArrowRightCircle />
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='right-text mt-3' style={{ textAlign: 'center' }}>
            <Button
              size='sm'
              type='button'
              variant='secondary'
              onClick={() => setPage(Pages.FileUpload)}
            >
              <ArrowLeftCircle /> {t('global.back')}
            </Button>
          </Col>
        </Row>
      </>
    );
    const pageSimple = () => (
      <>
        <Row>
          <Col style={{ whiteSpace: 'break-spaces' }}>
            {t('projectLARPAI.wizard.prototypeDescription')}
          </Col>
        </Row>
        <Row className='mt-1'>
          <Col>
            <Form.Control
              as='textarea'
              rows={5}
              size='lg'
              value={initText}
              onChange={(e) => setInitText(e.target.value)}
              style={{ height: 150 }}
            />
          </Col>
        </Row>
        <Row>
          <Col className='mt-3' style={{ whiteSpace: 'break-spaces' }}>
            <Alert>{t('projectLARPAI.wizard.prototypeEndDescription')}</Alert>
            <Alert variant='warning'>{t('projectLARPAI.wizard.timeDescription')}</Alert>
          </Col>
        </Row>
        <Row>
          {!initSpiner ? (
            <Col className='right-text mt-3' style={{ textAlign: 'center' }}>
              {' '}
              <Button
                size='sm'
                type='button'
                variant='secondary'
                onClick={() => setPage(Pages.Method)}
              >
                <ArrowLeftCircle /> {t('global.back')}
              </Button>
            </Col>
          ) : (
            ''
          )}
          <Col className='right-text mt-3' style={{ textAlign: 'center' }}>
            {initSpiner ? (
              <Spinner variant='warning' />
            ) : (
              <Button
                variant='warning'
                size='sm'
                type='button'
                disabled={!(bookFile && bookFile.name && initText)}
                onClick={() => sendAction()}
              >
                <Robot /> {t('projectLARPAI.wizard.doit')}
              </Button>
            )}
          </Col>
        </Row>
      </>
    );
    const pageDetail = () => (
      <>
        <Row>
          <Col style={{ whiteSpace: 'break-spaces' }}>
            {t('projectLARPAI.wizard.prototypeMainDescription')}
          </Col>
        </Row>
        <Row className='mt-1'>
          <Col>
            <Form.Control
              as='textarea'
              rows={5}
              size='lg'
              value={initMainText}
              onChange={(e) => setInitMainText(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col className='mt-3' style={{ whiteSpace: 'break-spaces' }}>
            {t('projectLARPAI.wizard.prototypeLocationDescription')}
          </Col>
        </Row>
        <Row className='mt-1'>
          <Col>
            <Form.Control
              as='textarea'
              rows={5}
              size='lg'
              value={initLocationText}
              onChange={(e) => setInitLocationText(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col className='mt-3' style={{ whiteSpace: 'break-spaces' }}>
            {t('projectLARPAI.wizard.prototypeGroupDescription')}
          </Col>
        </Row>
        <Row className='mt-1'>
          <Col>
            <Form.Control
              as='textarea'
              rows={5}
              size='lg'
              value={initGroupText}
              onChange={(e) => setInitGroupText(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col className='mt-3' style={{ whiteSpace: 'break-spaces' }}>
            {t('projectLARPAI.wizard.prototypePersonDescription')}
          </Col>
        </Row>
        <Row className='mt-1'>
          <Col>
            <Form.Control
              as='textarea'
              rows={5}
              size='lg'
              value={initPersonText}
              onChange={(e) => setInitPersonText(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col className='mt-3' style={{ whiteSpace: 'break-spaces' }}>
            {t('projectLARPAI.wizard.prototypeLinkDescription')}
          </Col>
        </Row>
        <Row className='mt-1'>
          <Col>
            <Form.Control
              as='textarea'
              rows={5}
              size='lg'
              value={initLinkText}
              onChange={(e) => setInitLinkText(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col className='mt-3' style={{ whiteSpace: 'break-spaces' }}>
            {t('projectLARPAI.wizard.prototypeActionDescription')}
          </Col>
        </Row>
        <Row className='mt-1'>
          <Col>
            <Form.Control
              as='textarea'
              rows={5}
              size='lg'
              value={initActionText}
              onChange={(e) => setInitActionText(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col className='mt-3' style={{ whiteSpace: 'break-spaces' }}>
            <Alert>{t('projectLARPAI.wizard.prototypeEndDescription')}</Alert>
            <Alert variant='warning'>{t('projectLARPAI.wizard.timeDescription')}</Alert>
          </Col>
        </Row>
        <Row>
          {!initSpiner ? (
            <Col className='right-text mt-3' style={{ textAlign: 'center' }}>
              {' '}
              <Button
                size='sm'
                type='button'
                variant='secondary'
                onClick={() => setPage(Pages.Method)}
              >
                <ArrowLeftCircle /> {t('global.back')}
              </Button>
            </Col>
          ) : (
            ''
          )}
          <Col className='right-text mt-3' style={{ textAlign: 'center' }}>
            {initSpiner ? (
              <Spinner variant='warning' />
            ) : (
              <Button
                variant='warning'
                size='sm'
                type='button'
                disabled={!(bookFile && bookFile.name && initText)}
                onClick={() => sendAction()}
              >
                <Robot /> {t('projectLARPAI.wizard.doit')}
              </Button>
            )}
          </Col>
        </Row>
      </>
    );
    const pages = {
      fileUpload: pageFileUpload,
      simple: pageSimple,
      detail: pageDetail,
      method: pageMethod,
    };
    return (
      <Modal show={show} size='lg' key='initAction' centered>
        <Modal.Header>{t('projectLARPAI.wizard.header')}</Modal.Header>
        <Modal.Body className='prototype-LARP-wizard'>{pages[page]()}</Modal.Body>
      </Modal>
    );
  }
);
export default ProjectLARPInitDialog;
