import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'services/useStore';
import { Modal, FloatingLabel, Form, Button, Row, Col, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styles from '../Heplers/style.module.scss';

export interface IGenerateImageDialog {
  type?: string;
  id?: number;
}

const GenerateImageDialog: React.FC<IGenerateImageDialog> = observer(
  ({ type = 'default', id = 0 }) => {
    const { t } = useTranslation();
    const { api, sessionStore } = useStores();
    const checkManager = (): boolean => api.isManager(sessionStore.session.user_project);
    const [text, setText] = useState<string | undefined>('');
    const [negative, setNegative] = useState<string | undefined>('');
    const [img, setImg] = useState<string>();
    const [showSpiner, setShowSpiner] = useState<boolean>(false);
    const [rand, setRand] = useState<string>('123');
    const [style, setStyle] = useState<string>('');
    const [stylesList, setStylesList] = useState<string[]>([]);
    const [styleDescription, setStyleDescription] = useState<string>(
      t('generation.imageStylesDescription.none')
    );
    const updateRandom = () => {
      setRand(moment().format('X'));
    };
    const getUrl = (imgName: string) =>
      `url('${api.settings.url.server}generation/${imgName}?${rand}')`;
    const save = async (event: React.FormEvent) => {
      event.preventDefault();
      event.stopPropagation();
      await api.useGenerateImage(type, id);
      if (sessionStore.appSettings.imageGenerateCallback)
        sessionStore.appSettings.imageGenerateCallback();
      updateRandom();
      setImg('');
      api.toggleImageGenerateDialog(false, '', () => {});
    };
    const changeStyle = (newStyle: string) => {
      setStyle(newStyle);
      setStyleDescription(t(`generation.imageStylesDescription.${newStyle}`));
    };
    const checkImage = async (imgId: string) => {
      console.log('Check', imgId);
      const result = await api.checkGenerateImage(imgId);
      if (!result) setTimeout(() => checkImage(imgId), 5000);
      else {
        setShowSpiner(false);
        setImg(imgId);
      }
    };
    const generateImg = () => {
      if (text) {
        setImg('');
        setShowSpiner(true);
        api.generateImage(text, type, id, style, negative || '').then((data) => {
          console.log(data.img);
          updateRandom();
          checkImage(data.img);
        });
      }
    };

    /*useEffect(() => {
      setText(sessionStore.appSettings.imageGenerateText);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionStore.appSettings.imageGenerateText]);*/
    useEffect(() => {
      updateRandom();
      const name = type + '_' + id;
      api.checkGenerateImage(name).then((result) => {
        if (result) setImg(name);
      });
      api.getStylesForGenerateImage().then((list) => setStylesList(list));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!checkManager) return <></>;
    return (
      <Modal
        show={sessionStore.appSettings.imageGenerateShow}
        size='lg'
        key='editModal'
        onHide={() => api.toggleImageGenerateDialog(false, '', () => {})}
      >
        <Modal.Header closeButton>{t('generation.imageHeader')}</Modal.Header>
        <Modal.Body>
          <Form onSubmit={save} action='#'>
            <Row className='justify-content-md-center'>
              <Col xs lg={12}>
                <FloatingLabel
                  controlId='title'
                  label={text ? '' : t('generation.prompt')}
                  className='mb-3'
                >
                  <Form.Control
                    as='textarea'
                    rows={5}
                    size='lg'
                    value={text || ''}
                    onChange={(e) => setText(e.target.value)}
                    placeholder={t('generation.prompt')}
                    style={{ height: '200px' }}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row className='justify-content-md-center'>
              <Col xs lg={12}>
                <FloatingLabel controlId='title' label={t('generation.negative')} className='mb-3'>
                  <Form.Control
                    type='text'
                    size='lg'
                    value={negative || ''}
                    onChange={(e) => setNegative(e.target.value)}
                    placeholder={t('generation.negative')}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row className='justify-content-md-center'>
              {img ? (
                <Col key='generated' md={7}>
                  <button
                    type='button'
                    onClick={() => api.showImagePopup(img, 'generation')}
                    style={{ border: 0, padding: 0 }}
                  >
                    <div
                      className={styles.imgGeneratedElement}
                      style={{ backgroundImage: getUrl(img), backgroundColor: '#00000030' }}
                    />
                  </button>
                </Col>
              ) : (
                <Col key='generated' md={7}>
                  <div
                    className={styles.imgGeneratedElement}
                    style={{ display: 'flex', whiteSpace: 'pre-wrap' }}
                  >
                    {' '}
                    {showSpiner ? (
                      <Spinner style={{ margin: 'auto', scale: 5 }} variant='warning' />
                    ) : (
                      t('generation.imageInstruction')
                    )}
                  </div>
                </Col>
              )}
              <Col key='button' xs={5}>
                <div key='selectStyle' className='d-grid' style={{ marginBottom: 20 }}>
                  <FloatingLabel controlId='type' label={t('generation.style')} className='mb-3'>
                    <Form.Select
                      aria-label={t('generation.style')}
                      required
                      size='lg'
                      value={style}
                      onChange={(e) => changeStyle(e.currentTarget.value)}
                    >
                      <option key='none' value='none'>
                        {t('generation.imageStyles.none')}
                      </option>
                      {stylesList.map((item) => (
                        <option key={item} value={item}>
                          {t(`generation.imageStyles.${item}`)}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                  <div>{styleDescription}</div>
                </div>
                <div key='buttons' className='d-grid'>
                  {!showSpiner ? (
                    <Button
                      key='generate'
                      variant='warning'
                      className='col-12'
                      size='lg'
                      type='button'
                      style={{ marginBottom: 20 }}
                      onClick={generateImg}
                    >
                      {t('generation.generate')}
                    </Button>
                  ) : (
                    ''
                  )}
                  {img ? (
                    <Button key='save' variant='primary' className='col-12' size='lg' type='submit'>
                      {t('global.save')}
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
);

export default GenerateImageDialog;
