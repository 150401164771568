import { action, makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';

export type TRelationLink = {
  source: number;
  target: number;
  type: string;
};

type TLinkGetSet = {
  type?: string;
  source?: number;
  target?: number;
};

class RelationsStore {
  data: TRelationLink[] = [];
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      data: observable,
      add: action,
      remove: action,
    });
  }

  add(newData: TRelationLink[], full?: boolean): void {
    if (full) this.data = [...newData];
    else this.data = [...this.data, ...newData];
  }

  get(setup: TLinkGetSet): TRelationLink[] {
    const { source, target, type } = setup;
    const result: TRelationLink[] = [];
    this.data.forEach((link: TRelationLink) => {
      if (source && link.source !== source) return;
      if (target && link.target !== target) return;
      if (type && link.type !== type) return;
      result.push(link);
    });
    return result;
  }

  remove(setup: TLinkGetSet): void {
    const { source, target, type } = setup;
    const copy = [...this.data];
    this.data.forEach((link: TRelationLink, index: number) => {
      if (source && link.source !== source) return;
      if (target && link.target !== target) return;
      if (type && link.type !== type) return;
      copy.splice(index, 1);
    });
    this.data = [...copy];
  }
}
export default RelationsStore;
