/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { TLocation } from 'stores/LocationsStore';
import { TGraphElement } from './LinksGraph';

type TElement = {
  element: TGraphElement;
  x: number;
  y: number;
};

export interface IPersonAvatar {
  api: any;
  element: TGraphElement;
  x: number;
  y: number;
  hide?: boolean;
  onMouseEnter?: (event: any) => void;
  onMouseLeave?: (event: any) => void;
}

const PersonAvatar: React.FC<IPersonAvatar> = ({
  api,
  element,
  x,
  y,
  hide = false,
  onMouseEnter,
  onMouseLeave,
}) => {
  const groupColor = '#005500';
  const circleStyle: React.CSSProperties = { stroke: groupColor, fill: '#fff', opacity: 1 };
  if (element.isVirtual) {
    circleStyle.strokeWidth = 3;
    circleStyle.stroke = '#000000';
  }
  return (
    <g
      key={element.id}
      data-type='person'
      data-id={element.id}
      className='userPoint'
      transform={'translate(' + x + ',' + y + ')'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ opacity: hide ? 0.3 : 1 }}
    >
      <circle cx='0' cy='0' r='26' style={circleStyle} />
      {element.image ? (
        <image
          id='mapImage'
          href={api.settings.url.server + 'img/' + element.image}
          x='-50'
          y='-50'
          style={{ opacity: 1, width: 100, height: 100 }}
          className='mapAvatar'
          clipPath='url(#circleAvatar)'
        />
      ) : (
        <image
          id='mapImage'
          href={api.settings.url.server + 'img/person.svg'}
          x='-25'
          y='-27'
          style={{ opacity: 1, width: 50, height: 50 }}
          className='mapAvatar'
        />
      )}
      {element.name && !hide ? (
        <text
          // eslint-disable-next-line no-unsafe-optional-chaining
          x={(element.name?.length / 2) * -5.5}
          y='35'
          style={{ fill: groupColor, fontSize: 10 }}
        >
          {element.name}
        </text>
      ) : (
        ''
      )}
    </g>
  );
};

const UserAvatar: React.FC<IPersonAvatar> = ({
  api,
  element,
  x,
  y,
  hide = false,
  onMouseEnter,
  onMouseLeave,
}) => {
  const groupColor = '#005500';
  const circleStyle: React.CSSProperties = { stroke: groupColor, fill: '#fff', opacity: 1 };
  return (
    <g
      key={element.id}
      data-type='user'
      data-id={element.id}
      className='userPoint'
      transform={'translate(' + x + ',' + y + ')'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ opacity: hide ? 0.3 : 1 }}
    >
      <circle cx='0' cy='0' r='26' style={circleStyle} />
      {element.image ? (
        <image
          id='mapImage'
          href={api.settings.url.server + 'img/' + element.image}
          x='-50'
          y='-50'
          style={{ opacity: 1, width: 100, height: 100 }}
          className='mapAvatar'
          clipPath='url(#circleAvatar)'
        />
      ) : (
        <image
          id='mapImage'
          href={api.settings.url.server + 'img/person.svg'}
          x='-25'
          y='-27'
          style={{ opacity: 1, width: 50, height: 50 }}
          className='mapAvatar'
        />
      )}
      {element.name && !hide ? (
        <text
          // eslint-disable-next-line no-unsafe-optional-chaining
          x={(element.name?.length / 2) * -5.5}
          y='35'
          style={{ fill: groupColor, fontSize: 10 }}
        >
          {element.name}
        </text>
      ) : (
        ''
      )}
    </g>
  );
};

export interface IGraphNode {
  api: any;
  source: TElement;
  target: TElement;
  hide?: boolean;
  graphType: string;
  type?: string;
  linkType?: string;
}

type TTypeColor = {
  color: string;
  width?: number;
  dash?: string;
  linecap?: 'round' | 'butt';
};

type TTypeColors = {
  [key: string]: TTypeColor;
};

const typeColors: TTypeColors = {
  blacklist: {
    color: '#ff0000',
    width: 2,
    dash: '10,3',
  },
  whitelist: {
    color: '#00b100',
    width: 2,
    dash: '10,5',
  },
  quest: {
    color: '#0000ff55',
    width: 1,
    dash: '10,5',
  },
  hide: {
    color: '#00000055',
    width: 1,
    dash: '10,5',
  },
  clone: {
    color: '#19875487',
    width: 5,
  },
  default: {
    color: '#00000055',
  },
};

const getStyle = (type?: string, linkType?: string): React.CSSProperties => {
  let settings = typeColors.default;
  if (type && typeColors[type]) settings = typeColors[type];
  else if (linkType && typeColors[linkType]) settings = typeColors[linkType];
  const css: React.CSSProperties = {
    stroke: settings.color,
    strokeWidth: settings?.width || 1,
  };
  if (settings.dash) css.strokeDasharray = settings.dash;
  if (settings.linecap) css.strokeLinecap = settings.linecap;
  return css;
};

const GraphNode: React.FC<IGraphNode> = ({
  api,
  source,
  target,
  graphType,
  hide = false,
  type,
  linkType,
}) => {
  if (hide)
    return source?.element && target?.element ? (
      <line
        key={source.element.id + '-' + target.element.id + '-' + type}
        x1={0}
        y1={0}
        x2={0}
        y2={0}
        stroke='#fff'
      />
    ) : (
      <></>
    );
  return source?.element && target?.element ? (
    <line
      key={source.element.id + '-' + target.element.id + '-' + type}
      data-type='link'
      data-source-id={source.element.id}
      data-target-id={target.element.id}
      className='userLink'
      x1={source.x}
      y1={source.y}
      x2={target.x}
      y2={target.y}
      style={getStyle(type, linkType)}
    />
  ) : (
    <></>
  );
};

export interface IShowLocation {
  api: any;
  location: TLocation;
  x: number;
  y: number;
  r: number;
}

const ShowLocation: React.FC<IShowLocation> = ({ api, location, x, y, r }) => {
  const groupColor = '#000000aa';
  // eslint-disable-next-line no-nested-ternary
  const img = location.location_images?.length
    ? location.location_images[0]
    : location.img_id
    ? `${location.img_id}.${location.img_mime}`
    : false;
  return (
    <g
      key={location.location_id}
      data-type='location'
      data-id={location.location_id}
      transform={'translate(' + x + ',' + y + ')'}
    >
      {img ? (
        <image
          id='mapImage'
          href={api.settings.url.server + 'img/' + img}
          x={String(-r * 2)}
          y={String(-r * 2)}
          style={{ opacity: 0.2, width: r * 4, height: r * 4 }}
          className='mapAvatar'
          clipPath='url(#circle350)'
        />
      ) : (
        <circle
          cx='0'
          cy='0'
          r={r + ''}
          style={{ fill: '#00ff00', stroke: '#000000', opacity: 0.1 }}
        />
      )}
      <text
        // eslint-disable-next-line no-unsafe-optional-chaining
        x={(location.location_name?.length / 2) * -25}
        y={String(r + 40)}
        style={{ fill: groupColor, fontSize: 50 }}
      >
        {location.location_name}
      </text>
    </g>
  );
};

export { PersonAvatar, UserAvatar, GraphNode, ShowLocation };
