/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from 'mobx-react';
import React from 'react';
import styles from './style.module.scss';

export interface IStatusesInCard {
  status: string | undefined;
}

const StatusesInCard: React.FC<IStatusesInCard> = observer(({ status }) => {
  const statuses = () => {
    if (!status) return '';
    const statusList = status ? status.split(',') : [];
    let className = '';
    switch (status.trim()) {
      case 'AI':
        className = styles.statusesCardAI;
        break;
      case 'ReadAI':
        className = styles.statusesCardAI;
        break;
      default:
        break;
    }
    return statusList.map((s: string) => (
      <div key={s} className={className}>
        {s.trim()}
      </div>
    ));
  };
  if (!status) return <></>;
  return <div className={styles.statusesCard}>{statuses()}</div>;
});
export default StatusesInCard;
