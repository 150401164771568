/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import { ROUTES } from 'components/App/App';
import CarouselList from 'components/Heplers/CarouselList';
import LocationShow from 'components/Heplers/LocationShow';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Alert, Badge, Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { TRequest } from 'stores/RequestsStore';
import RequestStatusBadger, { badgeStyle } from 'components/Heplers/RequestStatusBadger';
import { useStores } from '../../services/useStore';

export interface IBotRequestInfo {
  requestId: number | undefined;
  imageHeight?: number;
  isWindow: boolean;
}

const BotRequestInfo: React.FC<IBotRequestInfo> = observer(
  ({ requestId, isWindow, imageHeight = 300 }) => {
    const { t } = useTranslation();
    const history = useNavigate();
    const { api, sessionStore, personStore, requestStore } = useStores();
    api.noLogin();
    const [request, setRequest] = useState<TRequest>();
    const [imgList, setImgList] = useState<string[]>([]);
    const [isInvite, setIsInvite] = useState(false);
    const dataSource = () => {
      return new Promise((resolve, reject) => {
        if (!requestId) {
          reject();
          return;
        }
        const data: TRequest = requestStore.get(requestId);
        if (data?.request_id) resolve(data);
        else {
          api.getRequests(requestId).then(resolve);
        }
      });
    };
    useEffect(() => {
      if (!isWindow) sessionStore.tgHeader(t('request.invite2'));
      return () => setIsInvite(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (requestId) {
        dataSource().then(async (data) => {
          const lo = data as TRequest;
          if (lo.project_id) await api.updateProjectData(lo.project_id);
          setRequest(lo);
          if (lo.request_type === 'invite') setIsInvite(true);
          else setIsInvite(false);
          const images: string[] = [];
          if (lo.img_id) images.push(`${lo.img_id}.${lo.img_mime}`);
          if (lo.person_image) images.push(lo.person_image);
          setImgList(images);
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestId]);
    if (!request) return <div>{t('global.loading')}</div>;
    const isMy = request.user_id === sessionStore.session.user_id;
    const rejectInvite = async () => {
      if (!isInvite || sessionStore.session.user_id !== request.user_id || !requestId) return;
      await api.inviteFlow(requestId, 'reject');
      api.telegram.tg.close();
    };
    const acceptInvite = async () => {
      if (!isInvite || sessionStore.session.user_id !== request.user_id || !requestId) return;
      await api.inviteFlow(requestId, 'success');
      api.telegram.tg.close();
    };
    const statusName = (data: TRequest) => {
      let status = t('request.requestWork');
      if (data.request_flow === 'reject') status = t('request.rejected');
      if (data.request_flow === 'autoReject') status = t('request.rejected2');
      if (data.person_user === data.user_id) status = t('request.accepted');
      if (data.person_user === data.user_id)
        status = t(data.request_type === 'invite' ? 'request.accepted2' : 'request.accepted');
      return status;
    };

    const deleteItem = () => {
      api
        .deleteItem('request', requestId, t('global.deletePrompt'))
        .then((result) => {
          requestStore.remove(requestId || 0);
          api.closeDialog();
        })
        .catch((e) => {});
    };
    const editItem = () => {
      api.closeDialog();
      history(ROUTES.requestEdit + '/' + requestId);
    };
    const personClick = (personId: number | undefined) => {
      if (personId) api.showDialog('person', personId);
    };

    if (!isMy) {
      return (
        <Alert variant='warning'>
          <p>{t('global.empty')}</p>
          <hr />
          <div className='d-flex justify-content-end'>
            <Link to={ROUTES.requests} className='btn btn-primary'>
              {t('request.mainTitle')}
            </Link>
          </div>
        </Alert>
      );
    }
    return (
      <Container fluid className='verticalPadding greenForm' style={{ paddingBottom: 24 }}>
        <Row className='justify-content-md-center'>
          <Col xs lg={10}>
            <div className='mb-2'>
              <b>{t('project.project')}:</b>{' '}
              <Link to={ROUTES.projects + '/' + request.project_id}>{request.project_name}</Link>
            </div>
            {imgList.length ? <CarouselList imgList={imgList} height={imageHeight} /> : ''}
            <div className='mb-2'>
              {request.location_id ? (
                <LocationShow locationId={request.location_id} linkInDialog />
              ) : (
                <LocationShow
                  locationId={
                    request.person_id ? personStore.get(request.person_id)?.location_id : 0
                  }
                  linkInDialog
                />
              )}
            </div>
            {request.person_id ? (
              <div style={{ marginBottom: 14, marginTop: 8 }}>
                <h3>
                  <b role='link' onClick={() => personClick(request.person_id)}>
                    {request.person_name}
                  </b>
                </h3>
                {isWindow ? <RequestStatusBadger data={request} /> : ''}
                <div className='mb-3'>{request.person_title}</div>
                {isInvite ? <p className='mb-3'>{request.request_description}</p> : ''}
              </div>
            ) : (
              <>
                <h3>{request.request_name}</h3>
                {isWindow ? <RequestStatusBadger data={request} /> : ''}
                <div className='mb-3'>
                  <b>{request.request_title}</b>
                </div>
                <div>
                  {request.request_age || ''} {request.request_job || ''}{' '}
                  {request.person_title || ''}
                </div>
                {request.request_description ? <div>{request.request_description}</div> : ''}
              </>
            )}
            {request.request_wantplay ? (
              <div className='mt-3'>
                <b>{t('requestForm.wantPlay')}:</b> {request.request_wantplay}
              </div>
            ) : (
              ''
            )}
            {request.request_dontplay ? (
              <div className='mt-3'>
                <b>{t('requestForm.dontPlay')}:</b> {request.request_dontplay}
              </div>
            ) : (
              ''
            )}
            {isInvite ? (
              <>
                <hr />
                <p>{request.request_description}</p>
              </>
            ) : (
              ''
            )}
            <div className='mt-3'>&nbsp;</div>
          </Col>
        </Row>
        {isInvite &&
        sessionStore.session.user_id === request.user_id &&
        request.request_flow === 'init' ? (
          <Row className='inviteButtons'>
            <Col style={{ display: 'flex', justifyContent: 'space-around' }}>
              <Button variant='success' style={{ margin: 5 }} onClick={acceptInvite}>
                {t('request.acceptInvite')}
              </Button>
              <Button variant='danger' style={{ margin: 5 }} onClick={rejectInvite}>
                {t('request.rejectInvite')}
              </Button>
            </Col>
          </Row>
        ) : !isInvite && sessionStore.session.user_id === request.user_id ? (
          <Row className='inviteButtons'>
            <Col style={{ display: 'flex', justifyContent: 'space-around' }}>
              <Button variant='warning' style={{ margin: 5 }} onClick={editItem}>
                {t('global.edit')}
              </Button>
              <Button variant='danger' style={{ margin: 5 }} onClick={deleteItem}>
                {t('global.delete')}
              </Button>
            </Col>
          </Row>
        ) : (
          ''
        )}
        {isInvite && request.request_flow !== 'init' ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Badge bg={badgeStyle(request.request_flow || '')}>{statusName(request)}</Badge>
          </div>
        ) : (
          ''
        )}
      </Container>
    );
  }
);
export default BotRequestInfo;
